"use strict";

var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

var _a, _b;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MarketToQuoteSources = exports.realTimeEntitlementsExchangeCodes = exports.delayedEntitlementsExchangeCodes = exports.entitlementsExchangeCodes = exports.FilterType = exports.delayedSourcesForRTS = exports.delayedSources = exports.realTimeSources = exports.providerSourceNameToQuoteSourceMap = exports.quoteSourcesToDisplayNames = exports.rtsQuoteSourcesForExchangeCode = exports.exchangeCodeToQuoteSources = exports.quoteSourceToExchangeCodeMap = exports.quoteSourceToProviderMap = exports.quoteSourceToMarketMap = exports.QuoteSources = exports.Provider = void 0;

var assets_1 = require("./assets");

var Provider;

(function (Provider) {
  Provider["Refinitiv"] = "Refinitiv";
  Provider["IEX"] = "IEX";
  Provider["CBOE"] = "CBOE";
  Provider["COIN_GECKO"] = "COIN_GECKO";
})(Provider = exports.Provider || (exports.Provider = {}));

var QuoteSources;

(function (QuoteSources) {
  QuoteSources["CBOE_ONE"] = "CBOE_ONE";
  QuoteSources["CBOE_ONE_DELAYED"] = "CBOE_ONE_DELAYED";
  QuoteSources["EDGX"] = "EDGX";
  QuoteSources["BATS"] = "BATS";
  QuoteSources["TOPS"] = "TOPS";
  QuoteSources["TSX"] = "TSX";
  QuoteSources["OTC"] = "OTC";
  QuoteSources["COIN_GECKO"] = "COIN_GECKO";
  QuoteSources["RKD_RATIOS_REPORT_SAO"] = "RKD_RATIOS_REPORT_SAO";
})(QuoteSources = exports.QuoteSources || (exports.QuoteSources = {}));

exports.quoteSourceToMarketMap = {
  BATS: 'USA',
  TSX: 'CAN',
  TOPS: 'USA',
  OTC: 'OTC',
  CBOE_ONE: 'USA',
  CBOE_ONE_DELAYED: 'USA',
  EDGX: 'USA',
  COIN_GECKO: 'CRYPTO',
  RKD_RATIOS_REPORT_SAO: 'BRA'
};
exports.quoteSourceToProviderMap = {
  BATS: Provider.Refinitiv,
  TOPS: Provider.IEX,
  TSX: Provider.Refinitiv,
  OTC: Provider.IEX,
  EDGX: Provider.CBOE,
  CBOE_ONE: Provider.CBOE,
  CBOE_ONE_DELAYED: Provider.CBOE,
  COIN_GECKO: Provider.COIN_GECKO,
  RKD_RATIOS_REPORT_SAO: Provider.Refinitiv
};
exports.quoteSourceToExchangeCodeMap = {
  TSX: assets_1.TrExchangeCode.tor,
  BATS: assets_1.TrExchangeCode.bzx,
  OTC: assets_1.TrExchangeCode.pnk,
  TOPS: assets_1.TrExchangeCode.bzx,
  EDGX: assets_1.TrExchangeCode.bzx,
  CBOE_ONE: assets_1.TrExchangeCode.bzx,
  CBOE_ONE_DELAYED: assets_1.TrExchangeCode.bzx,
  COIN_GECKO: assets_1.TrExchangeCode.crypto,
  RKD_RATIOS_REPORT_SAO: assets_1.TrExchangeCode.sao
};
exports.exchangeCodeToQuoteSources = (_a = {}, _a[assets_1.TrExchangeCode.tor] = [QuoteSources.TSX], _a[assets_1.TrExchangeCode.bzx] = [QuoteSources.BATS, QuoteSources.EDGX, QuoteSources.CBOE_ONE, QuoteSources.CBOE_ONE_DELAYED], _a[assets_1.TrExchangeCode.pnk] = [QuoteSources.OTC], _a[assets_1.TrExchangeCode.crypto] = [QuoteSources.COIN_GECKO], _a);

var rtsQuoteSourcesForExchangeCode = function rtsQuoteSourcesForExchangeCode(exchangeCode) {
  var _a;

  var sources = (_a = exports.exchangeCodeToQuoteSources[exchangeCode]) !== null && _a !== void 0 ? _a : [];
  return sources.filter(function (source) {
    return source !== QuoteSources.CBOE_ONE_DELAYED;
  });
};

exports.rtsQuoteSourcesForExchangeCode = rtsQuoteSourcesForExchangeCode;
exports.quoteSourcesToDisplayNames = {
  BATS: 'BATS',
  TOPS: 'IEX TOPS',
  TSX: 'TSX',
  OTC: 'OTC',
  CBOE_ONE: 'CBOE One',
  EDGX: 'EDGX',
  CBOE_ONE_DELAYED: 'CBOE One (Delayed)',
  COIN_GECKO: 'CoinGecko',
  RKD_RATIOS_REPORT_SAO: 'Refinitiv Daily Prices (Brazil)'
};
/**
 * This map is only for IEX and Refinitiv data.
 * IEX ingestion will set a market (US or OTC) and
 * Refinitiv ingestion gives us the ticker suffix (BAT or TO).
 */

exports.providerSourceNameToQuoteSourceMap = {
  BAT: QuoteSources.BATS,
  TO: QuoteSources.TSX,
  US: QuoteSources.TOPS,
  OTC: QuoteSources.OTC
};
exports.realTimeSources = [QuoteSources.BATS, QuoteSources.CBOE_ONE, QuoteSources.EDGX, QuoteSources.OTC, QuoteSources.TOPS, QuoteSources.TSX, QuoteSources.COIN_GECKO, QuoteSources.RKD_RATIOS_REPORT_SAO]; // Add delayed feeds to these arrays if we add any new ones

exports.delayedSources = [QuoteSources.CBOE_ONE_DELAYED]; // For delayed intraday data, we use the CBOE_ONE feed, not CBOE_ONE_DELAYED.
// We exclude the last 15-minutes of the real-time feed to simulate a delayed one

exports.delayedSourcesForRTS = (_b = {}, _b[QuoteSources.CBOE_ONE_DELAYED] = QuoteSources.CBOE_ONE, _b);
var FilterType;

(function (FilterType) {
  FilterType["delayed"] = "delayed";
  FilterType["realTime"] = "realTime";
})(FilterType = exports.FilterType || (exports.FilterType = {}));
/**
 * @param filter The filter to apply to the sources, i.e. realTime or delayed, if not provided, then use both realTime and delayed sources.
 * @returns An array of entitled trExchangeCodes for the provided filter.
 */


var getEntitlementsExchangeCodes = function getEntitlementsExchangeCodes(filter) {
  var entitledExchangeCodes = new Set();

  var getExchangeCodesFromSources = function getExchangeCodesFromSources(sources) {
    sources.forEach(function (source) {
      entitledExchangeCodes.add(exports.quoteSourceToExchangeCodeMap[source]);
    });
  };

  if (filter) {
    switch (filter) {
      case FilterType.delayed:
        getExchangeCodesFromSources(exports.delayedSources);
        break;

      case FilterType.realTime:
        getExchangeCodesFromSources(exports.realTimeSources);
        break;
    }
  } else {
    // Use all sources
    getExchangeCodesFromSources(__spreadArray(__spreadArray([], exports.realTimeSources, true), exports.delayedSources, true));
  }

  return Array.from(entitledExchangeCodes);
};

exports.entitlementsExchangeCodes = getEntitlementsExchangeCodes();
exports.delayedEntitlementsExchangeCodes = getEntitlementsExchangeCodes(FilterType.delayed);
exports.realTimeEntitlementsExchangeCodes = getEntitlementsExchangeCodes(FilterType.realTime);
exports.MarketToQuoteSources = {
  BZX: QuoteSources.CBOE_ONE_DELAYED,
  TOR: QuoteSources.TSX,
  PNK: QuoteSources.OTC,
  SAO: QuoteSources.RKD_RATIOS_REPORT_SAO
};