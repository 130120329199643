import styled from 'styled-components';
import React from 'react';
import * as _ from 'lodash';

export default function Footer() {
  return (
    <FooterWrapper>
      <IFrameFooter src='https://go.atom.finance/footer' />
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div<{}>`
  height: fit-content:
  width: 100%;
`;

const IFrameFooter = styled.iframe<{}>`
  width: 100%;
  height: 465px;
  border: 0;

  @media (max-width: 890px) {
    height: 728px;
  }

  @media (max-width: 1200px) {
    height: 552.5px;
  }
`;
