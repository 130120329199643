import React from 'react';
import styled, { css } from 'styled-components';
import AtomIcon from './AtomIcon';
import {
  CloseButtonContainer,
  HeaderText,
  ModalHeaderContainer,
} from './styledComponents';

const ToggleSmallCircle = styled.div<{ disabled?: boolean }>`
  border-radius: 100%;
  height: 1em;
  width: 1em;
  margin: 3px;
  background-color: var(
    --${props => (props.disabled ? 'atom-dark-contrast' : 'atom-brand-1')}
  );
  border: solid
    var(--${props => (props.disabled ? 'atom-dark-contrast' : 'atom-brand-1')});
`;

const ToggleButton = styled.div<{ disabled?: boolean; locked?: boolean }>`
  background-color: var(
    --${props => (props.disabled ? 'atom-brand-4' : 'atom-dark-green')}
  );
  margin-right: 1em;
  border-radius: 1em;
  height: fit-content;
  width: 3em;
  align-items: right;
  justify-content: flex-${props => (props.disabled ? 'start' : 'end')};
  cursor: ${props => (props.locked ? 'default' : 'pointer')};
  display: flex;
`;

export const ModalHeader = ({ text, onClose }) => {
  return (
    <ModalHeaderContainer>
      <CloseButtonContainer onClick={() => onClose()}>
        <AtomIcon
          size={35}
          icon={'close'}
          iconCss={
            css`
              fill: var(--atom-green);
            ` as any
          }
          onClick={close}
        />
      </CloseButtonContainer>
      <HeaderText>{text}</HeaderText>
    </ModalHeaderContainer>
  );
};

export const ToggleSwitch = ({ enabled, locked, handleToggle }) => {
  return (
    <ToggleButton disabled={!enabled} locked={locked} onClick={handleToggle}>
      <ToggleSmallCircle disabled={!enabled} />
    </ToggleButton>
  );
};
