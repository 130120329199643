import styled from 'styled-components';
import { GET_PARTNER_LIST, SET_PARTNER } from '../common/queries';
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import AtomIcon from '../common/AtomIcon';
import { AgGridPagination } from '../common/TablePagination';
import { ceil } from 'lodash';
import { responsePathAsArray } from 'graphql';

const PartnerView = (stringSearch: { searchString: string | null }) => {
  const [setPartner, setPartnerResponse] = useMutation(SET_PARTNER);
  const [value, setValue] = useState('RenderedPartnerList');
  const success = setPartnerResponse?.data?.setPartner?.success;

  if (success) {
    window.location.href = '/';
  }
  return (
    <OuterContainer>
      <HeaderContainer>
        <Heading>Partners</Heading>
        <Icons>
          <AtomIcon
            style={{
              marginRight: '1rem',
              stroke:
                'RenderedPartnerList' === value
                  ? 'var(--atom-green)'
                  : 'var(--atom-dark-brand-6)',
            }}
            size={20}
            icon='list-view'
            onClick={() => setValue('RenderedPartnerList')}
          />
          <AtomIcon
            style={{
              stroke:
                'RenderedPartnerGrid' === value
                  ? 'var(--atom-green)'
                  : 'var(--atom-dark-brand-6)',
            }}
            size={20}
            icon='grid-view'
            onClick={() => setValue('RenderedPartnerGrid')}
          />
        </Icons>
      </HeaderContainer>

      <RenderedPartnerList
        setPartner={setPartner}
        isGrid={value === 'RenderedPartnerGrid'}
        searchPartner={stringSearch.searchString}
      />
    </OuterContainer>
  );
};

const RenderedPartnerList = props => {
  const pageSize = 100;
  const [currentPage, setCurrentPage] = useState(0);
  const searchTerm = props.searchPartner;
  const { data: partnerListResponse, refetch } = useQuery(GET_PARTNER_LIST, {
    variables: { pageSize, currentPage, searchTerm },
  });
  const partnerList = partnerListResponse?.partnerList?.partners ?? [];
  const totalCount = partnerListResponse?.partnerList?.totalCount ?? 0;
  useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);

  const maxPages = ceil(totalCount / pageSize);

  const paginationGetPageSize = () => pageSize;

  const paginationGetCurrentPage = () => currentPage;

  const paginationGetRowCount = () => totalCount;

  const paginationGetTotalPages = () => maxPages;

  const paginationGoToPreviousPage = () => {
    if (currentPage > 0) {
      document.documentElement.scrollTop = 0;
      setCurrentPage(currentPage - 1);
    }
  };

  const paginationGoToNextPage = () => {
    if (currentPage < maxPages - 1) {
      document.documentElement.scrollTop = 0;
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  return (
    <>
      {props.isGrid === true ? (
        <GridView
          {...props}
          partnerList={partnerList}
          setPartner={props.setPartner}
        />
      ) : (
        <ListView
          {...props}
          partnerList={partnerList}
          setPartner={props.setPartner}
        />
      )}
      <AgGridPagination
        data={partnerList}
        containerStyle={{ padding: '0.5rem 1rem .5rem .5rem' }}
        showPageSize
        paginationKey={'partnerList'}
        paginationSetPageSize={null}
        paginationGetPageSize={paginationGetPageSize}
        paginationGetCurrentPage={paginationGetCurrentPage}
        paginationGetRowCount={paginationGetRowCount}
        paginationGetTotalPages={paginationGetTotalPages}
        paginationGoToPreviousPage={paginationGoToPreviousPage}
        paginationGoToNextPage={paginationGoToNextPage}
      />
    </>
  );
};

const ListView = ({ partnerList, setPartner }) => {
  return (
    <div>
      {partnerList?.map(partner => {
        const { id, name, icon } = partner;
        return (
          <GridContainer
            key={id}
            onClick={() => setPartner({ variables: { id } })}
          >
            <Icon src={`${icon}`} />
            <h2 style={{ margin: 0, cursor: 'pointer' }}>{`${name}`}</h2>
            <div style={{ display: 'flex' }}>
              <AtomIcon size={20} icon={'web'} />
              <AtomIcon size={20} icon={'mobile'} />
            </div>
          </GridContainer>
        );
      })}
    </div>
  );
};

const GridView = ({ partnerList, setPartner }) => {
  return (
    <Wrapper>
      {partnerList?.map(partner => {
        const { id, name, icon } = partner;
        return (
          <Container key={id} onClick={() => setPartner({ variables: { id } })}>
            <Icon src={`${icon}`} />
            <div style={{ display: 'flex' }}>
              <AtomIcon size={20} icon={'web'} />
              <AtomIcon size={20} icon={'mobile'} />
            </div>
          </Container>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: stretch;
  grid-gap: 4rem;
`;

const Container = styled.div`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 4fr 1fr;
  background: var(--atom-dark-brand-4);
  border-radius: 5px;
  align-items: center;
  justify-items: start;
  padding: 2rem 3rem;
  cursor: pointer;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 7fr 1fr;
  border-top: solid var(--atom-dark-brand-7) 0.3px;
  padding: 1rem 0;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  flex-direction: row;
`;

const OuterContainer = styled.div`
  box-sizing: border-box;
  padding: 2vw 6vw 2vw 4vw;
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  borderradius: 5px;
`;

const Icons = styled.div`
  align-self: flex-end;
`;

const Heading = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

export default PartnerView;
