import hubspot from './hubspot';
import { PropertyLibrary as PL } from './propertyLibrary';

const eventColor = {
  PROD: '#ff7a00',
  STAGING: '#40c4ff',
  DEV: '#ff4495',
  LOCAL: '#52e3c2',
}[window.atomEnv];

type TrackingOptions = {shouldTrackWithHubspot: boolean };

const _track = async (
  eventType: string, 
  properties: any,
  options: TrackingOptions = {shouldTrackWithHubspot: true },
  ) => {
  const augmentedProperties = {
    ...properties,
  };
  window.analytics.track(eventType, augmentedProperties);
  console.info(
    `%cAtom Event Tracked: %c${eventType}`,
    `color:${eventColor}; font-weight:600`,
    'font-family: monospace',
  );

  if (options.shouldTrackWithHubspot) {
    hubspot.track(eventType, properties);
  }
  
  console.table(augmentedProperties);
};

const trackingFns = {
  signupStartedClient: (properties: PL.signupTypeProperties) =>
    _track('SignupStartedClient', properties),
  accountCreatedClient: (properties: PL.signupTypeProperties) =>
    _track('AccountCreatedClient', properties),
};

const atomlytics = {
  ...trackingFns,
};

export default atomlytics;
