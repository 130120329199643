import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import AddPartnerModal from '../components/AddPartnerModal';
import {
  CloseButtonContainer,
  GreenClickableText,
  GreenClickableButton,
  InputContainer,
  PaddedGreenClickableButton,
  ResponseText,
  Spinner,
  Input,
} from '../common/styledComponents';
import PartnerView from './PartnerListViews';
import Modal from '../components/configurationModals/Modal';
import {
  AddMemberContainer,
  AddMemberContent,
  FlexContainer,
  MainTextContainer,
  MemberInput,
  MobileConfiguration,
  TextContainer,
} from '../components/configurationModals/AddMemberModal';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { INVITE_ADMIN, LOGOUT } from '../common/queries';
import AtomIcon from '../common/AtomIcon';
import { AdminContext, defaultAdminContext } from '../common/AdminContext';

export const GrantAdminAccessModal = ({ closeModal }) => {
  const [grantAccessEmail, setGrantAccessEmail] = useState('');
  const [inviteMutation, inviteResp] = useMutation(INVITE_ADMIN);
  const submitInvite = () => {
    inviteMutation({
      variables: { email: grantAccessEmail, grantAtomAdminAccess: true },
    });
  };

  const { loading, data } = inviteResp;
  const success = data?.inviteAdmin?.success;
  const message = data?.inviteAdmin?.message;
  const disableSubmit = !grantAccessEmail?.length || loading;

  return (
    <AddMemberContainer>
      <AddMemberContent
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <FlexContainer>
          <CloseButtonContainer onClick={() => closeModal()}>
            <AtomIcon
              size={35}
              icon={'close'}
              iconCss={
                css`
                  fill: var(--atom-green);
                ` as any
              }
              onClick={close}
            />
          </CloseButtonContainer>
        </FlexContainer>
        <MainTextContainer>
          <TextContainer>Grant B2B access to Atom employee</TextContainer>
          <InputContainer>
            <MemberInput
              onChange={e => setGrantAccessEmail(e.target.value)}
              placeholder='Enter email address'
              value={grantAccessEmail}
            />
          </InputContainer>
          <ResponseText success={success}>{message}</ResponseText>
          <InputContainer>
            <PaddedGreenClickableButton
              disabled={disableSubmit}
              onClick={() => {
                if (!disableSubmit) submitInvite();
              }}
            >
              {loading ? <Spinner smaller={true} /> : `GRANT ACCESS`}
            </PaddedGreenClickableButton>
          </InputContainer>
        </MainTextContainer>
      </AddMemberContent>
    </AddMemberContainer>
  );
};

const ClientList = () => {
  const [logout, { loading }] = useLazyQuery(LOGOUT);
  const history = useHistory();
  const [showAddPartnerModal, setAddPartnerModal] = useState(false);
  const [showGrantAccessModal, setGrantAccessModal] = useState(false);
  const { updateAdminContext, partner, admin } = useContext(AdminContext);
  let [searchInputText, setSearchInputText] = useState<string>('');
  const debounced = useDebounce(searchInputText, 400);

  const onLogout = async () => {
    await logout();
    updateAdminContext(defaultAdminContext);
    history.push('/signin');
  };

  const handleSearchKeyDown = e => {
    searchInputText = e.target.value;
  };
  return (
    <div>
      {/* <MainHeader /> */}
      <Header>
        <AddPartnerButton>
          <GreenClickableText
            style={{ fontSize: '15px', paddingLeft: '2rem' }}
            onClick={() => setAddPartnerModal(true)}
          >
            {`+ ADD PARTNER`}
          </GreenClickableText>
        </AddPartnerButton>
        <AtomIcon
          style={{
            marginBottom: '10px',
            position: 'relative',
            left: '28px',
          }}
          icon='search'
          size={22}
          backgroundCss={`
              fill: none;
              `}
        />
        <SearchBar
          placeholder='Search client list'
          onKeyDown={e => handleSearchKeyDown(e)}
          onChange={e => setSearchInputText(e.target.value)}
          value={searchInputText}
        />
      </Header>
      {showAddPartnerModal && (
        <AddPartnerModal
          closeModal={() => setAddPartnerModal(false)}
        ></AddPartnerModal>
      )}

      <PartnerView searchString={debounced} />
      <div>
        <GreenClickableText
          style={{ fontSize: '15px', paddingLeft: '2rem' }}
          onClick={() => setGrantAccessModal(true)}
        >
          {`+ Grant B2B access to Atom employee`}
        </GreenClickableText>
        {showGrantAccessModal && (
          <Modal closeModal={() => setGrantAccessModal(false)}>
            <GrantAdminAccessModal
              closeModal={() => setGrantAccessModal(false)}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export const useDebounce = (value, delay) => {
  const [x, setX] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setX(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return x;
};

const AddPartnerButton = styled.div`
  display: flex;
  padding: 0.5em;
  width: fit-content;
  flex: 1;
  padding-top: 1em;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SearchBar = styled.input<{ disabled?: boolean }>`
  margin-bottom: 11px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: var(--atom-dark-brand-5);
  color: white;
  font-size: 16px;
  padding: 20px 22px;
  border-radius: 3px;
  border: hidden;
  box-sizing: border-box;
  max-width: 800px;
  justify-content: center;
  width: 40em;
  height: 2em;
  margin-right: 100px;
  padding-left: 30px;
`;

export default ClientList;
