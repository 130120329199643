"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScenarioTransactionType = exports.CUR_USD = exports.Currency = exports.OrderOption = exports.SortOption = exports.getPortfolioMarketStatus = exports.PortfolioMarketStatus = exports.HoldingType = void 0;

var luxonUtils_1 = require("../datetime/luxonUtils");

var HoldingType;

(function (HoldingType) {
  HoldingType["CASH"] = "CASH";
  HoldingType["CRYPTO"] = "CRYPTO";
  HoldingType["DERIVATIVE"] = "DERIVATIVE";
  HoldingType["EQUITY"] = "EQUITY";
  HoldingType["ETF"] = "ETF";
  HoldingType["FIXED_INCOME"] = "FIXED_INCOME";
  HoldingType["LOAN"] = "LOAN";
  HoldingType["MUTUAL_FUND"] = "MUTUAL_FUND";
  HoldingType["OTHER"] = "OTHER";
})(HoldingType = exports.HoldingType || (exports.HoldingType = {}));

var PortfolioMarketStatus;

(function (PortfolioMarketStatus) {
  PortfolioMarketStatus["CLOSED"] = "CLOSED";
  PortfolioMarketStatus["BEFORE"] = "BEFORE";
  PortfolioMarketStatus["AFTER"] = "AFTER";
  PortfolioMarketStatus["OPEN"] = "OPEN";
})(PortfolioMarketStatus = exports.PortfolioMarketStatus || (exports.PortfolioMarketStatus = {}));

var getPortfolioMarketStatus = function getPortfolioMarketStatus(exchange, luxDate) {
  if (exchange === void 0) {
    exchange = 'BZX';
  }

  if (luxDate === void 0) {
    luxDate = null;
  }

  if (exchange === 'SAO') {
    var saoMarketStatus = (0, luxonUtils_1.getMarketStatus)('SAO');
    if (saoMarketStatus === luxonUtils_1.MarketStatus.Closed) return PortfolioMarketStatus.CLOSED;
    if (saoMarketStatus === luxonUtils_1.MarketStatus.PreMarket) return PortfolioMarketStatus.BEFORE;
    if (saoMarketStatus === luxonUtils_1.MarketStatus.Open) return PortfolioMarketStatus.OPEN;
    return PortfolioMarketStatus.AFTER;
  } // default to the NYSE


  if (!luxDate) luxDate = (0, luxonUtils_1.luxEST)();
  if ((0, luxonUtils_1.isLuxUSMarketClosed)(luxDate)) return PortfolioMarketStatus.CLOSED;
  var marketOpen = luxDate.set({
    hour: 9,
    minute: 30
  });
  if (luxDate < marketOpen) return PortfolioMarketStatus.BEFORE; // we use 4:15 for portfolio because that's when prices would have settled

  var marketClose = luxDate.set({
    hour: 16,
    minute: 15
  });
  if (luxDate < marketClose) return PortfolioMarketStatus.OPEN;
  return PortfolioMarketStatus.AFTER;
};

exports.getPortfolioMarketStatus = getPortfolioMarketStatus;
var SortOption;

(function (SortOption) {
  SortOption["CREATED_AT"] = "createdAt";
})(SortOption = exports.SortOption || (exports.SortOption = {}));

var OrderOption;

(function (OrderOption) {
  OrderOption["asc"] = "ASC";
  OrderOption["desc"] = "DESC";
})(OrderOption = exports.OrderOption || (exports.OrderOption = {}));

var Currency;

(function (Currency) {
  Currency["USD"] = "USD";
})(Currency = exports.Currency || (exports.Currency = {}));

exports.CUR_USD = 'CUR:USD';
var ScenarioTransactionType;

(function (ScenarioTransactionType) {
  ScenarioTransactionType["BUY"] = "buy";
  ScenarioTransactionType["SELL"] = "sell";
  ScenarioTransactionType["DELETE"] = "delete";
})(ScenarioTransactionType = exports.ScenarioTransactionType || (exports.ScenarioTransactionType = {}));