"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DataType;

(function (DataType) {
  DataType["Double"] = "Double";
  DataType["Int64"] = "Int64";
  DataType["Utf8String"] = "Utf8String";
})(DataType || (DataType = {}));