import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  GENERATE_NEW_HMAC_SECRET,
  GET_WEBHOOK_INFO,
  UPDATE_PARTNER_WEBHOOK_STATUS,
} from '../common/queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ResponseText,
  WidthGreenClickableButton,
} from '../common/styledComponents';
import AtomIcon from '../common/AtomIcon';
import WebHookDetail from './WebHookDetail';
import { ToggleSwitch } from '../common/commonComponents';

const Webhooks = () => {
  const [isHmacKeyVisible, setIsHmacKeyVisible] = useState(false);
  const [addNewWebhook, setAddNewWebHook] = useState<String | null>(null);

  const { data: getWebhookInfoResponse, loading } = useQuery(GET_WEBHOOK_INFO);

  const hmac = getWebhookInfoResponse?.webhookInfo?.hmacSecret ?? '';
  const activeWebhooks = getWebhookInfoResponse?.webhookInfo?.webhookList ?? [];
  const allowedWebhookTypes =
    getWebhookInfoResponse?.webhookInfo?.allowedWebhookTypes ?? [];

  const [generateNewHmacSecret, generateNewHmacSecretResponse] = useMutation(
    GENERATE_NEW_HMAC_SECRET,
    {
      'refetchQueries': [{ query: GET_WEBHOOK_INFO }],
    },
  );

  const [updatePartnerWebhookStatus] = useMutation(
    UPDATE_PARTNER_WEBHOOK_STATUS,
    {
      'refetchQueries': [{ query: GET_WEBHOOK_INFO }],
    },
  );
  ``;

  const newHmacSuccess =
    generateNewHmacSecretResponse.data?.generateNewHmacSecret?.success;
  const newHmacMessage =
    generateNewHmacSecretResponse.data?.generateNewHmacSecret?.message;

  const isWebhooksEmpty = activeWebhooks.length == 0;
  const hasWebhookAccess = allowedWebhookTypes?.length > 0;

  if (loading) {
    return null;
  }

  if (!hasWebhookAccess) {
    return (
      <OverviewContainer>
        <OverviewHeader>
          <TextContainer>
            <LargeText>Webhooks</LargeText>
            <BetaLabel>Beta</BetaLabel>
          </TextContainer>
        </OverviewHeader>
        <DescriptionText>
          Webhooks are the best way to recieve content such as documents as soon
          as they become avaliable. They can also be used to setup notifications
          for your users.
        </DescriptionText>
        <DescriptionText>
          Please reach out to your Atom account representative if you&apos;re
          interested in using one of our webhook services.
        </DescriptionText>
        <WidthGreenClickableButton
          style={{ marginTop: '2em' }}
          onClick={async () => {
            window.open(
              'https://atom.finance/enterprise?contact_reason=general',
            );
          }}
        >
          {`TALK TO OUR TEAM`}
        </WidthGreenClickableButton>
      </OverviewContainer>
    );
  }

  if (addNewWebhook !== null) {
    return (
      <>
        <WebHookDetail
          setAddNewWebHook={setAddNewWebHook}
          webhookId={addNewWebhook}
          activeWebhooks={activeWebhooks}
          allowedWebhookTypes={allowedWebhookTypes}
        />
      </>
    );
  }

  return (
    <OverviewContainer>
      <OverviewHeader>
        <TextContainer>
          <LargeText>Webhooks</LargeText>
          <BetaLabel>Beta</BetaLabel>
        </TextContainer>
      </OverviewHeader>
      <RootContainer>
        <WebhookContainer>
          <HmacTestContainer>
            <MediumText>HMAC Secret Key</MediumText>
            <MiddleDescriptionText>
              Your HMAC Secret key is used to generate a signature sent with
              each webhook message, allowing you to certify that the message was
              indeed sent by Atom Finance. For more on how our webhooks work,
              see our
              <a
                style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/webhooks'
                target='_blank'
                rel='noopener noreferrer'
              >
                documentation on webhooks
              </a>
              .
            </MiddleDescriptionText>
          </HmacTestContainer>
          <HmacSecretContainer>
            <ApiKeySection>
              <SmallText
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(hmac).then(() => {
                    alert('HMAC copied to clipboard');
                  });
                }}
              >
                {isHmacKeyVisible
                  ? hmac
                  : 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'}
              </SmallText>
              <div
                style={{ marginTop: '0.25rem', marginLeft: 'auto' }}
                onClick={() => setIsHmacKeyVisible(!isHmacKeyVisible)}
              >
                <AtomIcon
                  icon={isHmacKeyVisible ? 'eye-opened' : 'eye-closed'}
                  size={24}
                  pointer
                />
              </div>
            </ApiKeySection>
            <ClickableText
              style={{ marginTop: '1em', width: 'fit-content' }}
              onClick={async () => {
                const isConfirmed = confirm(
                  `Do you really want to generate a new HMAC key? This will break all existing webhooks until the signature is replaced.`,
                );
                if (isConfirmed) {
                  await generateNewHmacSecret();
                }
              }}
            >
              {`GENERATE NEW HMAC SECRET`}
            </ClickableText>
            <ResponseText success={newHmacSuccess}>
              {newHmacMessage}
            </ResponseText>
          </HmacSecretContainer>
        </WebhookContainer>
        <MediumText
          style={{
            paddingTop: '1em',
            marginTop: '1em',
            borderTop: 'solid 1px var(--atom-brand-5)',
          }}
        >
          Webhook Configurations
        </MediumText>
        <DescriptionText>
          You can configure multiple webhooks each with a unique destination URL
          and any number of subscribed data types. Note that a webhook will
          automatically be disabled if your endpoint fails to return a 2xx
          response code for at least 100 consecutive messages over a period of
          at least 24 hours.
        </DescriptionText>
        <ActiveWebhooksHeader>
          <HeaderText style={{ width: '500px' }}>Destination URL</HeaderText>
          <HeaderText style={{ width: '400px' }}>
            Subscribed Data Types
          </HeaderText>
          <HeaderText style={{ marginLeft: 'auto', marginRight: '12px' }}>
            Enabled
          </HeaderText>
        </ActiveWebhooksHeader>
        {isWebhooksEmpty && (
          <MiddleDescriptionText>No Active Webhooks</MiddleDescriptionText>
        )}
        {activeWebhooks.map((row, index) => {
          const {
            id,
            webhookURL,
            isEnabled,
            documentWebhookOptions,
            portfolioWebhookOptions,
          } = row;
          const enabledDocuments = documentWebhookOptions
            .filter(option => option.enabled)
            .concat(portfolioWebhookOptions.filter(option => option.enabled));
          let dataSettingsText = '';
          if (enabledDocuments.length > 0) {
            enabledDocuments.forEach(enabledOption => {
              dataSettingsText = dataSettingsText + enabledOption.name + ', ';
            });
            dataSettingsText = dataSettingsText.slice(0, -2);
          } else {
            dataSettingsText = 'No Webhook Types Enabled';
          }
          return (
            <ActiveWebhooksContainerRow key={webhookURL}>
              <ActiveWebhooksRowTextSection>
                <ActiveWebhooksRow style={{ width: '500px' }}>
                  {webhookURL}
                </ActiveWebhooksRow>
                <ActiveWebhooksRow style={{ width: '400px' }}>
                  {dataSettingsText}
                </ActiveWebhooksRow>
              </ActiveWebhooksRowTextSection>
              <ClickableText
                onClick={() => {
                  setAddNewWebHook(id);
                }}
              >
                Edit
              </ClickableText>
              <ToggleSwitch
                enabled={isEnabled}
                locked={false}
                handleToggle={async () => {
                  const isConfirmed = confirm(
                    `Do you really want to ${
                      isEnabled ? 'disable' : 'enable'
                    } the webhook?`,
                  );
                  if (isConfirmed) {
                    const result = await updatePartnerWebhookStatus({
                      variables: { webhookId: id, status: !isEnabled },
                    });

                    if (!result?.data?.updatePartnerWebhookStatus?.success) {
                      alert(
                        'Failed to toggle webhook status. Please try again, or contact customer support.',
                      );
                    }
                  }
                }}
              />
            </ActiveWebhooksContainerRow>
          );
        })}
        {!isWebhooksEmpty && <Break />}
        <WidthGreenClickableButton
          style={{ marginTop: '2em' }}
          onClick={() => {
            setAddNewWebHook('');
          }}
        >
          {`ADD NEW WEBHOOK`}
        </WidthGreenClickableButton>
      </RootContainer>
    </OverviewContainer>
  );
};

const OverviewContainer = styled.div`
  margin: 0em 5em;
`;

const Break = styled.div`
  border-top: solid 1px var(--atom-brand-5);
  padding-top: 1em;
  margin-top: 1em;
`;
const OverviewHeader = styled.div`
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 3em;
`;

const IconContainer = styled.img`
  margin-right: 2em;
  border-radius: 10px;
  height: 5rem;
  width: 5rem;
`;

export const BetaLabel = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 3em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 3px;
  background-color: var(--atom-orange);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LargeText = styled.div`
  font-size: 33px;
`;

const MediumText = styled.div`
  font-size: 21px;
`;

const SmallText = styled.div`
  font-size: 15px;
  color: var(--atom-brand-7);
`;

const ClickableText = styled.a<{ disabled?: boolean }>`
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-dark-green);
  text-transform: uppercase;
  margin-left: 10px;
  user-select: none;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActiveWebhooksHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  font-size: 15px;
`;

const ActiveWebhooksContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px var(--atom-brand-5);
  padding-top: 1em;
  margin-top: 1em;
  font-size: 15px;
`;

const ActiveWebhooksRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActiveWebhooksRowTextSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 10px;
`;

const ActiveWebhooksRowText = styled.a``;

const WebhookContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: solid 1px var(--atom-brand-5);
  padding-top: 1em;
  justify-content: space-between;
  width: 100%;
`;

const HeaderText = styled.a`
  font-size: 16px;
  color: var(--atom-brand-7);
`;

const HmacTestContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MiddleDescriptionText = styled.div`
  font-size: 16px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 30em;
`;

const DescriptionText = styled.div`
  font-size: 16px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const TimeStamp = styled.a`
  align-items: flex-end;
  padding: 0.5rem;
  float: right;
`;

const ApiKeySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  border-radius: 3px;
  background-color: var(--atom-brand-4);
  padding-left: 1em;
  padding-right: 1em;
  width: 21em;
`;

const HmacSecretContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Webhooks;
