"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortDir = exports.EntityType = exports.PartOfSpeechTag = exports.AtomDocType = exports.LanguageCode = void 0;
var LanguageCode;

(function (LanguageCode) {
  LanguageCode["eng"] = "ENG";
  LanguageCode["por"] = "POR";
})(LanguageCode = exports.LanguageCode || (exports.LanguageCode = {}));

var AtomDocType;

(function (AtomDocType) {
  AtomDocType["financial"] = "FINANCIAL";
  AtomDocType["news"] = "NEWS";
  AtomDocType["prospectuses_registration"] = "PROSPECTUSES_REGISTRATION";
  AtomDocType["proxies"] = "PROXIES";
  AtomDocType["ownership"] = "OWNERSHIP";
  AtomDocType["other"] = "OTHER";
  AtomDocType["investor_presentation"] = "INVESTOR_PRESENTATION";
})(AtomDocType = exports.AtomDocType || (exports.AtomDocType = {}));

var PartOfSpeechTag;

(function (PartOfSpeechTag) {
  PartOfSpeechTag["PROPN"] = "PROPN";
  PartOfSpeechTag["NOUN"] = "NOUN";
  PartOfSpeechTag["OTHER"] = "OTHER";
})(PartOfSpeechTag = exports.PartOfSpeechTag || (exports.PartOfSpeechTag = {}));

var EntityType;

(function (EntityType) {
  EntityType["PERSON"] = "PERSON";
  EntityType["NORP"] = "NORP";
  EntityType["ORG"] = "ORG";
  EntityType["LOC"] = "LOC";
  EntityType["DATE"] = "DATE";
  EntityType["OTHER"] = "OTHER";
  EntityType["REPORTER"] = "REPORTER";
  EntityType["FAC"] = "FAC";
  EntityType["GPE"] = "GPE";
  EntityType["PRODUCT"] = "PRODUCT";
  EntityType["EVENT"] = "EVENT";
  EntityType["WORK_OF_ART"] = "WORK_OF_ART";
  EntityType["LAW"] = "LAW";
  EntityType["LANGUAGE"] = "LANGUAGE";
  EntityType["TIME"] = "TIME";
  EntityType["PERCENT"] = "PERCENT";
  EntityType["MONEY"] = "MONEY";
  EntityType["QUANTITY"] = "QUANTITY";
  EntityType["ORDINAL"] = "ORDINAL";
  EntityType["CARDINAL"] = "CARDINAL";
})(EntityType = exports.EntityType || (exports.EntityType = {}));

exports.SortDir = ['desc', 'asc'];