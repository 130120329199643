import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Modal = ({ closeModal, children }) => {
  return ReactDOM.createPortal(
    <Background onClick={(e) => {
      e.stopPropagation();
      closeModal()
    }}>{children}</Background>,
    document.querySelector('#modal') as Element,
  );
};

const Background = styled.div`
  position: relative;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export default Modal;
