import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  useHistory,
} from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import { withApollo } from '@apollo/react-hoc';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { GET_ACCOUNT_INFO, GET_AUTH_STATUS } from './modules/common/queries';
import SignIn from './modules/pages/signIn/SignIn';
import Main from './modules/pages/Main';
import {
  Admin,
  AdminContext,
  defaultAdminContext,
} from './modules/common/AdminContext';
import Footer from './modules/components/Footer';
import SignUp from './modules/pages/signIn/SignUp';
import SignUpComplete from './modules/pages/signIn/SignUpComplete';
import { ForgotPasswordAdmin } from './modules/pages/signIn/ForgotPasswordAdmin';
import PasswordResetAdmin from './modules/pages/signIn/PasswordResetAdmin';
import { isMobile, MobileView } from 'react-device-detect';
import { GreenClickableButton } from './modules/common/styledComponents';
import { transform } from 'lodash';
import SelfSignUpForm from './modules/pages/signIn/SignUpForm';

class ScrollToTopComponent extends React.Component<{
  location?: string;
  children?: React.ReactNode;
}> {
  // eslint-disable-next-line
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  // eslint-disable-next-line
  render() {
    return this.props.children;
  }
}

const ScrollToTop = withRouter<any, any>(ScrollToTopComponent);

const App = () => {
  const [adminContext, setAdminContext] = useState(defaultAdminContext);
  const { data, loading } = useQuery(GET_ACCOUNT_INFO);

  useEffect(() => {
    const adminContext = {
      ...defaultAdminContext,
      updateAdminContext: param => {
        setAdminContext(adminContext => ({ ...adminContext, ...param }));
      },
    };
    setAdminContext(adminContext);
  }, []);

  useEffect(() => {
    const accountInformation = data?.accountInformation;
    setAdminContext(adminContext => ({
      ...adminContext,
      ...accountInformation,
    }));
  }, [loading, data]);

  useEffect(() => {
    const hubspotScript = document.createElement('script');
    hubspotScript.src = '//js.hs-scripts.com/22419629.js';
    hubspotScript.async = true;
    hubspotScript.defer = true;
    hubspotScript.type = 'text/javascript';
    hubspotScript.id = 'hs-script-loader';

    document.head.appendChild(hubspotScript);

    return () => {
      document.head.removeChild(hubspotScript);
    };
  }, []);

  const { admin, partner } = adminContext;
  const isAuthenticated = admin?.id;

  if (loading) return <></>;
  return (
    <AdminContext.Provider value={adminContext}>
      <BrowserRouter>
        <AppInterior
          admin={admin}
          partner={partner}
          isAuthenticated={isAuthenticated}
        />
      </BrowserRouter>
    </AdminContext.Provider>
  );
};

const AppInterior = withRouter<any, any>(props => {
  const { isAuthenticated, admin, partner } = props;
  const [landingPage, setLandingPage] = useState('signin');
  const history = useHistory();
  useEffect(() => {
    if (isAuthenticated) {
      setLandingPage('main');
    } else {
      setLandingPage('signin');
    }
  }, [isAuthenticated, admin, partner]);

  return (
    <ScrollToTop>
      {isMobile ? (
        <MobileView>
          <img
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              marginTop: '15px',
            }}
            src={'/atom-finance-logo-white.png'}
            alt='atom123 finance'
            className='center'
            height='45'
          ></img>
          <p
            style={{
              paddingTop: 80,
              paddingLeft: 15,
              paddingRight: 15,
              fontSize: 35,
            }}
          >
            Please use your computer or another larger device to access the
            client portal
          </p>
          <a
            href='https://atom.finance/'
            rel=' noopener noreferrer'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GreenClickableButton
              style={{
                paddingLeft: 5,
                paddingRight: 15,
                paddingBottom: 10,
                paddingTop: 10,
                marginBottom: 15,
                fontSize: 14,
                width: '85%',
                bottom: 0,
                position: 'absolute',
              }}
            >
              {`Back to Atom finance site`}
            </GreenClickableButton>
          </a>
        </MobileView>
      ) : (
        <>
          <MainContainer>
            <Switch>
              <Route
                exact
                path='/'
                component={landingPage == 'main' ? Main : SignIn}
              />
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/selfsignup' component={SelfSignUpForm} />
              <Route exact path={['/main', '/main/:page']} component={Main} />
              <Route path='/signup/:id/:token' component={SignUp} />
              <Route
                path='/signuppartner/:id/:token'
                component={SignUpComplete}
              />
              <Route path='/forgotPassword' component={ForgotPasswordAdmin} />
              <Route path='/reset/:token' component={PasswordResetAdmin} />
            </Switch>
          </MainContainer>
          <Footer />
        </>
      )}
    </ScrollToTop>
  );
});

const MainContainer = styled.div.attrs({
  className: 'w-full flex flex-col lg:flex-row xl:flex-row flex-wrap',
})`
  min-height: 90%;
`;

const AdminBanner = styled.div`
  position: static;
  width: 100%;
  background-color: var(--atom-blue);
  color: white;
  text-align: center;
`;
// eslint-disable-next-line no-undef
export default hot(module)(withApollo(App));
