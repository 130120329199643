import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import MobileModal from './configurationModals/MobileModal';
import GlobalModal from './configurationModals/GlobalSetting';
import NewsFeedModal from './configurationModals/NewsFeedSetting';
import OptionBox from './OptionBox';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_OVERVIEW_CONFIG,
  UPDATE_OVERVIEW_CONFIG,
  GET_WHITELIST_URL_CONFIG,
} from '../common/queries';
import { AdminContext } from '../common/AdminContext';
import Popup from './configurationModals/TempPopup';
import KeyRotationPopup from './configurationModals/KeyRotationPopup';
import {
  AtomAssetSubtype,
  AtomAssetSubtypeCode,
  quoteSourcesToDisplayNames,
  QuoteSources,
} from '@atom-finance/atom-types';

import { OperationVariables } from 'apollo-client';
import { GreenClickableButton, Spinner } from '../common/styledComponents';
import { AdminLabel } from '../pages/signIn/AuthenticationContent';
import LanguageOptionBox from './LanguageOptionBox';

export interface OverviewSection {
  name: string;
  largeText: string;
  smallText: string;
  secondarySmallText?: string;
  link?: { text: string; url: string };
  setByAtom: boolean;
  isAtomOnlyDisplayed: boolean;
}

export interface Modal {
  name: string;
  Component: ({ showModal, openModal, closeModal }: any) => JSX.Element;
}

export interface Option {
  name: string;
  text: string;
  icon?: string;
  enabled?: boolean;
  modal?: Modal;
  overrideOnClickAction?: boolean;
}

export interface UpdateVariables {
  variables: {
    section: string;
    option: string;
    enabled: boolean;
  };
}

export interface OverviewDisplay {
  overviewSection: OverviewSection;
  options: Option[];
  optionsItemsPerRow: number;
  side?: Modal;
}

interface OverviewResponse {
  platform?: any[];
  market?: any[];
  language?: any[];
  module?: any[];
}

const overviewDisplay: OverviewDisplay[] = [
  {
    overviewSection: {
      name: 'quoteSource',
      largeText: 'Quote Sources',
      smallText: 'Choose the sources from which to receive quotes and prices.',
      secondarySmallText: ``,
      setByAtom: true,
      isAtomOnlyDisplayed: true,
    },
    optionsItemsPerRow: 3,
    options: Object.values(QuoteSources)
      .map(quoteSource => ({
        name: quoteSource,
        text: quoteSourcesToDisplayNames[quoteSource],
      }))
      .filter(({ name }) => {
        return name !== QuoteSources.BATS;
      }),
  },
  {
    overviewSection: {
      name: 'market',
      largeText: 'Markets',
      smallText:
        'Here you can see the markets you can include in your API responses. To make changes to this section, please contact your Atom Representative.',
      secondarySmallText: `Note: A production key will give you access to the top crypto assets. Please contact your Atom representative if you'd like to add additional crypto assets.`,
      setByAtom: true,
      isAtomOnlyDisplayed: true,
    },
    optionsItemsPerRow: 3,
    options: [
      { name: 'BZX', text: 'United States (Listed)' },
      { name: 'PNK', text: 'United States (OTC)' },
      { name: 'TOR', text: 'Canada (Listed)' },
      { name: 'SAO', text: 'B3 Brasil Bolsa Balcão' },
      { name: 'ATOM_CRYPTO', text: 'Crypto Exchange' },
    ],
  },
  {
    overviewSection: {
      name: 'asset',
      largeText: 'Assets',
      smallText:
        'Choose the asset subtypes you’d like to include in your API responses. To make changes to this section, please contact your Atom Representative. For more information on how these are classified see our documentation on ',
      setByAtom: true,
      link: {
        text: 'Asset Classification',
        url: 'https://docs.atom.finance/reference/asset-classification',
      },
      isAtomOnlyDisplayed: true,
    },
    optionsItemsPerRow: 4,
    options: Object.values(AtomAssetSubtypeCode)
      .filter(asset => asset != AtomAssetSubtypeCode.CRY)
      .map(asset => ({
        name: asset,
        text: AtomAssetSubtype[asset] ?? asset,
      })),
  },
  {
    overviewSection: {
      name: 'language',
      largeText: 'Languages',
      smallText:
        'Choose the languages to include in your API responses for news and other content. For more information on how these are classified see our documentation on ',
      setByAtom: false,
      link: {
        text: 'Languages',
        url: 'https://docs.atom.finance/reference/language-configuration',
      },
      isAtomOnlyDisplayed: false,
    },
    optionsItemsPerRow: 2,
    options: [
      { name: 'en-US', text: 'United States - English' },
      { name: 'pt-BR', text: 'Brazilian - Portuguese' },
    ],
  },
  {
    overviewSection: {
      name: 'newsSource',
      largeText: 'News Sources',
      smallText:
        'Here you can see the news sources you can include in your API responses. To make changes to this section, please contact your Atom Representative.',
      setByAtom: true,
      isAtomOnlyDisplayed: true,
    },
    optionsItemsPerRow: 2,
    options: [
      { name: 'Benzinga', text: 'Benzinga' },
      { name: 'Reuters', text: 'Reuters' },
      { name: 'Coindesk', text: 'Coindesk' },
      { name: 'Briefing.com', text: 'Briefing.com' },
      { name: 'MTNewswires', text: 'MT Newswires' },
      { name: 'StockStory.org', text: 'StockStory' },
    ],
  },
  {
    overviewSection: {
      name: 'newsInsights',
      largeText: 'News Insights',
      smallText:
        'Enable or disable Atom’s proprietary News Insights features, including Sentiment Analysis and Trending Topics.',
      setByAtom: true,
      isAtomOnlyDisplayed: true,
    },
    optionsItemsPerRow: 1,
    options: [{ name: 'insightsEnabled', text: 'insightsEnabled' }],
  },
  {
    overviewSection: {
      name: 'whitelistUrl',
      largeText: 'Active Endpoints',
      smallText:
        "Here you can see the active endpoints in your organization's account. To make changes to this section, please contact your Atom Representative.",
      setByAtom: true,
      isAtomOnlyDisplayed: true,
    },
    optionsItemsPerRow: 2,
    options: [],
  },
];

export const mapResponseToDisplay = (
  overviewDisplay: OverviewDisplay[],
  overviewResponse: OverviewResponse,
  whitelistResponse,
): OverviewDisplay[] => {
  if (!overviewResponse) return overviewDisplay;

  return overviewDisplay.map(displaySection => {
    const displaySectionName = displaySection.overviewSection.name;
    let displayOptions;
    if (displaySection.overviewSection.name === 'whitelistUrl') {
      displayOptions = whitelistResponse?.whiteListUrlConfig?.options.map(
        element => ({
          'name': element.name,
          'text': '/' + element.name.replaceAll('__', '-').replaceAll('_', '/'),
        }),
      );
    } else {
      displayOptions = displaySection.options;
    }
    const responseOptions: Option[] = overviewResponse[displaySectionName];
    const mappedResponseOptions = displayOptions?.map(option => {
      const optionEnabled =
        responseOptions?.find(
          responseOption => responseOption.name === option.name,
        )?.enabled ?? false;
      return {
        ...option,
        enabled: optionEnabled,
      };
    });
    return {
      ...displaySection,
      options: mappedResponseOptions,
    };
  });
};

const ApiSettingsContent = () => {
  const [displayModal, setDisplayModal] = useState('');
  const [actionsQueue, setActionsQueue] = useState<UpdateVariables[]>([]);
  const [editMode, setEditMode] = useState<'loading' | 'editing' | 'normal'>(
    'normal',
  );
  const [language, setLanguage] = useState('');
  const [overviewContent, setOverviewContent] = useState(overviewDisplay);
  const [selectedKeyRotation, setSelectedKeyRotation] = useState<
    string | undefined
  >();
  const { admin } = useContext(AdminContext);
  const { data } = useQuery(GET_OVERVIEW_CONFIG, {
    fetchPolicy: 'network-only',
  });
  const [updateOverviewConfig, { loading: isLoading, data: updateData }] =
    useMutation(UPDATE_OVERVIEW_CONFIG);
  const overviewResponse = data?.overviewConfig;
  const updateOverviewResponse = updateData?.updateOverviewConfig;

  const isAdmin = admin?.isAtomAdmin === true;

  const { data: getConfigResponse } = useQuery(GET_WHITELIST_URL_CONFIG, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (overviewResponse)
      setOverviewContent(
        mapResponseToDisplay(
          overviewDisplay,
          overviewResponse,
          getConfigResponse,
        ),
      );
  }, [overviewResponse, getConfigResponse]);

  useEffect(() => {
    if (overviewResponse) {
      if (overviewResponse['language']) {
        setLanguage(overviewResponse['language'][0].name);
      }
    }
  }, [overviewResponse]);

  useEffect(() => {
    if (updateOverviewResponse) {
      if (updateOverviewResponse['language']) {
        setLanguage(updateOverviewResponse['language'][0].name);
      }
    }
  }, [updateOverviewResponse, editMode]);

  useEffect(() => {
    if (updateOverviewResponse)
      setOverviewContent(
        mapResponseToDisplay(
          overviewDisplay,
          updateOverviewResponse,
          getConfigResponse,
        ),
      );
  }, [updateOverviewResponse, getConfigResponse]);

  const adminOnlySections = new Set(['keyRotation']);

  const saveChanges = useCallback(async () => {
    setEditMode('loading');
    for (const action of actionsQueue) {
      await updateOverviewConfig(action);
    }
    setActionsQueue([]);
    setEditMode('normal');
  }, [updateOverviewConfig, actionsQueue]);

  return (
    <>
      <EditHeader editing={editMode === 'editing'}>
        {editMode === 'normal' && (
          <EditButton onClick={() => setEditMode('editing')}>EDIT</EditButton>
        )}
        {editMode === 'editing' && (
          <ExitEditButton
            onClick={() => {
              setEditMode('normal');
              setActionsQueue([]);
            }}
          >
            CANCEL CHANGES
          </ExitEditButton>
        )}
        {editMode === 'loading' && <Spinner />}
        {actionsQueue.length > 0 && editMode === 'editing' ? (
          <EditButton onClick={saveChanges}>SAVE CHANGES</EditButton>
        ) : editMode === 'editing' ? (
          <EditButton disabled>SAVED</EditButton>
        ) : (
          <></>
        )}
      </EditHeader>

      {overviewContent.map((row, index) => {
        const { overviewSection, options, optionsItemsPerRow } = row;
        const {
          largeText,
          smallText,
          secondarySmallText,
          setByAtom,
          name: sectionName,
          link,
          isAtomOnlyDisplayed,
        } = overviewSection;
        if (adminOnlySections.has(sectionName) && !isAdmin) return;
        return (
          <ConfigurationContainer key={index}>
            <DescriptionContainer>
              <LargeTextContainer>
                <LargeText>{largeText}</LargeText>
                {isAtomOnlyDisplayed && (
                  <AdminLabel>Atom Representative Only</AdminLabel>
                )}
              </LargeTextContainer>
              <SmallText>
                {smallText}
                <MoreLink href={link?.url} target='blank'>
                  {' ' + (link ? link?.text + '.' : '')}
                </MoreLink>
                {secondarySmallText ? (
                  <SmallText> {secondarySmallText} </SmallText>
                ) : null}
              </SmallText>
              {row?.side &&
                (() => {
                  const { name: sideName, Component } = row.side;
                  return (
                    <SideText>
                      <Component
                        showModal={sideName === displayModal}
                        openModal={() => setDisplayModal(sideName)}
                        closeModal={() => setDisplayModal('')}
                        text={sideName}
                      ></Component>
                    </SideText>
                  );
                })()}
            </DescriptionContainer>
            <OptionsContainer optionsItemsPerRow={optionsItemsPerRow}>
              {options?.map((option, key) => {
                const {
                  icon,
                  text,
                  modal,
                  enabled,
                  name: optionName,
                  overrideOnClickAction,
                } = option;
                const Component = modal?.Component;
                const componentName = modal?.name;
                const locked = !admin?.isAtomAdmin && setByAtom;
                if (sectionName === 'newsInsights') {
                  return (
                    <ToggleSwitch
                      option={option}
                      editMode={editMode}
                      sectionName={sectionName}
                      locked={locked}
                      actionsQueue={actionsQueue}
                      setActionsQueue={setActionsQueue}
                    ></ToggleSwitch>
                  );
                } else if (sectionName == 'language') {
                  return (
                    <LanguageComponent
                      key={key}
                      editMode={editMode}
                      icon={icon}
                      text={text}
                      enabled={enabled}
                      locked={locked}
                      optionName={optionName}
                      sectionName={sectionName}
                      language={language}
                      overrideOnClickAction={overrideOnClickAction}
                      componentName={componentName}
                      component={Component}
                      actionsQueue={actionsQueue}
                      setActionsQueue={setActionsQueue}
                      setLanguage={setLanguage}
                      setDisplayModal={setDisplayModal}
                      setSelectedKeyRotation={setSelectedKeyRotation}
                    ></LanguageComponent>
                  );
                } else {
                  return (
                    <OptionBox
                      key={key}
                      icon={icon}
                      text={text}
                      enabled={enabled}
                      locked={locked}
                      editMode={editMode === 'editing'}
                      isSelectionToggleable={true}
                      onClick={() => {
                        if (!locked && editMode === 'editing') {
                          if (overrideOnClickAction) {
                            if (Component && componentName) {
                              setSelectedKeyRotation(optionName);
                              setDisplayModal(componentName);
                            }
                          } else {
                            toggleOption(
                              sectionName,
                              optionName,
                              actionsQueue,
                              setActionsQueue,
                              enabled,
                            );
                          }
                        }
                      }}
                      Modal={() => {
                        if (Component && componentName) {
                          if (componentName === 'keyRotation') {
                            return (
                              <Component
                                showModal={componentName === displayModal}
                                openModal={() => setDisplayModal(componentName)}
                                closeModal={() => setDisplayModal('')}
                                disabled={!enabled}
                                selectedKeyRotation={selectedKeyRotation}
                              />
                            );
                          } else {
                            return (
                              <Component
                                showModal={componentName === displayModal}
                                openModal={() => setDisplayModal(componentName)}
                                closeModal={() => setDisplayModal('')}
                                disabled={!enabled}
                              />
                            );
                          }
                        } else {
                          return null;
                        }
                      }}
                    />
                  );
                }
              })}
            </OptionsContainer>
          </ConfigurationContainer>
        );
      })}
    </>
  );
};

const ConfigurationContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  border-top: solid 1px var(--atom-brand-5);
  margin-top: 2em;
  padding-top: 2em;
`;
const OptionsContainer = styled.div<{
  optionsItemsPerRow: number;
}>`
  display: grid;
  grid-template-columns: ${props =>
    [...Array(props.optionsItemsPerRow)].map(
      () => ` ${Math.floor((1 / props.optionsItemsPerRow) * 100)}%`,
    )};
  justify-content: center;
`;
const ToggleContainer = styled.div<{}>`
  display: flex;
  justify-content: end;
`;

const ToggleText = styled.div`
  font-size: 15px;
  margin-right: 1em;
`;
export const LargeTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  margin: 1em 1em 0.5em 0em;
  position: relative;
`;

const LargeText = styled.div`
  font-size: 21px;
`;

const SmallText = styled.div`
  font-size: 15px;
  margin-top: 1em;
`;

const LoadingText = styled.div`
  font-size: 1em;
  margin-bottom: 0.5em;
  text-align: right;
  color: var(--atom-green);
`;

const SideText = styled.div`
  position: absolute;
  bottom: 0;
`;

const MoreLink = styled.a`
  color: var(--atom-green);
`;

const EditButton = styled(GreenClickableButton as any)`
  display: flex;
  justify-content: center;
  margin-bottom: 0em;
  margin-top: 0;
  z-index: 10;
  font-size: 15px;
  width: 20em;
`;

const ExitEditButton = styled(GreenClickableButton as any)`
  display: flex;
  font-size: 15px;
  width: 20em;
  justify-content: center;
  margin-bottom: 0em;
  margin-right: 1em;
  margin-top: 0;
  z-index: 10;
  background-color: #f27362;
  &:hover {
    background-color: var(
      --atom-${props => (props.disabled ? 'dark-red' : 'dark-red')}
    );
  }
`;

const EditHeader = styled.div<{
  editing: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: ${props => (props.editing ? 'sticky' : 'static')};
  top: 0;
  background-color: var(--atom-background);
  opacity: 1 !important;
  padding: 1em 1em 1em 0em;
  z-index: 8;
`;

const ToggleSmallCircle = styled.div<{ disabled?: boolean }>`
  border-radius: 100%;
  height: 1em;
  width: 1em;
  margin: 3px;
  background-color: var(
    --${props => (props.disabled ? 'atom-dark-contrast' : 'atom-brand-1')}
  );
  border: solid
    var(--${props => (props.disabled ? 'atom-dark-contrast' : 'atom-brand-1')});
`;

const ToggleButton = styled.div<{ disabled?: boolean; locked?: boolean }>`
  background-color: var(
    --${props => (props.disabled ? 'atom-brand-4' : 'atom-dark-green')}
  );
  margin-right: 1em;
  border-radius: 1em;
  height: fit-content;
  width: 3em;
  align-items: right;
  justify-content: flex-${props => (props.disabled ? 'start' : 'end')};
  cursor: ${props => (props.locked ? 'default' : 'pointer')};
  display: flex;
`;
export default ApiSettingsContent;

function toggleOption(
  sectionName: string,
  optionName: string,
  actionsQueue: UpdateVariables[],
  setActionsQueue: React.Dispatch<React.SetStateAction<UpdateVariables[]>>,
  enabled: boolean | undefined,
) {
  const resetOption = actionsQueue.findIndex(
    config =>
      config.variables.section == sectionName &&
      config.variables.option == optionName,
  );
  if (resetOption >= 0) {
    const tempActions = JSON.parse(JSON.stringify(actionsQueue));
    tempActions.splice(resetOption, 1);
    setActionsQueue(tempActions);
  } else {
    setActionsQueue(actionsQueue => [
      ...actionsQueue,
      {
        variables: {
          section: sectionName,
          option: optionName,
          enabled: !enabled,
        },
      },
    ]);
  }
}

export const LanguageComponent = ({
  key,
  editMode,
  icon,
  text,
  enabled,
  locked,
  optionName,
  sectionName,
  language,
  overrideOnClickAction,
  componentName,
  component,
  actionsQueue,
  setActionsQueue,
  setLanguage,
  setDisplayModal,
  setSelectedKeyRotation,
}) => {
  return (
    <LanguageOptionBox
      key={key}
      icon={icon}
      text={text}
      enabled={enabled}
      locked={(locked || optionName == language) && editMode === 'editing'}
      editMode={editMode === 'editing'}
      matchesCurrLanguage={optionName == language}
      onClick={() => {
        if (!locked && editMode === 'editing') {
          if (overrideOnClickAction) {
            if (component && componentName) {
              setSelectedKeyRotation(optionName);
              setDisplayModal(componentName);
            }
          } else {
            setLanguage(optionName);
            actionsQueue.filter(
              action => action.variables.section != 'language',
            );
            setActionsQueue(actionsQueue => [
              ...actionsQueue,
              {
                variables: {
                  section: sectionName,
                  option: optionName,
                  enabled: optionName == language,
                },
              },
            ]);
          }
        }
      }}
    />
  );
};

export const ToggleSwitch = ({
  option,
  editMode,
  sectionName,
  locked,
  actionsQueue,
  setActionsQueue,
}) => {
  const { enabled, name: optionName } = option;
  return (
    <ToggleContainer>
      <ToggleText>{enabled ? 'Enabled' : 'Disabled'}</ToggleText>
      <ToggleButton
        disabled={!enabled}
        locked={editMode !== 'editing'}
        onClick={() => {
          if (!locked && editMode === 'editing') {
            option.enabled = !enabled;
            toggleOption(
              sectionName,
              optionName,
              actionsQueue,
              setActionsQueue,
              enabled,
            );
          }
        }}
      >
        <ToggleSmallCircle disabled={!enabled} />
      </ToggleButton>
    </ToggleContainer>
  );
};
