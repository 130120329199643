"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CoinGeckoOrderBy = void 0;
var CoinGeckoOrderBy;

(function (CoinGeckoOrderBy) {
  CoinGeckoOrderBy["MARKET_CAP_DESC"] = "market_cap_desc";
  CoinGeckoOrderBy["MARKET_CAP_ASC"] = "market_cap_asc";
  CoinGeckoOrderBy["GECKO_DESC"] = "gecko_desc";
  CoinGeckoOrderBy["GECKO_ASC"] = "gecko_asc";
  CoinGeckoOrderBy["VOLUME_DESC"] = "volume_desc";
  CoinGeckoOrderBy["VOLUME_ASC"] = "volume_asc";
  CoinGeckoOrderBy["ID_DESC"] = "id_desc";
  CoinGeckoOrderBy["ID_ASC"] = "id_asc";
})(CoinGeckoOrderBy = exports.CoinGeckoOrderBy || (exports.CoinGeckoOrderBy = {}));