"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OnboardingPlan = exports.AtomPlusStatus = exports.AdminSubscriptionActions = void 0;
var AdminSubscriptionActions;

(function (AdminSubscriptionActions) {
  AdminSubscriptionActions["CancelActive"] = "CancelActive";
  AdminSubscriptionActions["GrantAtomVIP"] = "GrantAtomVIP";
  AdminSubscriptionActions["GrantNewTrial"] = "GrantNewTrial";
})(AdminSubscriptionActions = exports.AdminSubscriptionActions || (exports.AdminSubscriptionActions = {}));

var AtomPlusStatus;

(function (AtomPlusStatus) {
  AtomPlusStatus["Basic"] = "basic";
  AtomPlusStatus["ChurnedSubscriber"] = "churned_subscriber";
  AtomPlusStatus["ChurnedTrial"] = "churned_trial";
  AtomPlusStatus["DelinquentSubscriber"] = "delinquent_subscriber";
  AtomPlusStatus["FirstTimeSubscriber"] = "first_time_subscriber";
  AtomPlusStatus["ReturningSubscriber"] = "returning_subscriber";
  AtomPlusStatus["Trial"] = "trial";
  AtomPlusStatus["TrialPaymentVerified"] = "trial_payment_verified";
  AtomPlusStatus["PreOnboarding"] = "pre_onboarding";
})(AtomPlusStatus = exports.AtomPlusStatus || (exports.AtomPlusStatus = {}));

var OnboardingPlan;

(function (OnboardingPlan) {
  OnboardingPlan["BASIC"] = "BASIC";
  OnboardingPlan["TRIAL"] = "TRIAL";
  OnboardingPlan["SUBSCRIPTION"] = "SUBSCRIPTION";
  OnboardingPlan["NONE"] = "NONE";
})(OnboardingPlan = exports.OnboardingPlan || (exports.OnboardingPlan = {}));