"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupType = exports.UserRoomStatus = void 0;
var UserRoomStatus;

(function (UserRoomStatus) {
  UserRoomStatus["ELIGIBLE"] = "ELIGIBLE";
  UserRoomStatus["JOIN_PENDING"] = "JOIN_PENDING";
  UserRoomStatus["INELIGIBLE"] = "INELIGIBLE";
  UserRoomStatus["JOINED"] = "JOINED";
  UserRoomStatus["LEFT"] = "LEFT";
  UserRoomStatus["REMOVED"] = "REMOVED";
  UserRoomStatus["DECLINED"] = "DECLINED";
  UserRoomStatus["BANNED"] = "BANNED";
  UserRoomStatus["WARNING1"] = "WARNING1";
  UserRoomStatus["WARNING2"] = "WARNING2";
  UserRoomStatus["WARNING3"] = "WARNING3";
  UserRoomStatus["READ_ONLY"] = "READ_ONLY";
  UserRoomStatus["ROOM_NOT_EXIST"] = "ROOM_NOT_EXIST";
})(UserRoomStatus = exports.UserRoomStatus || (exports.UserRoomStatus = {}));

var GroupType;

(function (GroupType) {
  GroupType["ATOM_GENERAL"] = "ATOM_GENERAL";
  GroupType["STOCK_GENERAL"] = "STOCK_GENERAL";
  GroupType["TOPIC_GENERAL"] = "TOPIC_GENERAL";
  GroupType["SECTOR_GENERAL"] = "SECTOR_GENERAL";
  GroupType["STOCK_CUSTOM"] = "STOCK_CUSTOM";
  GroupType["TOPIC_CUSTOM"] = "TOPIC_CUSTOM";
  GroupType["SECTOR_CUSTOM"] = "SECTOR_CUSTOM";
  GroupType["STOCK_TOP_25_HOLDERS"] = "STOCK_TOP_25_HOLDERS";
  GroupType["USER_CUSTOM"] = "USER_CUSTOM";
})(GroupType = exports.GroupType || (exports.GroupType = {}));