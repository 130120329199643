import React, { useContext } from 'react';
import styled from 'styled-components';
import { AdminContext } from '../common/AdminContext';
import { GET_PARTNER_ICON } from '../common/queries';
import { useQuery } from '@apollo/react-hooks';
import ChangesContent from './ChangesContent';

const Changes = () => {
  const { partner } = useContext(AdminContext);
  const partnerIconResponse = useQuery(GET_PARTNER_ICON);
  const partnerIcon = partnerIconResponse?.data?.partnerIcon?.icon;

  return (
    <OverviewContainer>
      <OverviewHeader>
        <TextContainer>
          <LargeText>Change History</LargeText>
          <SmallText>
            Here you can see which settings were updated, when, and by whom.
          </SmallText>
        </TextContainer>
      </OverviewHeader>
      <ChangesContent />
    </OverviewContainer>
  );
};

const OverviewContainer = styled.div`
  margin: 0em 5em;
`;

const OverviewHeader = styled.div`
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 2em;
`;

const IconContainer = styled.img`
  margin-right: 2em;
  border-radius: 10px;
  height: 5rem;
  width: 5rem;
`;

const TextContainer = styled.div``;

const LargeText = styled.div`
  font-size: 33px;
  margin-bottom: 0.5em;
`;

const SmallText = styled.div`
  font-size: 16px;
`;

export default Changes;
