"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exchangeToLuxMarket = exports.luxMarket = void 0;

var luxon_1 = require("luxon");

var luxonUtils_1 = require("./luxonUtils");

var marketHolidays_1 = require("./marketHolidays");

var USAMarket = {
  code: 'USA',
  zone: 'America/New_York',
  localTime: function localTime(dt) {
    return (0, luxonUtils_1.luxEST)(dt);
  },
  fullHolidays: marketHolidays_1.NYSE_FULL_MARKET_HOLIDAYS,
  halfHolidays: marketHolidays_1.NYSE_HALF_MARKET_HOLIDAYS,
  toTimeOfDay: {
    // https://www.tradinghours.com/markets/nyse/hours
    preMarketOpen: function preMarketOpen(day) {
      return day.set({
        hour: 4,
        minute: 0
      }).startOf('minute');
    },
    open: function open(day) {
      return day.set({
        hour: 9,
        minute: 30
      }).startOf('minute');
    },
    earlyClose: function earlyClose(day) {
      return day.set({
        hour: 13,
        minute: 0
      }).startOf('minute');
    },
    close: function close(day) {
      return day.set({
        hour: 16,
        minute: 0
      }).startOf('minute');
    },
    afterMarketClose: function afterMarketClose(day) {
      return day.set({
        hour: 20,
        minute: 0
      }).startOf('minute');
    }
  }
};

var CANMarket = __assign(__assign({}, USAMarket), {
  code: 'CAN',
  fullHolidays: marketHolidays_1.TOR_FULL_MARKET_HOLIDAYS,
  halfHolidays: marketHolidays_1.TOR_HALF_MARKET_HOLIDAYS
});

var BRAMarket = {
  code: 'BRA',
  zone: 'America/Sao_Paulo',
  localTime: function localTime(dt) {
    return (0, luxonUtils_1.luxSAO)(dt);
  },
  fullHolidays: marketHolidays_1.SAO_FULL_MARKET_HOLIDAYS,
  halfHolidays: marketHolidays_1.SAO_HALF_MARKET_HOLIDAYS_LATE_START,
  toTimeOfDay: {
    // https://www.tradinghours.com/markets/bovespa/holidays
    open: function open(day) {
      return day.set({
        hour: 10,
        minute: 0
      }).startOf('minute');
    },
    lateOpen: function lateOpen(day) {
      return day.set({
        hour: 13,
        minute: 0
      }).startOf('minute');
    },
    close: function close(day) {
      return day.set({
        hour: 16,
        minute: 55
      }).startOf('minute');
    },
    afterMarketOpen: function afterMarketOpen(day) {
      return day.set({
        hour: 17,
        minute: 30
      }).startOf('minute');
    },
    afterMarketClose: function afterMarketClose(day) {
      return day.set({
        hour: 18,
        minute: 0
      }).startOf('minute');
    }
  }
};

var OTCMarket = __assign(__assign({}, USAMarket), {
  code: 'OTC',
  fullHolidays: marketHolidays_1.OTC_FULL_MARKET_HOLIDAYS,
  halfHolidays: marketHolidays_1.OTC_HALF_MARKET_HOLIDAYS,
  toTimeOfDay: {
    // https://www.tradinghours.com/markets/otc/hours
    preMarketOpen: function preMarketOpen(day) {
      return day.set({
        hour: 6,
        minute: 0
      }).startOf('minute');
    },
    open: function open(day) {
      return day.set({
        hour: 9,
        minute: 30
      }).startOf('minute');
    },
    earlyClose: function earlyClose(day) {
      return day.set({
        hour: 13,
        minute: 0
      }).startOf('minute');
    },
    close: function close(day) {
      return day.set({
        hour: 16,
        minute: 0
      }).startOf('minute');
    },
    afterMarketClose: function afterMarketClose(day) {
      return day.set({
        hour: 17,
        minute: 0
      }).startOf('minute');
    }
  }
});

var CRYPTOMarket = {
  code: 'CRYPTO',
  zone: 'Etc/GMT',
  localTime: function localTime(dt) {
    return dt ? dt.toUTC() : luxon_1.DateTime.utc();
  },
  fullHolidays: new Set(),
  halfHolidays: new Set(),
  toTimeOfDay: {
    open: function open(dt) {
      return dt.toUTC().startOf('day');
    },
    close: function close(dt) {
      return dt.toUTC().endOf('day');
    }
  }
};
var markets = {
  USA: USAMarket,
  BRA: BRAMarket,
  CAN: CANMarket,
  OTC: OTCMarket,
  CRYPTO: CRYPTOMarket
};
var exchangeToMarketMap = {
  BZX: USAMarket,
  SAO: BRAMarket,
  TOR: CANMarket,
  PNK: OTCMarket,
  ATOM_CRYPTO: CRYPTOMarket
};

var luxMarket = function luxMarket(marketCode) {
  var market = markets[marketCode];

  if (!market) {
    throw new Error("Market code not found: " + marketCode);
  }

  return market;
};

exports.luxMarket = luxMarket;

var exchangeToLuxMarket = function exchangeToLuxMarket(exchangeCode) {
  var market = exchangeToMarketMap[exchangeCode];

  if (!market) {
    throw new Error("trExchangeCode not found: " + exchangeCode);
  }

  return market;
};

exports.exchangeToLuxMarket = exchangeToLuxMarket;