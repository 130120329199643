import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { render } from 'react-dom';
import styled from 'styled-components';
import { AdminContext } from '../common/AdminContext';
import AtomIcon from '../common/AtomIcon';
import { GET_ACCOUNT_INFO, REMOVE_ADMIN } from '../common/queries';
import AddMemberModal from './configurationModals/AddMemberModal';

const Company = () => {
  const { admin, partner } = useContext(AdminContext);
  const [removeAdmin, removeAdminInfoResp] = useMutation(REMOVE_ADMIN, {
    refetchQueries: [{ query: GET_ACCOUNT_INFO }],
  });
  const getAdmins = () => {
    return partner?.admins ?? [];
  };

  const isAdmin = admin?.isAtomAdmin === true;

  return (
    <CompanyContainer>
      <CompanyHeader>
        <MemberListContainer>
          <LargeText>{`Team Members`}</LargeText>
          <SmallText>
            Here you can see all current team members, invite new
            administrators, and see their invitation status.
          </SmallText>

          <MembersContainer>
            {getAdmins().map((admin, i) => {
              const { firstName, lastName, email, accessStatus } = admin;
              return (
                <RowContainer key={i} isAdmin={isAdmin}>
                  <MemberText>{`${firstName ?? ''} ${
                    lastName ?? ''
                  }`}</MemberText>
                  <MemberText>{email}</MemberText>
                  <MemberText lastColumn={true}>{accessStatus}</MemberText>
                  {isAdmin && (
                    <RemoveButton
                      onClick={() => {
                        removeAdmin({ variables: { id: admin.id } });
                      }}
                    >
                      Remove Member
                    </RemoveButton>
                  )}
                </RowContainer>
              );
            })}
            {getAdmins().length == 0 &&
              'This partner does not have any members'}
          </MembersContainer>
          <AddMemberModal />
        </MemberListContainer>
      </CompanyHeader>
    </CompanyContainer>
  );
};

const MemberText = styled.div<{
  lastColumn?: boolean;
}>`
  margin: 2px;
  ${props => (props.lastColumn ? 'text-transform: uppercase;' : '')}
`;

const QuestionLargeText = styled.div`
  font-size: 21px;
`;

const MemberListContainer = styled.div`
  flex-grow: 2;
  margin-right: 2em;
`;

const QuestionContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  max-width: 15em;
  margin-left: 2em;
`;

const CompanyContainer = styled.div`
  margin: 0em 5em;
`;

const CompanyHeader = styled.div`
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 3em;
`;

const MemberTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  font-size: 15px;
`;

const HeaderText = styled.a`
  font-size: 16px;
  color: var(--atom-brand-7);
`;

const LargeText = styled.div`
  font-size: 33px;
  margin-bottom: 0.5em;
`;

const SmallText = styled.div`
  font-size: 16px;
  margin-bottom: 2em;
`;

const MembersContainer = styled.div`
  border-top: 1px solid var(--atom-dark-brand-5);
  border-bottom: 1px solid var(--atom-dark-brand-5);
  padding: 1em;
  margin-top: 3em;
  margin-bottom: 3em;
`;

const RowContainer = styled.div<{
  isAdmin: boolean;
}>`
  display: grid;
  grid-template-columns: ${props =>
    props.isAdmin ? '2fr 2fr 1fr 1fr' : '2fr 2fr 1fr'};
  white-space: nowrap;
  align-items: center;
`;

const RemoveButton = styled.div`
  background-color: var(--atom-bright-orange);
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  padding: 5px;
  text-transform: uppercase;
  color: var(--atom-brand-0);
  &:hover {
    cursor: pointer;
    );
  }
  margin: 2px 0;
  width: 100px;
`;

export default Company;
