"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InsideViewReportTypeTitles = exports.InsideViewReportType = exports.StockBriefType = exports.BriefFeedbackCategory = exports.Sentiment = void 0;
var Sentiment;

(function (Sentiment) {
  Sentiment["positive"] = "positive";
  Sentiment["neutral"] = "neutral";
  Sentiment["negative"] = "negative";
})(Sentiment = exports.Sentiment || (exports.Sentiment = {}));

var BriefFeedbackCategory;

(function (BriefFeedbackCategory) {
  BriefFeedbackCategory["correctness"] = "correctness";
  BriefFeedbackCategory["relevance"] = "relevance";
  BriefFeedbackCategory["sentiment"] = "sentiment";
  BriefFeedbackCategory["verbosity"] = "verbosity";
  BriefFeedbackCategory["duplication"] = "duplication";
  BriefFeedbackCategory["detail"] = "detail";
  BriefFeedbackCategory["other"] = "other";
})(BriefFeedbackCategory = exports.BriefFeedbackCategory || (exports.BriefFeedbackCategory = {}));

var StockBriefType;

(function (StockBriefType) {
  StockBriefType["NEWS_INSIGHTS"] = "NewsInsights";
  StockBriefType["INSIDER_VIEW"] = "InsiderView";
  StockBriefType["CONTROVERSY"] = "Controversy";
})(StockBriefType = exports.StockBriefType || (exports.StockBriefType = {}));

var InsideViewReportType;

(function (InsideViewReportType) {
  InsideViewReportType["financialPerformance"] = "financialPerformance";
  InsideViewReportType["businessPerformance"] = "businessPerformance";
  InsideViewReportType["headwinds"] = "headwinds";
  InsideViewReportType["tailwinds"] = "tailwinds";
})(InsideViewReportType = exports.InsideViewReportType || (exports.InsideViewReportType = {}));

exports.InsideViewReportTypeTitles = {
  financialPerformance: 'Financial Performance',
  businessPerformance: 'Business Performance',
  headwinds: 'Headwinds',
  tailwinds: 'Tailwinds'
};