"use strict";

var _a, _b, _c, _d, _e, _f;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AtomAssetSubtypeCodes = exports.AtomAssetTypes = exports.TrToAtomAssetSubtypeCode = exports.AssetSubtypeCodeToTrAssetCode = exports.TrAssetCategoryToAssetType = exports.RefinitivToIexAssetCategory = exports.AssetCategory = exports.SubtypeCodeToAssetType = exports.AssetTypeToSubtypeCode = exports.AtomAssetSubtypeCode = exports.AtomAssetSubtype = exports.AtomAssetType = exports.EventTypes = exports.DocumentType = exports.Languages = exports.CountryCode = exports.EntityCategory = exports.TransactionType = exports.PortfolioAssetType = exports.AssetTypes = exports.ListingType = exports.MICToTrExchangeCode = exports.MICToMarket = exports.MarketToMics = exports.MICs = exports.TrExchangeCodeToMarket = exports.MarketToTrExchangeCode = exports.Markets = exports.TrExchangeCode = exports.IdentifierTypes = void 0;
exports.IdentifierTypes = ['ticker', 'atomAssetId', 'figi', 'isin', 'cusip'];
var TrExchangeCode;

(function (TrExchangeCode) {
  TrExchangeCode["bzx"] = "BZX";
  TrExchangeCode["tor"] = "TOR";
  TrExchangeCode["pnk"] = "PNK";
  TrExchangeCode["sao"] = "SAO";
  TrExchangeCode["crypto"] = "ATOM_CRYPTO";
})(TrExchangeCode = exports.TrExchangeCode || (exports.TrExchangeCode = {}));

exports.Markets = ['USA', 'CAN', 'OTC', 'BRA', 'CRYPTO'];
exports.MarketToTrExchangeCode = {
  USA: TrExchangeCode.bzx,
  CAN: TrExchangeCode.tor,
  OTC: TrExchangeCode.pnk,
  BRA: TrExchangeCode.sao,
  CRYPTO: TrExchangeCode.crypto
};
exports.TrExchangeCodeToMarket = (_a = {}, _a[TrExchangeCode.bzx] = 'USA', _a[TrExchangeCode.tor] = 'CAN', _a[TrExchangeCode.pnk] = 'OTC', _a[TrExchangeCode.sao] = 'BRA', //crypto's no longer a "Market"
_a[TrExchangeCode.crypto] = TrExchangeCode.crypto, _a);
exports.MICs = ['BATS', 'XNAS', 'XNYS', 'ARCX', 'XASE', 'OTCB'];
exports.MarketToMics = {
  USA: ['BATS', 'XNAS', 'XNYS', 'ARCX', 'XASE'],
  OTC: ['OTCB'],
  BRA: [],
  CAN: [],
  CRYPTO: []
};
exports.MICToMarket = {
  BATS: 'USA',
  XNAS: 'USA',
  XNYS: 'USA',
  ARCX: 'USA',
  XASE: 'USA',
  OTCB: 'OTC'
};
exports.MICToTrExchangeCode = {
  // US
  BATS: TrExchangeCode.bzx,
  XNAS: TrExchangeCode.bzx,
  XNYS: TrExchangeCode.bzx,
  ARCX: TrExchangeCode.bzx,
  XASE: TrExchangeCode.bzx,
  // OTC
  OTCB: TrExchangeCode.pnk
};
var ListingType;

(function (ListingType) {
  ListingType["IPO"] = "IPO";
  ListingType["Listed"] = "Listed";
  ListingType["Delisted"] = "Delisted";
})(ListingType = exports.ListingType || (exports.ListingType = {}));

exports.AssetTypes = ['equity', 'fund', 'crypto', 'index'];
var PortfolioAssetType;

(function (PortfolioAssetType) {
  PortfolioAssetType["EQUITY"] = "equity";
  PortfolioAssetType["FUND"] = "fund";
  PortfolioAssetType["CRYPTO"] = "crypto";
  PortfolioAssetType["CASH"] = "cash";
})(PortfolioAssetType = exports.PortfolioAssetType || (exports.PortfolioAssetType = {}));

var TransactionType;

(function (TransactionType) {
  TransactionType["BUY"] = "buy";
  TransactionType["SELL"] = "sell";
  TransactionType["DEPOSIT"] = "deposit";
  TransactionType["WITHDRAWAL"] = "withdrawal";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));

var EntityCategory;

(function (EntityCategory) {
  EntityCategory["etf"] = "ETF";
  EntityCategory["symbol"] = "SYMBOL";
  EntityCategory["sector"] = "SECTOR";
  EntityCategory["investor"] = "INVESTOR";
  EntityCategory["mutual_fund"] = "MUTUAL_FUND";
  EntityCategory["fii"] = "FII";
  EntityCategory["bdr"] = "BDR";
  EntityCategory["crypto_category"] = "CRYPTO_CATEGORY";
})(EntityCategory = exports.EntityCategory || (exports.EntityCategory = {}));

var CountryCode;

(function (CountryCode) {
  CountryCode["usa"] = "USA";
  CountryCode["bra"] = "BRA";
  CountryCode["can"] = "CAN";
})(CountryCode = exports.CountryCode || (exports.CountryCode = {}));

exports.Languages = ['en-US', 'pt-BR'];
var DocumentType;

(function (DocumentType) {
  DocumentType["EarningsTranscript"] = "EarningsTranscript";
  DocumentType["10-K"] = "10-K";
  DocumentType["10-Q"] = "10-Q";
  DocumentType["8-K"] = "8-K";
  DocumentType["Presentation"] = "Presentation";
  DocumentType["Other"] = "Other";
})(DocumentType = exports.DocumentType || (exports.DocumentType = {}));

exports.EventTypes = ['BrokerageAnalystCalls', 'BrokerageAnalystMeetings', 'CompanyVisits', 'ConferencePresentations', 'Conferences', 'CorporateAnalystMeetings', 'CorporateCallsAndPresentations', 'EarningsCallsAndPresentations', 'EarningsReleases', 'ExDividends', 'GeneralAndPoliticalEvents', 'GuidanceCallsAndPresentations', 'IpoFilings', 'IpoLockupExpirations', 'IpoPricings', 'MergerAndAcquisitionCallsAndPresentations', 'OtherCorporate', 'SalesAndTradingStatementCallsAndPresentations', 'SalesAndTradingStatementReleases', 'SecondaryFilings', 'SecondaryPricings', 'SecondaryWithdrawals', 'ShareholderAndAnnualMeetings', 'StockSplits', 'SyndicateRoadshows']; //from table https://www.notion.so/atomfinance/Data-Standardization-421fc45f0ddc49309ae5d556fb2b57d9

var AtomAssetType;

(function (AtomAssetType) {
  AtomAssetType["equity"] = "Equity";
  AtomAssetType["crypto"] = "Crypto";
  AtomAssetType["fund"] = "Fund";
  AtomAssetType["index"] = "Index"; //later:
  //fixedIncome = 'Fixed Income',
  //realEstate = 'Real Estate',
  //cash = 'Cash',
  //derivatives = 'Derivatives',
  //currencies = 'Currencies',
  //commodities = 'commodities',
})(AtomAssetType = exports.AtomAssetType || (exports.AtomAssetType = {}));

var AtomAssetSubtype;

(function (AtomAssetSubtype) {
  AtomAssetSubtype["COMMON"] = "Common Stock";
  AtomAssetSubtype["PREFERRED"] = "Preferred Stock";
  AtomAssetSubtype["RIGHTS"] = "Rights";
  AtomAssetSubtype["UNITS"] = "Units";
  AtomAssetSubtype["ADR"] = "American Depository Receipt";
  AtomAssetSubtype["BDR"] = "Brazilian Depository Receipt";
  AtomAssetSubtype["CEF"] = "Closed-End Fund";
  AtomAssetSubtype["OEF"] = "Open-End Fund";
  AtomAssetSubtype["WARRANTS"] = "Warrants";
  AtomAssetSubtype["ETF"] = "Exchange Traded Fund";
  AtomAssetSubtype["MF"] = "Mutual Fund";
  AtomAssetSubtype["CONVERT"] = "Convertible";
  AtomAssetSubtype["CRY"] = "Cryptocurrencies";
  AtomAssetSubtype["REIT"] = "Real Estate Investment Trust";
  AtomAssetSubtype["PAR"] = "Equity - Participation";
  AtomAssetSubtype["INDEX"] = "Index"; //later
  //RE = "Real Estate",
  //LOAN = "Loans",
})(AtomAssetSubtype = exports.AtomAssetSubtype || (exports.AtomAssetSubtype = {}));

var AtomAssetSubtypeCode;

(function (AtomAssetSubtypeCode) {
  AtomAssetSubtypeCode["COMMON"] = "COMMON";
  AtomAssetSubtypeCode["PREFERRED"] = "PREFERRED";
  AtomAssetSubtypeCode["RIGHTS"] = "RIGHTS";
  AtomAssetSubtypeCode["UNITS"] = "UNITS";
  AtomAssetSubtypeCode["ADR"] = "ADR";
  AtomAssetSubtypeCode["BDR"] = "BDR";
  AtomAssetSubtypeCode["CEF"] = "CEF";
  AtomAssetSubtypeCode["OEF"] = "OEF";
  AtomAssetSubtypeCode["WARRANTS"] = "WARRANTS";
  AtomAssetSubtypeCode["ETF"] = "ETF";
  AtomAssetSubtypeCode["MF"] = "MF";
  AtomAssetSubtypeCode["CONVERT"] = "CONVERT";
  AtomAssetSubtypeCode["CRY"] = "CRY";
  AtomAssetSubtypeCode["REIT"] = "REIT";
  AtomAssetSubtypeCode["PAR"] = "PAR";
  AtomAssetSubtypeCode["INDEX"] = "INDEX";
})(AtomAssetSubtypeCode = exports.AtomAssetSubtypeCode || (exports.AtomAssetSubtypeCode = {}));

exports.AssetTypeToSubtypeCode = (_b = {}, _b[AtomAssetType.equity] = [AtomAssetSubtypeCode.COMMON, AtomAssetSubtypeCode.PAR, AtomAssetSubtypeCode.CONVERT, AtomAssetSubtypeCode.PREFERRED, AtomAssetSubtypeCode.RIGHTS, AtomAssetSubtypeCode.UNITS, AtomAssetSubtypeCode.ADR, AtomAssetSubtypeCode.BDR, AtomAssetSubtypeCode.WARRANTS, AtomAssetSubtypeCode.REIT, AtomAssetSubtypeCode.OEF, AtomAssetSubtypeCode.CEF], _b[AtomAssetType.fund] = [AtomAssetSubtypeCode.ETF, AtomAssetSubtypeCode.MF], _b[AtomAssetType.crypto] = [AtomAssetSubtypeCode.CRY], _b[AtomAssetType.index] = [AtomAssetSubtypeCode.INDEX], _b);
exports.SubtypeCodeToAssetType = (_c = {}, _c[AtomAssetSubtypeCode.COMMON] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.PREFERRED] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.RIGHTS] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.UNITS] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.ADR] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.BDR] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.WARRANTS] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.PAR] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.CONVERT] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.CEF] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.OEF] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.ETF] = AtomAssetType.fund, _c[AtomAssetSubtypeCode.MF] = AtomAssetType.fund, _c[AtomAssetSubtypeCode.CRY] = AtomAssetType.crypto, _c[AtomAssetSubtypeCode.REIT] = AtomAssetType.equity, _c[AtomAssetSubtypeCode.INDEX] = AtomAssetType.index, _c);
var AssetCategory;

(function (AssetCategory) {
  AssetCategory["ORD"] = "ORD";
  AssetCategory["DRC"] = "DRC";
  AssetCategory["UNT"] = "UNT";
  AssetCategory["PRF"] = "PRF";
  AssetCategory["CPR"] = "CPR";
  AssetCategory["RTS"] = "RTS";
  AssetCategory["EIW"] = "EIW";
  AssetCategory["ECL"] = "ECL";
  AssetCategory["ETF"] = "ETF";
  AssetCategory["BDR"] = "BDR";
  AssetCategory["FII"] = "FII";
  AssetCategory["PAR"] = "PAR";
  AssetCategory["OPF"] = "OPF";
  AssetCategory["EQI"] = "EQI";
  AssetCategory["CRYPTO"] = "ATOM_CRYPTO";
  AssetCategory["MF"] = "ATOM_MF";
})(AssetCategory = exports.AssetCategory || (exports.AssetCategory = {}));

var RefinitivToIexAssetCategory;

(function (RefinitivToIexAssetCategory) {
  RefinitivToIexAssetCategory["ORD"] = "cs";
  RefinitivToIexAssetCategory["UNT"] = "ut";
  RefinitivToIexAssetCategory["PRF"] = "ps";
  RefinitivToIexAssetCategory["RTS"] = "rt";
  RefinitivToIexAssetCategory["EIW"] = "wt";
  RefinitivToIexAssetCategory["ETF"] = "et";
})(RefinitivToIexAssetCategory = exports.RefinitivToIexAssetCategory || (exports.RefinitivToIexAssetCategory = {}));

exports.TrAssetCategoryToAssetType = (_d = {}, // equity
_d[AssetCategory.ORD] = 'equity', _d[AssetCategory.DRC] = 'equity', _d[AssetCategory.UNT] = 'equity', _d[AssetCategory.PRF] = 'equity', _d[AssetCategory.RTS] = 'equity', _d[AssetCategory.CPR] = 'equity', _d[AssetCategory.EIW] = 'equity', _d[AssetCategory.ECL] = 'equity', _d[AssetCategory.OPF] = 'equity', _d[AssetCategory.PAR] = 'equity', // inter
_d[AssetCategory.BDR] = 'equity', _d[AssetCategory.FII] = 'equity', // fund
_d[AssetCategory.ETF] = 'fund', _d[AssetCategory.MF] = 'fund', // crypto
_d[AssetCategory.CRYPTO] = 'crypto', // index
_d[AssetCategory.EQI] = 'index', _d);
exports.AssetSubtypeCodeToTrAssetCode = (_e = {}, _e[AtomAssetSubtypeCode.COMMON] = AssetCategory.ORD, _e[AtomAssetSubtypeCode.PREFERRED] = AssetCategory.PRF, _e[AtomAssetSubtypeCode.RIGHTS] = AssetCategory.RTS, _e[AtomAssetSubtypeCode.UNITS] = AssetCategory.UNT, _e[AtomAssetSubtypeCode.ADR] = AssetCategory.DRC, _e[AtomAssetSubtypeCode.BDR] = AssetCategory.BDR, _e[AtomAssetSubtypeCode.CEF] = AssetCategory.ECL, _e[AtomAssetSubtypeCode.OEF] = AssetCategory.OPF, _e[AtomAssetSubtypeCode.WARRANTS] = AssetCategory.EIW, _e[AtomAssetSubtypeCode.ETF] = AssetCategory.ETF, _e[AtomAssetSubtypeCode.CONVERT] = AssetCategory.CPR, _e[AtomAssetSubtypeCode.CRY] = AssetCategory.CRYPTO, _e[AtomAssetSubtypeCode.REIT] = AssetCategory.FII, _e[AtomAssetSubtypeCode.PAR] = AssetCategory.PAR, _e[AtomAssetSubtypeCode.MF] = AssetCategory.MF, _e[AtomAssetSubtypeCode.INDEX] = AssetCategory.EQI, _e);
exports.TrToAtomAssetSubtypeCode = (_f = {}, _f[AssetCategory.ORD] = AtomAssetSubtypeCode.COMMON, _f[AssetCategory.PRF] = AtomAssetSubtypeCode.PREFERRED, _f[AssetCategory.RTS] = AtomAssetSubtypeCode.RIGHTS, _f[AssetCategory.UNT] = AtomAssetSubtypeCode.UNITS, _f[AssetCategory.DRC] = AtomAssetSubtypeCode.ADR, _f[AssetCategory.BDR] = AtomAssetSubtypeCode.BDR, _f[AssetCategory.ECL] = AtomAssetSubtypeCode.CEF, _f[AssetCategory.OPF] = AtomAssetSubtypeCode.OEF, _f[AssetCategory.EIW] = AtomAssetSubtypeCode.WARRANTS, _f[AssetCategory.ETF] = AtomAssetSubtypeCode.ETF, _f[AssetCategory.CPR] = AtomAssetSubtypeCode.CONVERT, _f[AssetCategory.FII] = AtomAssetSubtypeCode.REIT, _f[AssetCategory.PAR] = AtomAssetSubtypeCode.PAR, _f[AssetCategory.CRYPTO] = AtomAssetSubtypeCode.CRY, _f[AssetCategory.MF] = AtomAssetSubtypeCode.MF, _f[AssetCategory.EQI] = AtomAssetSubtypeCode.INDEX, _f);
exports.AtomAssetTypes = Object.values(AtomAssetType);
exports.AtomAssetSubtypeCodes = Object.values(AtomAssetSubtypeCode);