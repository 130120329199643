import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AdminContext, defaultAdminContext } from '../../common/AdminContext';
import { LOGOUT, GET_PARTNER_ICON } from '../../common/queries';
import { MobileConfiguration } from '../configurationModals/AddMemberModal';
import Modal from './Modal';
import {
  PaddedGreenClickableButton,
  Spinner,
} from '../../common/styledComponents';

const LogoutModal = ({ closeModal }) => {
  const [logout, { loading }] = useLazyQuery(LOGOUT);
  const { updateAdminContext, partner, admin } = useContext(AdminContext);
  const history = useHistory();

  const onLogout = async () => {
    await logout();
    updateAdminContext(defaultAdminContext);
    history.push('/signin');
  };

  return ReactDOM.createPortal(
    <Background onClick={closeModal}>
      <LogoutContainer
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ContentContainer>
          <OverviewContainer>
            <TextContainer>
              <LargeText>{`${admin?.firstName} ${admin?.lastName}`}</LargeText>
              <SmallText>{partner?.name}</SmallText>
            </TextContainer>
          </OverviewContainer>
          <PaddedGreenClickableButton onClick={onLogout}>
            {loading ? <Spinner smaller={true} /> : `SIGN OUT`}
          </PaddedGreenClickableButton>
        </ContentContainer>
      </LogoutContainer>
    </Background>,
    document.querySelector('#modal') as Element,
  );
};

const ContentContainer = styled.div.attrs({
  className: 'pop-large',
})`
  margin-top: 0.5em;
  padding: 2em;
  border-radius: 4px;
  background-color: var(--atom-brand-3);
`;

const OverviewContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid var(--atom-dark-brand-4);
`;

const IconContainer = styled.img`
  margin-right: 1em;
  border-radius: 10px;
  height: 2.5rem;
  width: 2.5rem;
`;

const TextContainer = styled.div`
  align-content: flex-center;
`;

const LargeText = styled.div`
  font-size: 24px;
`;

const SmallText = styled.div`
  font-size: 18px;
  color: var(--atom-brand-7);
`;

const LogoutContainer = styled.div`
  margin: 5em 2em 1em 1em;
  min-width: 17rem;
`;

const Background = styled.div`
  position: relative;
  z-index: 999;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
`;

const Item = styled.div`
  padding-left: 5px;
  border-bottom: 2px solid var(--atom-dark-brand-4);
  margin-bottom: 10px;
  padding-bottom: 10px;
  cursor: pointer;
`;

export default LogoutModal;
