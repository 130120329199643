"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IntrinioSecurityCodeToTrAssetCategory = void 0;

var assets_1 = require("../general/assets");

exports.IntrinioSecurityCodeToTrAssetCategory = {
  BBR: assets_1.AssetCategory.DRC,
  CEF: assets_1.AssetCategory.ECL,
  DR: assets_1.AssetCategory.DRC,
  EQS: assets_1.AssetCategory.ORD,
  ETC: assets_1.AssetCategory.ETF,
  ETF: assets_1.AssetCategory.ETF,
  UNT: assets_1.AssetCategory.UNT,
  WAR: assets_1.AssetCategory.EIW,
  PRF: assets_1.AssetCategory.PRF
};