import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AdminContext } from '../common/AdminContext';
import OverviewContent from './OverviewContent';
import { GET_PARTNER_ICON } from '../common/queries';
import { useQuery } from '@apollo/react-hooks';

const ApiSettings = () => {
  const { partner } = useContext(AdminContext);
  const partnerIconResponse = useQuery(GET_PARTNER_ICON);
  const partnerIcon = partnerIconResponse?.data?.partnerIcon?.icon;
  const expiry = partner?.expiry ? new Date(partner?.expiry as Date) : null;

  return (
    <OverviewContainer>
      <OverviewHeader>
        <TextContainer>
          <LargeText>API Settings</LargeText>
          <SmallText>
            The settings you make here will apply across all of Atom’s APIs for
            your organization. This makes it easy to customize the markets,
            assets, languages, and content that you’ll receive from our APIs, so
            you don’t have to filter them on your end.
          </SmallText>
          <SmallText>
            Some of these settings will not affect your organizational plan, so
            you can edit them yourself on this screen. Other settings may
            require an update to your plan. Those fields can be edited by your
            Atom account representative.
          </SmallText>
        </TextContainer>
      </OverviewHeader>
      <OverviewContent />
    </OverviewContainer>
  );
};

const OverviewContainer = styled.div`
  margin: 0em 5em;
  display: flex;
  flex-direction: column;
`;

const OverviewHeader = styled.div`
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 1em;
`;

const IconContainer = styled.img`
  margin-right: 2em;
  border-radius: 10px;
  height: 5rem;
  width: 5rem;
`;

const TextContainer = styled.div``;

const LargeText = styled.div`
  font-size: 33px;
`;

const SmallText = styled.div`
  font-size: 16px;
  padding: 0em 0em;
  margin-top: 1em;
`;

export default ApiSettings;
