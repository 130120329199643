"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewsImageSize = exports.NewsSubtypesEnum = exports.NewsTypeEnum = void 0;
var NewsTypeEnum;

(function (NewsTypeEnum) {
  NewsTypeEnum["Business"] = "Business";
  NewsTypeEnum["Climate"] = "Climate";
  NewsTypeEnum["Commodities"] = "Commodities";
  NewsTypeEnum["Crime"] = "Crime";
  NewsTypeEnum["CryptoBlockchain"] = "Crypto & Blockchain";
  NewsTypeEnum["CultureEntertainment"] = "Culture & Entertainment";
  NewsTypeEnum["ESG"] = "ESG";
  NewsTypeEnum["Energy"] = "Energy";
  NewsTypeEnum["FinanceAccounting"] = "Finance & Accounting";
  NewsTypeEnum["GeneralNews"] = "General News";
  NewsTypeEnum["GeographySpecific"] = "Geography-Specific";
  NewsTypeEnum["Geopolitics"] = "Geopolitics";
  NewsTypeEnum["HealthMedicine"] = "Health & Medicine";
  NewsTypeEnum["Ideas"] = "Ideas";
  NewsTypeEnum["Incidents"] = "Incidents";
  NewsTypeEnum["Industries"] = "Industries";
  NewsTypeEnum["Infrastructure"] = "Infrastructure";
  NewsTypeEnum["Institutions"] = "Institutions";
  NewsTypeEnum["Investing"] = "Investing";
  NewsTypeEnum["Investors"] = "Investors";
  NewsTypeEnum["Languages"] = "Languages";
  NewsTypeEnum["Law"] = "Law";
  NewsTypeEnum["Macro"] = "Macro";
  NewsTypeEnum["MacroEconomy"] = "Macro / Economy";
  NewsTypeEnum["MarketActivity"] = "Market Activity";
  NewsTypeEnum["MarketCommentary"] = "Market Commentary";
  NewsTypeEnum["Markets"] = "Markets";
  NewsTypeEnum["Misc"] = "Misc";
  NewsTypeEnum["PolicyRegulation"] = "Policy & Regulation";
  NewsTypeEnum["PoliticsGovernment"] = "Politics & Government";
  NewsTypeEnum["Religion"] = "Religion";
  NewsTypeEnum["Research"] = "Research";
  NewsTypeEnum["Science"] = "Science";
  NewsTypeEnum["Security"] = "Security";
  NewsTypeEnum["SocietySocialIssues"] = "Society & Social Issues";
  NewsTypeEnum["SportsLeisure"] = "Sports & Leisure";
  NewsTypeEnum["SupplyChainLogistics"] = "Supply Chain & Logistics";
  NewsTypeEnum["TechnicalAnalysis"] = "Technical Analysis";
})(NewsTypeEnum = exports.NewsTypeEnum || (exports.NewsTypeEnum = {}));

var NewsSubtypesEnum;

(function (NewsSubtypesEnum) {
  NewsSubtypesEnum["ADRs"] = "ADRs";
  NewsSubtypesEnum["AIDSHIV"] = "AIDS HIV";
  NewsSubtypesEnum["ASEANCountries"] = "ASEAN Countries";
  NewsSubtypesEnum["AccountingIssues"] = "Accounting Issues";
  NewsSubtypesEnum["AdvancedMedicalEquipment"] = "Advanced Medical Equipment";
  NewsSubtypesEnum["Advisories"] = "Advisories";
  NewsSubtypesEnum["AdvocacyGroupsPressureGroupsLobbies"] = "Advocacy Groups Pressure Groups Lobbies";
  NewsSubtypesEnum["Aerospace"] = "Aerospace";
  NewsSubtypesEnum["AerospaceDefense"] = "Aerospace Defense";
  NewsSubtypesEnum["Afghanistan"] = "Afghanistan";
  NewsSubtypesEnum["Africa"] = "Africa";
  NewsSubtypesEnum["AfricaCrudes"] = "Africa Crudes";
  NewsSubtypesEnum["AfricaCupOfNations"] = "Africa Cup Of Nations";
  NewsSubtypesEnum["AfricanUnion"] = "African Union";
  NewsSubtypesEnum["AfterhoursCommentary"] = "After-hours Commentary";
  NewsSubtypesEnum["AfterhoursCommentaryDetailed"] = "After-hours Commentary - Detailed";
  NewsSubtypesEnum["AfterhoursCommentaryMini"] = "After-hours Commentary - Mini";
  NewsSubtypesEnum["AfternoonBriefing"] = "Afternoon Briefing";
  NewsSubtypesEnum["AgencyDebt"] = "Agency Debt";
  NewsSubtypesEnum["AgriculturalChemicals"] = "Agricultural Chemicals";
  NewsSubtypesEnum["AgriculturalMarkets"] = "Agricultural Markets";
  NewsSubtypesEnum["AidReliefHumanitarianAgencies"] = "Aid Relief Humanitarian Agencies";
  NewsSubtypesEnum["AirAccidents"] = "Air Accidents";
  NewsSubtypesEnum["Airlines"] = "Airlines";
  NewsSubtypesEnum["AirportConstruction"] = "Airport Construction";
  NewsSubtypesEnum["AirportServices"] = "Airport Services";
  NewsSubtypesEnum["Alabama"] = "Alabama";
  NewsSubtypesEnum["Alaska"] = "Alaska";
  NewsSubtypesEnum["Albania"] = "Albania";
  NewsSubtypesEnum["AlcoholAbuse"] = "Alcohol Abuse";
  NewsSubtypesEnum["Algeria"] = "Algeria";
  NewsSubtypesEnum["AllPreciousMetalsAndMinerals"] = "All Precious Metals And Minerals";
  NewsSubtypesEnum["AllRetail"] = "All Retail";
  NewsSubtypesEnum["Allergies"] = "Allergies";
  NewsSubtypesEnum["AlpineSkiing"] = "Alpine Skiing";
  NewsSubtypesEnum["AlternativeETFs"] = "Alternative ETFs";
  NewsSubtypesEnum["AlternativeHealth"] = "Alternative Health";
  NewsSubtypesEnum["Alternatives"] = "Alternatives";
  NewsSubtypesEnum["Aluminum"] = "Aluminum";
  NewsSubtypesEnum["AmericanFootball"] = "American Football";
  NewsSubtypesEnum["AmericanSamoa"] = "American Samoa";
  NewsSubtypesEnum["Americas"] = "Americas";
  NewsSubtypesEnum["AmericasCrudes"] = "Americas Crudes";
  NewsSubtypesEnum["AmericasEnergy"] = "Americas Energy";
  NewsSubtypesEnum["Analyses"] = "Analyses";
  NewsSubtypesEnum["AnalysisInsight"] = "Analysis Insight";
  NewsSubtypesEnum["AnalystRatings"] = "Analyst Ratings";
  NewsSubtypesEnum["Andorra"] = "Andorra";
  NewsSubtypesEnum["Angola"] = "Angola";
  NewsSubtypesEnum["Antarctic"] = "Antarctic";
  NewsSubtypesEnum["Antarctica"] = "Antarctica";
  NewsSubtypesEnum["AntiCompetitiveBehavior"] = "Anti Competitive Behavior";
  NewsSubtypesEnum["AntiguaAndBarbuda"] = "Antigua And Barbuda";
  NewsSubtypesEnum["AntitrustPolicyRegulation"] = "Antitrust Policy Regulation";
  NewsSubtypesEnum["AnxietyDisorders"] = "Anxiety Disorders";
  NewsSubtypesEnum["ApparelAccessories"] = "Apparel Accessories";
  NewsSubtypesEnum["AppliedResources"] = "Applied Resources";
  NewsSubtypesEnum["AquaticSports"] = "Aquatic Sports";
  NewsSubtypesEnum["Arabic"] = "Arabic";
  NewsSubtypesEnum["Arabica"] = "Arabica";
  NewsSubtypesEnum["Argentina"] = "Argentina";
  NewsSubtypesEnum["Arizona"] = "Arizona";
  NewsSubtypesEnum["Arkansas"] = "Arkansas";
  NewsSubtypesEnum["Armenia"] = "Armenia";
  NewsSubtypesEnum["Art"] = "Art";
  NewsSubtypesEnum["Arthritis"] = "Arthritis";
  NewsSubtypesEnum["ArtsCultureEntertainment"] = "Arts Culture Entertainment";
  NewsSubtypesEnum["Aruba"] = "Aruba";
  NewsSubtypesEnum["Asia"] = "Asia";
  NewsSubtypesEnum["AsiaCrudes"] = "Asia Crudes";
  NewsSubtypesEnum["AsiaEnergy"] = "Asia Energy";
  NewsSubtypesEnum["AsiaPacific"] = "Asia Pacific";
  NewsSubtypesEnum["AsiaPacificEconomicCooperation"] = "Asia Pacific Economic Cooperation";
  NewsSubtypesEnum["AsianADRs"] = "Asian ADRs";
  NewsSubtypesEnum["AsianGames"] = "Asian Games";
  NewsSubtypesEnum["AssetBackedSecurities"] = "Asset Backed Securities";
  NewsSubtypesEnum["AssetSales"] = "Asset Sales";
  NewsSubtypesEnum["AssociationOfSoutheastAsianNations"] = "Association Of Southeast Asian Nations";
  NewsSubtypesEnum["Asthma"] = "Asthma";
  NewsSubtypesEnum["AsylumImmigrationRefugees"] = "Asylum Immigration Refugees";
  NewsSubtypesEnum["Athletics"] = "Athletics";
  NewsSubtypesEnum["AttentionDeficitHyperactivityDisorder"] = "Attention Deficit Hyperactivity Disorder";
  NewsSubtypesEnum["Australia"] = "Australia";
  NewsSubtypesEnum["AustraliaNewZealand"] = "Australia New Zealand";
  NewsSubtypesEnum["AustralianRulesFootball"] = "Australian Rules Football";
  NewsSubtypesEnum["Austria"] = "Austria";
  NewsSubtypesEnum["AutoGeneratedAlert"] = "Auto Generated Alert";
  NewsSubtypesEnum["AutoTruckManufacturers"] = "Auto Truck Manufacturers";
  NewsSubtypesEnum["AutomobilesAutoParts"] = "Automobiles Auto Parts";
  NewsSubtypesEnum["Automotive"] = "Automotive";
  NewsSubtypesEnum["AutomotiveBatteries"] = "Automotive Batteries";
  NewsSubtypesEnum["Azerbaijan"] = "Azerbaijan";
  NewsSubtypesEnum["BMX"] = "BMX";
  NewsSubtypesEnum["Badminton"] = "Badminton";
  NewsSubtypesEnum["Bahamas"] = "Bahamas";
  NewsSubtypesEnum["Bahrain"] = "Bahrain";
  NewsSubtypesEnum["BalticStates"] = "Baltic States";
  NewsSubtypesEnum["Bangladesh"] = "Bangladesh";
  NewsSubtypesEnum["BankIndonesia"] = "Bank Indonesia";
  NewsSubtypesEnum["BankNegaraMalaysia"] = "Bank Negara Malaysia";
  NewsSubtypesEnum["BankOfCanada"] = "Bank Of Canada";
  NewsSubtypesEnum["BankOfEngland"] = "Bank Of England";
  NewsSubtypesEnum["BankOfJapan"] = "Bank Of Japan";
  NewsSubtypesEnum["BankOfKorea"] = "Bank Of Korea";
  NewsSubtypesEnum["BankOfMexico"] = "Bank Of Mexico";
  NewsSubtypesEnum["BankOfThailand"] = "Bank Of Thailand";
  NewsSubtypesEnum["BankQualified"] = "Bank Qualified";
  NewsSubtypesEnum["BankingCapitalAndLiquidityRequirements"] = "Banking Capital And Liquidity Requirements";
  NewsSubtypesEnum["BankingInvestmentServices"] = "Banking Investment Services";
  NewsSubtypesEnum["BankingServices"] = "Banking Services";
  NewsSubtypesEnum["Bankruptcy"] = "Bankruptcy";
  NewsSubtypesEnum["Barbados"] = "Barbados";
  NewsSubtypesEnum["BargeFreight"] = "Barge Freight";
  NewsSubtypesEnum["Barley"] = "Barley";
  NewsSubtypesEnum["BaseMetals"] = "Base Metals";
  NewsSubtypesEnum["Baseball"] = "Baseball";
  NewsSubtypesEnum["BasicMaterial"] = "Basic Material";
  NewsSubtypesEnum["BasicMaterials"] = "Basic Materials";
  NewsSubtypesEnum["Basketball"] = "Basketball";
  NewsSubtypesEnum["BatteriesUninterruptablePowerSupplies"] = "Batteries Uninterruptable Power Supplies";
  NewsSubtypesEnum["Beef"] = "Beef";
  NewsSubtypesEnum["Belarus"] = "Belarus";
  NewsSubtypesEnum["Belgium"] = "Belgium";
  NewsSubtypesEnum["Belize"] = "Belize";
  NewsSubtypesEnum["BeltAndRoadInitiative"] = "Belt And Road Initiative";
  NewsSubtypesEnum["Benelux"] = "Benelux";
  NewsSubtypesEnum["Benin"] = "Benin";
  NewsSubtypesEnum["Bermuda"] = "Bermuda";
  NewsSubtypesEnum["Beverages"] = "Beverages";
  NewsSubtypesEnum["Bhutan"] = "Bhutan";
  NewsSubtypesEnum["Biathlon"] = "Biathlon";
  NewsSubtypesEnum["Billiards"] = "Billiards";
  NewsSubtypesEnum["Biodiesel"] = "Biodiesel";
  NewsSubtypesEnum["Bioethanol"] = "Bioethanol";
  NewsSubtypesEnum["Biofuels"] = "Biofuels";
  NewsSubtypesEnum["Biomass"] = "Biomass";
  NewsSubtypesEnum["BiomassPowerStations"] = "Biomass Power Stations";
  NewsSubtypesEnum["Biotechnology"] = "Biotechnology";
  NewsSubtypesEnum["BiotechnologyMedicalResearch"] = "Biotechnology Medical Research";
  NewsSubtypesEnum["BipolarDisorder"] = "Bipolar Disorder";
  NewsSubtypesEnum["BlockTrades"] = "Block Trades";
  NewsSubtypesEnum["Blockchain"] = "Blockchain";
  NewsSubtypesEnum["BloodCancer"] = "Blood Cancer";
  NewsSubtypesEnum["BoatingSailing"] = "Boating Sailing";
  NewsSubtypesEnum["Bobsleigh"] = "Bobsleigh";
  NewsSubtypesEnum["Bolivia"] = "Bolivia";
  NewsSubtypesEnum["BombingNonMilitary"] = "Bombing Non Military";
  NewsSubtypesEnum["BonaireSintEustatiusAndSaba"] = "Bonaire Sint Eustatius And Saba";
  NewsSubtypesEnum["BondETFs"] = "Bond ETFs";
  NewsSubtypesEnum["BondInventoryBalances"] = "Bond Inventory Balances";
  NewsSubtypesEnum["Bonds"] = "Bonds";
  NewsSubtypesEnum["BoneMuscleJointDiseases"] = "Bone Muscle Joint Diseases";
  NewsSubtypesEnum["BonusShareIssues"] = "Bonus Share Issues";
  NewsSubtypesEnum["Books"] = "Books";
  NewsSubtypesEnum["BosniaAndHerzegovina"] = "Bosnia And Herzegovina";
  NewsSubtypesEnum["Botswana"] = "Botswana";
  NewsSubtypesEnum["Boxing"] = "Boxing";
  NewsSubtypesEnum["Brazil"] = "Brazil";
  NewsSubtypesEnum["Brewers"] = "Brewers";
  NewsSubtypesEnum["Brexit"] = "Brexit";
  NewsSubtypesEnum["BritishVirginIslands"] = "British Virgin Islands";
  NewsSubtypesEnum["Broadcasting"] = "Broadcasting";
  NewsSubtypesEnum["BrokerResearch"] = "Broker Research";
  NewsSubtypesEnum["Brunei"] = "Brunei";
  NewsSubtypesEnum["Buddhism"] = "Buddhism";
  NewsSubtypesEnum["Bulgaria"] = "Bulgaria";
  NewsSubtypesEnum["BunkerFuel"] = "Bunker Fuel";
  NewsSubtypesEnum["BurkinaFaso"] = "Burkina Faso";
  NewsSubtypesEnum["Burundi"] = "Burundi";
  NewsSubtypesEnum["BusinessSentiment"] = "Business Sentiment";
  NewsSubtypesEnum["BusinessSupportServices"] = "Business Support Services";
  NewsSubtypesEnum["BusinessSupportSupplies"] = "Business Support Supplies";
  NewsSubtypesEnum["CISCountries"] = "CIS Countries";
  NewsSubtypesEnum["CLevelChange"] = "C-Level Change";
  NewsSubtypesEnum["CONCACAFGoldCup"] = "CONCACAF Gold Cup";
  NewsSubtypesEnum["Cadmium"] = "Cadmium";
  NewsSubtypesEnum["California"] = "California";
  NewsSubtypesEnum["Cambodia"] = "Cambodia";
  NewsSubtypesEnum["Cameroon"] = "Cameroon";
  NewsSubtypesEnum["Canada"] = "Canada";
  NewsSubtypesEnum["CanadaCrudes"] = "Canada Crudes";
  NewsSubtypesEnum["Cancer"] = "Cancer";
  NewsSubtypesEnum["Cannabis"] = "Cannabis";
  NewsSubtypesEnum["Canoeing"] = "Canoeing";
  NewsSubtypesEnum["CapeVerde"] = "Cape Verde";
  NewsSubtypesEnum["CarbonEmissionsMarkets"] = "Carbon Emissions Markets";
  NewsSubtypesEnum["CarbonOffsetting"] = "Carbon Offsetting";
  NewsSubtypesEnum["CarbonPricing"] = "Carbon Pricing";
  NewsSubtypesEnum["CardiacDevices"] = "Cardiac Devices";
  NewsSubtypesEnum["Cardio"] = "Cardio";
  NewsSubtypesEnum["Caribbean"] = "Caribbean";
  NewsSubtypesEnum["CaribbeanCentralAmericaEnergy"] = "Caribbean Central America Energy";
  NewsSubtypesEnum["Cattle"] = "Cattle";
  NewsSubtypesEnum["CaymanIslands"] = "Cayman Islands";
  NewsSubtypesEnum["Celebrities"] = "Celebrities";
  NewsSubtypesEnum["CellulosicBiofuels"] = "Cellulosic Biofuels";
  NewsSubtypesEnum["CentralAfrica"] = "Central Africa";
  NewsSubtypesEnum["CentralAfricanRepublic"] = "Central African Republic";
  NewsSubtypesEnum["CentralAmerica"] = "Central America";
  NewsSubtypesEnum["CentralAsia"] = "Central Asia";
  NewsSubtypesEnum["CentralBankOfBrazil"] = "Central Bank Of Brazil";
  NewsSubtypesEnum["CentralBankOfChina"] = "Central Bank Of China";
  NewsSubtypesEnum["CentralBankOfNigeria"] = "Central Bank Of Nigeria";
  NewsSubtypesEnum["CentralBankOfRussia"] = "Central Bank Of Russia";
  NewsSubtypesEnum["CentralBankOfTheRepublicOfTurkey"] = "Central Bank Of The Republic Of Turkey";
  NewsSubtypesEnum["CentralBanks"] = "Central Banks";
  NewsSubtypesEnum["CentralEasternEurope"] = "Central Eastern Europe";
  NewsSubtypesEnum["CentralEasternEuropeEnergy"] = "Central Eastern Europe Energy";
  NewsSubtypesEnum["Chad"] = "Chad";
  NewsSubtypesEnum["ChainingCode"] = "Chaining Code";
  NewsSubtypesEnum["ChampionsLeagueSoccerCompetitions"] = "Champions League Soccer Competitions";
  NewsSubtypesEnum["ChangeOfBoardMember"] = "Change Of Board Member";
  NewsSubtypesEnum["ChangeOfCEO"] = "Change Of CEO";
  NewsSubtypesEnum["ChangeOfCFO"] = "Change Of CFO";
  NewsSubtypesEnum["ChangeOfChairperson"] = "Change Of Chairperson";
  NewsSubtypesEnum["ChangeOfPresident"] = "Change Of President";
  NewsSubtypesEnum["ChannelIslands"] = "Channel Islands";
  NewsSubtypesEnum["Cheese"] = "Cheese";
  NewsSubtypesEnum["Chemicals"] = "Chemicals";
  NewsSubtypesEnum["Chess"] = "Chess";
  NewsSubtypesEnum["ChildrenYouthIssues"] = "Children Youth Issues";
  NewsSubtypesEnum["Chile"] = "Chile";
  NewsSubtypesEnum["China"] = "China";
  NewsSubtypesEnum["ChinaPRC"] = "China PRC";
  NewsSubtypesEnum["Chinese"] = "Chinese";
  NewsSubtypesEnum["ChineseExclusiveNews"] = "Chinese Exclusive News";
  NewsSubtypesEnum["ChineseFactboxes"] = "Chinese Factboxes";
  NewsSubtypesEnum["ChineseOpinionStories"] = "Chinese Opinion Stories";
  NewsSubtypesEnum["ChinesePolls"] = "Chinese Polls";
  NewsSubtypesEnum["ChineseSimplified"] = "Chinese Simplified";
  NewsSubtypesEnum["ChineseTraditional"] = "Chinese Traditional";
  NewsSubtypesEnum["ChineseYuanOffshore"] = "Chinese Yuan Offshore";
  NewsSubtypesEnum["Christianity"] = "Christianity";
  NewsSubtypesEnum["Chromium"] = "Chromium";
  NewsSubtypesEnum["CircularEconomy"] = "Circular Economy";
  NewsSubtypesEnum["CivilUnrest"] = "Civil Unrest";
  NewsSubtypesEnum["ClimateAdaptationAndSolutions"] = "Climate Adaptation And Solutions";
  NewsSubtypesEnum["ClimateChange"] = "Climate Change";
  NewsSubtypesEnum["ClimateFinance"] = "Climate Finance";
  NewsSubtypesEnum["ClimatePolicyAndRegulation"] = "Climate Policy And Regulation";
  NewsSubtypesEnum["ClimatePolitics"] = "Climate Politics";
  NewsSubtypesEnum["Climbing"] = "Climbing";
  NewsSubtypesEnum["ClinicalMedicine"] = "Clinical Medicine";
  NewsSubtypesEnum["Coal"] = "Coal";
  NewsSubtypesEnum["CoalFiredPowerStations"] = "Coal Fired Power Stations";
  NewsSubtypesEnum["Cobalt"] = "Cobalt";
  NewsSubtypesEnum["Cocoa"] = "Cocoa";
  NewsSubtypesEnum["CocosKeelingIslands"] = "Cocos Keeling Islands";
  NewsSubtypesEnum["Coffee"] = "Coffee";
  NewsSubtypesEnum["Colombia"] = "Colombia";
  NewsSubtypesEnum["Colorado"] = "Colorado";
  NewsSubtypesEnum["Commentaries"] = "Commentaries";
  NewsSubtypesEnum["CommercialContractWinsTerminations"] = "Commercial Contract Wins Terminations";
  NewsSubtypesEnum["CommercialMortgageBackedSecurities"] = "Commercial Mortgage Backed Securities";
  NewsSubtypesEnum["CommercialPrintingServices"] = "Commercial Printing Services";
  NewsSubtypesEnum["CommercialREITs"] = "Commercial REITs";
  NewsSubtypesEnum["CommercialServicesSupplies"] = "Commercial Services Supplies";
  NewsSubtypesEnum["Commodities"] = "Commodities";
  NewsSubtypesEnum["CommodityArbitrage"] = "Commodity Arbitrage";
  NewsSubtypesEnum["CommodityChemicals"] = "Commodity Chemicals";
  NewsSubtypesEnum["CommodityETFs"] = "Commodity ET Fs";
  NewsSubtypesEnum["CommodityPhysicalOrCash"] = "Commodity Physical Or Cash";
  NewsSubtypesEnum["CommunicableDiseases"] = "Communicable Diseases";
  NewsSubtypesEnum["CommunicationsEquipment"] = "Communications Equipment";
  NewsSubtypesEnum["Comoros"] = "Comoros";
  NewsSubtypesEnum["CompanyFocus"] = "Company Focus";
  NewsSubtypesEnum["CompanyNews"] = "Company News";
  NewsSubtypesEnum["ComputerElectronicsRetailers"] = "Computer Electronics Retailers";
  NewsSubtypesEnum["ComputerHardware"] = "Computer Hardware";
  NewsSubtypesEnum["CongoDRC"] = "Congo DRC";
  NewsSubtypesEnum["CongoRC"] = "Congo RC";
  NewsSubtypesEnum["Connecticut"] = "Connecticut";
  NewsSubtypesEnum["ConstructionEngineering"] = "Construction Engineering";
  NewsSubtypesEnum["ConstructionMaterials"] = "Construction Materials";
  NewsSubtypesEnum["ConstructionSuppliesFixtures"] = "Construction Supplies Fixtures";
  NewsSubtypesEnum["Consumer"] = "Consumer";
  NewsSubtypesEnum["ConsumerElectronics"] = "Consumer Electronics";
  NewsSubtypesEnum["ConsumerFinancialServices"] = "Consumer Financial Services";
  NewsSubtypesEnum["ConsumerGoods"] = "Consumer Goods";
  NewsSubtypesEnum["ConsumerSentimentSurveys"] = "Consumer Sentiment Surveys";
  NewsSubtypesEnum["ConsumerServices"] = "Consumer Services";
  NewsSubtypesEnum["ContainerCarriers"] = "Container Carriers";
  NewsSubtypesEnum["ContainerFreight"] = "Container Freight";
  NewsSubtypesEnum["ContainersPackaging"] = "Containers Packaging";
  NewsSubtypesEnum["ContainersRoRos"] = "Containers Ro Ros";
  NewsSubtypesEnum["ContentProducedInBangalore"] = "Content Produced In Bangalore";
  NewsSubtypesEnum["ContentProducedInGdynia"] = "Content Produced In Gdynia";
  NewsSubtypesEnum["Contracts"] = "Contracts";
  NewsSubtypesEnum["CookIslands"] = "Cook Islands";
  NewsSubtypesEnum["Copper"] = "Copper";
  NewsSubtypesEnum["Corn"] = "Corn";
  NewsSubtypesEnum["CornOil"] = "Corn Oil";
  NewsSubtypesEnum["Coronavirus"] = "Coronavirus";
  NewsSubtypesEnum["CorporateActivism"] = "Corporate Activism";
  NewsSubtypesEnum["CorporateBankruptcyInsolvency"] = "Corporate Bankruptcy Insolvency";
  NewsSubtypesEnum["CorporateDebtCorporateDebtFinancing"] = "Corporate Debt Corporate Debt Financing";
  NewsSubtypesEnum["CorporateDebtRestructurings"] = "Corporate Debt Restructurings";
  NewsSubtypesEnum["CorporateEvents"] = "Corporate Events";
  NewsSubtypesEnum["CorporateFinance"] = "Corporate Finance";
  NewsSubtypesEnum["CorporateIdentityChanges"] = "Corporate Identity Changes";
  NewsSubtypesEnum["CorporateMarketRegulation"] = "Corporate Market Regulation";
  NewsSubtypesEnum["CorruptionBriberyEmbezzlement"] = "Corruption Bribery Embezzlement";
  NewsSubtypesEnum["CostaRica"] = "Costa Rica";
  NewsSubtypesEnum["Cotton"] = "Cotton";
  NewsSubtypesEnum["CoveredBonds"] = "Covered Bonds";
  NewsSubtypesEnum["CreditDebtRatings"] = "Credit Debt Ratings";
  NewsSubtypesEnum["CreditDefaultSwaps"] = "Credit Default Swaps";
  NewsSubtypesEnum["CreditMarkets"] = "Credit Markets";
  NewsSubtypesEnum["CreditRatings"] = "Credit Ratings";
  NewsSubtypesEnum["Cricket"] = "Cricket";
  NewsSubtypesEnum["Crime"] = "Crime";
  NewsSubtypesEnum["CrimeLawJustice"] = "Crime Law Justice";
  NewsSubtypesEnum["Croatia"] = "Croatia";
  NewsSubtypesEnum["CrossCountrySkiing"] = "Cross Country Skiing";
  NewsSubtypesEnum["Crowdsourcing"] = "Crowdsourcing";
  NewsSubtypesEnum["CrudeOil"] = "Crude Oil";
  NewsSubtypesEnum["CryptoRegulation"] = "Crypto Regulation";
  NewsSubtypesEnum["Cryptocurrencies"] = "Cryptocurrencies";
  NewsSubtypesEnum["Cuba"] = "Cuba";
  NewsSubtypesEnum["Curacao"] = "Curacao";
  NewsSubtypesEnum["Curling"] = "Curling";
  NewsSubtypesEnum["Currencies"] = "Currencies";
  NewsSubtypesEnum["CurrenciesForeignExchangeMarkets"] = "Currencies Foreign Exchange Markets";
  NewsSubtypesEnum["CurrencyDevaluation"] = "Currency Devaluation";
  NewsSubtypesEnum["CurrencyIntervention"] = "Currency Intervention";
  NewsSubtypesEnum["CyclicalConsumerGoodsServices"] = "Cyclical Consumer Goods Services";
  NewsSubtypesEnum["CyclicalConsumerProducts"] = "Cyclical Consumer Products";
  NewsSubtypesEnum["CyclicalConsumerServices"] = "Cyclical Consumer Services";
  NewsSubtypesEnum["Cycling"] = "Cycling";
  NewsSubtypesEnum["Cyprus"] = "Cyprus";
  NewsSubtypesEnum["Czech"] = "Czech";
  NewsSubtypesEnum["CzechRepublic"] = "Czech Republic";
  NewsSubtypesEnum["Dairy"] = "Dairy";
  NewsSubtypesEnum["DataBreach"] = "Data Breach";
  NewsSubtypesEnum["DealTermination"] = "Deal Termination";
  NewsSubtypesEnum["Deals"] = "Deals";
  NewsSubtypesEnum["Dealtalk"] = "Dealtalk";
  NewsSubtypesEnum["DebtFixedIncomeMarkets"] = "Debt Fixed Income Markets";
  NewsSubtypesEnum["Defense"] = "Defense";
  NewsSubtypesEnum["Deforestation"] = "Deforestation";
  NewsSubtypesEnum["Delaware"] = "Delaware";
  NewsSubtypesEnum["Delisting"] = "Delisting";
  NewsSubtypesEnum["Dementia"] = "Dementia";
  NewsSubtypesEnum["Denmark"] = "Denmark";
  NewsSubtypesEnum["DentalConditions"] = "Dental Conditions";
  NewsSubtypesEnum["DepartmentStores"] = "Department Stores";
  NewsSubtypesEnum["Depression"] = "Depression";
  NewsSubtypesEnum["Derivatives"] = "Derivatives";
  NewsSubtypesEnum["DermatologicalDisorders"] = "Dermatological Disorders";
  NewsSubtypesEnum["DeutscheBundesbank"] = "Deutsche Bundesbank";
  NewsSubtypesEnum["DevelopmentFinanceInstitutions"] = "Development Finance Institutions";
  NewsSubtypesEnum["Diabetes"] = "Diabetes";
  NewsSubtypesEnum["DiamondMarkets"] = "Diamond Markets";
  NewsSubtypesEnum["Diaries"] = "Diaries";
  NewsSubtypesEnum["DiplomacyForeignPolicy"] = "Diplomacy Foreign Policy";
  NewsSubtypesEnum["DirectPublicOfferings"] = "Direct Public Offerings";
  NewsSubtypesEnum["DirectorsDealings"] = "Directors Dealings";
  NewsSubtypesEnum["DisastersAccidents"] = "Disasters Accidents";
  NewsSubtypesEnum["DiscountStores"] = "Discount Stores";
  NewsSubtypesEnum["Diseases"] = "Diseases";
  NewsSubtypesEnum["Disruptors"] = "Disruptors";
  NewsSubtypesEnum["DistillersWineries"] = "Distillers Wineries";
  NewsSubtypesEnum["DiversifiedREITs"] = "Diversified REITs";
  NewsSubtypesEnum["DiversifiedRetail"] = "Diversified Retail";
  NewsSubtypesEnum["DiversityAndInclusion"] = "Diversity And Inclusion";
  NewsSubtypesEnum["DivestituresSpinOffs"] = "Divestitures Spin Offs";
  NewsSubtypesEnum["Dividends"] = "Dividends";
  NewsSubtypesEnum["Djibouti"] = "Djibouti";
  NewsSubtypesEnum["DominicanRepublic"] = "Dominican Republic";
  NewsSubtypesEnum["DopingDrugsTests"] = "Doping Drugs Tests";
  NewsSubtypesEnum["Droughts"] = "Droughts";
  NewsSubtypesEnum["DrugDeviceDevelopment"] = "Drug Device Development";
  NewsSubtypesEnum["DrugRetailers"] = "Drug Retailers";
  NewsSubtypesEnum["DrugSafety"] = "Drug Safety";
  NewsSubtypesEnum["DrugSubstanceAbuse"] = "Drug Substance Abuse";
  NewsSubtypesEnum["DrugTraffickingNarcotics"] = "Drug Trafficking Narcotics";
  NewsSubtypesEnum["DrugUpdates"] = "Drug Updates";
  NewsSubtypesEnum["DryBulkFreight"] = "Dry Bulk Freight";
  NewsSubtypesEnum["EMEA"] = "EMEA";
  NewsSubtypesEnum["ERCOT"] = "ERCOT";
  NewsSubtypesEnum["ESG"] = "ESG";
  NewsSubtypesEnum["ESGDisclosureAndTransparency"] = "ESG Disclosure And Transparency";
  NewsSubtypesEnum["ESGEnvironment"] = "ESG Environment";
  NewsSubtypesEnum["ESGGovernance"] = "ESG Governance";
  NewsSubtypesEnum["ESGRatingsAndRankings"] = "ESG Ratings And Rankings";
  NewsSubtypesEnum["ESGRegulation"] = "ESG Regulation";
  NewsSubtypesEnum["ESGSocial"] = "ESG Social";
  NewsSubtypesEnum["ETFs"] = "ETFs";
  NewsSubtypesEnum["EUInstitutions"] = "EU Institutions";
  NewsSubtypesEnum["Earnings"] = "Earnings";
  NewsSubtypesEnum["EarningsGuidance"] = "Earnings Guidance";
  NewsSubtypesEnum["Earthquakes"] = "Earthquakes";
  NewsSubtypesEnum["EastAfrica"] = "East Africa";
  NewsSubtypesEnum["EastAsia"] = "East Asia";
  NewsSubtypesEnum["EastCoastPADDI"] = "East Coast PADD I";
  NewsSubtypesEnum["EastEuropeanCountries"] = "East European Countries";
  NewsSubtypesEnum["EastTimorTimorLeste"] = "East Timor Timor Leste";
  NewsSubtypesEnum["EatingDisorders"] = "Eating Disorders";
  NewsSubtypesEnum["EconomicBriefs"] = "Economic Briefs";
  NewsSubtypesEnum["EconomicData"] = "Economic Data";
  NewsSubtypesEnum["EconomicIndicators"] = "Economic Indicators";
  NewsSubtypesEnum["Economics"] = "Economics";
  NewsSubtypesEnum["Economy"] = "Economy";
  NewsSubtypesEnum["Ecuador"] = "Ecuador";
  NewsSubtypesEnum["Education"] = "Education";
  NewsSubtypesEnum["Educational"] = "Educational";
  NewsSubtypesEnum["Egypt"] = "Egypt";
  NewsSubtypesEnum["ElSalvador"] = "El Salvador";
  NewsSubtypesEnum["Elections"] = "Elections";
  NewsSubtypesEnum["ElectionsVoting"] = "Elections Voting";
  NewsSubtypesEnum["ElectricUtilities"] = "Electric Utilities";
  NewsSubtypesEnum["ElectricalComponentsEquipment"] = "Electrical Components Equipment";
  NewsSubtypesEnum["ElectronicSports"] = "Electronic Sports";
  NewsSubtypesEnum["Embargoed"] = "Embargoed";
  NewsSubtypesEnum["EmergingMarkets"] = "Emerging Markets";
  NewsSubtypesEnum["EmploymentServices"] = "Employment Services";
  NewsSubtypesEnum["Endocrinology"] = "Endocrinology";
  NewsSubtypesEnum["Energy"] = "Energy";
  NewsSubtypesEnum["EnergyMarkets"] = "Energy Markets";
  NewsSubtypesEnum["England"] = "England";
  NewsSubtypesEnum["English"] = "English";
  NewsSubtypesEnum["EnglishSoccer"] = "English Soccer";
  NewsSubtypesEnum["EnterpriseReporting"] = "Enterprise Reporting";
  NewsSubtypesEnum["EntertainmentProduction"] = "Entertainment Production";
  NewsSubtypesEnum["Entrepreneurship"] = "Entrepreneurship";
  NewsSubtypesEnum["Environment"] = "Environment";
  NewsSubtypesEnum["EnvironmentalJustice"] = "Environmental Justice";
  NewsSubtypesEnum["EnvironmentalServices"] = "Environmental Services";
  NewsSubtypesEnum["Epidemics"] = "Epidemics";
  NewsSubtypesEnum["Epidemiology"] = "Epidemiology";
  NewsSubtypesEnum["EquatorialGuinea"] = "Equatorial Guinea";
  NewsSubtypesEnum["Equities"] = "Equities";
  NewsSubtypesEnum["EquitiesMarkets"] = "Equities Markets";
  NewsSubtypesEnum["EquityCapitalChanges"] = "Equity Capital Changes";
  NewsSubtypesEnum["EquityDebtOffering"] = "Equity / Debt Offering";
  NewsSubtypesEnum["EquityETFs"] = "Equity ETFs";
  NewsSubtypesEnum["EquityIndices"] = "Equity Indices";
  NewsSubtypesEnum["EquityLinkedBonds"] = "Equity Linked Bonds";
  NewsSubtypesEnum["EquityStakes"] = "Equity Stakes";
  NewsSubtypesEnum["Eritrea"] = "Eritrea";
  NewsSubtypesEnum["Estonia"] = "Estonia";
  NewsSubtypesEnum["Ethiopia"] = "Ethiopia";
  NewsSubtypesEnum["EuroZone"] = "Euro Zone";
  NewsSubtypesEnum["Eurobonds"] = "Eurobonds";
  NewsSubtypesEnum["EuropaLeague"] = "Europa League";
  NewsSubtypesEnum["Europe"] = "Europe";
  NewsSubtypesEnum["EuropeMiddleEastAfricaCrudes"] = "Europe Middle East Africa Crudes";
  NewsSubtypesEnum["EuropeMiddleEastAfricaEnergy"] = "Europe Middle East Africa Energy";
  NewsSubtypesEnum["EuropeanADRs"] = "European ADRs";
  NewsSubtypesEnum["EuropeanCentralBank"] = "European Central Bank";
  NewsSubtypesEnum["EuropeanInternationalSoccerChampionship"] = "European International Soccer Championship";
  NewsSubtypesEnum["EuropeanUnion"] = "European Union";
  NewsSubtypesEnum["Eurozone"] = "Eurozone";
  NewsSubtypesEnum["EveningBriefing"] = "Evening Briefing";
  NewsSubtypesEnum["ExchangeTradedFunds"] = "Exchange Traded Funds";
  NewsSubtypesEnum["ExchangeTradedProducts"] = "Exchange Traded Products";
  NewsSubtypesEnum["ExcludeStoryFromDNP"] = "Exclude Story From DNP";
  NewsSubtypesEnum["ExcludeStoryFromPSP"] = "Exclude Story From PSP";
  NewsSubtypesEnum["ExcludeStoryFromRNP"] = "Exclude Story From RNP";
  NewsSubtypesEnum["Exclusive"] = "Exclusive";
  NewsSubtypesEnum["ExerciseFitness"] = "Exercise Fitness";
  NewsSubtypesEnum["Explainers"] = "Explainers";
  NewsSubtypesEnum["ExtendedHours"] = "Extended-hours";
  NewsSubtypesEnum["FDA"] = "FDA";
  NewsSubtypesEnum["FIFAConfederationsCup"] = "FIFA Confederations Cup";
  NewsSubtypesEnum["Factboxes"] = "Factboxes";
  NewsSubtypesEnum["FalklandIslands"] = "Falkland Islands";
  NewsSubtypesEnum["FaroeIslands"] = "Faroe Islands";
  NewsSubtypesEnum["Fashion"] = "Fashion";
  NewsSubtypesEnum["Features"] = "Features";
  NewsSubtypesEnum["FederalReserve"] = "Federal Reserve";
  NewsSubtypesEnum["FertilityReproduction"] = "Fertility Reproduction";
  NewsSubtypesEnum["Fertilizers"] = "Fertilizers";
  NewsSubtypesEnum["FieldHockey"] = "Field Hockey";
  NewsSubtypesEnum["FigureSkating"] = "Figure Skating";
  NewsSubtypesEnum["Fiji"] = "Fiji";
  NewsSubtypesEnum["Film"] = "Film";
  NewsSubtypesEnum["Financial"] = "Financial";
  NewsSubtypesEnum["FinancialAdvisors"] = "Financial Advisors";
  NewsSubtypesEnum["FinancialCommodityMarketOperators"] = "Financial Commodity Market Operators";
  NewsSubtypesEnum["FinancialCrime"] = "Financial Crime";
  NewsSubtypesEnum["FinancialRegulation"] = "Financial Regulation";
  NewsSubtypesEnum["Financials"] = "Financials";
  NewsSubtypesEnum["Financing"] = "Financing";
  NewsSubtypesEnum["Finland"] = "Finland";
  NewsSubtypesEnum["Fires"] = "Fires";
  NewsSubtypesEnum["FirstOilChat"] = "First Oil Chat";
  NewsSubtypesEnum["FishingFarming"] = "Fishing Farming";
  NewsSubtypesEnum["Floods"] = "Floods";
  NewsSubtypesEnum["Florida"] = "Florida";
  NewsSubtypesEnum["FoodBeverages"] = "Food Beverages";
  NewsSubtypesEnum["FoodDistributionConvenienceStores"] = "Food Distribution Convenience Stores";
  NewsSubtypesEnum["FoodDrugRetailing"] = "Food Drug Retailing";
  NewsSubtypesEnum["FoodPoisoning"] = "Food Poisoning";
  NewsSubtypesEnum["FoodTobacco"] = "Food Tobacco";
  NewsSubtypesEnum["Footwear"] = "Footwear";
  NewsSubtypesEnum["ForestWoodProducts"] = "Forest Wood Products";
  NewsSubtypesEnum["Forex"] = "Forex";
  NewsSubtypesEnum["FormerSovietUnionEnergy"] = "Former Soviet Union Energy";
  NewsSubtypesEnum["Formula1"] = "Formula 1";
  NewsSubtypesEnum["France"] = "France";
  NewsSubtypesEnum["FreedomOfSpeechCensorship"] = "Freedom Of Speech Censorship";
  NewsSubtypesEnum["FreestyleSkiing"] = "Freestyle Skiing";
  NewsSubtypesEnum["FreightTransportationGround"] = "Freight Transportation Ground";
  NewsSubtypesEnum["French"] = "French";
  NewsSubtypesEnum["FrenchGuiana"] = "French Guiana";
  NewsSubtypesEnum["FrenchLanguageGeneralNews"] = "French Language General News";
  NewsSubtypesEnum["FrenchPolynesia"] = "French Polynesia";
  NewsSubtypesEnum["FrenchSoccer"] = "French Soccer";
  NewsSubtypesEnum["FuelOil"] = "Fuel Oil";
  NewsSubtypesEnum["FundamentalRightsCivilLiberties"] = "Fundamental Rights Civil Liberties";
  NewsSubtypesEnum["Funds"] = "Funds";
  NewsSubtypesEnum["Futures"] = "Futures";
  NewsSubtypesEnum["G20"] = "G 20";
  NewsSubtypesEnum["G7"] = "G 7";
  NewsSubtypesEnum["G8"] = "G 8";
  NewsSubtypesEnum["Gabon"] = "Gabon";
  NewsSubtypesEnum["Gallium"] = "Gallium";
  NewsSubtypesEnum["Gambia"] = "Gambia";
  NewsSubtypesEnum["GasFiredPowerStations"] = "Gas Fired Power Stations";
  NewsSubtypesEnum["Gasoline"] = "Gasoline";
  NewsSubtypesEnum["GastricDisorders"] = "Gastric Disorders";
  NewsSubtypesEnum["GenderPayGap"] = "Gender Pay Gap";
  NewsSubtypesEnum["GeneralCommentary"] = "General Commentary";
  NewsSubtypesEnum["GeneralHealth"] = "General Health";
  NewsSubtypesEnum["GeneralNews"] = "General News";
  NewsSubtypesEnum["GeneralObligationDebt"] = "General Obligation Debt";
  NewsSubtypesEnum["GeneticallyModifiedOrganisms"] = "Genetically Modified Organisms";
  NewsSubtypesEnum["GeopoliticsMarketNews"] = "Geopolitics / Market News";
  NewsSubtypesEnum["GeorgiaCountry"] = "Georgia Country";
  NewsSubtypesEnum["GeorgiaUSState"] = "Georgia US State";
  NewsSubtypesEnum["GeothermalPowerStations"] = "Geothermal Power Stations";
  NewsSubtypesEnum["GeriatricsAgeing"] = "Geriatrics Ageing";
  NewsSubtypesEnum["German"] = "German";
  NewsSubtypesEnum["GermanLanguageEquities"] = "German Language Equities";
  NewsSubtypesEnum["GermanLanguageGeneral"] = "German Language General";
  NewsSubtypesEnum["GermanLanguageMoney"] = "German Language Money";
  NewsSubtypesEnum["GermanSoccer"] = "German Soccer";
  NewsSubtypesEnum["Germanium"] = "Germanium";
  NewsSubtypesEnum["Germany"] = "Germany";
  NewsSubtypesEnum["Ghana"] = "Ghana";
  NewsSubtypesEnum["Gibraltar"] = "Gibraltar";
  NewsSubtypesEnum["Global"] = "Global";
  NewsSubtypesEnum["GolanHeights"] = "Golan Heights";
  NewsSubtypesEnum["Gold"] = "Gold";
  NewsSubtypesEnum["GoldAndMetals"] = "Gold and Metals";
  NewsSubtypesEnum["Golf"] = "Golf";
  NewsSubtypesEnum["GovernmentBorrowingRequirement"] = "Government Borrowing Requirement";
  NewsSubtypesEnum["GovernmentFinances"] = "Government Finances";
  NewsSubtypesEnum["GovernmentPolitics"] = "Government Politics";
  NewsSubtypesEnum["Grains"] = "Grains";
  NewsSubtypesEnum["Graphic"] = "Graphic";
  NewsSubtypesEnum["Graphics"] = "Graphics";
  NewsSubtypesEnum["Greece"] = "Greece";
  NewsSubtypesEnum["Greek"] = "Greek";
  NewsSubtypesEnum["GreenBondsOther"] = "Green Bonds Other";
  NewsSubtypesEnum["Greenland"] = "Greenland";
  NewsSubtypesEnum["Grenada"] = "Grenada";
  NewsSubtypesEnum["GroundAccidentsCollisions"] = "Ground Accidents Collisions";
  NewsSubtypesEnum["Guadeloupe"] = "Guadeloupe";
  NewsSubtypesEnum["Guam"] = "Guam";
  NewsSubtypesEnum["Guatemala"] = "Guatemala";
  NewsSubtypesEnum["Guernsey"] = "Guernsey";
  NewsSubtypesEnum["Guidance"] = "Guidance";
  NewsSubtypesEnum["Guinea"] = "Guinea";
  NewsSubtypesEnum["GuineaBissau"] = "Guinea Bissau";
  NewsSubtypesEnum["Gulf"] = "Gulf";
  NewsSubtypesEnum["GulfCoastPADDIII"] = "Gulf Coast PADD III";
  NewsSubtypesEnum["GunControl"] = "Gun Control";
  NewsSubtypesEnum["Guyana"] = "Guyana";
  NewsSubtypesEnum["Gymnastics"] = "Gymnastics";
  NewsSubtypesEnum["HacksCybercrime"] = "Hacks Cybercrime";
  NewsSubtypesEnum["HaematologyBloodDisorders"] = "Haematology Blood Disorders";
  NewsSubtypesEnum["Haiti"] = "Haiti";
  NewsSubtypesEnum["Handball"] = "Handball";
  NewsSubtypesEnum["Hawaii"] = "Hawaii";
  NewsSubtypesEnum["HeadacheMigraine"] = "Headache Migraine";
  NewsSubtypesEnum["HealthCare"] = "Health Care";
  NewsSubtypesEnum["HealthMedicine"] = "Health Medicine";
  NewsSubtypesEnum["Healthcare"] = "Healthcare";
  NewsSubtypesEnum["HealthcareEquipmentSupplies"] = "Healthcare Equipment Supplies";
  NewsSubtypesEnum["HealthcareFacilitiesServices"] = "Healthcare Facilities Services";
  NewsSubtypesEnum["HealthcarePolicy"] = "Healthcare Policy";
  NewsSubtypesEnum["HealthcareProfessionalDevelopment"] = "Healthcare Professional Development";
  NewsSubtypesEnum["HealthcareProvidersServices"] = "Healthcare Providers Services";
  NewsSubtypesEnum["HealthcareRegulation"] = "Healthcare Regulation";
  NewsSubtypesEnum["HeartCardiacDisease"] = "Heart Cardiac Disease";
  NewsSubtypesEnum["HeartFailureACS"] = "Heart Failure ACS";
  NewsSubtypesEnum["HeatingOilGasoil"] = "Heating Oil Gasoil";
  NewsSubtypesEnum["HeavyElectricalEquipment"] = "Heavy Electrical Equipment";
  NewsSubtypesEnum["HeavyMachineryVehicles"] = "Heavy Machinery Vehicles";
  NewsSubtypesEnum["HedgeFunds"] = "Hedge Funds";
  NewsSubtypesEnum["Hepatitis"] = "Hepatitis";
  NewsSubtypesEnum["HighYieldSecurities"] = "High Yield Securities";
  NewsSubtypesEnum["Highlights"] = "Highlights";
  NewsSubtypesEnum["HijackingPiracy"] = "Hijacking Piracy";
  NewsSubtypesEnum["Hinduism"] = "Hinduism";
  NewsSubtypesEnum["HomeFurnishingsRetailers"] = "Home Furnishings Retailers";
  NewsSubtypesEnum["HomeImprovementProductsServicesRetailers"] = "Home Improvement Products Services Retailers";
  NewsSubtypesEnum["Homebuilding"] = "Homebuilding";
  NewsSubtypesEnum["Honduras"] = "Honduras";
  NewsSubtypesEnum["HongKong"] = "Hong Kong";
  NewsSubtypesEnum["HongKongMonetaryAuthority"] = "Hong Kong Monetary Authority";
  NewsSubtypesEnum["HorseRacing"] = "Horse Racing";
  NewsSubtypesEnum["HotelsEntertainmentServices"] = "Hotels Entertainment Services";
  NewsSubtypesEnum["HotelsMotelsCruiseLines"] = "Hotels Motels Cruise Lines";
  NewsSubtypesEnum["HumanDiseasesConditions"] = "Human Diseases Conditions";
  NewsSubtypesEnum["HumanInterestBrightsOddNews"] = "Human Interest Brights Odd News";
  NewsSubtypesEnum["HumanLedBreaking"] = "Human Led Breaking";
  NewsSubtypesEnum["HumanLedFeature"] = "Human Led Feature";
  NewsSubtypesEnum["HumanLedQuirky"] = "Human Led Quirky";
  NewsSubtypesEnum["HumanLedStories"] = "Human Led Stories";
  NewsSubtypesEnum["HumanRightsCivilRights"] = "Human Rights Civil Rights";
  NewsSubtypesEnum["HungarianNationalBank"] = "Hungarian National Bank";
  NewsSubtypesEnum["Hungary"] = "Hungary";
  NewsSubtypesEnum["HydroelectricPowerStations"] = "Hydroelectric Power Stations";
  NewsSubtypesEnum["IPOs"] = "IPOs";
  NewsSubtypesEnum["ISONE"] = "ISO NE";
  NewsSubtypesEnum["ITServicesConsulting"] = "IT Services Consulting";
  NewsSubtypesEnum["IceHockey"] = "Ice Hockey";
  NewsSubtypesEnum["Iceland"] = "Iceland";
  NewsSubtypesEnum["Idaho"] = "Idaho";
  NewsSubtypesEnum["Illinois"] = "Illinois";
  NewsSubtypesEnum["Immunology"] = "Immunology";
  NewsSubtypesEnum["IndexLinkedBonds"] = "Index Linked Bonds";
  NewsSubtypesEnum["India"] = "India";
  NewsSubtypesEnum["Indiana"] = "Indiana";
  NewsSubtypesEnum["IndicesChanges"] = "Indices Changes";
  NewsSubtypesEnum["IndoChina"] = "Indo China";
  NewsSubtypesEnum["Indonesia"] = "Indonesia";
  NewsSubtypesEnum["Industrial"] = "Industrial";
  NewsSubtypesEnum["IndustrialConglomerates"] = "Industrial Conglomerates";
  NewsSubtypesEnum["IndustrialGoods"] = "Industrial Goods";
  NewsSubtypesEnum["IndustrialMachineryEquipment"] = "Industrial Machinery Equipment";
  NewsSubtypesEnum["IndustrialServices"] = "Industrial Services";
  NewsSubtypesEnum["Industrials"] = "Industrials";
  NewsSubtypesEnum["InfectiousDiseases"] = "Infectious Diseases";
  NewsSubtypesEnum["Inflation"] = "Inflation";
  NewsSubtypesEnum["Influenza"] = "Influenza";
  NewsSubtypesEnum["InformationTechnologiesComputerSciences"] = "Information Technologies Computer Sciences";
  NewsSubtypesEnum["Infrastructure"] = "Infrastructure";
  NewsSubtypesEnum["Initiation"] = "Initiation";
  NewsSubtypesEnum["InsiderTrades"] = "Insider Trades";
  NewsSubtypesEnum["Insights"] = "Insights";
  NewsSubtypesEnum["InstantViews"] = "Instant Views";
  NewsSubtypesEnum["Insurance"] = "Insurance";
  NewsSubtypesEnum["InsuranceBrokers"] = "Insurance Brokers";
  NewsSubtypesEnum["InsuranceLinkedSecurities"] = "Insurance Linked Securities";
  NewsSubtypesEnum["Insurgencies"] = "Insurgencies";
  NewsSubtypesEnum["IntegratedOilGas"] = "Integrated Oil Gas";
  NewsSubtypesEnum["IntegratedTelecommunicationsServices"] = "Integrated Telecommunications Services";
  NewsSubtypesEnum["IntellectualProperty"] = "Intellectual Property";
  NewsSubtypesEnum["IntellectualPropertyCrime"] = "Intellectual Property Crime";
  NewsSubtypesEnum["InterestRatesPolicy"] = "Interest Rates Policy";
  NewsSubtypesEnum["InternalMedicine"] = "Internal Medicine";
  NewsSubtypesEnum["InternationalAgenciesTreatyGroups"] = "International Agencies Treaty Groups";
  NewsSubtypesEnum["InternationalAtomicEnergyAgency"] = "International Atomic Energy Agency";
  NewsSubtypesEnum["InternationalCourtOfJustice"] = "International Court Of Justice";
  NewsSubtypesEnum["InternationalCriminalCourt"] = "International Criminal Court";
  NewsSubtypesEnum["InternationalMonetaryFund"] = "International Monetary Fund";
  NewsSubtypesEnum["InternationalNationalSecurity"] = "International National Security";
  NewsSubtypesEnum["InternationalTrade"] = "International Trade";
  NewsSubtypesEnum["InternetWorldWideWeb"] = "Internet World Wide Web";
  NewsSubtypesEnum["Interviews"] = "Interviews";
  NewsSubtypesEnum["IntradayCommentary"] = "Intraday Commentary";
  NewsSubtypesEnum["IntradayCommentaryDetailed"] = "Intraday Commentary - Detailed";
  NewsSubtypesEnum["IntradayCommentaryMini"] = "Intraday Commentary - Mini";
  NewsSubtypesEnum["InvestmentGradeSecurities"] = "Investment Grade Securities";
  NewsSubtypesEnum["InvestmentManagementFundOperators"] = "Investment Management Fund Operators";
  NewsSubtypesEnum["InvestmentTrusts"] = "Investment Trusts";
  NewsSubtypesEnum["Iowa"] = "Iowa";
  NewsSubtypesEnum["Iran"] = "Iran";
  NewsSubtypesEnum["Iraq"] = "Iraq";
  NewsSubtypesEnum["Ireland"] = "Ireland";
  NewsSubtypesEnum["IronOre"] = "Iron Ore";
  NewsSubtypesEnum["IronSteel"] = "Iron Steel";
  NewsSubtypesEnum["Islam"] = "Islam";
  NewsSubtypesEnum["IslamicFinance"] = "Islamic Finance";
  NewsSubtypesEnum["IsleOfMan"] = "Isle Of Man";
  NewsSubtypesEnum["Israel"] = "Israel";
  NewsSubtypesEnum["Italian"] = "Italian";
  NewsSubtypesEnum["ItalianSoccer"] = "Italian Soccer";
  NewsSubtypesEnum["Italy"] = "Italy";
  NewsSubtypesEnum["IvoryCoast"] = "Ivory Coast";
  NewsSubtypesEnum["JaiAlaiPelota"] = "Jai Alai Pelota";
  NewsSubtypesEnum["Jamaica"] = "Jamaica";
  NewsSubtypesEnum["Japan"] = "Japan";
  NewsSubtypesEnum["JapaneseEditorialSpecials"] = "Japanese Editorial Specials";
  NewsSubtypesEnum["Jersey"] = "Jersey";
  NewsSubtypesEnum["JetFuelKerosene"] = "Jet Fuel Kerosene";
  NewsSubtypesEnum["JobCuts"] = "Job Cuts";
  NewsSubtypesEnum["Jordan"] = "Jordan";
  NewsSubtypesEnum["Judaism"] = "Judaism";
  NewsSubtypesEnum["JudicialProcessCourtCasesCourtDecisions"] = "Judicial Process Court Cases Court Decisions";
  NewsSubtypesEnum["Judo"] = "Judo";
  NewsSubtypesEnum["Kansas"] = "Kansas";
  NewsSubtypesEnum["Kazakhstan"] = "Kazakhstan";
  NewsSubtypesEnum["Kentucky"] = "Kentucky";
  NewsSubtypesEnum["Kenya"] = "Kenya";
  NewsSubtypesEnum["KeyPerformanceIndicators"] = "Key Performance Indicators";
  NewsSubtypesEnum["KeyPersonnelChanges"] = "Key Personnel Changes";
  NewsSubtypesEnum["Kidnapping"] = "Kidnapping";
  NewsSubtypesEnum["Kiribati"] = "Kiribati";
  NewsSubtypesEnum["Korean"] = "Korean";
  NewsSubtypesEnum["Kosovo"] = "Kosovo";
  NewsSubtypesEnum["Kuwait"] = "Kuwait";
  NewsSubtypesEnum["Kyrgyzstan"] = "Kyrgyzstan";
  NewsSubtypesEnum["LaborDisputes"] = "Labor Disputes";
  NewsSubtypesEnum["Laos"] = "Laos";
  NewsSubtypesEnum["LatinAmericaCrudes"] = "Latin America Crudes";
  NewsSubtypesEnum["Latvia"] = "Latvia";
  NewsSubtypesEnum["Lawmaking"] = "Lawmaking";
  NewsSubtypesEnum["Lawsuits"] = "Lawsuits";
  NewsSubtypesEnum["Lead"] = "Lead";
  NewsSubtypesEnum["LeadingIndicators"] = "Leading Indicators";
  NewsSubtypesEnum["Lebanon"] = "Lebanon";
  NewsSubtypesEnum["LegalServices"] = "Legal Services";
  NewsSubtypesEnum["LeisureProducts"] = "Leisure Products";
  NewsSubtypesEnum["LeisureRecreation"] = "Leisure Recreation";
  NewsSubtypesEnum["LesbianGayBisexualTransgender"] = "Lesbian Gay Bisexual Transgender";
  NewsSubtypesEnum["LeveragedLoans"] = "Leveraged Loans";
  NewsSubtypesEnum["Liberia"] = "Liberia";
  NewsSubtypesEnum["Libya"] = "Libya";
  NewsSubtypesEnum["Liechtenstein"] = "Liechtenstein";
  NewsSubtypesEnum["LifeHealthInsurance"] = "Life Health Insurance";
  NewsSubtypesEnum["LifeSciences"] = "Life Sciences";
  NewsSubtypesEnum["LiquefiedNaturalGas"] = "Liquefied Natural Gas";
  NewsSubtypesEnum["LiquefiedPetroleumGas"] = "Liquefied Petroleum Gas";
  NewsSubtypesEnum["Lithium"] = "Lithium";
  NewsSubtypesEnum["Lithuania"] = "Lithuania";
  NewsSubtypesEnum["LiverDisease"] = "Liver Disease";
  NewsSubtypesEnum["Livestock"] = "Livestock";
  NewsSubtypesEnum["LivingLifestyle"] = "Living Lifestyle";
  NewsSubtypesEnum["Loans"] = "Loans";
  NewsSubtypesEnum["LongIdeas"] = "Long Ideas";
  NewsSubtypesEnum["Louisiana"] = "Louisiana";
  NewsSubtypesEnum["LowerHouseElections"] = "Lower House Elections";
  NewsSubtypesEnum["Luge"] = "Luge";
  NewsSubtypesEnum["LungCancer"] = "Lung Cancer";
  NewsSubtypesEnum["Luxembourg"] = "Luxembourg";
  NewsSubtypesEnum["LuxuryGoodsServices"] = "Luxury Goods Services";
  NewsSubtypesEnum["M&A"] = "M&A";
  NewsSubtypesEnum["Macau"] = "Macau";
  NewsSubtypesEnum["MachineGeneratedContent"] = "Machine Generated Content";
  NewsSubtypesEnum["MachineryEquipmentComponents"] = "Machinery Equipment Components";
  NewsSubtypesEnum["Madagascar"] = "Madagascar";
  NewsSubtypesEnum["Maine"] = "Maine";
  NewsSubtypesEnum["MajorNews"] = "Major News";
  NewsSubtypesEnum["Malaria"] = "Malaria";
  NewsSubtypesEnum["Malawi"] = "Malawi";
  NewsSubtypesEnum["Malaysia"] = "Malaysia";
  NewsSubtypesEnum["Maldives"] = "Maldives";
  NewsSubtypesEnum["Mali"] = "Mali";
  NewsSubtypesEnum["Malta"] = "Malta";
  NewsSubtypesEnum["ManagedHealthcare"] = "Managed Healthcare";
  NewsSubtypesEnum["Management"] = "Management";
  NewsSubtypesEnum["ManagementIssuesPolicies"] = "Management Issues Policies";
  NewsSubtypesEnum["ManagementPay"] = "Management Pay";
  NewsSubtypesEnum["Manganese"] = "Manganese";
  NewsSubtypesEnum["Manufacturing"] = "Manufacturing";
  NewsSubtypesEnum["Marathon"] = "Marathon";
  NewsSubtypesEnum["MarketEvents"] = "Market Events";
  NewsSubtypesEnum["MarketReports"] = "Market Reports";
  NewsSubtypesEnum["MarketedFollowOns"] = "Marketed Follow Ons";
  NewsSubtypesEnum["Markets"] = "Markets";
  NewsSubtypesEnum["MarshallIslands"] = "Marshall Islands";
  NewsSubtypesEnum["MartialArts"] = "Martial Arts";
  NewsSubtypesEnum["Martinique"] = "Martinique";
  NewsSubtypesEnum["Maryland"] = "Maryland";
  NewsSubtypesEnum["Massachusetts"] = "Massachusetts";
  NewsSubtypesEnum["MatchPreviews"] = "Match Previews";
  NewsSubtypesEnum["MatchReports"] = "Match Reports";
  NewsSubtypesEnum["Mauritania"] = "Mauritania";
  NewsSubtypesEnum["Mauritius"] = "Mauritius";
  NewsSubtypesEnum["MealsFeedsPulses"] = "Meals Feeds Pulses";
  NewsSubtypesEnum["Media"] = "Media";
  NewsSubtypesEnum["MediaPublishing"] = "Media Publishing";
  NewsSubtypesEnum["MedicalConferences"] = "Medical Conferences";
  NewsSubtypesEnum["MedicalEquipmentSuppliesDistribution"] = "Medical Equipment Supplies Distribution";
  NewsSubtypesEnum["MedicalEthics"] = "Medical Ethics";
  NewsSubtypesEnum["MedicalLaw"] = "Medical Law";
  NewsSubtypesEnum["MediterraneanNorthAfricaEnergy"] = "Mediterranean North Africa Energy";
  NewsSubtypesEnum["MensHealth"] = "Mens Health";
  NewsSubtypesEnum["MentalIllness"] = "Mental Illness";
  NewsSubtypesEnum["MergersAcquisitions"] = "Mergers & Acquisitions";
  NewsSubtypesEnum["MetalsMarkets"] = "Metals Markets";
  NewsSubtypesEnum["MetalsMining"] = "Metals Mining";
  NewsSubtypesEnum["Metaverse"] = "Metaverse";
  NewsSubtypesEnum["Mexico"] = "Mexico";
  NewsSubtypesEnum["Michigan"] = "Michigan";
  NewsSubtypesEnum["Micronesia"] = "Micronesia";
  NewsSubtypesEnum["MiddleDistillates"] = "Middle Distillates";
  NewsSubtypesEnum["MiddleEast"] = "Middle East";
  NewsSubtypesEnum["MidwestPADDII"] = "Midwest PADD II";
  NewsSubtypesEnum["MilitaryConflicts"] = "Military Conflicts";
  NewsSubtypesEnum["MilitaryProcurement"] = "Military Procurement";
  NewsSubtypesEnum["Milk"] = "Milk";
  NewsSubtypesEnum["MineralResources"] = "Mineral Resources";
  NewsSubtypesEnum["Mining"] = "Mining";
  NewsSubtypesEnum["MiningExploration"] = "Mining & Exploration";
  NewsSubtypesEnum["Minnesota"] = "Minnesota";
  NewsSubtypesEnum["MinorMetalsMinerals"] = "Minor Metals Minerals";
  NewsSubtypesEnum["MiscellaneousSpecialtyRetailers"] = "Miscellaneous Specialty Retailers";
  NewsSubtypesEnum["Mississippi"] = "Mississippi";
  NewsSubtypesEnum["Missouri"] = "Missouri";
  NewsSubtypesEnum["MixedMartialArts"] = "Mixed Martial Arts";
  NewsSubtypesEnum["Moldova"] = "Moldova";
  NewsSubtypesEnum["Molybdenum"] = "Molybdenum";
  NewsSubtypesEnum["Monaco"] = "Monaco";
  NewsSubtypesEnum["MonetaryAuthorityOfSingapore"] = "Monetary Authority Of Singapore";
  NewsSubtypesEnum["MonetaryFiscalPolicyPolicyMakers"] = "Monetary Fiscal Policy Policy Makers";
  NewsSubtypesEnum["MoneyMarkets"] = "Money Markets";
  NewsSubtypesEnum["Mongolia"] = "Mongolia";
  NewsSubtypesEnum["Montana"] = "Montana";
  NewsSubtypesEnum["Montenegro"] = "Montenegro";
  NewsSubtypesEnum["MorningBriefing"] = "Morning Briefing";
  NewsSubtypesEnum["Morocco"] = "Morocco";
  NewsSubtypesEnum["MortgageBackedSecurities"] = "Mortgage Backed Securities";
  NewsSubtypesEnum["MotorRacing"] = "Motor Racing";
  NewsSubtypesEnum["MotorcycleRacing"] = "Motorcycle Racing";
  NewsSubtypesEnum["Mozambique"] = "Mozambique";
  NewsSubtypesEnum["MultilineInsurance"] = "Multiline Insurance";
  NewsSubtypesEnum["MultilineUtilities"] = "Multiline Utilities";
  NewsSubtypesEnum["MultipleSclerosis"] = "Multiple Sclerosis";
  NewsSubtypesEnum["Muni"] = "Muni";
  NewsSubtypesEnum["MunicipalBonds"] = "Municipal Bonds";
  NewsSubtypesEnum["MurderSuspectedMurder"] = "Murder Suspected Murder";
  NewsSubtypesEnum["Music"] = "Music";
  NewsSubtypesEnum["Myanmar"] = "Myanmar";
  NewsSubtypesEnum["NBABasketball"] = "NBA Basketball";
  NewsSubtypesEnum["NFLAmericanFootball"] = "NFL American Football";
  NewsSubtypesEnum["NHLIceHockey"] = "NHL Ice Hockey";
  NewsSubtypesEnum["NameChange"] = "Name Change";
  NewsSubtypesEnum["Namibia"] = "Namibia";
  NewsSubtypesEnum["Naphtha"] = "Naphtha";
  NewsSubtypesEnum["NationalBankOfPoland"] = "National Bank Of Poland";
  NewsSubtypesEnum["NationalGovernmentDebt"] = "National Government Debt";
  NewsSubtypesEnum["NaturalGas"] = "Natural Gas";
  NewsSubtypesEnum["NaturalGasUtilities"] = "Natural Gas Utilities";
  NewsSubtypesEnum["NatureWildlife"] = "Nature Wildlife";
  NewsSubtypesEnum["Nauru"] = "Nauru";
  NewsSubtypesEnum["Nebraska"] = "Nebraska";
  NewsSubtypesEnum["Nepal"] = "Nepal";
  NewsSubtypesEnum["NephrologicalDisorders"] = "Nephrological Disorders";
  NewsSubtypesEnum["NerveInjury"] = "Nerve Injury";
  NewsSubtypesEnum["Netherlands"] = "Netherlands";
  NewsSubtypesEnum["NeurologicalDisorders"] = "Neurological Disorders";
  NewsSubtypesEnum["Nevada"] = "Nevada";
  NewsSubtypesEnum["NewCaledonia"] = "New Caledonia";
  NewsSubtypesEnum["NewETFs"] = "New ETFs";
  NewsSubtypesEnum["NewHampshire"] = "New Hampshire";
  NewsSubtypesEnum["NewIssues"] = "New Issues";
  NewsSubtypesEnum["NewJersey"] = "New Jersey";
  NewsSubtypesEnum["NewMexico"] = "New Mexico";
  NewsSubtypesEnum["NewProjectsVentures"] = "New Projects / Ventures";
  NewsSubtypesEnum["NewYork"] = "New York";
  NewsSubtypesEnum["NewYorkCity"] = "New York City";
  NewsSubtypesEnum["NewZealand"] = "New Zealand";
  NewsSubtypesEnum["News"] = "News";
  NewsSubtypesEnum["NewsAnnouncements"] = "News Announcements";
  NewsSubtypesEnum["Newsmakers"] = "Newsmakers";
  NewsSubtypesEnum["Nicaragua"] = "Nicaragua";
  NewsSubtypesEnum["Nickel"] = "Nickel";
  NewsSubtypesEnum["Niger"] = "Niger";
  NewsSubtypesEnum["Nigeria"] = "Nigeria";
  NewsSubtypesEnum["Niobium"] = "Niobium";
  NewsSubtypesEnum["Niue"] = "Niue";
  NewsSubtypesEnum["NonCyclicalConsumerGoodsServices"] = "Non Cyclical Consumer Goods Services";
  NewsSubtypesEnum["NonDeliverableForwards"] = "Non Deliverable Forwards";
  NewsSubtypesEnum["NonGovernmentalOrganizations"] = "Non Governmental Organizations";
  NewsSubtypesEnum["NordicSkiing"] = "Nordic Skiing";
  NewsSubtypesEnum["NordicStates"] = "Nordic States";
  NewsSubtypesEnum["NorfolkIsland"] = "Norfolk Island";
  NewsSubtypesEnum["NorgesBank"] = "Norges Bank";
  NewsSubtypesEnum["NorthAfrica"] = "North Africa";
  NewsSubtypesEnum["NorthAmerica"] = "North America";
  NewsSubtypesEnum["NorthAmericaEnergy"] = "North America Energy";
  NewsSubtypesEnum["NorthAmericanFreeTradeAgreement"] = "North American Free Trade Agreement";
  NewsSubtypesEnum["NorthAsia"] = "North Asia";
  NewsSubtypesEnum["NorthAsiaCrudes"] = "North Asia Crudes";
  NewsSubtypesEnum["NorthAtlanticTreatyOrganization"] = "North Atlantic Treaty Organization";
  NewsSubtypesEnum["NorthCarolina"] = "North Carolina";
  NewsSubtypesEnum["NorthDakota"] = "North Dakota";
  NewsSubtypesEnum["NorthKorea"] = "North Korea";
  NewsSubtypesEnum["NorthMacedonia"] = "North Macedonia";
  NewsSubtypesEnum["NorthWestEuropeEnergy"] = "North West Europe Energy";
  NewsSubtypesEnum["NorthernIreland"] = "Northern Ireland";
  NewsSubtypesEnum["NorthernMarianaIslands"] = "Northern Mariana Islands";
  NewsSubtypesEnum["Norway"] = "Norway";
  NewsSubtypesEnum["NuclearArmamentsNuclearProliferation"] = "Nuclear Armaments Nuclear Proliferation";
  NewsSubtypesEnum["Nursing"] = "Nursing";
  NewsSubtypesEnum["OPEC"] = "OPEC";
  NewsSubtypesEnum["Obesity"] = "Obesity";
  NewsSubtypesEnum["Obituaries"] = "Obituaries";
  NewsSubtypesEnum["ObstetricGynaecologicalConditions"] = "Obstetric Gynaecological Conditions";
  NewsSubtypesEnum["Offerings"] = "Offerings";
  NewsSubtypesEnum["OfficeEquipment"] = "Office Equipment";
  NewsSubtypesEnum["OfficeOfTheUNHighCommissionerForRefugees"] = "Office Of The UN High Commissioner For Refugees";
  NewsSubtypesEnum["OfficialReserves"] = "Official Reserves";
  NewsSubtypesEnum["Ohio"] = "Ohio";
  NewsSubtypesEnum["Oil"] = "Oil";
  NewsSubtypesEnum["OilAndGas"] = "Oil and Gas";
  NewsSubtypesEnum["OilFiredPowerStations"] = "Oil Fired Power Stations";
  NewsSubtypesEnum["OilGas"] = "Oil Gas";
  NewsSubtypesEnum["OilGasDrilling"] = "Oil Gas Drilling";
  NewsSubtypesEnum["OilGasRefiningAndMarketing"] = "Oil Gas Refining And Marketing";
  NewsSubtypesEnum["OilGasTransportationServices"] = "Oil Gas Transportation Services";
  NewsSubtypesEnum["OilRefineries"] = "Oil Refineries";
  NewsSubtypesEnum["OilRelatedServicesAndEquipment"] = "Oil Related Services And Equipment";
  NewsSubtypesEnum["OilStorage"] = "Oil Storage";
  NewsSubtypesEnum["Oilseeds"] = "Oilseeds";
  NewsSubtypesEnum["Oklahoma"] = "Oklahoma";
  NewsSubtypesEnum["OliveOil"] = "Olive Oil";
  NewsSubtypesEnum["Olympics"] = "Olympics";
  NewsSubtypesEnum["OlympicsFactboxes"] = "Olympics Factboxes";
  NewsSubtypesEnum["OlympicsMedals"] = "Olympics Medals";
  NewsSubtypesEnum["OlympicsMedalsTables"] = "Olympics Medals Tables";
  NewsSubtypesEnum["Oman"] = "Oman";
  NewsSubtypesEnum["OpeningClosingCeremonies"] = "Opening Closing Ceremonies";
  NewsSubtypesEnum["OperationsUpdate"] = "Operations Update";
  NewsSubtypesEnum["OphthalmologicalDisorders"] = "Ophthalmological Disorders";
  NewsSubtypesEnum["Options"] = "Options";
  NewsSubtypesEnum["OrangeJuice"] = "Orange Juice";
  NewsSubtypesEnum["OrderImbalance"] = "Order Imbalance";
  NewsSubtypesEnum["Oregon"] = "Oregon";
  NewsSubtypesEnum["OrganisationForEconomicCoOperationAndDevelopment"] = "Organisation For Economic Co Operation And Development";
  NewsSubtypesEnum["OrganizationForSecurityAndCoOperationInEurope"] = "Organization For Security And Co Operation In Europe";
  NewsSubtypesEnum["OrganizationalRestructuring"] = "Organizational Restructuring";
  NewsSubtypesEnum["Orthopaedics"] = "Orthopaedics";
  NewsSubtypesEnum["OtherAfricaEnergy"] = "Other Africa Energy";
  NewsSubtypesEnum["OverseasDevelopmentAid"] = "Overseas Development Aid";
  NewsSubtypesEnum["PDFs"] = "PD Fs";
  NewsSubtypesEnum["PacificEnergy"] = "Pacific Energy";
  NewsSubtypesEnum["PacificIslands"] = "Pacific Islands";
  NewsSubtypesEnum["PaediatricMedicine"] = "Paediatric Medicine";
  NewsSubtypesEnum["PainManagement"] = "Pain Management";
  NewsSubtypesEnum["Pakistan"] = "Pakistan";
  NewsSubtypesEnum["Palau"] = "Palau";
  NewsSubtypesEnum["PalestinianTerritories"] = "Palestinian Territories";
  NewsSubtypesEnum["Palladium"] = "Palladium";
  NewsSubtypesEnum["PalmOil"] = "Palm Oil";
  NewsSubtypesEnum["Panama"] = "Panama";
  NewsSubtypesEnum["PaperForestProducts"] = "Paper Forest Products";
  NewsSubtypesEnum["PaperProducts"] = "Paper Products";
  NewsSubtypesEnum["PapuaNewGuinea"] = "Papua New Guinea";
  NewsSubtypesEnum["Paraguay"] = "Paraguay";
  NewsSubtypesEnum["Paralympics"] = "Paralympics";
  NewsSubtypesEnum["ParkinsonsDisease"] = "Parkinsons Disease";
  NewsSubtypesEnum["PassengerTransportationGroundSea"] = "Passenger Transportation Ground Sea";
  NewsSubtypesEnum["PeanutOil"] = "Peanut Oil";
  NewsSubtypesEnum["Pennsylvania"] = "Pennsylvania";
  NewsSubtypesEnum["PennyStocks"] = "Penny Stocks";
  NewsSubtypesEnum["PeopleMoves"] = "People Moves";
  NewsSubtypesEnum["PeoplesBankOfChina"] = "Peoples Bank Of China";
  NewsSubtypesEnum["PeripheralVascularDisease"] = "Peripheral Vascular Disease";
  NewsSubtypesEnum["PersonalFinance"] = "Personal Finance";
  NewsSubtypesEnum["PersonalInvesting"] = "Personal Investing";
  NewsSubtypesEnum["PersonalServices"] = "Personal Services";
  NewsSubtypesEnum["PersonalitiesPeople"] = "Personalities People";
  NewsSubtypesEnum["Peru"] = "Peru";
  NewsSubtypesEnum["Petrochemicals"] = "Petrochemicals";
  NewsSubtypesEnum["Pharmaceuticals"] = "Pharmaceuticals";
  NewsSubtypesEnum["PharmaceuticalsMedicalResearch"] = "Pharmaceuticals Medical Research";
  NewsSubtypesEnum["PhilanthropyCharity"] = "Philanthropy Charity";
  NewsSubtypesEnum["Philippines"] = "Philippines";
  NewsSubtypesEnum["Phosphates"] = "Phosphates";
  NewsSubtypesEnum["PictureAvailable"] = "Picture Available";
  NewsSubtypesEnum["Pictures"] = "Pictures";
  NewsSubtypesEnum["PigsHogs"] = "Pigs Hogs";
  NewsSubtypesEnum["Pipelines"] = "Pipelines";
  NewsSubtypesEnum["Platinum"] = "Platinum";
  NewsSubtypesEnum["PlatinumGroupMetals"] = "Platinum Group Metals";
  NewsSubtypesEnum["Poland"] = "Poland";
  NewsSubtypesEnum["Polish"] = "Polish";
  NewsSubtypesEnum["PoliticalLobbying"] = "Political Lobbying";
  NewsSubtypesEnum["Politics"] = "Politics";
  NewsSubtypesEnum["Polls"] = "Polls";
  NewsSubtypesEnum["Pollution"] = "Pollution";
  NewsSubtypesEnum["PolymersPlastics"] = "Polymers Plastics";
  NewsSubtypesEnum["Pork"] = "Pork";
  NewsSubtypesEnum["PorkBellies"] = "Pork Bellies";
  NewsSubtypesEnum["Ports"] = "Ports";
  NewsSubtypesEnum["Portugal"] = "Portugal";
  NewsSubtypesEnum["Portuguese"] = "Portuguese";
  NewsSubtypesEnum["Potash"] = "Potash";
  NewsSubtypesEnum["Poultry"] = "Poultry";
  NewsSubtypesEnum["PowerGeneration"] = "Power Generation";
  NewsSubtypesEnum["PowerMarkets"] = "Power Markets";
  NewsSubtypesEnum["PreciousMetals"] = "Precious Metals";
  NewsSubtypesEnum["PreciousMetalsMinerals"] = "Precious Metals Minerals";
  NewsSubtypesEnum["Precipitation"] = "Precipitation";
  NewsSubtypesEnum["PregnancyNewborn"] = "Pregnancy Newborn";
  NewsSubtypesEnum["Premarket"] = "Pre-market";
  NewsSubtypesEnum["PremarketCommentaryDetailed"] = "Pre-market Commentary - Detailed";
  NewsSubtypesEnum["PremarketCommentaryMini"] = "Pre-market Commentary - Mini";
  NewsSubtypesEnum["PresidentialElections"] = "Presidential Elections";
  NewsSubtypesEnum["PressDigests"] = "Press Digests";
  NewsSubtypesEnum["PressReleases"] = "Press Releases";
  NewsSubtypesEnum["Previews"] = "Previews";
  NewsSubtypesEnum["PreviewsSchedulesDiaries"] = "Previews Schedules Diaries";
  NewsSubtypesEnum["PriceMovements"] = "Price Movements";
  NewsSubtypesEnum["PriceTargets"] = "Price Targets";
  NewsSubtypesEnum["PrivacyDataProtection"] = "Privacy Data Protection";
  NewsSubtypesEnum["PrivateCompanyNews"] = "Private Company News";
  NewsSubtypesEnum["PrivateEquityFunds"] = "Private Equity Funds";
  NewsSubtypesEnum["Privatization"] = "Privatization";
  NewsSubtypesEnum["ProductLaunches"] = "Product Launches";
  NewsSubtypesEnum["ProductRecalls"] = "Product Recalls";
  NewsSubtypesEnum["ProductionStatusChanges"] = "Production Status Changes";
  NewsSubtypesEnum["ProductsServices"] = "Products Services";
  NewsSubtypesEnum["ProjectFinance"] = "Project Finance";
  NewsSubtypesEnum["ProjectSponsors"] = "Project Sponsors";
  NewsSubtypesEnum["PropertyCasualtyInsurance"] = "Property Casualty Insurance";
  NewsSubtypesEnum["PropertyTransaction"] = "Property Transaction";
  NewsSubtypesEnum["ProstateCancer"] = "Prostate Cancer";
  NewsSubtypesEnum["ProxyFights"] = "Proxy Fights";
  NewsSubtypesEnum["Psychology"] = "Psychology";
  NewsSubtypesEnum["PublicHealth"] = "Public Health";
  NewsSubtypesEnum["Publishing"] = "Publishing";
  NewsSubtypesEnum["PuertoRico"] = "Puerto Rico";
  NewsSubtypesEnum["PurchasingManagersSurveys"] = "Purchasing Managers Surveys";
  NewsSubtypesEnum["PureContainerCarriers"] = "Pure Container Carriers";
  NewsSubtypesEnum["Qatar"] = "Qatar";
  NewsSubtypesEnum["REITs"] = "REITs";
  NewsSubtypesEnum["RaceRelationsEthnicIssues"] = "Race Relations Ethnic Issues";
  NewsSubtypesEnum["RailFreight"] = "Rail Freight";
  NewsSubtypesEnum["RapeseedCanolaOil"] = "Rapeseed Canola Oil";
  NewsSubtypesEnum["RareEarths"] = "Rare Earths";
  NewsSubtypesEnum["RatingAgencies"] = "Rating Agencies";
  NewsSubtypesEnum["RealEstate"] = "Real Estate";
  NewsSubtypesEnum["RealEstateMarkets"] = "Real Estate Markets";
  NewsSubtypesEnum["RealEstateOperations"] = "Real Estate Operations";
  NewsSubtypesEnum["RealEstateServices"] = "Real Estate Services";
  NewsSubtypesEnum["Recapitalization"] = "Recapitalization";
  NewsSubtypesEnum["Recycling"] = "Recycling";
  NewsSubtypesEnum["RefinedProducts"] = "Refined Products";
  NewsSubtypesEnum["Regulations"] = "Regulations";
  NewsSubtypesEnum["RegulatoryAction"] = "Regulatory Action";
  NewsSubtypesEnum["RegulatoryCorporateNewsAnnouncements"] = "Regulatory Corporate News Announcements";
  NewsSubtypesEnum["RegulatorySystemEnzymeDisorders"] = "Regulatory System Enzyme Disorders";
  NewsSubtypesEnum["Reinsurance"] = "Reinsurance";
  NewsSubtypesEnum["ReligionBelief"] = "Religion Belief";
  NewsSubtypesEnum["RenewableEnergy"] = "Renewable Energy";
  NewsSubtypesEnum["RenewableEnergyEquipmentServices"] = "Renewable Energy Equipment Services";
  NewsSubtypesEnum["RenewableEnergyPowerStations"] = "Renewable Energy Power Stations";
  NewsSubtypesEnum["RenewableFuels"] = "Renewable Fuels";
  NewsSubtypesEnum["Reports"] = "Reports";
  NewsSubtypesEnum["ReserveBankOfAustralia"] = "Reserve Bank Of Australia";
  NewsSubtypesEnum["ReserveBankOfIndia"] = "Reserve Bank Of India";
  NewsSubtypesEnum["ReserveBankOfNewZealand"] = "Reserve Bank Of New Zealand";
  NewsSubtypesEnum["ResidentialCommercialREITs"] = "Residential Commercial REITs";
  NewsSubtypesEnum["ResidentialMortgageBackedSecurities"] = "Residential Mortgage Backed Securities";
  NewsSubtypesEnum["ResidentialREITs"] = "Residential REITs";
  NewsSubtypesEnum["RespiratoryConditions"] = "Respiratory Conditions";
  NewsSubtypesEnum["Restaurants"] = "Restaurants";
  NewsSubtypesEnum["Restructuring"] = "Restructuring";
  NewsSubtypesEnum["ResultsForecastsWarnings"] = "Results Forecasts Warnings";
  NewsSubtypesEnum["RetailSales"] = "Retail Sales";
  NewsSubtypesEnum["RetailersExclFoodAndDrugSpecialists"] = "Retailers Excl Food And Drug Specialists";
  NewsSubtypesEnum["RetirementOldAge"] = "Retirement Old Age";
  NewsSubtypesEnum["Reunion"] = "Reunion";
  NewsSubtypesEnum["ReutersNews"] = "Reuters News";
  NewsSubtypesEnum["ReutersPolls"] = "Reuters Polls";
  NewsSubtypesEnum["ReutersTopNews"] = "Reuters Top News";
  NewsSubtypesEnum["ReutersTopNewsPages"] = "Reuters Top News Pages";
  NewsSubtypesEnum["RevenueBonds"] = "Revenue Bonds";
  NewsSubtypesEnum["Reviews"] = "Reviews";
  NewsSubtypesEnum["RheumaticConditions"] = "Rheumatic Conditions";
  NewsSubtypesEnum["RhodeIsland"] = "Rhode Island";
  NewsSubtypesEnum["Rhodium"] = "Rhodium";
  NewsSubtypesEnum["Rice"] = "Rice";
  NewsSubtypesEnum["RightsIssues"] = "Rights Issues";
  NewsSubtypesEnum["RoadFreight"] = "Road Freight";
  NewsSubtypesEnum["Romania"] = "Romania";
  NewsSubtypesEnum["Rowing"] = "Rowing";
  NewsSubtypesEnum["Rubber"] = "Rubber";
  NewsSubtypesEnum["RugbyLeague"] = "Rugby League";
  NewsSubtypesEnum["RugbyUnion"] = "Rugby Union";
  NewsSubtypesEnum["Rumors"] = "Rumors";
  NewsSubtypesEnum["Russia"] = "Russia";
  NewsSubtypesEnum["RussiaOtherCISCrudes"] = "Russia Other CIS Crudes";
  NewsSubtypesEnum["Russian"] = "Russian";
  NewsSubtypesEnum["Rwanda"] = "Rwanda";
  NewsSubtypesEnum["Rye"] = "Rye";
  NewsSubtypesEnum["SEC"] = "SEC";
  NewsSubtypesEnum["SPSectorSummary"] = "S&P Sector Summary";
  NewsSubtypesEnum["STDs"] = "ST Ds";
  NewsSubtypesEnum["SaintBarthelemy"] = "Saint Barthelemy";
  NewsSubtypesEnum["SaintHelenaAscensionAndTristanDaCunha"] = "Saint Helena Ascension And Tristan Da Cunha";
  NewsSubtypesEnum["SaintKittsAndNevis"] = "Saint Kitts And Nevis";
  NewsSubtypesEnum["SaintLucia"] = "Saint Lucia";
  NewsSubtypesEnum["SaintMartin"] = "Saint Martin";
  NewsSubtypesEnum["SaintPierreAndMiquelon"] = "Saint Pierre And Miquelon";
  NewsSubtypesEnum["SaintVincentAndTheGrenadines"] = "Saint Vincent And The Grenadines";
  NewsSubtypesEnum["Samoa"] = "Samoa";
  NewsSubtypesEnum["SanMarino"] = "San Marino";
  NewsSubtypesEnum["SaoTomeAndPrincipe"] = "Sao Tome And Principe";
  NewsSubtypesEnum["SaudiArabia"] = "Saudi Arabia";
  NewsSubtypesEnum["Scandinavia"] = "Scandinavia";
  NewsSubtypesEnum["Schedules"] = "Schedules";
  NewsSubtypesEnum["ScienceTechnology"] = "Science Technology";
  NewsSubtypesEnum["Scotland"] = "Scotland";
  NewsSubtypesEnum["ScrapMetals"] = "Scrap Metals";
  NewsSubtypesEnum["SeaAccidents"] = "Sea Accidents";
  NewsSubtypesEnum["SeaborneFreight"] = "Seaborne Freight";
  NewsSubtypesEnum["SecondaryShareOfferingsIssues"] = "Secondary Share Offerings Issues";
  NewsSubtypesEnum["SectorETFs"] = "Sector ETFs";
  NewsSubtypesEnum["SecuritiesTransactions"] = "Securities Transactions";
  NewsSubtypesEnum["SecurityListingsDelistings"] = "Security Listings Delistings";
  NewsSubtypesEnum["Semiconductors"] = "Semiconductors";
  NewsSubtypesEnum["SemiconductorsSemiconductorEquipment"] = "Semiconductors Semiconductor Equipment";
  NewsSubtypesEnum["Senegal"] = "Senegal";
  NewsSubtypesEnum["Serbia"] = "Serbia";
  NewsSubtypesEnum["ServiceAlerts"] = "Service Alerts";
  NewsSubtypesEnum["SexCrimes"] = "Sex Crimes";
  NewsSubtypesEnum["SexualHealth"] = "Sexual Health";
  NewsSubtypesEnum["Seychelles"] = "Seychelles";
  NewsSubtypesEnum["ShaleOilShaleGas"] = "Shale Oil Shale Gas";
  NewsSubtypesEnum["ShareConsolidations"] = "Share Consolidations";
  NewsSubtypesEnum["ShareholderActivism"] = "Shareholder Activism";
  NewsSubtypesEnum["ShareholderMeetings"] = "Shareholder Meetings";
  NewsSubtypesEnum["ShareholderProtectionPlans"] = "Shareholder Protection Plans";
  NewsSubtypesEnum["ShootingSport"] = "Shooting Sport";
  NewsSubtypesEnum["ShortMediumTermNotes"] = "Short Medium Term Notes";
  NewsSubtypesEnum["ShortSellers"] = "Short Sellers";
  NewsSubtypesEnum["ShortTrackSkating"] = "Short Track Skating";
  NewsSubtypesEnum["Silicon"] = "Silicon";
  NewsSubtypesEnum["Silver"] = "Silver";
  NewsSubtypesEnum["Singapore"] = "Singapore";
  NewsSubtypesEnum["Skateboarding"] = "Skateboarding";
  NewsSubtypesEnum["Skeleton"] = "Skeleton";
  NewsSubtypesEnum["SkiJumping"] = "Ski Jumping";
  NewsSubtypesEnum["SledDogRacing"] = "Sled Dog Racing";
  NewsSubtypesEnum["Slovakia"] = "Slovakia";
  NewsSubtypesEnum["Slovenia"] = "Slovenia";
  NewsSubtypesEnum["SmallBusiness"] = "Small Business";
  NewsSubtypesEnum["SmallCaps"] = "Small Caps";
  NewsSubtypesEnum["Smoking"] = "Smoking";
  NewsSubtypesEnum["Snowboarding"] = "Snowboarding";
  NewsSubtypesEnum["Soccer"] = "Soccer";
  NewsSubtypesEnum["SoccerWorldCup"] = "Soccer World Cup";
  NewsSubtypesEnum["SocialMedia"] = "Social Media";
  NewsSubtypesEnum["SocialSentiment"] = "Social Sentiment";
  NewsSubtypesEnum["SociallyResponsibleInvesting"] = "Socially Responsible Investing";
  NewsSubtypesEnum["SocietySocialIssues"] = "Society Social Issues";
  NewsSubtypesEnum["Softball"] = "Softball";
  NewsSubtypesEnum["Softs"] = "Softs";
  NewsSubtypesEnum["Software"] = "Software";
  NewsSubtypesEnum["SoftwareITServices"] = "Software IT Services";
  NewsSubtypesEnum["SolomonIslands"] = "Solomon Islands";
  NewsSubtypesEnum["Somalia"] = "Somalia";
  NewsSubtypesEnum["SorghumMilo"] = "Sorghum Milo";
  NewsSubtypesEnum["SouthAfrica"] = "South Africa";
  NewsSubtypesEnum["SouthAfricanReserveBank"] = "South African Reserve Bank";
  NewsSubtypesEnum["SouthAmerica"] = "South America";
  NewsSubtypesEnum["SouthAmericaCentralAmerica"] = "South America Central America";
  NewsSubtypesEnum["SouthAmericaEnergy"] = "South America Energy";
  NewsSubtypesEnum["SouthAsia"] = "South Asia";
  NewsSubtypesEnum["SouthAsiaEnergy"] = "South Asia Energy";
  NewsSubtypesEnum["SouthCarolina"] = "South Carolina";
  NewsSubtypesEnum["SouthChinaSea"] = "South China Sea";
  NewsSubtypesEnum["SouthEastAsia"] = "South East Asia";
  NewsSubtypesEnum["SouthEasternEurope"] = "South Eastern Europe";
  NewsSubtypesEnum["SouthKorea"] = "South Korea";
  NewsSubtypesEnum["SouthSudan"] = "South Sudan";
  NewsSubtypesEnum["SouthWestAsia"] = "South West Asia";
  NewsSubtypesEnum["SouthernAfrica"] = "Southern Africa";
  NewsSubtypesEnum["SovereignDebtAuctions"] = "Sovereign Debt Auctions";
  NewsSubtypesEnum["SovereignWealthFunds"] = "Sovereign Wealth Funds";
  NewsSubtypesEnum["Soybean"] = "Soybean";
  NewsSubtypesEnum["SoybeanOil"] = "Soybean Oil";
  NewsSubtypesEnum["SpaceExploration"] = "Space Exploration";
  NewsSubtypesEnum["Spain"] = "Spain";
  NewsSubtypesEnum["Spanish"] = "Spanish";
  NewsSubtypesEnum["SpanishLanguageGeneralNews"] = "Spanish Language General News";
  NewsSubtypesEnum["SpanishSoccer"] = "Spanish Soccer";
  NewsSubtypesEnum["SpecialPurposeAcquisitionCompanies"] = "Special Purpose Acquisition Companies";
  NewsSubtypesEnum["SpecialReports"] = "Special Reports";
  NewsSubtypesEnum["SpecialtyETFs"] = "Specialty ETFs";
  NewsSubtypesEnum["SpecialtyMiningMetals"] = "Specialty Mining Metals";
  NewsSubtypesEnum["SpeedSkating"] = "Speed Skating";
  NewsSubtypesEnum["SpicesHerbsFruitVegetables"] = "Spices Herbs Fruit Vegetables";
  NewsSubtypesEnum["Sport"] = "Sport";
  NewsSubtypesEnum["Sports"] = "Sports";
  NewsSubtypesEnum["SportsBusiness"] = "Sports Business";
  NewsSubtypesEnum["SportsResults"] = "Sports Results";
  NewsSubtypesEnum["SriLanka"] = "Sri Lanka";
  NewsSubtypesEnum["StaffingAddCuts"] = "Staffing / Add / Cuts";
  NewsSubtypesEnum["StainlessSteel"] = "Stainless Steel";
  NewsSubtypesEnum["StakeholderEngagement"] = "Stakeholder Engagement";
  NewsSubtypesEnum["Startups"] = "Startups";
  NewsSubtypesEnum["Steel"] = "Steel";
  NewsSubtypesEnum["StockBuybacks"] = "Stock Buybacks";
  NewsSubtypesEnum["StockSplits"] = "Stock Splits";
  NewsSubtypesEnum["StrategicCombinations"] = "Strategic Combinations";
  NewsSubtypesEnum["StrokeCerebrovascularApoplexy"] = "Stroke Cerebrovascular Apoplexy";
  NewsSubtypesEnum["Subsidies"] = "Subsidies";
  NewsSubtypesEnum["Sudan"] = "Sudan";
  NewsSubtypesEnum["Sugar"] = "Sugar";
  NewsSubtypesEnum["Sukuk"] = "Sukuk";
  NewsSubtypesEnum["Summaries"] = "Summaries";
  NewsSubtypesEnum["SunflowerOil"] = "Sunflower Oil";
  NewsSubtypesEnum["SupranationalAgencyDebt"] = "Supranational Agency Debt";
  NewsSubtypesEnum["Surfing"] = "Surfing";
  NewsSubtypesEnum["Surgery"] = "Surgery";
  NewsSubtypesEnum["Suriname"] = "Suriname";
  NewsSubtypesEnum["SustainabilityReporting"] = "Sustainability Reporting";
  NewsSubtypesEnum["SustainableSourcing"] = "Sustainable Sourcing";
  NewsSubtypesEnum["SvalbardAndJanMayen"] = "Svalbard And Jan Mayen";
  NewsSubtypesEnum["SverigesRiksbank"] = "Sveriges Riksbank";
  NewsSubtypesEnum["Sweden"] = "Sweden";
  NewsSubtypesEnum["Swimming"] = "Swimming";
  NewsSubtypesEnum["SwissNationalBank"] = "Swiss National Bank";
  NewsSubtypesEnum["Switzerland"] = "Switzerland";
  NewsSubtypesEnum["Syndicate"] = "Syndicate";
  NewsSubtypesEnum["Syria"] = "Syria";
  NewsSubtypesEnum["TableTennis"] = "Table Tennis";
  NewsSubtypesEnum["Taiwan"] = "Taiwan";
  NewsSubtypesEnum["TaiwanTaipei"] = "Taiwan (Taipei)";
  NewsSubtypesEnum["Tajikistan"] = "Tajikistan";
  NewsSubtypesEnum["TakeALook"] = "Take A Look";
  NewsSubtypesEnum["TankerFreight"] = "Tanker Freight";
  NewsSubtypesEnum["Tanzania"] = "Tanzania";
  NewsSubtypesEnum["Tariffs"] = "Tariffs";
  NewsSubtypesEnum["Taxables"] = "Taxables";
  NewsSubtypesEnum["Taxation"] = "Taxation";
  NewsSubtypesEnum["Tea"] = "Tea";
  NewsSubtypesEnum["Tech"] = "Tech";
  NewsSubtypesEnum["TechnicalAnalysis"] = "Technical Analysis";
  NewsSubtypesEnum["Technology"] = "Technology";
  NewsSubtypesEnum["TechnologyEquipment"] = "Technology Equipment";
  NewsSubtypesEnum["TechnologyMediaTelecoms"] = "Technology Media Telecoms";
  NewsSubtypesEnum["Telecommunications"] = "Telecommunications";
  NewsSubtypesEnum["TelecommunicationsServices"] = "Telecommunications Services";
  NewsSubtypesEnum["Television"] = "Television";
  NewsSubtypesEnum["Temperature"] = "Temperature";
  NewsSubtypesEnum["Tennessee"] = "Tennessee";
  NewsSubtypesEnum["Tennis"] = "Tennis";
  NewsSubtypesEnum["TerminalCare"] = "Terminal Care";
  NewsSubtypesEnum["TermsConditions"] = "Terms Conditions";
  NewsSubtypesEnum["Texas"] = "Texas";
  NewsSubtypesEnum["TextilesApparel"] = "Textiles Apparel";
  NewsSubtypesEnum["TextilesLeatherGoods"] = "Textiles Leather Goods";
  NewsSubtypesEnum["TextsTranscripts"] = "Texts Transcripts";
  NewsSubtypesEnum["Thai"] = "Thai";
  NewsSubtypesEnum["Thailand"] = "Thailand";
  NewsSubtypesEnum["Theater"] = "Theater";
  NewsSubtypesEnum["TimberLumber"] = "Timber Lumber";
  NewsSubtypesEnum["TimelinesChronologies"] = "Timelines Chronologies";
  NewsSubtypesEnum["Tin"] = "Tin";
  NewsSubtypesEnum["TiresRubberProducts"] = "Tires Rubber Products";
  NewsSubtypesEnum["Titanium"] = "Titanium";
  NewsSubtypesEnum["Tobacco"] = "Tobacco";
  NewsSubtypesEnum["Togo"] = "Togo";
  NewsSubtypesEnum["Tonga"] = "Tonga";
  NewsSubtypesEnum["TopNews"] = "Top News";
  NewsSubtypesEnum["TourismTravel"] = "Tourism Travel";
  NewsSubtypesEnum["ToysGames"] = "Toys Games";
  NewsSubtypesEnum["TradingHalts"] = "Trading Halts";
  NewsSubtypesEnum["TradingIdeas"] = "Trading Ideas";
  NewsSubtypesEnum["TranslatedNews"] = "Translated News";
  NewsSubtypesEnum["TransmissionLines"] = "Transmission Lines";
  NewsSubtypesEnum["Transplant"] = "Transplant";
  NewsSubtypesEnum["Transportation"] = "Transportation";
  NewsSubtypesEnum["TransportationMarketsTransportation"] = "Transportation Markets Transportation";
  NewsSubtypesEnum["Treasuries"] = "Treasuries";
  NewsSubtypesEnum["TreasuryBonds"] = "Treasury Bonds";
  NewsSubtypesEnum["TrinidadAndTobago"] = "Trinidad And Tobago";
  NewsSubtypesEnum["Tsunami"] = "Tsunami";
  NewsSubtypesEnum["Tunisia"] = "Tunisia";
  NewsSubtypesEnum["Turkey"] = "Turkey";
  NewsSubtypesEnum["Turkish"] = "Turkish";
  NewsSubtypesEnum["Turkmenistan"] = "Turkmenistan";
  NewsSubtypesEnum["TurksAndCaicosIslands"] = "Turks And Caicos Islands";
  NewsSubtypesEnum["Tuvalu"] = "Tuvalu";
  NewsSubtypesEnum["USA"] = "USA";
  NewsSubtypesEnum["USCorporateBonds"] = "US Corporate Bonds";
  NewsSubtypesEnum["USCrudes"] = "US Crudes";
  NewsSubtypesEnum["USEquities"] = "US Equities";
  NewsSubtypesEnum["USGovernmentNews"] = "US Government News";
  NewsSubtypesEnum["USHouseOfRepresentatives"] = "US House Of Representatives";
  NewsSubtypesEnum["USPresident"] = "US President";
  NewsSubtypesEnum["USSenate"] = "US Senate";
  NewsSubtypesEnum["USSupremeCourt"] = "US Supreme Court";
  NewsSubtypesEnum["USVirginIslands"] = "U S Virgin Islands";
  NewsSubtypesEnum["Uganda"] = "Uganda";
  NewsSubtypesEnum["Ukraine"] = "Ukraine";
  NewsSubtypesEnum["UnitedArabEmirates"] = "United Arab Emirates";
  NewsSubtypesEnum["UnitedKingdom"] = "United Kingdom";
  NewsSubtypesEnum["UnitedNations"] = "United Nations";
  NewsSubtypesEnum["UnitedNationsChildrensFund"] = "United Nations Childrens Fund";
  NewsSubtypesEnum["UnitedNationsEducationalScientificAndCulturalOrganization"] = "United Nations Educational Scientific And Cultural Organization";
  NewsSubtypesEnum["UnitedStates"] = "United States";
  NewsSubtypesEnum["UnitedStatesDepartmentOfAgriculture"] = "United States Department Of Agriculture";
  NewsSubtypesEnum["UnitedStatesMexicoCanadaAgreement"] = "United States Mexico Canada Agreement";
  NewsSubtypesEnum["UnitedStatesMinorOutlyingIslands"] = "United States Minor Outlying Islands";
  NewsSubtypesEnum["UpperHouseElections"] = "Upper House Elections";
  NewsSubtypesEnum["Uranium"] = "Uranium";
  NewsSubtypesEnum["UrologicalDisorders"] = "Urological Disorders";
  NewsSubtypesEnum["Uruguay"] = "Uruguay";
  NewsSubtypesEnum["Utah"] = "Utah";
  NewsSubtypesEnum["Utilities"] = "Utilities";
  NewsSubtypesEnum["Uzbekistan"] = "Uzbekistan";
  NewsSubtypesEnum["Vaccines"] = "Vaccines";
  NewsSubtypesEnum["Vanadium"] = "Vanadium";
  NewsSubtypesEnum["Vanuatu"] = "Vanuatu";
  NewsSubtypesEnum["VaticanCity"] = "Vatican City";
  NewsSubtypesEnum["Venezuela"] = "Venezuela";
  NewsSubtypesEnum["Vermont"] = "Vermont";
  NewsSubtypesEnum["Vessels"] = "Vessels";
  NewsSubtypesEnum["Video"] = "Video";
  NewsSubtypesEnum["VideoAvailable"] = "Video Available";
  NewsSubtypesEnum["VideoGames"] = "Video Games";
  NewsSubtypesEnum["Vietnam"] = "Vietnam";
  NewsSubtypesEnum["Virginia"] = "Virginia";
  NewsSubtypesEnum["VolatileSharesHotStocks"] = "Volatile Shares Hot Stocks";
  NewsSubtypesEnum["Volleyball"] = "Volleyball";
  NewsSubtypesEnum["WIIM"] = "WIIM";
  NewsSubtypesEnum["Wales"] = "Wales";
  NewsSubtypesEnum["WallisAndFutuna"] = "Wallis And Futuna";
  NewsSubtypesEnum["WarConflicts"] = "War Conflicts";
  NewsSubtypesEnum["Washington"] = "Washington";
  NewsSubtypesEnum["WashingtonDC"] = "Washington DC";
  NewsSubtypesEnum["WasteManagementPlantConstruction"] = "Waste Management Plant Construction";
  NewsSubtypesEnum["Water"] = "Water";
  NewsSubtypesEnum["WaterOtherUtilities"] = "Water Other Utilities";
  NewsSubtypesEnum["WaterPolo"] = "Water Polo";
  NewsSubtypesEnum["WealthManagement"] = "Wealth Management";
  NewsSubtypesEnum["WeatherMarketsWeather"] = "Weather Markets Weather";
  NewsSubtypesEnum["WestAfrica"] = "West Africa";
  NewsSubtypesEnum["WestCoastPADDV"] = "West Coast PADD V";
  NewsSubtypesEnum["WestIndies"] = "West Indies";
  NewsSubtypesEnum["WestVirginia"] = "West Virginia";
  NewsSubtypesEnum["WesternEurope"] = "Western Europe";
  NewsSubtypesEnum["WesternSahara"] = "Western Sahara";
  NewsSubtypesEnum["Wheat"] = "Wheat";
  NewsSubtypesEnum["WhiteCollarCrimeFraudCorporateCrime"] = "White Collar Crime Fraud Corporate Crime";
  NewsSubtypesEnum["WildfiresForestFires"] = "Wildfires Forest Fires";
  NewsSubtypesEnum["WindFarms"] = "Wind Farms";
  NewsSubtypesEnum["WindHurricanesTyphoonsTornadoes"] = "Wind Hurricanes Typhoons Tornadoes";
  NewsSubtypesEnum["Wine"] = "Wine";
  NewsSubtypesEnum["WirelessTelecommunicationsServices"] = "Wireless Telecommunications Services";
  NewsSubtypesEnum["Wisconsin"] = "Wisconsin";
  NewsSubtypesEnum["Withdrawn"] = "Withdrawn";
  NewsSubtypesEnum["WomensHealth"] = "Womens Health";
  NewsSubtypesEnum["WomensIssues"] = "Womens Issues";
  NewsSubtypesEnum["WomensSports"] = "Womens Sports";
  NewsSubtypesEnum["WorkersPay"] = "Workers Pay";
  NewsSubtypesEnum["Workforce"] = "Workforce";
  NewsSubtypesEnum["WorldBank"] = "World Bank";
  NewsSubtypesEnum["WorldCup"] = "World Cup";
  NewsSubtypesEnum["WorldHealthOrganization"] = "World Health Organization";
  NewsSubtypesEnum["WorldTradeOrganization"] = "World Trade Organization";
  NewsSubtypesEnum["Wrestling"] = "Wrestling";
  NewsSubtypesEnum["Wyoming"] = "Wyoming";
  NewsSubtypesEnum["YachtingSailing"] = "Yachting Sailing";
  NewsSubtypesEnum["Yemen"] = "Yemen";
  NewsSubtypesEnum["Zambia"] = "Zambia";
  NewsSubtypesEnum["Zimbabwe"] = "Zimbabwe";
  NewsSubtypesEnum["Zinc"] = "Zinc";
})(NewsSubtypesEnum = exports.NewsSubtypesEnum || (exports.NewsSubtypesEnum = {}));

var NewsImageSize;

(function (NewsImageSize) {
  NewsImageSize["Thumb"] = "thumb";
  NewsImageSize["Small"] = "small";
  NewsImageSize["Medium"] = "medium";
  NewsImageSize["Large"] = "large";
})(NewsImageSize = exports.NewsImageSize || (exports.NewsImageSize = {}));