import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WidthGreenClickableButton } from '../common/styledComponents';
import Modal from './configurationModals/Modal';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_ALERT_STATUS, UPDATE_ALERT_STATUS } from '../common/queries';

export const AlertMessage = ({ closeModal }) => {
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  return (
    <AlertContainer>
      <AlertContent
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <MainTextContainer>
          <TextContainer>{`Our Terms of Service have been updated`}</TextContainer>
          <TextContainerSmall>
            In order to continue using our services, please read our new terms
            of service
            <a
              style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
              href='https://go.atom.finance/legal/api-terms-of-use'
              rel='noopener noreferrer'
              target='_blank'
              onClick={() => setDisabledSubmit(false)}
            >
              linked here.
            </a>
          </TextContainerSmall>
          <TextContainerSmall>
            {`Clicking on this link means you have acknowledged, agreed to, and accepted our polices.`}
          </TextContainerSmall>
          <ButtonContainer>
            <WidthGreenClickableButton
              onClick={closeModal}
              disabled={disabledSubmit}
            >
              {`Agree and continue`}
            </WidthGreenClickableButton>
          </ButtonContainer>
        </MainTextContainer>
      </AlertContent>
    </AlertContainer>
  );
};

const AlertOverlay = () => {
  const [showModal, setShowModal] = useState({ showTermsOfService: false });
  const { data, loading } = useQuery(GET_ALERT_STATUS);
  const [updateAlertStatusMutation, updateAlertStatusResponse] =
    useMutation(UPDATE_ALERT_STATUS);
  const submitAcknowledgement = () => {
    setShowModal({ showTermsOfService: false });
    updateAlertStatusMutation({
      variables: { termsOfServiceViewed: true },
    });
  };

  useEffect(() => {
    const showTermsOfService = data?.alertStatus?.showTermsOfService;
    setShowModal({ showTermsOfService });
  }, [loading, data]);

  return (
    <div>
      {showModal.showTermsOfService && (
        <Modal closeModal={() => {}}>
          <AlertMessage closeModal={submitAcknowledgement} />
        </Modal>
      )}
    </div>
  );
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0em 0em 0em;
`;

export const MainTextContainer = styled.div`
  margin: 1em;
`;

export const TextContainer = styled.div`
  padding: 1em;
  font-size: 24px;
  text-align: center;
`;

export const TextContainerSmall = styled.div`
  padding: 0em 5px;
  font-size: 16px;
  text-align: center;
`;

export const AlertContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  display: flex;
`;

export const AlertContent = styled.div`
  padding: 1em 2em 2em 2em;
  border-radius: 6px;
  background-color: var(--atom-brand-3);
  align-items: center;
`;

export default AlertOverlay;
