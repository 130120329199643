import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import AtomIcon from './AtomIcon';

export interface CompsSSPaginationConfig {
  startRow: number;
  endRow: number;
  currentPage: number;
  pageSize: number;
  totalPages?: number;
  totalRows?: number;
}

interface CompsSSPaginationProps {
  config: CompsSSPaginationConfig;
  setConfig: (CompsConfig) => void;
  containerStyle?: any;
  showPageSize?: boolean;
  paginationKey?: string;
}

interface AgGridPaginationProps {
  data: any[];
  containerStyle?: any;
  showPageSize?: boolean;
  paginationKey?: string;
  paginationSetPageSize;
  paginationGetPageSize;
  paginationGetCurrentPage;
  paginationGetRowCount;
  paginationGetTotalPages;
  paginationGoToPreviousPage;
  paginationGoToNextPage;
}

const DropDown = styled.div.attrs({
  className: 'justify-center font-din',
})`
  display: inline-block;
  position: relative;
  text-align: center;

  border-radius: 15px;
  color: var(--atom-contrast);
  padding: 0.45rem 1.2rem 0.4rem 1.2rem;
  /* border: 1px solid var(--atom-brand-6); */
  /* height: 33px; */
  transition: all 0.1s ease-out;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: auto;
  //background-color: var(--atom-brand-5);

  min-width: ${p => p.theme?.width ?? 'fit-content'};
  justify-self: flex-end;

  i {
    font-size: 1em;
    vertical-align: middle;
    display: inline-block;

    -webkit-transition: transform 0.1s linear;
    -moz-transition: transform 0.1s linear;
    -o-transition: transform 0.1s linear;
    transition: transform 0.1s linear;

    transform: ${(p: any) =>
      p.showDropDown && p.shouldRotate ? 'rotate(-45deg)' : 'rotate(0deg)'};
    transform-origin: center;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    color: var(--atom-contrast);
  }

  &:hover {
    background-color: var(--atom-brand-5);

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media (max-width: 768px) {
    font-size: 11px;
  }

  @media (max-width: 414px) {
    height: 28px;
  }
`;

const Options = styled.div`
  overflow: auto;
  max-height: 350px;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
  height: ${(p: any) => (p.showDropDown ? undefined : '0')};
  transition: height 0.25s ease-out;
  background-color: var(--atom-brand-2);
`;

const Option = styled.div.attrs({
  className: 'font-din',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1rem 0.6rem 1rem;
  width: 100%;
  background-color: var(--atom-brand-5);

  font-weight: normal;
  color: var(--atom-contrast);

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  transition: 0.3s;
  &:hover {
    color: var(--atom-light-text);
  }
`;

const Pagination = ({
  conf,
  showPageSize,
  containerStyle,
  pageSizeChange,
  previous,
  next,
  bumpByOne = false,
}) => {
  let { startRow, endRow, pageSize, totalRows, currentPage, totalPages } = conf;

  if (bumpByOne) {
    startRow += 1;
    currentPage += 1;
  }
  return (
    <Container style={containerStyle}>
      <Left>
        {totalRows === 0 ? null : (
          <Text>
            {startRow} to {Math.min(endRow, totalRows)} of {totalRows}
          </Text>
        )}
      </Left>

      <Right>
        <Text style={{ marginTop: '0.2rem', marginRight: '1rem' }}>
          Page Size: {pageSize}
        </Text>
        {totalPages === 0 ? null : (
          <ControlContainer>
            <div
              onClick={previous}
              style={{ transform: 'rotate(90deg)', marginRight: '0.5rem' }}
            >
              <AtomIcon
                icon='chevron-down-small'
                size={6}
                iconCss={`
                fill: var(--atom-brand-7);
                cursor: ${currentPage === 1 ? 'not-allowed' : 'pointer'};
                :hover {
                  fill: var(--atom-brand-8);
                }
              `}
              />
            </div>
            <Text style={{ color: 'var(--atom-contrast)' }}>
              Page {currentPage} of {totalPages}
            </Text>
            <div
              onClick={next}
              style={{ transform: 'rotate(-90deg)', marginLeft: '0.5rem' }}
            >
              <AtomIcon
                icon='chevron-down-small'
                size={6}
                iconCss={`
                fill: var(--atom-brand-7);
                cursor: ${
                  currentPage === totalPages ? 'not-allowed' : 'pointer'
                };
                :hover {
                  fill: var(--atom-brand-8);
                }
              `}
              />
            </div>
          </ControlContainer>
        )}
      </Right>
    </Container>
  );
};

export const CompsSSPagination = ({
  config,
  setConfig,
  containerStyle = {},
  showPageSize = false,
  paginationKey = 'compsSSPageSize',
}: CompsSSPaginationProps) => {
  const previous = () => {
    setConfig(c => {
      const currentPage = Math.max(c.currentPage - 1, 0);
      return {
        ...c,
        startRow: currentPage * c.pageSize,
        endRow: currentPage * c.pageSize + c.pageSize,
        currentPage,
      };
    });
  };

  const next = () => {
    setConfig(c => {
      const currentPage = Math.min(c.currentPage + 1, c.totalPages - 1);
      return {
        ...c,
        startRow: currentPage * c.pageSize,
        endRow: currentPage * c.pageSize + c.pageSize,
        currentPage,
      };
    });
  };

  const pageSizeChange = ({ value }) => {
    localStorage.setItem(paginationKey, value);
    setConfig(c => {
      const pageSize = parseInt(value);
      return {
        ...c,
        pageSize,
        startRow: 0,
        endRow: pageSize,
        currentPage: 0,
        totalPages: Math.ceil(c.totalRows / pageSize),
      };
    });
  };
  return (
    <Pagination
      containerStyle={containerStyle}
      conf={config}
      showPageSize={showPageSize}
      next={next}
      pageSizeChange={pageSizeChange}
      previous={previous}
      bumpByOne
    />
  );
};

export const AgGridPagination = ({
  data,
  containerStyle = {},
  showPageSize = false,
  paginationKey = 'agGridPageSize',
  paginationSetPageSize,
  paginationGetPageSize,
  paginationGetCurrentPage,
  paginationGetRowCount,
  paginationGetTotalPages,
  paginationGoToPreviousPage,
  paginationGoToNextPage,
}: AgGridPaginationProps) => {
  const [conf, setConf] = useState({
    startRow: 0,
    endRow: 0,
    totalRows: 0,
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
  });

  const update = useCallback(() => {
    const pageSize = paginationGetPageSize();
    const currentPage = paginationGetCurrentPage();
    const totalRows = paginationGetRowCount();
    const totalPages = paginationGetTotalPages();
    const config = {
      startRow: currentPage * pageSize + 1,
      endRow: Math.min(currentPage * pageSize + pageSize, totalRows),
      currentPage: currentPage + 1,
      totalRows,
      totalPages,
      pageSize,
    };
    setConf(config);
  }, [
    paginationGetPageSize,
    paginationGetCurrentPage,
    paginationGetRowCount,
    paginationGetTotalPages,
  ]);

  useEffect(() => {
    if (showPageSize) {
      const s: any = localStorage.getItem(paginationKey);
      if (s != null && paginationSetPageSize)
        paginationSetPageSize(parseInt(s));
    }
    setTimeout(update, 10);
  }, [paginationSetPageSize, update, data, paginationKey, showPageSize]);

  const previous = () => {
    paginationGoToPreviousPage();
    update();
  };

  const next = () => {
    paginationGoToNextPage();
    update();
  };

  const pageSizeChange = ({ value }) => {
    if (paginationSetPageSize) {
      paginationSetPageSize(value);
    }
    localStorage.setItem(paginationKey, value);
    update();
  };
  return (
    <Pagination
      containerStyle={containerStyle}
      conf={conf}
      showPageSize={showPageSize}
      next={next}
      pageSizeChange={pageSizeChange}
      previous={previous}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--atom-dark-brand-5);
`;

const Left = styled.div``;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div.attrs({
  className: 'font-din',
})`
  font-size: 12px;
  color: var(--atom-brand-7);
`;
