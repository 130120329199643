import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_ADMIN_INFO, SAVE_ADMIN_INFO } from '../../common/queries';
import MainHeader from '../../components/MainHeader';
import {
  CheckboxContainer,
  Input,
  InputContainer,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';
import atomlytics from '../../../helpers/atomlytics';

type Checkbox = {
  label: string;
  labelWithLink?: JSX.Element;
  checked: boolean;
  updateBox: () => void;
  style?: any;
};

const Checkbox = ({ label, labelWithLink, checked, updateBox }: Checkbox) => {
  // style div, style input?
  const displayLabel = labelWithLink ? labelWithLink : label;
  return (
    <SelectionContainer>
      <CustomCheckbox checked={checked} updateBox={updateBox} />
      <TextContainer>{displayLabel}</TextContainer>
    </SelectionContainer>
  );
};

export const CustomCheckbox = ({ checked, updateBox }) => {
  return (
    <CheckboxContainer
      style={{
        marginTop: '.5em',
      }}
      onClick={updateBox}
    >
      <input type='checkbox' checked={checked} />
      <span className='checkmark' />
    </CheckboxContainer>
  );
};

const SignUp = props => {
  const token = props?.match?.params?.token;
  const id = props?.match?.params?.id;
  const [saveAdminInfo, saveAdminInfoResponse] = useMutation(SAVE_ADMIN_INFO);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordVerification, setUserPasswordVerfication] = useState('');
  const [checked, setChecked] = useState(false);
  const [termsServiceChecked, setTermsServiceChecked] = useState(false);

  const updateBox = () => {
    setChecked(!checked);
  };

  const cookies = {};
  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=');
    cookies[key.trim()] = value;
  });
  const hutk = cookies['hubspotutk'];

  const submitInfo = () => {
    saveAdminInfo({
      variables: {
        firstName: userFirstName,
        lastName: userLastName,
        password: userPassword,
        passwordVerification: userPasswordVerification,
        token,
        id,
        hutk,
        checked,
        termsServiceChecked,
      },
    });
  };

  const { data: adminInfo } = useQuery(GET_ADMIN_INFO, {
    variables: { id, token },
  });

  const existingFirstName = adminInfo?.adminInformation?.firstName;
  const existingLastName = adminInfo?.adminInformation?.lastName;
  if (existingFirstName && userFirstName.length === 0) {
    setUserFirstName(existingFirstName);
  }

  if (existingLastName && userLastName.length === 0) {
    setUserLastName(existingLastName);
  }

  const { loading, data } = saveAdminInfoResponse;
  const success = data?.saveAdminInfo?.success;
  const message = data?.saveAdminInfo?.message;
  const disableSubmit =
    !userFirstName?.length ||
    !userLastName?.length ||
    !userPassword?.length ||
    !userPasswordVerification?.length ||
    !termsServiceChecked ||
    loading;

  if (success) {
    atomlytics.accountCreatedClient({
      signup_type: 'portal',
      signup_location: 'email_link',
    });
    window.analytics.identify(id, {
      firstName: userFirstName,
      lastName: userLastName,
      email: adminInfo?.adminInformation?.email,
      opt_in: !checked,
      reason: 'Request a trial API key',
    });
    window.location.href = '/';
  }
  return (
    <>
      <MainHeader includeRight={false} />
      <SigninContainer>
        <SigninSection>
          <TitleSectionTop>Welcome</TitleSectionTop>
          <TitleSectionSmallText>
            {`Welcome to the Atom Partner Portal. Get started by creating a secure password with`}
            {` at least 8 characters, one uppercase character and one number.`}
          </TitleSectionSmallText>
          <InputSection>
            <InputLabel>first name</InputLabel>
            <Input
              onChange={e => setUserFirstName(e.target.value)}
              placeholder='Enter first name'
              value={userFirstName}
              style={{ width: '22em' }}
            />
          </InputSection>
          <InputSection>
            <InputLabel>last name</InputLabel>
            <Input
              onChange={e => setUserLastName(e.target.value)}
              placeholder='Enter last name'
              value={userLastName}
              style={{ width: '22em' }}
            />
          </InputSection>
          <InputSection>
            <InputLabel>password</InputLabel>
            <Input
              onChange={e => setUserPassword(e.target.value)}
              placeholder='Enter password'
              type='password'
              value={userPassword}
              style={{ width: '22em' }}
            />
          </InputSection>
          <InputSection>
            <InputLabel>verify password</InputLabel>
            <Input
              onChange={e => setUserPasswordVerfication(e.target.value)}
              placeholder='Verify password'
              type='password'
              value={userPasswordVerification}
              style={{ width: '22em' }}
            />
          </InputSection>
          <PrivacyBlock>
            <Disclaimer>
              Before you submit your contact details, please read our
              <a href='https://go.atom.finance/legal/privacy-policy'>
                {' '}
                Privacy Policy{' '}
              </a>
              to understand how we will handle your information.
            </Disclaimer>
            <Checkbox
              label=''
              labelWithLink={
                <span>
                  Checking this box means you have acknowledged, agreed to, and
                  accepted our
                  <a
                    style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                    href='https://go.atom.finance/legal/api-terms-of-use'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    terms of service polices
                  </a>
                  . You cannot open an account without doing so.
                </span>
              }
              checked={termsServiceChecked}
              updateBox={() => setTermsServiceChecked(!termsServiceChecked)}
              style={{ paddingTop: '1em' }}
            />
            <Checkbox
              label={
                ' Atom Finance may contact you about relevant content, products, and services.\n' +
                'Please check this box if you do NOT wish to receive our communications.'
              }
              checked={checked}
              updateBox={updateBox}
            />
          </PrivacyBlock>
          <ResponseText success={success}>{message}</ResponseText>
          <InputContainer>
            <WidthGreenClickableButton
              disabled={disableSubmit}
              onClick={() => {
                if (!disableSubmit) submitInfo();
              }}
            >
              {loading ? <Spinner smaller={true} /> : `Continue`}
            </WidthGreenClickableButton>
          </InputContainer>
        </SigninSection>
      </SigninContainer>
    </>
  );
};

const TitleSectionTop = styled.div`
  font-size: 48px;
`;

const TitleSectionSmallText = styled.div`
  font-size: 15px;
  margin-bottom: 1.5em;
  max-width: 27em;
  text-align: center;
`;

const SigninSection = styled.div`
  padding: 4em 6em 6em 6em;
  border-radius: 6px;
  margin: 1.5em 0em;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.div`
  padding: 0.5em 0.2em;
  font-size: 13px;
  text-transform: uppercase;
`;

const Disclaimer = styled.div`
  justify-content: left;
  margin-top: 15px;
  padding-bottom: 10px;
`;

const PrivacyBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 10px 35px;
  width: 80%;
  max-width: 27em;
  font-size: 12px;
`;

const TextContainer = styled.span`
  margin-top: 0em;
  padding-left: 0.7em;
  margin-bottom: 0.2em;
`;

const SelectionContainer = styled.div`
  display: flex;
  align-items: left;
  padding: 0.5em;
`;

export default withRouter(SignUp);
