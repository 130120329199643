import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Company from '../components/Company';
import MainHeader from '../components/MainHeader';
import ApiSettings from '../components/ApiSettings';
import Changes from '../components/Changes';
import EmbeddedUIs from '../components/EmbeddedUIs';
import Webhooks from '../components/Webhooks';
import Authentication from '../components/Authentication';
import { AdminContext } from '../common/AdminContext';
import ClientList from './ClientList';
import AlertOverlay from '../components/AlertOverlay';

const Main = () => {
  const params: { page?: string } = useParams();
  const history = useHistory();
  const { admin, partner } = useContext(AdminContext);
  const sideBarOptions = [
    { label: 'CLIENT LIST', value: 'CLIENT LIST', content: <ClientList /> },
    {
      label: 'AUTHENTICATION',
      value: 'AUTHENTICATION',
      content: <Authentication />,
    },
    { label: 'API SETTINGS', value: 'API SETTINGS', content: <ApiSettings /> },
    { label: 'TEAM MEMBERS', value: 'TEAM MEMBERS', content: <Company /> },
    { label: 'CHANGE HISTORY', value: 'CHANGE HISTORY', content: <Changes /> },
    { label: 'EMBEDDED UIS', value: 'EMBEDDED UIS', content: <EmbeddedUIs /> },
    { label: 'WEBHOOKS', value: 'WEBHOOKS', content: <Webhooks /> },
  ];
  const isAdmin = admin?.isAtomAdmin;
  if (isAdmin === false) {
    sideBarOptions.shift();
  }
  let selectedValue = params?.page;
  if (!selectedValue) {
    selectedValue = isAdmin && !partner?.id ? 'CLIENT LIST' : 'AUTHENTICATION';
  }
  const selection = sideBarOptions.filter(
    item => item.value === selectedValue,
  )[0];

  return (
    <>
      <MainHeader />
      <MainContainer>
        <SideBar>
          {sideBarOptions.map(option => {
            const selected = option.value === selection.value;
            return (
              <SideBarOption
                onClick={() => history.push(`/main/${option.value}`)}
                selected={selected}
                key={option.value}
              >
                {option.value}
              </SideBarOption>
            );
          })}
        </SideBar>
        <MainContent>{selection.content}</MainContent>
      </MainContainer>
      <AlertOverlay />
    </>
  );
};

const MainContainer = styled.div`
  margin-top: 3em;
  display: flex;
`;

const SideBar = styled.div``;

const SideBarOption = styled.div<{
  selected: boolean;
}>`
  font-weight: bold;
  border-left-style: solid;
  border-left-color: var(
    --atom-${props => (props.selected ? 'dark-green' : 'brand-2')}
  );
  cursor: pointer;
  padding: 0.5em 2em;
  margin: 1em 0em;
`;

const MainContent = styled.div`
  width: 100%;
`;

export default Main;
