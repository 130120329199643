import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AdminContext } from '../common/AdminContext';
import { ReactComponent as AtomLogo } from '../../static/icons/atom-logo-black.svg';
import LogoutModal from './configurationModals/LogoutModal';
import { useHistory } from 'react-router-dom';

const MainHeader = ({
  includeRight = true,
  includeNav = true,
  includeSignIn = false,
}: {
  includeRight?: boolean;
  includeNav?: boolean;
  includeSignIn?: boolean;
}) => {
  const { admin, partner } = useContext(AdminContext);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const goToHome = async () => {
    history.push('/signin');
  };

  const getInitials = ({ admin }) => {
    const firstName = admin?.firstName ?? '';
    const lastName = admin?.lastName ?? '';

    const initials = [
      `${firstName?.charAt(0)?.toUpperCase()}${lastName
        ?.charAt(0)
        ?.toUpperCase()}`,
    ];
    return initials.map((initial, index) => {
      return (
        <div key={index}>
          <RightContainer>
            <OrganizationHeader>{partner?.name ?? ''}</OrganizationHeader>
            <ProfileLink onClick={() => setShowModal(true)}>
              {initial}
            </ProfileLink>
            {showModal && (
              <LogoutModal closeModal={() => setShowModal(false)} />
            )}
          </RightContainer>
        </div>
      );
    });
  };

  return (
    <HeaderContainer>
      <LeftContent>
        {includeNav && (
          <>
            <a href='/'>
              <AppLogo
                src={'/common/atom-finance-logo-white.png'}
                alt='Atom Finance'
              />
            </a>
            <MenuItem
              href='https://docs.atom.finance/reference/introduction'
              target='_blank'
            >
              API REFERENCE
            </MenuItem>
            <MenuItem href='https://docs.atom.finance/docs' target='_blank'>
              UI COMPONENTS
            </MenuItem>
            <MenuItem href='https://status.atom.finance/' target='_blank'>
              SYSTEM STATUS
            </MenuItem>
            <MenuItem
              href='https://knowledge.atom.finance/knowledge'
              target='_blank'
            >
              SUPPORT
            </MenuItem>
          </>
        )}
      </LeftContent>
      {includeRight && <RightContent>{getInitials({ admin })}</RightContent>}
      {includeSignIn && (
        <RightContent>
          {
            <GreenClickableButton onClick={goToHome}>
              {'SIGN IN'}
            </GreenClickableButton>
          }
        </RightContent>
      )}
    </HeaderContainer>
  );
};

const MenuItem = styled.a.attrs({
  className: 'hdr-btn',
})`
  margin-left: 30px;

  cursor: pointer;

  text-underline-offset: 5px;
  text-decoration-color: var(--atom-green);
  text-decoration-thickness: 3px;
  color: white;

  &:hover {
    color: var(--atom-green-hover);
  }

  &:visited {
    text-decoration: none;
  }

  font-size: 13px;
  padding: 5px;
`;

const OrganizationHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 12px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2em;
  background-color: var(--atom-brand-3);
  border-radius: 3px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
`;

export const AppLogo = styled.img`
  height: 2em;
  cursor: pointer;
  z-index: 11;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`;

const ProfileLink = styled.div.attrs({
  className: 'flex items-center justify-center transition-smooth',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  padding: 5px;
  position: relative;
  font-size: 12px;
  height: 32px;
  width: 32px;
  background-color: var(--atom-green);
  color: var(--atom-always-dark-text);
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: var(--atom-green-hover);
  }
`;

const InitialsText = styled.div`
  color: var(--atom-brand-3);
  font-size: 12px;
`;

export const GreenClickableButton = styled.div<{ disabled?: boolean }>`
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  padding: 1em;
  margin-left: 20px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-${props => (props.disabled ? 'brand-7' : 'brand-3')});
  background-color: var(
    --atom-${props => (props.disabled ? 'dark-green' : 'dark-green')}
  );
  &:hover {
    background-color: var(
      --atom-${props => (props.disabled ? 'dark-green' : 'dark-green-hover')}
    );
  }
  text-transform: uppercase;
`;
export default MainHeader;
