import axios from 'axios';
import _ from 'lodash';

/**
 * An Axios instance only for use with the internal API, with CSRF token added.
 * @type {AxiosInstance}
 */
const apiAxios = axios.create();

// Add CSRF token to Axios requests
let csrfToken: string | null = null;
const csrfTag = document.querySelector('meta[name="csrf-token"]');
if (csrfTag) {
  csrfToken = csrfTag.getAttribute('content');

  // https://github.com/axios/axios/issues/385
  const headers = _.cloneDeep(apiAxios.defaults.headers);
  headers.common['CSRF-Token'] = csrfToken;
  apiAxios.defaults.headers = headers;
}

export default apiAxios;
