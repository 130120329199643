import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import OptionBox from './OptionBox';
import { useMutation } from '@apollo/react-hooks';
import {
  GET_WEBHOOK_INFO,
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  SEND_TEST_WEBHOOK_MESSAGE,
} from '../common/queries';
import { AdminContext } from '../common/AdminContext';
import { WebhookType } from '@atom-finance/atom-types';
import {
  GreenClickableButton,
  Input,
  ResponseText,
} from '../common/styledComponents';

type WebhookOption = {
  text: string;
  enabled: boolean;
  name: string;
};

type WebhookSection = {
  title: string;
  description: string;
  webhookOptions: WebhookOption[];
};

const WebhookContent = ({
  setAddNewWebHook,
  webhookId,
  activeWebhooks,
  allowedWebhookTypes,
}) => {
  const activeWebhook =
    activeWebhooks?.find(webhook => webhookId === webhook.id) ?? null;

  const [createWebHook, createWebHookResponse] = useMutation(CREATE_WEBHOOK, {
    'refetchQueries': [{ query: GET_WEBHOOK_INFO }],
  });

  const [sendTestWebHookMessage, sendTestWebHookMessageResponse] = useMutation(
    SEND_TEST_WEBHOOK_MESSAGE,
  );

  const initialUrl = activeWebhook?.webhookURL ?? '';

  const [webhookURL, setWebhookURL] = useState(initialUrl);

  const [deleteWebhook, deleteWebHookResponse] = useMutation(DELETE_WEBHOOK, {
    'refetchQueries': [{ query: GET_WEBHOOK_INFO }],
  });

  const createWebHookCallback = useCallback(
    async (
      webhookId: String,
      webhookURL: string,
      sub_docs_earningsTranscripts: boolean,
      sub_docs_10K: boolean,
      sub_docs_10Q: boolean,
      sub_docs_8K: boolean,
      sub_docs_presentations: boolean,
      sub_docs_other: boolean,
      sub_portfolio_percent: boolean,
      sub_portfolio_daily_wrap: boolean,
    ) => {
      createWebHook({
        variables: {
          webhookId,
          webhookURL,
          sub_docs_earningsTranscripts,
          sub_docs_10K,
          sub_docs_10Q,
          sub_docs_8K,
          sub_docs_presentations,
          sub_docs_other,
          sub_portfolio_percent,
          sub_portfolio_daily_wrap,
        },
      });
    },
    [createWebHook],
  );

  const deleteWebHookCallback = useCallback(
    async (id: string) => {
      deleteWebhook({
        variables: {
          id,
        },
      });
    },
    [deleteWebhook],
  );
  const isAddingWebhook = webhookId === '';
  const { admin } = useContext(AdminContext);
  const isAdmin = admin?.isAtomAdmin === true;

  if (
    createWebHookResponse?.data?.createWebhook?.success ||
    deleteWebHookResponse?.data?.deleteWebhook?.success
  ) {
    setAddNewWebHook(null);
  }

  let documentWebhookOptions: WebhookOption[] = [
    {
      'text': 'Earnings Transcript',
      'enabled': false,
      'name': 'Earnings Transcript',
    },
    { 'text': '10-K', 'enabled': false, 'name': '10-K' },
    { 'text': '10-Q', 'enabled': false, 'name': '10-Q' },
    { 'text': '8-K', 'enabled': false, 'name': '8-K' },
    { 'text': 'Presentation', 'enabled': false, 'name': 'Presentation' },
    { 'text': 'Other', 'enabled': false, 'name': 'Other' },
  ];

  let portfolioWebhookOptions: WebhookOption[] = [
    {
      'text': 'Portfolio Daily Wrap',
      'enabled': false,
      'name': 'Portfolio Daily Wrap',
    },
    {
      'text': 'Portfolio % Change',
      'enabled': false,
      'name': 'Portfolio % Change',
    },
  ];

  if (activeWebhook?.documentWebhookOptions) {
    documentWebhookOptions = activeWebhook?.documentWebhookOptions;
  }
  if (activeWebhook?.portfolioWebhookOptions) {
    portfolioWebhookOptions = activeWebhook?.portfolioWebhookOptions;
  }

  const webhookSectionInfo: WebhookSection[] = [];
  if (allowedWebhookTypes.includes(WebhookType.documents)) {
    webhookSectionInfo.push({
      'title': 'Document Webhooks',
      'description':
        'Choose the document types you’d like pushed to the webhook URL above. These will include documents for all of the asset types and markets you’ve specified in API Settings.',
      'webhookOptions': documentWebhookOptions,
    });
  }
  if (allowedWebhookTypes.includes(WebhookType.portfolio)) {
    webhookSectionInfo.push({
      'title': 'Portfolio Notifications Webhooks',
      'description':
        'Choose the user-specific portfolio notification types you’d like pushed to the webhook URL above.',
      'webhookOptions': portfolioWebhookOptions,
    });
  }
  const WebhookSection = ({ title, description, webhookOptions }) => {
    return (
      <ConfigurationContainer style={{ marginTop: '3em' }}>
        <DescriptionContainer>
          <SubHeaderText>{title}</SubHeaderText>
          <SmallText>{description}</SmallText>
        </DescriptionContainer>
        <OptionsContainer optionsItemsPerRow={3}>
          {webhookOptions?.map((option, key) => {
            const { enabled, text, name: optionName } = option;
            const locked = false;
            return (
              <OptionBox
                key={key}
                text={text}
                enabled={enabled}
                locked={locked}
                editMode={true}
                isSelectionToggleable={true}
                onClick={() => {
                  const webhookOption = webhookOptions.find(
                    option => option.name === optionName,
                  );
                  if (webhookOption) {
                    webhookOption.enabled = !webhookOption.enabled;
                  }
                }}
                Modal={() => {
                  return null;
                }}
              />
            );
          })}
        </OptionsContainer>
      </ConfigurationContainer>
    );
  };

  const disableSave = !webhookURL?.length;

  const testMessageSuccess =
    sendTestWebHookMessageResponse.data?.sendTestWebhook?.success;
  const testMessageStatusCode =
    sendTestWebHookMessageResponse?.data?.sendTestWebhook?.statusCode;

  const webhookTestFailure =
    !sendTestWebHookMessageResponse.loading &&
    (testMessageStatusCode < 200 ||
      testMessageStatusCode >= 300 ||
      !testMessageSuccess);
  let webhookTestMessage = '';
  if (sendTestWebHookMessageResponse.loading) {
    webhookTestMessage = 'Sending...';
  } else if (sendTestWebHookMessageResponse.called) {
    if (testMessageSuccess) {
      if (testMessageStatusCode) {
        webhookTestMessage = `Recieved status code: ${testMessageStatusCode}`;
      } else {
        webhookTestMessage = 'There was no response from the url';
      }
    } else {
      webhookTestMessage =
        'There was an error sending the message, please try again later';
    }
  }

  return (
    <>
      <RootContainer>
        <RootHeader>
          <LargeText>
            {isAddingWebhook ? 'Add New Webhook' : 'Edit Webhook'}{' '}
          </LargeText>
          <ActionButtons>
            <GreenClickableButton
              disabled={disableSave}
              style={{ marginRight: '1em' }}
              onClick={() => {
                if (!disableSave)
                  createWebHookCallback(
                    webhookId,
                    webhookURL,
                    documentWebhookOptions?.find(
                      option => option.name === 'Earnings Transcript',
                    )?.enabled ?? false,
                    documentWebhookOptions?.find(
                      option => option.name === '10-K',
                    )?.enabled ?? false,
                    documentWebhookOptions?.find(
                      option => option.name === '10-Q',
                    )?.enabled ?? false,
                    documentWebhookOptions?.find(
                      option => option.name === '8-K',
                    )?.enabled ?? false,
                    documentWebhookOptions?.find(
                      option => option.name === 'Presentation',
                    )?.enabled ?? false,
                    documentWebhookOptions?.find(
                      option => option.name === 'Other',
                    )?.enabled ?? false,
                    portfolioWebhookOptions?.find(
                      option => option.name === 'Portfolio Daily Wrap',
                    )?.enabled ?? false,
                    portfolioWebhookOptions?.find(
                      option => option.name === 'Portfolio % Change',
                    )?.enabled ?? false,
                  );
              }}
            >
              {`SAVE`}
            </GreenClickableButton>
            <GreenClickableButton
              style={{
                marginRight: '2em',
                backgroundColor: 'var(--atom-brand-7)',
              }}
              onClick={() => {
                setAddNewWebHook(null);
              }}
            >
              {`CANCEL`}
            </GreenClickableButton>
          </ActionButtons>
        </RootHeader>
        <DescriptionText>
          Here you can set the types of data you’d like to receive via this
          webhook. Atom will deliver subscribed messages to the URL below. Note
          that some changes may take up to 15 minutes to take effect. For more
          information on how these webhooks should be consumed, please see our
          <a
            style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
            href='https://docs.atom.finance/reference/webhooks'
            target='_blank'
            rel='noopener noreferrer'
          >
            documentation on webhooks
          </a>
          .
        </DescriptionText>

        <SubHeaderText>Delivery URL</SubHeaderText>

        <Input
          onChange={e => setWebhookURL(e.target.value)}
          placeholder='https://example.com/webhooks'
          value={webhookURL}
          style={{ width: '30em', marginRight: '8px' }}
        />

        <ClickableText
          disabled={
            sendTestWebHookMessageResponse.loading || !webhookURL.length
          }
          onClick={() => {
            if (!sendTestWebHookMessageResponse.loading && webhookURL.length) {
              sendTestWebHookMessage({
                variables: {
                  url: webhookURL,
                },
              });
            }
          }}
        >
          {`SEND A TEST MESSAGE`}
        </ClickableText>
        <ResponseText
          success={!webhookTestFailure}
          style={{ textAlign: 'unset', marginTop: '10px' }}
        >
          {webhookTestMessage}
        </ResponseText>

        {webhookSectionInfo.map((section, key) => {
          return (
            <WebhookSection
              key={key}
              title={section.title}
              description={section.description}
              webhookOptions={section.webhookOptions}
            />
          );
        })}
        {!isAddingWebhook && (
          <>
            <SubHeaderText
              style={{
                paddingTop: '1em',
                marginTop: '1em',
                borderTop: 'solid 1px var(--atom-brand-5)',
              }}
            >
              Delete Webhook
            </SubHeaderText>
            <SmallText>
              This will delete this entire webhook, effective immediately. Only
              do this if you know what you are doing!
            </SmallText>
            <WidthRedClickableButton
              onClick={async () => {
                const isConfirmed = confirm(
                  'Do you really want to delete this webhook?',
                );
                if (isConfirmed) {
                  const id = activeWebhook?.id;
                  if (id) {
                    deleteWebHookCallback(id);
                  }
                }
              }}
            >
              DELETE WEBHOOK
            </WidthRedClickableButton>
          </>
        )}
      </RootContainer>
    </>
  );
};

export const WidthRedClickableButton = styled(GreenClickableButton as any)`
  margin: 15px 5px 5px 5px;
  width: 20em;
  background-color: var(--atom-${props => (props.disabled ? 'red' : 'red')});
  &:hover {
    background-color: var(
      --atom-${props => (props.disabled ? 'dark-red' : 'dark-red')}
    );
  }
`;

const ClickableText = styled.a<{ disabled?: boolean }>`
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-dark-green);
  text-transform: uppercase;
  user-select: none;
  width: fit-content;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5em;
  margin-right: 5em;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const RootHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LargeText = styled.a`
  font-size: 33px;
`;

const DescriptionText = styled.div`
  font-size: 16px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 75em;
`;

const SubHeaderText = styled.a`
  font-size: 20px;
  margin-bottom: 1em;
`;

const ConfigurationContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  border-top: solid 1px var(--atom-brand-5);
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
`;
const OptionsContainer = styled.div<{
  optionsItemsPerRow: number;
}>`
  display: grid;
  grid-template-columns: ${props =>
    [...Array(props.optionsItemsPerRow)].map(
      () => ` ${Math.floor((1 / props.optionsItemsPerRow) * 100)}%`,
    )};
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  margin: 1em 1em 0.5em 0em;
  position: relative;
`;

const SmallText = styled.div`
  font-size: 1em;
  margin-top: 1em;
  margin-right: 9em;
`;

export default WebhookContent;
