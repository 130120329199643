import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AdminContext } from '../common/AdminContext';
import OverviewContent from './OverviewContent';
import { GET_PARTNER_ICON } from '../common/queries';
import { useQuery } from '@apollo/react-hooks';
import SignUpComplete from '../pages/signIn/SignUpComplete';
import AuthenticationContent from '../pages/signIn/AuthenticationContent';
import AtomIcon from '../common/AtomIcon';

const Overview = () => {
  const { partner } = useContext(AdminContext);
  const partnerIconResponse = useQuery(GET_PARTNER_ICON);
  const apikey = partnerIconResponse?.data?.partnerIcon?.apiKey;
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const tier = partnerIconResponse?.data?.partnerIcon?.tier;
  const isLimitedApiKey = tier === 'Limited';

  let expiry;
  const partnerIconResponseExpiry =
    partnerIconResponse?.data?.partnerIcon?.expiry;
  if (partnerIconResponseExpiry) {
    expiry = new Date(partnerIconResponseExpiry as Date);
  }

  return (
    <OverviewContainer>
      <OverviewHeader>
        <TextContainer>
          <LargeText>Authentication</LargeText>
          <SubHeader>
            <ApiLabel>
              <MediumText>API Key</MediumText>
              {expiry && (
                <SmallRedText>
                  Note: Your trial ends on {expiry.toDateString()}.
                </SmallRedText>
              )}
            </ApiLabel>
            <ApiKeySection>
              {isLimitedApiKey && <LimitedLabel>Trial Key</LimitedLabel>}
              {!isLimitedApiKey && (
                <ProductionLabel>Production Key</ProductionLabel>
              )}
              <ApiKeySecretSection>
                <SmallText
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(apikey).then(() => {
                      alert('API key copied to clipboard');
                    });
                  }}
                >
                  {isApiKeyVisible
                    ? apikey
                    : 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'}
                </SmallText>
                <div
                  style={{ marginTop: '0.1rem' }}
                  onClick={() => setIsApiKeyVisible(!isApiKeyVisible)}
                >
                  <AtomIcon
                    icon={isApiKeyVisible ? 'eye-opened' : 'eye-closed'}
                    size={24}
                    pointer
                  />
                </div>
              </ApiKeySecretSection>
            </ApiKeySection>
          </SubHeader>
        </TextContainer>
      </OverviewHeader>
      <AuthenticationContent />
    </OverviewContainer>
  );
};

export const ProductionLabel = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 3em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 3px;
  margin-right: 2em;
  background-color: #ac5bbe;
`;

export const LimitedLabel = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 3em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 3px;
  margin-right: 2em;
  background-color: var(--atom-orange);
`;

const OverviewContainer = styled.div`
  margin: 0em 5em;
  display: flex;
  flex-direction: column;
`;

const OverviewHeader = styled.div`
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 1em;
`;

const IconContainer = styled.img`
  margin-right: 2em;
  border-radius: 10px;
  height: 5rem;
  width: 5rem;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2em;
  padding-top: 2em;
  border-top: solid 1px var(--atom-brand-5);
`;

const ApiLabel = styled.div`
  display: flex;
`;

const ApiKeySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
`;

const ApiKeySecretSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  background-color: var(--atom-brand-4);
  padding-left: 1em;
  padding-right: 1em;
`;

const LargeText = styled.div`
  font-size: 33px;
`;

const LeftText = styled.a`
  font-size: 24px;
  color: var(--atom-brand-8);
  padding-top: 3em;
`;

const MediumText = styled.div`
  font-size: 21px;
`;

const SmallText = styled.a`
  font-size: 15px;
  color: var(--atom-brand-7);
  align-self: center;
  margin-right: 1em;
`;

const SmallRedText = styled(SmallText as any)`
  color: red;
  align-self: center;
  margin-left: 1em;
`;

export default Overview;
