import gql from 'graphql-tag';

export const AUTHENTICATE_AS_ADMIN = gql`
  mutation authenticateAsAdmin($email: String!, $password: String!) {
    authenticateAsAdmin(email: $email, password: $password) {
      success
      message
    }
  }
`;

export const LOGOUT = gql`
  query logout {
    logout {
      success
      message
    }
  }
`;

export const GET_AUTH_STATUS = gql`
  query authStatus {
    authStatus {
      id
      partnerId
      isAtomAdmin
    }
  }
`;

export const GET_ACCOUNT_INFO = gql`
  query accountInformation {
    accountInformation {
      admin {
        id
        firstName
        lastName
        email
        isAtomAdmin
      }
      partner {
        id
        name
        language
        expiry
        admins {
          id
          firstName
          lastName
          email
          accessStatus
        }
      }
    }
  }
`;

export const GET_ADMIN_INFO = gql`
  query adminInformation($token: String!, $id: String!) {
    adminInformation(token: $token, id: $id) {
      id
      firstName
      lastName
      email
      isAtomAdmin
      partner {
        name
        id
        tier
        description
        apiKey
      }
    }
  }
`;

export const SET_PARTNER = gql`
  mutation setPartner($id: String!) {
    setPartner(id: $id) {
      success
      message
    }
  }
`;

export const GET_PARTNER_LIST = gql`
  query partnerList($currentPage: Int!, $pageSize: Int!, $searchTerm: String) {
    partnerList(
      currentPage: $currentPage
      pageSize: $pageSize
      searchTerm: $searchTerm
    ) {
      partners {
        id
        name
        icon
      }
      totalCount
    }
  }
`;

export const GET_PARTNER_ICON = gql`
  query partnerIcon {
    partnerIcon {
      icon
      apiKey
      tier
      expiry
    }
  }
`;

export const GET_PARTNER_GLOBAL_STYLE = gql`
  query getPartnerGlobalStyle {
    platformConfig {
      id
      lightHexBrand0
      lightHexBrand1
      lightHexBrand2
      lightHexBrand3
      lightHexBrand4
      lightHexBrand5
      lightHexBrand6
      lightHexBrand7
      lightHexBrand8
      lightHexContrast
      lightHexBackground
      lightHexText
      darkHexText
      darkHexBrand0
      darkHexBrand1
      darkHexBrand2
      darkHexBrand3
      darkHexBrand4
      darkHexBrand5
      darkHexBrand6
      darkHexBrand7
      darkHexBrand8
      darkHexContrast
      font {
        id
        name
        url
      }
      header
      bodyBold
      bodyRegular
      label
      ticker
      priceUp
      priceDown
      tabs
      capitalized
      activeTab
      otherTabs
      highlight
      mode
      defaultFonts {
        id
        name
        url
      }
      customFonts {
        id
        name
        url
      }
    }
  }
`;

export const UPDATE_GLOBAL_STYLE = gql`
  mutation updateGlobalStyle(
    $styleId: String!
    $field: String!
    $value: String!
  ) {
    updatePlatformConfig(styleId: $styleId, field: $field, value: $value)
  }
`;

export const SET_API_KEY_TYPE = gql`
  mutation setApiKeyType($isLimited: Boolean!) {
    setApiKeyType(isLimited: $isLimited) {
      success
      message
    }
  }
`;

export const EXTEND_TRIAL_API_KEY = gql`
  mutation extendTrialApiKey($trialExtensionInDays: String!) {
    extendTrialApiKey(trialExtensionInDays: $trialExtensionInDays) {
      success
      message
    }
  }
`;

export const DEACTIVATE_TRIAL_KEY = gql`
  mutation deactivateTrialKey {
    deactivateTrialKey {
      success
      message
    }
  }
`;

export const UPLOAD_FONT = gql`
  mutation uploadFontAsName($file: Upload!, $name: String!) {
    uploadFont(file: $file, name: $name)
  }
`;

export const REMOVE_FONT = gql`
  mutation removePartnerFont($fontId: String!) {
    removeFont(fontId: $fontId)
  }
`;

export const INVITE_ADMIN = gql`
  mutation inviteAdmin($email: String!, $grantAtomAdminAccess: Boolean!) {
    inviteAdmin(email: $email, grantAtomAdminAccess: $grantAtomAdminAccess) {
      success
      message
    }
  }
`;

export const SAVE_ADMIN_INFO = gql`
  mutation saveAdminInfo(
    $firstName: String!
    $lastName: String!
    $password: String!
    $passwordVerification: String!
    $token: String!
    $id: String!
    $hutk: String
    $checked: Boolean
    $termsServiceChecked: Boolean
  ) {
    saveAdminInfo(
      firstName: $firstName
      lastName: $lastName
      password: $password
      passwordVerification: $passwordVerification
      token: $token
      id: $id
      hutk: $hutk
      checked: $checked
      termsServiceChecked: $termsServiceChecked
    ) {
      success
      message
    }
  }
`;

export const REMOVE_ADMIN = gql`
  mutation removeAdmin($id: String!) {
    removeAdmin(id: $id) {
      success
      message
    }
  }
`;

export const CONFIRM_SIGN_UP = gql`
  mutation confirmSignUp($token: String!, $id: String!) {
    confirmSignUp(token: $token, id: $id) {
      success
      message
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation addPartner(
    $name: String!
    $description: String
    $tier: String!
    $icon: Upload
    $expiry: DateTime
  ) {
    addPartner(
      name: $name
      description: $description
      tier: $tier
      icon: $icon
      expiry: $expiry
    ) {
      success
      message
      partner {
        name
        id
        tier
        description
        expiry
        apiKey
      }
      apiKey
    }
  }
`;

export const GET_PARTNER_NEWS_CONFIG = gql`
  query getPartnerNewsConfig {
    partnerNewsConfig {
      id
      partnerId
      tabConfigA
      tabConfigB
      showTopicCodes
      showNewsTags
      allowSharing
      sharingURL
    }
  }
`;

export const GET_OVERVIEW_CONFIG = gql`
  query overviewConfig {
    overviewConfig {
      quoteSource {
        name
        enabled
      }
      platform {
        name
        enabled
      }
      market {
        name
        enabled
      }
      asset {
        name
        enabled
      }
      language {
        name
        enabled
      }
      embeddedUI {
        name
        enabled
      }
      newsSource {
        name
        enabled
      }
      whitelistUrl {
        name
        enabled
      }
      newsInsights {
        name
        enabled
      }
    }
  }
`;

export const UPDATE_PARTNER_NEWS_CONFIG = gql`
  mutation updatePartnerNewsConfig($changed: PartnerNewsConfigInput!) {
    updatePartnerNewsConfig(changed: $changed)
  }
`;

export const UPDATE_PARTNER_WEBHOOK_STATUS = gql`
  mutation updatePartnerWebhookStatus($webhookId: String!, $status: Boolean!) {
    updatePartnerWebhookStatus(webhookId: $webhookId, status: $status) {
      success
      message
    }
  }
`;

export const UPDATE_OVERVIEW_CONFIG = gql`
  mutation updateOverviewConfig(
    $section: String!
    $option: String!
    $enabled: Boolean!
  ) {
    updateOverviewConfig(
      section: $section
      option: $option
      enabled: $enabled
    ) {
      quoteSource {
        name
        enabled
      }
      platform {
        name
        enabled
      }
      market {
        name
        enabled
      }
      asset {
        name
        enabled
      }
      language {
        name
        enabled
      }
      embeddedUI {
        name
        enabled
      }
      newsSource {
        name
        enabled
      }
      whitelistUrl {
        name
        enabled
      }
      newsInsights {
        name
        enabled
      }
    }
  }
`;

export const DELETE_WEBHOOK = gql`
  mutation deleteWebhook($id: String!) {
    deleteWebhook(id: $id) {
      success
      message
    }
  }
`;

export const UPLOAD_MOBILE_CERT = gql`
  mutation uploadMobileCert(
    $file: Upload
    $operatingSystem: String!
    $environment: String!
    $password: String
  ) {
    uploadMobileCert(
      file: $file
      operatingSystem: $operatingSystem
      environment: $environment
      password: $password
    ) {
      success
      message
    }
  }
`;

export const UPLOAD_ANDROID_CREDENTIALS = gql`
  mutation uploadAndroidCredentials($serverKey: String, $senderId: String) {
    uploadAndroidCredentials(serverKey: $serverKey, senderId: $senderId) {
      success
      message
    }
  }
`;

export const UPDATE_MOBILE_CONFIG = gql`
  mutation updateMobileConfig($enabled: Boolean!, $operatingSystem: String!) {
    updateMobileConfig(enabled: $enabled, operatingSystem: $operatingSystem) {
      success
      message
    }
  }
`;

export const GET_MOBILE_CONFIG = gql`
  query mobileConfig {
    mobileConfig {
      operatingSystem
      enabled
      certificates {
        path
        environment
        password
      }
      credentials {
        serverKey
        senderId
      }
    }
  }
`;

export const UPDATE_ALERT_STATUS = gql`
  mutation updateAlertStatus($termsOfServiceViewed: Boolean!) {
    updateAlertStatus(termsOfServiceViewed: $termsOfServiceViewed) {
      showTermsOfService
    }
  }
`;

export const GET_ALERT_STATUS = gql`
  query alertStatus {
    alertStatus {
      showTermsOfService
    }
  }
`;

export const UPDATE_PARTNER_API_KEY = gql`
  mutation updatePartnerAPIKey {
    updatePartnerAPIKey {
      success
      message
    }
  }
`;

export const GET_WHITELIST_URL_CONFIG = gql`
  query whiteListUrlConfig {
    whiteListUrlConfig {
      options {
        name
        enabled
      }
    }
  }
`;

export const GET_PARTNER_CONFIG_ACTIVITY = gql`
  query partnerConfigActivity($currentPage: Int!, $pageSize: Int!) {
    partnerConfigActivity(currentPage: $currentPage, pageSize: $pageSize) {
      activity {
        description
        authorFirstName
        authorLastName
        activityType
        createdAt
      }
      totalCount
    }
  }
`;

export const GET_WEBHOOK_INFO = gql`
  query getWebhookInfo {
    webhookInfo {
      hmacSecret
      webhookList {
        id
        webhookURL
        isEnabled
        documentWebhookOptions {
          name
          enabled
          text
        }
        portfolioWebhookOptions {
          name
          enabled
          text
        }
      }
      allowedWebhookTypes
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp(
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $referrer: String!
    $phoneNumber: String!
    $companyWebsite: String
    $hutk: String
    $pageURL: String
    $pageName: String
    $checked: Boolean
    $termsServiceChecked: Boolean
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      referrer: $referrer
      phoneNumber: $phoneNumber
      companyWebsite: $companyWebsite
      hutk: $hutk
      pageURL: $pageURL
      pageName: $pageName
      checked: $checked
      termsServiceChecked: $termsServiceChecked
    ) {
      success
      message
    }
  }
`;

export const SEND_TEST_WEBHOOK_MESSAGE = gql`
  mutation sendTestWebhook($url: String!) {
    sendTestWebhook(url: $url) {
      success
      statusCode
    }
  }
`;

export const CREATE_WEBHOOK = gql`
  mutation createWebhook(
    $webhookId: String!
    $webhookURL: String!
    $sub_docs_earningsTranscripts: Boolean!
    $sub_docs_10K: Boolean!
    $sub_docs_10Q: Boolean!
    $sub_docs_8K: Boolean!
    $sub_docs_presentations: Boolean!
    $sub_docs_other: Boolean!
    $sub_portfolio_percent: Boolean!
    $sub_portfolio_daily_wrap: Boolean!
  ) {
    createWebhook(
      webhookId: $webhookId
      webhookURL: $webhookURL
      sub_docs_earningsTranscripts: $sub_docs_earningsTranscripts
      sub_docs_10K: $sub_docs_10K
      sub_docs_10Q: $sub_docs_10Q
      sub_docs_8K: $sub_docs_8K
      sub_docs_presentations: $sub_docs_presentations
      sub_docs_other: $sub_docs_other
      sub_portfolio_percent: $sub_portfolio_percent
      sub_portfolio_daily_wrap: $sub_portfolio_daily_wrap
    ) {
      success
      message
    }
  }
`;

export const GENERATE_NEW_HMAC_SECRET = gql`
  mutation generateNewHmacSecret {
    generateNewHmacSecret {
      success
      message
    }
  }
`;

export const TRACK_WITH_HUBSPOT = gql`
  mutation trackWithHubspot($hubspotData: String!) {
    trackWithHubspot(hubspotData: $hubspotData) {
      success
      message
    }
  }
`;
