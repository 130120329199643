"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationType = void 0;
var NotificationType;

(function (NotificationType) {
  NotificationType["ANALYST_RATING_CHANGE"] = "Analyst Rating Change";
  NotificationType["BRIEFING"] = "Briefing";
  NotificationType["EVENT"] = "Event";
  NotificationType["NEWS"] = "News";
  NotificationType["WIIM"] = "Wiim";
  NotificationType["PRICE_ALERT"] = "Price Alert";
  NotificationType["PORTFOLIO"] = "Portfolio";
  NotificationType["CORRECTION"] = "Correction";
  NotificationType["BenzingaStory"] = "BenzingaStory";
  NotificationType["CoindeskStory"] = "CoindeskStory";
  NotificationType["ReutersStory"] = "ReutersStory";
  NotificationType["InPlayBIPStory"] = "InPlayBIPStory";
  NotificationType["StockStory"] = "StockStory";
  NotificationType["BrokerageAction"] = "BrokerageAction";
  NotificationType["WiimStory"] = "WiimStory";
  NotificationType["Unknown"] = "Unknown";
  NotificationType["GLOBAL"] = "Global";
  NotificationType["DOCUMENT"] = "Document";
  NotificationType["ABANDONED"] = "Abandoned";
  NotificationType["PROMOTION"] = "Promotion";
  NotificationType["PRICE_TARGET_ALERT"] = "Price Target Alert";
  NotificationType["EARNINGS"] = "Earnings";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));