import { useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AtomIcon from '../../common/AtomIcon';
import { GET_ACCOUNT_INFO, INVITE_ADMIN } from '../../common/queries';
import {
  CloseButtonContainer,
  GreenClickableText,
  Input,
  InputContainer,
  PaddedGreenClickableButton,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';
import Modal from './Modal';

export const MobileConfiguration = ({ closeModal }) => {
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteMutation, inviteResp] = useMutation(INVITE_ADMIN, {
    refetchQueries: [{ query: GET_ACCOUNT_INFO }],
  });
  const submitInvite = () => {
    inviteMutation({
      variables: { email: inviteEmail, grantAtomAdminAccess: false },
    });
  };

  const { loading, data } = inviteResp;
  const success = data?.inviteAdmin?.success;
  const message = data?.inviteAdmin?.message;
  const disableSubmit = !inviteEmail?.length || loading;

  return (
    <AddMemberContainer>
      <AddMemberContent
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <FlexContainer>
          <CloseButtonContainer onClick={() => closeModal()}>
            <AtomIcon
              size={35}
              icon={'close'}
              iconCss={
                css`
                  fill: var(--atom-green);
                ` as any
              }
              onClick={close}
            />
          </CloseButtonContainer>
        </FlexContainer>
        <MainTextContainer>
          <TextContainer>Add others</TextContainer>
          <InputContainer>
            <MemberInput
              onChange={e => setInviteEmail(e.target.value)}
              placeholder='Enter email address'
              value={inviteEmail}
            />
          </InputContainer>
          <ResponseText success={success}>{message}</ResponseText>
          <InputContainer>
            <PaddedGreenClickableButton
              disabled={disableSubmit}
              onClick={() => {
                if (!disableSubmit) submitInvite();
              }}
            >
              {loading ? <Spinner smaller={true} /> : `INVITE`}
            </PaddedGreenClickableButton>
          </InputContainer>
        </MainTextContainer>
      </AddMemberContent>
    </AddMemberContainer>
  );
};

const AddMemberModal = ({ fontSize = 15 }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <WidthGreenClickableButton
        style={{ fontSize }}
        onClick={() => setShowModal(true)}
      >
        {`ADD MEMBER`}
      </WidthGreenClickableButton>
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <MobileConfiguration closeModal={() => setShowModal(false)} />
        </Modal>
      )}
    </div>
  );
};

export const MainTextContainer = styled.div`
  margin: 1em;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MemberInput = styled(Input as any)`
  min-width: 23em;
  width: 100%;
  margin: 5px;
`;

export const TextContainer = styled.div`
  padding: 1em 5px;
  font-size: 24px;
`;

export const AddMemberContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  display: flex;
`;

export const AddMemberContent = styled.div`
  padding: 1.5em 2em 2em 2em;
  border-radius: 6px;
  background-color: var(--atom-brand-3);
  align-items: center;
`;

export default AddMemberModal;
