"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmailCategory = void 0;
var EmailCategory;

(function (EmailCategory) {
  EmailCategory["DAILY_DIGEST"] = "dailyDigest";
  EmailCategory["VERIFICATION"] = "verification";
  EmailCategory["PORTFOLIO_RECONNECT"] = "portfolioReconnect";
  EmailCategory["LAST_DELINQUENCY"] = "lastDelinquency";
  EmailCategory["DOC_ALERT"] = "docAlert";
  EmailCategory["MAGIC_LINK"] = "magicLink";
  EmailCategory["ADMIN_SIGNUP_LINK"] = "adminSignupLink";
  EmailCategory["PARTNER_SIGNUP_LINK"] = "partnerSignupLink";
  EmailCategory["SUBSCRIBE_ONBOARDING"] = "subscribeOnboarding";
  EmailCategory["SUBSCRIBE_POST_TRIAL"] = "subscribePostTrial";
  EmailCategory["SUBSCRIBE_DURING_TRIAL_BRAINTREE"] = "subscribeDuringTrialBraintree";
  EmailCategory["SUBSCRIBE_DURING_TRIAL_IOS"] = "subscribeDuringTrialIOS";
  EmailCategory["SUBSCRIPTION_CANCELED"] = "subscriptionCanceled";
  EmailCategory["FRIEND_INVITE"] = "friendInvite";
  EmailCategory["FIRST_DELINQUENCY"] = "firstDelinquency";
  EmailCategory["RESET_PASSWORD"] = "resetPassword";
  EmailCategory["RESET_PASSWORD_ADMIN"] = "resetPasswordAdmin";
  EmailCategory["PAYMENT_CHANGED"] = "paymentChanged";
  EmailCategory["ALERTS_INTERRUPTION"] = "alertsInterruption";
  EmailCategory["PREMIUM_ANNOUNCEMENT"] = "premiumAnnouncement";
  EmailCategory["ACCOUNT_UPDATES"] = "accountUpdates";
  EmailCategory["PRODUCT_ANNOUNCEMENT"] = "productAnnouncement";
  EmailCategory["ABANDONED_CART"] = "abandonedCart";
  EmailCategory["ABANDONED_SIGNUP"] = "abandonedSignup";
  EmailCategory["ABANDONED_PORTFOLIO"] = "abandonedPortfolio";
  EmailCategory["ACCOUNT_DELETED"] = "accountDeleted";
  EmailCategory["WEBHOOK_DISABLED"] = "webhookDisabled";
})(EmailCategory = exports.EmailCategory || (exports.EmailCategory = {}));