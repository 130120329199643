import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { AUTHENTICATE_AS_ADMIN, SIGN_UP } from '../../common/queries';
import MainHeader from '../../components/MainHeader';
import {
  CheckboxContainer,
  GreenClickableText,
  Input,
  InputContainer,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';
import ConfirmEmail from './ConfirmEmail';
import { warnOnceInDevelopment } from 'apollo-utilities';
import { first } from 'lodash';
import { useHistory } from 'react-router-dom';

type Checkbox = {
  label: string;
  labelWithLink?: JSX.Element;
  checked: boolean;
  updateBox: () => void;
};

const Checkbox = ({ label, labelWithLink, checked, updateBox }: Checkbox) => {
  // style div, style input?
  const displayLabel = labelWithLink ? labelWithLink : label;
  return (
    <SelectionContainer>
      <CustomCheckbox checked={checked} updateBox={updateBox} />
      <TextContainer>{displayLabel}</TextContainer>
    </SelectionContainer>
  );
};

export const CustomCheckbox = ({ checked, updateBox }) => {
  return (
    <CheckboxContainer onClick={updateBox}>
      <input type='checkbox' checked={checked} />
      <span className='checkmark' />
    </CheckboxContainer>
  );
};

const SelfSignUpForm = ({ setSignUp }) => {
  const history = useHistory();
  const [signUp, signUpResponse] = useMutation(SIGN_UP);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');

  const [checked, setChecked] = useState(false);
  const [termsServiceChecked, setTermsServiceChecked] = useState(false);

  const updateBox = () => {
    setChecked(!checked);
  };

  const signUpUser = useCallback(
    async (
      firstName: string,
      lastName: string,
      companyName: string,
      email: string,
      password: string,
      confirmPassword: string,
      phoneNumber: string,
      companyWebsite: string,
      checked: boolean,
      termsServiceChecked: boolean,
    ) => {
      const cookies = {};
      document.cookie.split(';').forEach(function (el) {
        const [key, value] = el.split('=');
        cookies[key.trim()] = value;
      });
      const hutk = cookies['hubspotutk'];
      const pageURL = window.location.hostname;
      const pageName = window.location.pathname;
      signUp({
        variables: {
          firstName,
          lastName,
          companyName,
          email,
          password,
          confirmPassword,
          referrer: 'admin-client-sign-up',
          phoneNumber,
          companyWebsite,
          hutk,
          pageURL,
          pageName,
          checked,
          termsServiceChecked,
        },
      });
    },
    [signUp],
  );

  const { loading, data } = signUpResponse;
  const success = data?.signUp?.success;
  const message = data?.signUp?.message;
  const disableSubmit =
    !firstName?.length ||
    !lastName?.length ||
    !companyName?.length ||
    !email?.length ||
    !password?.length ||
    !confirmPassword?.length ||
    phoneNumber?.length < 9 ||
    !termsServiceChecked ||
    loading;

  if (success) {
    window.analytics.identify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      companyName: companyName,
      opt_in: !checked,
      reason: 'Request a trial API key',
    });
    return (
      <>
        <ConfirmEmail email={email} />
      </>
    );
  }

  return (
    /*Sign In Screen*/
    <>
      <MainHeader includeRight={false} />
      <SigninContainer>
        <SigninSection>
          <TitleSectionTop>Let&apos;s get started</TitleSectionTop>
          <InputRow>
            <Input
              onChange={e => setFirstName(e.target.value)}
              placeholder='First Name'
              value={firstName}
            />
            <Input
              onChange={e => setLastName(e.target.value)}
              placeholder='Last Name'
              value={lastName}
            />
          </InputRow>
          <InputRow>
            <Input
              onChange={e => setphoneNumber(e.target.value)}
              placeholder='Phone Number'
              value={phoneNumber}
            />
            <Input
              onChange={e => setCompanyWebsite(e.target.value)}
              placeholder='Company Website (optional)'
              value={companyWebsite}
            />
          </InputRow>
          <InputRow>
            <Input
              onChange={e => setCompanyName(e.target.value)}
              placeholder='Company Name'
              value={companyName}
            />
            <Input
              onChange={e => setEmail(e.target.value)}
              placeholder='Work email address'
              value={email}
            />
          </InputRow>
          <InputRow>
            <Input
              onChange={e => setPassword(e.target.value)}
              placeholder='Password'
              value={password}
              type='password'
              autoComplete='off'
            />
            <Input
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder='Confirm Password'
              value={confirmPassword}
              type='password'
              autoComplete='off'
            />
          </InputRow>
          <PrivacyBlock>
            <Disclaimer>
              Before you submit your contact details, please read our
              <a href='https://go.atom.finance/legal/privacy-policy'>
                {' '}
                Privacy Policy{' '}
              </a>
              to understand how we will handle your information.
            </Disclaimer>
            <Checkbox
              label=''
              labelWithLink={
                <span>
                  Checking this box means you have acknowledged, agreed to, and
                  accepted our
                  <a
                    style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                    href='https://go.atom.finance/legal/api-terms-of-use'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    terms of service polices
                  </a>
                  . You cannot open an account without doing so.
                </span>
              }
              checked={termsServiceChecked}
              updateBox={() => setTermsServiceChecked(!termsServiceChecked)}
            />
            <Checkbox
              label={
                ' Atom Finance may contact you about relevant content, products, and services.\n' +
                'Please check this box if you do NOT wish to receive our communications.'
              }
              checked={checked}
              updateBox={updateBox}
            />
          </PrivacyBlock>
          <ResponseText success={success}>{message}</ResponseText>
          <InputContainer>
            <WidthGreenClickableButton
              disabled={disableSubmit}
              onClick={() => {
                if (!disableSubmit)
                  signUpUser(
                    firstName,
                    lastName,
                    companyName,
                    email,
                    password,
                    confirmPassword,
                    phoneNumber,
                    companyWebsite,
                    checked,
                    termsServiceChecked,
                  );
              }}
            >
              {loading ? <Spinner smaller={true} /> : `CREATE ACCOUNT`}
            </WidthGreenClickableButton>
          </InputContainer>
          <div
            className='flex row'
            style={{
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '20px',
            }}
          >
            HAVE AN ACCOUNT?
            <ClickableText
              onClick={() => {
                if (setSignUp) {
                  setSignUp(false);
                } else {
                  history.push('/');
                }
              }}
            >
              {`SIGN IN`}
            </ClickableText>
          </div>
        </SigninSection>
      </SigninContainer>
    </>
  );
};

const ClickableText = styled.a<{ disabled?: boolean }>`
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-dark-green);
  text-transform: uppercase;
  margin-left: 10px;
`;

const TitleSectionTop = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
`;

const SigninSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

const Disclaimer = styled.div`
  justify-content: left;
  margin-top: 15px;
  padding-bottom: 10px;
`;

const PrivacyBlock = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 10px 35px;
  width: 80%;
  max-width: 44em;
  font-size: 12px;
`;

const TextContainer = styled.span`
  margin-top: 0em;
  padding-left: 0.7em;
  margin-bottom: 0.2em;
`;

const SelectionContainer = styled.div`
  display: flex;
  align-items: left;
  padding: 0.5em;
`;

export default SelfSignUpForm;
