import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { AUTHENTICATE_AS_ADMIN, SIGN_UP } from '../../common/queries';
import MainHeader from '../../components/MainHeader';
import {
  GreenClickableText,
  Input,
  InputContainer,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';

const ConfirmEmail = ({ email }) => {
  return (
    <>
      <MainHeader includeRight={false} />
      <SigninContainer>
        <SigninSection>
          <TitleSectionTop>Confirm your email</TitleSectionTop>
          <DescriptionText>
            Please click the link in the email sent to {email}. This may take up
            to a minute to reach your inbox. Once you confirm your email, you
            will be taken to the Admin Portal where you will find your Trial
            Key.
          </DescriptionText>
          <div
            className='flex row'
            style={{
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '12em',
            }}
          >
            DIDN&apos;T GET AN EMAIL
            <ClickableText
              onClick={() => {
                window.open(
                  'https://atom.finance/enterprise?contact_reason=general',
                );
              }}
            >
              {`CONTACT SALES DIRECTLY`}
            </ClickableText>
          </div>
        </SigninSection>
      </SigninContainer>
    </>
  );
};

const ClickableText = styled.a<{ disabled?: boolean }>`
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-dark-green);
  text-transform: uppercase;
  margin-left: 10px;
`;

const TitleSectionTop = styled.div`
  font-size: 48px;
  margin-bottom: 1.5em;
`;

const DescriptionText = styled.div`
  font-size: 22px;
  margin-bottom: 1.5em;
  margin-left: 5em;
  margin-right: 5em;
`;

const SigninSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Button = styled.div`
  margin-top: 8px;
  background-color: var(--atom-green);
  padding: 1em;
  font-size: 16px;
  border-radius: 3px;
  width: 20em;
  text-align: center;
  color: rgb(50, 50, 62, 0.7);
  &:hover {
    color: rgb(50, 50, 62, 1);
  }
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
`;

export default ConfirmEmail;
