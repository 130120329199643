import styled from 'styled-components';

export const GreenClickableText = styled.div<{ disabled?: boolean }>`
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-${props => (props.disabled ? 'brand-7' : 'dark-green')});
  &:hover {
    color: var(
      --atom-${props => (props.disabled ? 'brand-7' : 'dark-green-hover')}
    );
  }
  text-transform: uppercase;
`;

export const GreenClickableButton = styled.div<{ disabled?: boolean }>`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  border-radius: 3px;
  padding: 1em;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: var(--atom-${props => (props.disabled ? 'brand-7' : 'brand-3')});
  background-color: var(
    --atom-${props => (props.disabled ? 'dark-green' : 'dark-green')}
  );
  &:hover {
    background-color: var(
      --atom-${props => (props.disabled ? 'dark-green' : 'dark-green-hover')}
    );
  }
  text-transform: uppercase;
`;

export const PaddedGreenClickableButton = styled(GreenClickableButton as any)`
  margin: 15px 5px 5px 5px;
  flex-grow: 1;
`;

export const WidthGreenClickableButton = styled(GreenClickableButton as any)`
  margin: 15px 5px 5px 0px;
  font-size: 15px;
  width: 22em;
`;

export const InputContainer = styled.div`
  display: flex;
`;

export const Spinner = styled.div<{
  smaller?: boolean;
}>`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    margin: auto;
    content: '';
    width: ${props => (props.smaller ? '20px' : '75px')};
    height: ${props => (props.smaller ? '20px' : '75px')};
    display: block;
    border-radius: 50%;
    border-top: 2px solid white;
    animation: spinner 0.5s linear infinite;
  }
`;

export const SmallCircle = styled.div<{ disabled?: boolean }>`
  border-radius: 100%;
  height: 1em;
  width: 1em;
  margin: 3px;
  background-color: var(
    --atom-dark-${props => (props.disabled ? 'contrast' : 'green')}
  );
  border: solid
    var(--atom-dark-${props => (props.disabled ? 'contrast' : 'green')});
`;

export const ToggleButton = styled.div<{ disabled?: boolean }>`
  background-color: var(--atom-brand-4);
  margin-right: 4px;
  border-radius: 1em;
  height: fit-content;
  width: 3em;
  min-width: 3em;
  align-items: right;
  justify-content: flex-${props => (props.disabled ? 'start' : 'end')};
  cursor: pointer;
  display: flex;
`;

export const HeaderText = styled.div`
  padding-top: 1.5em;
  font-size: 24px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const ModalHeaderContainer = styled.div`
  padding: 2em;
  border-bottom: 1px solid var(--atom-dark-brand-5);
`;

export const Input = styled.input<{ disabled?: boolean }>`
  margin-bottom: 11px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: var(--atom-dark-brand-5);
  color: white;
  font-size: 16px;
  padding: 20px 22px;
  border-radius: 3px;
  border: hidden;
  box-sizing: border-box;
  max-width: 616px;
  justify-content: center;
  width: 22em;
`;

export const ResponseText = styled.div<{ success?: boolean }>`
  color: var(--atom-${props => (props.success ? 'dark-green' : 'red')});
  padding-top: 2px;
  text-align: center;
`;

export const OptionContainer = styled.div`
  border-top: 1px solid var(--atom-dark-brand-4);
  padding: 2em 2em;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 1em 0em;
`;

export const ConfigurationContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
`;

export const ConfigurationContent = styled.div`
  min-width: 28rem;
  height: 100vh;
  background-color: var(--atom-dark-brand-3);
  align-self: flex-end;
  display: flex;
  flex-direction: column;
`;

// set padding and margin to avoid the dropdown/palette not showing up
// see https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
export const SectionContainer = styled.div`
  padding: 0em 0em;
  padding-left: 30%;
  margin-left: -30%;
  overflow-y: scroll;
`;

export const IndexHeader = styled.h2.attrs({
  className: 'font-din',
})<{ noMargin?: boolean }>`
  font-weight: 400;
  padding-bottom: 1.875em;
  width: 100%;
  max-width: 616px;
  font-size: 42px;
  text-align: center;
  color: white;
  @media (max-width: 567px) {
    font-size: 18px;
  }
  margin-bottom: ${props => (props.noMargin ? '0' : '1rem')};
`;

export const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 3px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--atom-dark-brand-4);
    border: solid var(--atom-dark-brand-7);
    border-width: 1px;
  }
  input:checked ~ .checkmark {
    background-color: var(--atom-dark-green);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
