import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { darken } from 'polished/lib/index';
import apiAxios from './apiAxios';
import { WizardContainer as Container } from './AdminWizard';
import { Helmet } from 'react-helmet';
import MainHeader from '../../components/MainHeader';
import { IndexHeader, Input, Spinner } from '../../common/styledComponents';

class PW extends React.Component<{ history: any; match: any }> {
  state = {
    loading: false,
    error: null,
    password: '',
    confirmPassword: '',
    success: false,
  };

  handleChangeInput = e => {
    if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.state.password || !this.state.confirmPassword) {
      this.setState({
        error: 'Fill out the fields below.',
      });

      return null;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        error: 'Passwords do not match.',
      });

      return null;
    }

    if (this.state.password.length < 8) {
      this.setState({
        error: 'Password must be at least 8 characters long.',
      });

      return null;
    }

    // Passwords exist & do not match
    this.setState({
      loading: true,
    });

    const reset = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      token: this.props.match.params.token,
    };

    try {
      const response = await apiAxios.post('/session/resetPassword', reset);
      const { data } = response;
      if (data.success) {
        this.setState({
          error: '',
          success: true,
          loading: false,
        });

        // Finally, refresh the page
        setTimeout(() => {
          this.props.history.replace('/signIn');
        }, 5000);
      } else if (data.error) {
        this.setState({
          error: data.error,
          loading: false,
        });
      }

      return;
    } catch (err) {
      this.setState({
        error: 'An error occurred while resetting password.',
        loading: false,
      });

      return;
    }
  };

  render() {
    return (
      <div>
        <IndexHeader>Reset Password</IndexHeader>
        {this.state.success ? (
          <>
            <Success
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Password has been
              <br />
              successfully reset!
              <br />
              Reloading page momentarily...
              <br />
            </Success>
            <Spinner
              style={{
                display: 'flex',
                paddingTop: '2em',
              }}
              smaller={true}
            />
          </>
        ) : (
          <Form onSubmit={this.handleSubmit}>
            {this.state.error && (
              <ErrorMessage
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {this.state.error}
              </ErrorMessage>
            )}
            <FieldRowExpanded
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Input
                style={{ width: '22em' }}
                name='password'
                type='password'
                placeholder='New Password'
                value={this.state.password}
                onChange={this.handleChangeInput}
              />
            </FieldRowExpanded>
            {}
            <FieldRowExpanded
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Input
                style={{ width: '22em' }}
                type='password'
                name='confirmPassword'
                placeholder='Confirm Password'
                value={this.state.confirmPassword}
                onChange={this.handleChangeInput}
              />
            </FieldRowExpanded>
            <FieldRowSubmit
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CommonGreenButton type='submit' disabled={this.state.loading}>
                Reset Password
              </CommonGreenButton>
            </FieldRowSubmit>
          </Form>
        )}
      </div>
    );
  }
}

const Reset = withRouter(PW);

const ResetContainer = props => {
  return (
    <Container>
      <Helmet>
        <title>Password Reset | Atom Finance</title>
      </Helmet>
      <MainHeader includeRight={false} includeSignIn={true} />
      <ResetPasswordContainer>
        <ResetPasswordSection>
          <Reset />
        </ResetPasswordSection>
      </ResetPasswordContainer>
    </Container>
  );
};

export default ResetContainer;

const Form = styled.form.attrs({
  className: 'my-8',
})`
  width: 300px;
`;

const ErrorMessage = styled.div.attrs({
  className: 'w-full',
})`
  font-size: 12px;
  font-weight: bold;
  color: red;
`;

const Success = styled.div.attrs({
  className: 'w-full h-full flex items-center justify-center mt-4',
})`
  font-size: 16px;
  text-align: center;
`;

const CommonInput = styled.input.attrs({ className: 'font-din' })`
  min-width: 500px;
  max-width: 616px;
  margin-bottom: 16px;
  background-color: var(--atom-dark-brand-5);
  color: white;
  font-size: 16px;
  padding: 18px 22px;
  border-radius: 3px;
`;

const FieldRowExpanded = styled.div`
  margin-bottom: 1rem;
`;

const FieldRowSubmit = styled.div<{ marginTop?: string }>`
  margin-top: ${props => props.marginTop || '1.5rem'};
`;

const ResetPasswordContainer = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ResetPasswordSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  margin: 1.5em 0em;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 616px;
  min-width: 616px;
`;

const CommonGreenButton = styled.button.attrs({
  className: 'flex justify-center items-center  font-din',
})<{ marginRight?: string }>`
  margin-right: ${props => props.marginRight || '0'};
  width: 22.5em;
  font-size: 16px;
  height: 3em;
  font-weight: bold;
  color: var(--atom-${props => (props.disabled ? 'brand-7' : 'brand-3')});
  background-color: var(
    --atom-${props => (props.disabled ? 'dark-green' : 'dark-green')}
  );
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  transition: background-color 0.1s ease-out;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:active {
    background-color: ${darken(0.1, '#526682')};
  }
  &:hover {
    background-color: var(
      --atom-${props => (props.disabled ? 'dark-green' : 'dark-green-hover')}
    );
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
