"use strict";
/**
 * easy to read delays that can be used everywhere
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._1Week_S = exports._1day_S = exports._1hr_S = exports._10min_S = exports._5min_S = exports._3min_S = exports._2min_S = exports._1min_S = exports._30s_S = exports._10s_S = exports._1Week_MS = exports._1day_MS = exports._1hr_MS = exports._10min_MS = exports._5min_MS = exports._3min_MS = exports._2min_MS = exports._1min_MS = exports._30s_MS = exports._10s_MS = void 0; // MILLISECONDS

exports._10s_MS = 10 * 1000;
exports._30s_MS = 30 * 1000;
exports._1min_MS = 60 * 1000;
exports._2min_MS = 2 * exports._1min_MS;
exports._3min_MS = 3 * exports._1min_MS;
exports._5min_MS = 5 * exports._1min_MS;
exports._10min_MS = 10 * exports._1min_MS;
exports._1hr_MS = 60 * exports._1min_MS;
exports._1day_MS = 24 * exports._1hr_MS;
exports._1Week_MS = 7 * exports._1day_MS; // SECONDS (redis uses seconds)

exports._10s_S = 10;
exports._30s_S = 30;
exports._1min_S = 60;
exports._2min_S = 2 * exports._1min_S;
exports._3min_S = 3 * exports._1min_S;
exports._5min_S = 5 * exports._1min_S;
exports._10min_S = 10 * exports._1min_S;
exports._1hr_S = 60 * exports._1min_S;
exports._1day_S = 24 * exports._1hr_S;
exports._1Week_S = 7 * exports._1day_S;