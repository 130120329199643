"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidCurrencyCode = exports.CurrencyCode = void 0; // codes from ISO 4217 (Jan 1 2023)

var CurrencyCode;

(function (CurrencyCode) {
  CurrencyCode["AFN"] = "AFN";
  CurrencyCode["ALL"] = "ALL";
  CurrencyCode["AOA"] = "AOA";
  CurrencyCode["XCD"] = "XCD";
  CurrencyCode["AMD"] = "AMD";
  CurrencyCode["AWG"] = "AWG";
  CurrencyCode["AZN"] = "AZN";
  CurrencyCode["BSD"] = "BSD";
  CurrencyCode["BHD"] = "BHD";
  CurrencyCode["BDT"] = "BDT";
  CurrencyCode["BBD"] = "BBD";
  CurrencyCode["BYN"] = "BYN";
  CurrencyCode["BZD"] = "BZD";
  CurrencyCode["XOF"] = "XOF";
  CurrencyCode["BMD"] = "BMD";
  CurrencyCode["BTN"] = "BTN";
  CurrencyCode["BOB"] = "BOB";
  CurrencyCode["BOV"] = "BOV";
  CurrencyCode["BAM"] = "BAM";
  CurrencyCode["BWP"] = "BWP";
  CurrencyCode["BND"] = "BND";
  CurrencyCode["BGN"] = "BGN";
  CurrencyCode["BIF"] = "BIF";
  CurrencyCode["CVE"] = "CVE";
  CurrencyCode["KHR"] = "KHR";
  CurrencyCode["XAF"] = "XAF";
  CurrencyCode["KYD"] = "KYD";
  CurrencyCode["CLF"] = "CLF";
  CurrencyCode["COU"] = "COU";
  CurrencyCode["KMF"] = "KMF";
  CurrencyCode["CDF"] = "CDF";
  CurrencyCode["CRC"] = "CRC";
  CurrencyCode["CUP"] = "CUP";
  CurrencyCode["CUC"] = "CUC";
  CurrencyCode["ANG"] = "ANG";
  CurrencyCode["DJF"] = "DJF";
  CurrencyCode["DOP"] = "DOP";
  CurrencyCode["SVC"] = "SVC";
  CurrencyCode["ERN"] = "ERN";
  CurrencyCode["SZL"] = "SZL";
  CurrencyCode["ETB"] = "ETB";
  CurrencyCode["FKP"] = "FKP";
  CurrencyCode["FJD"] = "FJD";
  CurrencyCode["GMD"] = "GMD";
  CurrencyCode["GEL"] = "GEL";
  CurrencyCode["GHS"] = "GHS";
  CurrencyCode["GIP"] = "GIP";
  CurrencyCode["GTQ"] = "GTQ";
  CurrencyCode["GNF"] = "GNF";
  CurrencyCode["GYD"] = "GYD";
  CurrencyCode["HTG"] = "HTG";
  CurrencyCode["HNL"] = "HNL";
  CurrencyCode["XDR"] = "XDR";
  CurrencyCode["IRR"] = "IRR";
  CurrencyCode["IQD"] = "IQD";
  CurrencyCode["JMD"] = "JMD";
  CurrencyCode["JOD"] = "JOD";
  CurrencyCode["KES"] = "KES";
  CurrencyCode["KPW"] = "KPW";
  CurrencyCode["KWD"] = "KWD";
  CurrencyCode["KGS"] = "KGS";
  CurrencyCode["LAK"] = "LAK";
  CurrencyCode["LBP"] = "LBP";
  CurrencyCode["LSL"] = "LSL";
  CurrencyCode["LRD"] = "LRD";
  CurrencyCode["LYD"] = "LYD";
  CurrencyCode["MOP"] = "MOP";
  CurrencyCode["MKD"] = "MKD";
  CurrencyCode["MGA"] = "MGA";
  CurrencyCode["MWK"] = "MWK";
  CurrencyCode["MVR"] = "MVR";
  CurrencyCode["MDL"] = "MDL";
  CurrencyCode["MNT"] = "MNT";
  CurrencyCode["MZN"] = "MZN";
  CurrencyCode["MMK"] = "MMK";
  CurrencyCode["NAD"] = "NAD";
  CurrencyCode["NPR"] = "NPR";
  CurrencyCode["NIO"] = "NIO";
  CurrencyCode["NGN"] = "NGN";
  CurrencyCode["TJS"] = "TJS";
  CurrencyCode["TZS"] = "TZS";
  CurrencyCode["TOP"] = "TOP";
  CurrencyCode["TTD"] = "TTD";
  CurrencyCode["TND"] = "TND";
  CurrencyCode["TMT"] = "TMT";
  CurrencyCode["UGX"] = "UGX";
  CurrencyCode["UAH"] = "UAH";
  CurrencyCode["AED"] = "AED";
  CurrencyCode["USN"] = "USN";
  CurrencyCode["UYU"] = "UYU";
  CurrencyCode["UYI"] = "UYI";
  CurrencyCode["UYW"] = "UYW";
  CurrencyCode["UZS"] = "UZS";
  CurrencyCode["VUV"] = "VUV";
  CurrencyCode["VED"] = "VED";
  CurrencyCode["VND"] = "VND";
  CurrencyCode["XPF"] = "XPF";
  CurrencyCode["YER"] = "YER";
  CurrencyCode["ZMW"] = "ZMW";
  CurrencyCode["ZWL"] = "ZWL";
  CurrencyCode["XBA"] = "XBA";
  CurrencyCode["XBB"] = "XBB";
  CurrencyCode["XBC"] = "XBC";
  CurrencyCode["XBD"] = "XBD";
  CurrencyCode["XTS"] = "XTS";
  CurrencyCode["XXX"] = "XXX";
  CurrencyCode["XAU"] = "XAU";
  CurrencyCode["XPD"] = "XPD";
  CurrencyCode["XPT"] = "XPT";
  CurrencyCode["XAG"] = "XAG";
  CurrencyCode["ARS"] = "ARS";
  CurrencyCode["AUD"] = "AUD";
  CurrencyCode["BRL"] = "BRL";
  CurrencyCode["CAD"] = "CAD";
  CurrencyCode["CHF"] = "CHF";
  CurrencyCode["CLP"] = "CLP";
  CurrencyCode["CNH"] = "CNH";
  CurrencyCode["CNY"] = "CNY";
  CurrencyCode["COP"] = "COP";
  CurrencyCode["CZK"] = "CZK";
  CurrencyCode["DKK"] = "DKK";
  CurrencyCode["DZD"] = "DZD";
  CurrencyCode["EGP"] = "EGP";
  CurrencyCode["EUR"] = "EUR";
  CurrencyCode["GBP"] = "GBP";
  CurrencyCode["HKD"] = "HKD";
  CurrencyCode["HUF"] = "HUF";
  CurrencyCode["IDR"] = "IDR";
  CurrencyCode["ILS"] = "ILS";
  CurrencyCode["INR"] = "INR";
  CurrencyCode["ISK"] = "ISK";
  CurrencyCode["JPY"] = "JPY";
  CurrencyCode["KRW"] = "KRW";
  CurrencyCode["KZT"] = "KZT";
  CurrencyCode["LKR"] = "LKR";
  CurrencyCode["MAD"] = "MAD";
  CurrencyCode["MXN"] = "MXN";
  CurrencyCode["MYR"] = "MYR";
  CurrencyCode["NOK"] = "NOK";
  CurrencyCode["NZD"] = "NZD";
  CurrencyCode["PEN"] = "PEN";
  CurrencyCode["PHP"] = "PHP";
  CurrencyCode["PLN"] = "PLN";
  CurrencyCode["RON"] = "RON";
  CurrencyCode["RUB"] = "RUB";
  CurrencyCode["SEK"] = "SEK";
  CurrencyCode["SGD"] = "SGD";
  CurrencyCode["THB"] = "THB";
  CurrencyCode["TRY"] = "TRY";
  CurrencyCode["TWD"] = "TWD";
  CurrencyCode["USD"] = "USD";
  CurrencyCode["VES"] = "VES";
  CurrencyCode["ZAR"] = "ZAR";
})(CurrencyCode = exports.CurrencyCode || (exports.CurrencyCode = {}));

var isValidCurrencyCode = function isValidCurrencyCode(candidateCurrencyCode) {
  return Object.values(CurrencyCode).includes(candidateCurrencyCode);
};

exports.isValidCurrencyCode = isValidCurrencyCode;