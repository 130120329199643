import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  AUTHENTICATE_AS_ADMIN,
  CONFIRM_SIGN_UP,
  GET_ACCOUNT_INFO,
  GET_ADMIN_INFO,
  GET_PARTNER_CONFIG_ACTIVITY,
  SAVE_ADMIN_INFO,
  SIGN_UP,
} from '../../common/queries';
import MainHeader from '../../components/MainHeader';
import {
  GreenClickableText,
  Input,
  InputContainer,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';
import { withRouter } from 'react-router-dom';
import atomlytics from '../../../helpers/atomlytics';

const SignUpComplete = props => {
  const token = props?.match?.params?.token;
  const id = props?.match?.params?.id;
  const [saveAdminInfo, saveAdminInfoResponse] = useMutation(CONFIRM_SIGN_UP);

  const { data: adminInfo } = useQuery(GET_ADMIN_INFO, {
    variables: { id, token },
  });

  const success = saveAdminInfoResponse?.data?.confirmSignUp?.success;
  const message = saveAdminInfoResponse?.data?.confirmSignUp?.message;

  const apiKey = adminInfo?.adminInformation?.partner?.apiKey;
  const partnerName = adminInfo?.adminInformation?.partner?.name;

  useEffect(() => {
    saveAdminInfo({
      variables: {
        token,
        id,
      },
    });
  }, [id, token, saveAdminInfo]);

  useEffect(() => {
    atomlytics.accountCreatedClient({
      signup_type: 'portal',
      signup_location: 'homepage',
    });
    window.analytics.identify(id, {
      firstName: adminInfo?.adminInformation?.firstName,
      lastName: adminInfo?.adminInformation?.lastName,
      email: adminInfo?.adminInformation?.email,
      phoneNumber: adminInfo?.adminInformation?.phoneNumber,
      companyName: partnerName,
    });
  }, [id, adminInfo, partnerName]);

  return (
    /*Sign In Screen*/
    <>
      <MainHeader includeRight={false} />
      <SigninContainer>
        <SigninSection>
          <TitleSectionTop>{partnerName}</TitleSectionTop>
          <SignInSectionContent>
            <DescriptionText>
              You&apos;re all set to get started with your trial API key. Our
              sales team will be in touch to discuss your specific needs, after
              which they can get you set up with a production API key. In the
              meantime, you can use this key to test our API endpoint in our
              <a
                style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/introduction'
              >
                docs
              </a>
              .
            </DescriptionText>
            <DescriptionText>
              Note: In order to access our full library of assets, you will need
              a production key. You can still test any of our endpoints with
              your Trial Key, though the responses you receive with your Trial
              Key will be limited to a select few assets (e.g. AAPL, TSLA, SPY,
              QQQ, VTSAX, BTC and DOGE). For more info, see our docs on
              <a
                style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/authentication'
              >
                authentication
              </a>
              .
            </DescriptionText>
            {!success ? (
              <ResponseText success={success}>{message}</ResponseText>
            ) : null}
            <WidthGreenClickableButton
              disabled={false}
              onClick={async () => {
                window.open('https://docs.atom.finance/reference/introduction');
                window.location.href = '/';
              }}
            >
              VIEW DOCS & TEST NOW
            </WidthGreenClickableButton>
          </SignInSectionContent>
        </SigninSection>
      </SigninContainer>
    </>
  );
};

const DescriptionText = styled.div`
  font-size: 22px;
  margin-bottom: 1.5em;
  margin-left: 3.5em;
  margin-right: 3.5em;
`;

const TitleSectionTop = styled.div`
  font-size: 48px;
  margin-bottom: 1em;
  margin-left: 1.5em;
  margin-right: 1.5em;
`;

const TitleSectionBottom = styled.div`
  font-size: 48px;
  margin-bottom: 1.5em;
`;

const SignInSectionContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SigninSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  flex-direction: column;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const Button = styled.div`
  margin-top: 8px;
  background-color: var(--atom-green);
  padding: 1em;
  font-size: 16px;
  border-radius: 3px;
  width: 20em;
  text-align: center;
  color: rgb(50, 50, 62, 0.7);
  &:hover {
    color: rgb(50, 50, 62, 1);
  }
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
`;

export default withRouter(SignUpComplete);
