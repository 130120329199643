import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/Footer';
export const OuterContainer = styled.div.attrs({
  className: 'flex w-full',
})`
  padding: 0 36px;
  margin: 0 auto;
`;

const Wizard = ({ children }: { children?: any }) => {
  return (
    <>
      <OuterContainer>
        <WizardContainer>
          <ContentSection>{children}</ContentSection>
        </WizardContainer>
      </OuterContainer>
      <Footer />
    </>
  );
};

export default Wizard;

export const WizardContainer = styled.div.attrs({
  className: 'w-full self-start flex flex-col',
})<{ imgBackground?: boolean }>`
  overflow-x: hidden;
  min-height: calc(100vh - 140px);
  min-width: 100hh;

  ${p =>
    p.imgBackground ? `background-image: url('/HeroBackground.jpg');` : ''}

  @media (max-width: 1400px) {
    background-size: auto 100%;
    background-position: right;
  }

  @media (max-width: 576px) {
    background-attachment: scroll;
    background-size: 100%;
    background-position: 0% 0%;
  }
`;

export const ContentSection = styled.div.attrs({
  className: 'flex-1 flex flex-col',
})`
  padding: 70px 0;

  justify-content: center;
`;

interface RowTypes {
  noMarginBottom?: boolean;
}
export const Row = styled.div<RowTypes>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.noMarginBottom ? '0' : '0rem 0rem 1.5rem 0rem')};
  padding: 18px 0rem 0rem 0rem;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const NavbarRow = styled.div<RowTypes>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.noMarginBottom ? '0' : '0rem 0rem 1.5rem 0rem')};
  padding: 15px 0rem 0rem 0rem;
`;

export const InputTitle = styled.div.attrs({})`
  font-size: 16px;
  color: var(--atom-green);
  margin-top: 28px;
  margin-bottom: 12px;
`;

export const AppLogo = styled.img.attrs({})`
  height: 26px;
  margin-top: 20px;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const SectionHeader = styled.div.attrs({
  className: 'mt-4 mb-4 font-din',
})`
  font-size: 20px;
  font-weight: bold;
`;

export const SectionDescription = styled.div.attrs({
  className: 'mb-4',
})`
  font-size: 16px;
  color: var(--atom-brand-8);
  max-width: 540px;
`;

// NEW FORM SHARED ELEMENTS

export const Input = styled.input.attrs({ className: 'font-din' })`
  min-width: 500px;
  max-width: 616px;
  margin-bottom: 16px;
  background-color: var(--atom-dark-brand-5);
  color: white;
  font-size: 16px;
  padding: 18px 22px;
  border-radius: 3px;
`;

interface AccountActionTypes {
  active?: boolean;
  fullWidth?: boolean;
}

export const AccountActionButton = styled.button.attrs<AccountActionTypes>({
  className: 'flex justify-center items-center  font-din',
})<AccountActionTypes>`
  background-color: var(--atom-green);
  min-width: 550px;
  max-width: 616px;
  border-radius: 3px;
  padding: 18px 22px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  color: ${({ active }) => (active ? 'var(--atom-brand-3)' : '#458E83')};
  text-transform: uppercase;
  &:hover {
    ${({ active }) =>
      active ? 'background-color: var(--atom-green-hover)' : ''};
  }

  &:focus {
    ${({ active }) => (active ? 'var(--atom-green-hover)' : '')};
  }
`;

export const ContinueWithBasicBtn = styled.button.attrs<AccountActionTypes>({
  className: 'flex justify-center items-center  font-din',
})<AccountActionTypes>`
  width: 100%;
  border-radius: 5px;
  padding: 15px 22px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  color: ${({ active }) => (active ? 'var(--atom-green)' : '#458E83')};
  &:hover {
    ${({ active }) => (active ? 'color: var(--atom-green-hover)' : '')};
  }
  &:focus {
    ${({ active }) => (active ? 'var(--atom-green-hover)' : '')};
  }
`;

export const ErrorText = styled.div<{ noBullets?: boolean }>`
  color: white;
  margin-bottom: 6px;

  & ::before {
    content: ${props => (props.noBullets ? '' : '- ')};
  }
`;

export const ErrorSpace = styled.div`
  background-color: var(--atom-red);
  width: 20em;
  color: white;
  margin-bottom: 16px;
  border-radius: 5px;
  padding: 15px 22px 9px 22px;
`;

export const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 616px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 550px;
  align-items: center;
`;

export const FinalRow = styled.div`
  margin-top: 14px;
  min-width: 616px;
  max-width: 616px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
`;

export const SuccessSpace = styled.div`
  background-color: var(--atom-green);
  color: var(--atom-brand-5);
  border-radius: 5px;
  width: 20em;
  padding: 15px 22px 9px 22px;
`;

export const SuccessText = styled.div`
  color: var(--atom-brand-3);
  margin-bottom: 6px;

  & ::before {
    content: '- ';
  }
`;
