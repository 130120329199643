"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalystRatingProvider = void 0;
var AnalystRatingProvider;

(function (AnalystRatingProvider) {
  AnalystRatingProvider["Intrinio"] = "Intrinio";
  AnalystRatingProvider["Refinitiv"] = "Refinitiv";
})(AnalystRatingProvider = exports.AnalystRatingProvider || (exports.AnalystRatingProvider = {}));