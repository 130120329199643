import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  AUTHENTICATE_AS_ADMIN,
  CONFIRM_SIGN_UP,
  DEACTIVATE_TRIAL_KEY,
  EXTEND_TRIAL_API_KEY,
  GET_ACCOUNT_INFO,
  GET_ADMIN_INFO,
  GET_PARTNER_CONFIG_ACTIVITY,
  GET_PARTNER_ICON,
  GET_PARTNER_LIST,
  SAVE_ADMIN_INFO,
  SET_API_KEY_TYPE,
  SIGN_UP,
  UPDATE_OVERVIEW_CONFIG,
} from '../../common/queries';
import MainHeader from '../../components/MainHeader';
import {
  GreenClickableButton,
  GreenClickableText,
  Input,
  InputContainer,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';
import { withRouter } from 'react-router-dom';
import { AdminContext } from '../../common/AdminContext';
import OptionBox from '../../components/OptionBox';
import KeyRotationPopup from '../../components/configurationModals/KeyRotationPopup';
import { LargeTextContainer } from '../../components/OverviewContent';

const AuthenticationContent = () => {
  const partnerIconResponse = useQuery(GET_PARTNER_ICON);
  const tier = partnerIconResponse?.data?.partnerIcon?.tier;
  const expiry = partnerIconResponse?.data?.partnerIcon?.expiry;

  const [numberOfDays, setNumberOfDays] = useState('');

  const [displayModal, setDisplayModal] = useState('');
  const [setApiKeyType] = useMutation(SET_API_KEY_TYPE, {
    'refetchQueries': [{ query: GET_PARTNER_ICON }],
  });

  const [extendTrial, { data: extendData }] = useMutation(
    EXTEND_TRIAL_API_KEY,
    {
      'refetchQueries': [{ query: GET_PARTNER_ICON }],
    },
  );

  const [deactivateTrial] = useMutation(DEACTIVATE_TRIAL_KEY, {
    'refetchQueries': [{ query: GET_PARTNER_ICON }],
  });

  const { admin } = useContext(AdminContext);

  const isAtomAdmin = admin?.isAtomAdmin === true;

  const [selectedKeyRotation, setSelectedKeyRotation] = useState<
    string | undefined
  >();

  const isLimitedApiKey = tier === 'Limited';

  const isExpired = expiry < Date.now();

  const options = [
    {
      name: 'apiKey',
      icon: 'key',
      text: 'API Key',
      overrideOnClickAction: true,
      modal: { name: 'keyRotation', Component: KeyRotationPopup },
    },
  ];

  return (
    /*Sign In Screen*/
    <RootContainer>
      <SignInSectionContent>
        {isLimitedApiKey && (
          <>
            <DescriptionText>
              You&apos;re all set to get started with your trial API key. Our
              sales team will be in touch to discuss your specific needs, after
              which they can get you set up with a production API key. In the
              meantime, you can use this key to test our API endpoint in our
              <a
                style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/introduction'
              >
                docs
              </a>
              .
            </DescriptionText>
            <DescriptionText>
              Note: In order to access our full library of assets, you will need
              a production key. You can still test any of our endpoints with
              your Trial Key, though the responses you receive with your Trial
              Key will be limited to a select few assets (e.g. AAPL, TSLA, SPY,
              QQQ, VTSAX, BTC and DOGE). For more info, see our docs on
              <a
                style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/authentication'
              >
                authentication
              </a>
              .
            </DescriptionText>
          </>
        )}
        {!isLimitedApiKey && (
          <>
            <DescriptionText>
              Your production API key is active, and all Atom API Endpoints used
              with this key will return responses specific to the settings
              you&lsquo;ve selected on the API Settings tab. You can test our
              API endpoints with your production key using our interactive
              <a
                style={{ color: 'var(--atom-green)', marginLeft: '5px' }}
                href='https://docs.atom.finance/reference/introduction'
              >
                documentation
              </a>
              .
            </DescriptionText>
          </>
        )}
        <WidthGreenClickableButton
          disabled={false}
          onClick={async () => {
            window.open('https://docs.atom.finance/reference/introduction');
            window.location.href = '/';
          }}
        >
          VIEW DOCS & TEST NOW
        </WidthGreenClickableButton>
        {isLimitedApiKey && isAtomAdmin && (
          <>
            <ExtendTrialContainer>
              <LargeTitleText>Activate Production Key</LargeTitleText>
              <AdminLabel>Atom Representative Only</AdminLabel>
            </ExtendTrialContainer>
            <DescriptionText>
              Once the client has signed with us and you’ve configured their API
              Settings accordingly, you can use this button to convert their
              Trial Key to a Production key. Once you do this, the client will
              have production access limited only by the settings in the API
              Settings tab.
            </DescriptionText>
            <WidthRedClickableButton
              disabled={false}
              onClick={async () => {
                const isConfirmed = confirm(
                  'Do you really want to activate production key?',
                );
                if (isConfirmed) {
                  await setApiKeyType({
                    variables: {
                      isLimited: false,
                    },
                  });
                }
              }}
            >
              ACTIVATE PRODUCTION KEY
            </WidthRedClickableButton>
          </>
        )}
        {!isLimitedApiKey && isAtomAdmin && (
          <>
            <ExtendTrialContainer>
              <LargeText>Disable Production Key</LargeText>
              <AdminLabel>Atom Representative Only</AdminLabel>
            </ExtendTrialContainer>
            <DescriptionText>
              If you need to disable production access for a client, you can do
              so here. Note that this will turn their key back into a Trial Key,
              and anything the client is using the key for may stop working.
              ONLY DO THIS IF YOU KNOW WHAT YOU ARE DOING!
            </DescriptionText>
            <WidthRedClickableButton
              disabled={false}
              onClick={async () => {
                const isConfirmed = confirm(
                  'Do you really want to disable production key?',
                );
                if (isConfirmed) {
                  await setApiKeyType({
                    variables: {
                      isLimited: true,
                    },
                  });
                }
              }}
            >
              DEACTIVATE PRODUCTION KEY
            </WidthRedClickableButton>
          </>
        )}
        {!isLimitedApiKey && isAtomAdmin && (
          <ConfigurationContainer>
            <DescriptionContainer>
              <LargeTextContainer>
                <LargeText>Rotate Client API Keys</LargeText>
                <AdminLabel>Atom Representative Only</AdminLabel>
              </LargeTextContainer>
              <SmallText>
                You can generate a new API key for this client here. The
                existing key will stop working immediately. Only do this if you
                know what you are doing!
              </SmallText>
            </DescriptionContainer>
            <OptionsContainer
              optionsItemsPerRow={1}
              style={{
                width: '22em',
                position: 'absolute',
                right: 0,
                paddingRight: '5em',
              }}
            >
              {options?.map((option, key) => {
                const {
                  icon,
                  text,
                  modal,
                  name: optionName,
                  overrideOnClickAction,
                } = option;
                const Component = modal?.Component;
                const componentName = modal?.name;
                const locked = false;
                const enabled = false;
                return (
                  <OptionBox
                    key={key}
                    icon={icon}
                    text={text}
                    enabled={true}
                    locked={locked}
                    editMode={true}
                    isSelectionToggleable={false}
                    onClick={() => {
                      if (!locked) {
                        if (overrideOnClickAction) {
                          if (Component && componentName) {
                            setSelectedKeyRotation(optionName);
                            setDisplayModal(componentName);
                          }
                        }
                      }
                    }}
                    Modal={() => {
                      if (Component && componentName) {
                        if (componentName === 'keyRotation') {
                          return (
                            <Component
                              showModal={componentName === displayModal}
                              openModal={() => setDisplayModal(componentName)}
                              closeModal={() => setDisplayModal('')}
                              disabled={!enabled}
                              selectedKeyRotation={selectedKeyRotation}
                            />
                          );
                        } else {
                          return (
                            <Component
                              showModal={componentName === displayModal}
                              openModal={() => setDisplayModal(componentName)}
                              closeModal={() => setDisplayModal('')}
                              disabled={!enabled}
                            />
                          );
                        }
                      } else {
                        return null;
                      }
                    }}
                  />
                );
              })}
            </OptionsContainer>
          </ConfigurationContainer>
        )}
        {isLimitedApiKey && isAtomAdmin && (
          <>
            <ExtendTrialContainer>
              <LargeTitleText>Extend Trial Key</LargeTitleText>
              <AdminLabel>Atom Representative Only</AdminLabel>
            </ExtendTrialContainer>
            <MiddleDescriptionText>
              If the client needs more time to sign, you can extend any number
              of days. You can do this more than once, so use your judgement
              here on an appropriate amount of time.
            </MiddleDescriptionText>
            <Input
              onChange={e => setNumberOfDays(e.target.value)}
              placeholder='Extend how many days'
              value={numberOfDays}
              style={{ width: '22em', marginRight: '8px' }}
              type='number'
            />
            <ResponseText success={extendData?.extendTrialApiKey?.success}>
              {extendData?.extendTrialApiKey?.message}
            </ResponseText>
            <WidthGreenClickableButton
              disabled={false}
              onClick={async () => {
                await extendTrial({
                  variables: {
                    trialExtensionInDays: numberOfDays,
                  },
                });
                setNumberOfDays('');
              }}
            >
              EXTEND TRIAL
            </WidthGreenClickableButton>
          </>
        )}
        {isLimitedApiKey && isAtomAdmin && !isExpired && (
          <>
            <ExtendTrialContainer>
              <LargeText>Disable Trial Key</LargeText>
              <AdminLabel>Atom Representative Only</AdminLabel>
            </ExtendTrialContainer>
            <DescriptionText>
              If you need to disable a trial access key for a client, you can do
              so here. This will set the current expiration date to today.
              Anything the client is using the key for may stop working. ONLY DO
              THIS IF YOU KNOW WHAT YOU ARE DOING!
            </DescriptionText>
            <WidthRedClickableButton
              disabled={false}
              onClick={async () => {
                const isConfirmed = confirm(
                  'Do you really want to disable trial key?',
                );
                if (isConfirmed) {
                  await deactivateTrial();
                }
              }}
            >
              DEACTIVATE TRIAL KEY
            </WidthRedClickableButton>
          </>
        )}
      </SignInSectionContent>
    </RootContainer>
  );
};

export const WidthRedClickableButton = styled(GreenClickableButton as any)`
  margin: 15px 5px 5px 0px;
  font-size: 15px;
  width: 20em;
  background-color: #f27362;
  &:hover {
    background-color: var(
      --atom-${props => (props.disabled ? 'dark-red' : 'dark-red')}
    );
  }
`;

const DescriptionText = styled.div`
  font-size: 15px;
  margin-top: 1em;
  margin-bottom: 1.5em;
`;

export const AdminLabel = styled.a`
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 2em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 3px;
  margin-right: 1em;
  height: 20px;
  align-self: center;
  color: var(--atom-contrast);
  background-color: #f27362;
`;

const MiddleDescriptionText = styled.div`
  font-size: 15px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const TitleSectionTop = styled.div`
  font-size: 48px;
  margin-bottom: 1em;
`;

const TitleSectionBottom = styled.div`
  font-size: 48px;
  margin-bottom: 1.5em;
`;

const SignInSectionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExtendTrialContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4em;
  margin-top: 4em;
  border-top: solid 1px var(--atom-brand-5);
`;

const SigninSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  flex-direction: column;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const LargeText = styled.div`
  font-size: 21px;
`;

const LargeTitleText = styled.div`
  font-size: 21px;
`;

const Button = styled.div`
  margin-top: 8px;
  background-color: var(--atom-green);
  padding: 1em;
  font-size: 16px;
  border-radius: 3px;
  width: 20em;
  text-align: center;
  color: rgb(50, 50, 62, 0.7);
  &:hover {
    color: rgb(50, 50, 62, 1);
  }
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
`;

const ConfigurationContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  border-top: solid 1px var(--atom-brand-5);
  margin-top: 3em;
  padding-top: 3em;
  padding-bottom: 9em;
`;
const OptionsContainer = styled.div<{
  optionsItemsPerRow: number;
}>`
  display: grid;
  grid-template-columns: ${props =>
    [...Array(props.optionsItemsPerRow)].map(
      () => ` ${Math.floor((1 / props.optionsItemsPerRow) * 100)}%`,
    )};
  justify-content: center;
`;

const DescriptionContainer = styled.div`
  margin: 1em 1em 0.5em 0em;
  position: relative;
`;

const SmallText = styled.div`
  font-size: 1em;
  margin-top: 1em;
  margin-right: 9em;
  margin-bottom: 2em;
`;

export default withRouter(AuthenticationContent);
