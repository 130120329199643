import React from 'react';
import styled from 'styled-components';
import { WidthGreenClickableButton } from '../common/styledComponents';
import imgsrc from '../../static/embeddeduis.svg';
const EmbeddedUIs = () => {
  return (
    <>
      <OverviewContainer>
        <OverviewHeader>
          <TextContainer>
            <LargeText>Embedded UIs</LargeText>
            <BetaLabel>Beta</BetaLabel>
          </TextContainer>
          <SmallText>
            If you&apos;re looking to get to market faster, you can customize
            our Embedded UIs and use them right in your app. You can customize
            these to use your preferred colors, fonts, and more to fit the look
            and feel of your brand.
          </SmallText>
          <SmallText>
            Note that this product is still in development, so please reach out
            to your Atom account representative if you&apos;re interested in
            something you don&apos;t see yet, and we can craft a solution that
            meets your needs.
          </SmallText>
        </OverviewHeader>
      </OverviewContainer>
      <Container>
        <Image src={imgsrc}></Image>
        <WidthGreenClickableButton
          style={{ marginTop: '2em' }}
          onClick={async () => {
            window.open(
              'https://atom.finance/enterprise?contact_reason=general',
            );
          }}
        >
          {`TALK TO OUR TEAM`}
        </WidthGreenClickableButton>
      </Container>
    </>
  );
};

const OverviewContainer = styled.div`
  margin: 0em 5em;
  display: flex;
  flex-direction: column;
`;

const OverviewHeader = styled.div`
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 0em;
  flex-direction: column;
`;

export const BetaLabel = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 3em;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 3px;
  background-color: var(--atom-orange);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0em 5em 5em 5em;
`;

const LargeText = styled.div`
  font-size: 33px;
`;

const SmallText = styled.div`
  font-size: 16px;
  padding: 0em 0em;
  margin-top: 1em;
  color: white;
`;

const Image = styled.img`
  width: 70%;
  height: 70%;
  padding-top: 2em;
  margin-bottom: 1em;
  display: flex;
  resizemode: contain;
  align-self: center;
  margin: 0em;
`;

export default EmbeddedUIs;
