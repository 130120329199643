"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RiverServerMessageType = exports.RiverClientMessageType = void 0;
var RiverClientMessageType;

(function (RiverClientMessageType) {
  RiverClientMessageType["Sub"] = "Sub";
  RiverClientMessageType["Unsub"] = "Unsub";
  RiverClientMessageType["Ping"] = "Ping";
})(RiverClientMessageType = exports.RiverClientMessageType || (exports.RiverClientMessageType = {}));

var RiverServerMessageType;

(function (RiverServerMessageType) {
  RiverServerMessageType["Pong"] = "Pong";
  RiverServerMessageType["Trades"] = "Trades";
  RiverServerMessageType["Error"] = "Error";
  RiverServerMessageType["Stream"] = "Stream";
})(RiverServerMessageType = exports.RiverServerMessageType || (exports.RiverServerMessageType = {}));