"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compsFieldsMap = exports.scenarioOnlyFields = exports.portfolioOnlyFields = exports.institutionalOnlyFields = exports.fundOnlyFields = exports.timeFrameOperators = exports.coreOnlyFields = exports.cryptoOnlyFields = exports.displayTypes = void 0;
exports.displayTypes = {
  PERCENTAGE: 'PERCENTAGE',
  PERCENTAGE_2_DECIMALS: 'PERCENTAGE_2_DECIMALS',
  SKIP: 'SKIP',
  MULTIPLIER: 'MULTIPLIER',
  NUMBER: 'NUMBER',
  NUMBER_SINGLE_DECIMAL: 'NUMBER_SINGLE_DECIMAL',
  MONEY_ABSOLUTE: 'MONEY_ABSOLUTE',
  MONEY_SCALED_WITH_LABELS: 'MONEY_SCALED_WITH_LABELS',
  MONEY_SCALED_WITHOUT_LABELS: 'MONEY_SCALED_WITHOUT_LABELS',
  STRING: 'STRING',
  DATE: 'DATE',
  EARNINGS_DATE: 'EARNINGS_DATE',
  SIMPLE_MONEY: 'SIMPLE_MONEY',
  CURRENCY_MONEY: 'CURRENCY_MONEY'
};
exports.cryptoOnlyFields = Object.freeze({
  market_cap: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Market Cap',
    description: 'Market Cap (Capitalization) is the total market value of all outstanding shares of a company.'
  },
  market_cap_rank: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Rank'
  },
  fully_diluted_valuation: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Fully Diluted Valuation',
    multiLabel: {
      top: 'Fully Diluted',
      bottom: 'Valuation'
    }
  },
  total_volume: {
    displayType: exports.displayTypes.NUMBER,
    label: '24H Volume',
    multiLabel: {
      top: '24H',
      bottom: 'Volume'
    }
  },
  high_24hr: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'High 24hr'
  },
  low_24hr: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Low 24hr'
  },
  circulating_supply: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Circulating Supply',
    multiLabel: {
      top: 'Circulating',
      bottom: 'Supply'
    }
  },
  total_supply: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Total Supply',
    multiLabel: {
      top: 'Total',
      bottom: 'Supply'
    }
  },
  max_supply: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Max Supply'
  },
  ath: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'All Time High',
    multiLabel: {
      top: 'All Time',
      bottom: 'High'
    }
  },
  ath_date: {
    displayType: exports.displayTypes.DATE,
    label: 'All Time High Date',
    multiLabel: {
      top: 'All Time',
      bottom: 'High Date'
    }
  },
  atl: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'All Time Low',
    multiLabel: {
      top: 'All Time',
      bottom: 'Low'
    }
  },
  atl_date: {
    displayType: exports.displayTypes.DATE,
    label: 'All Time Low Date',
    multiLabel: {
      top: 'All Time',
      bottom: 'Low Date'
    }
  },
  twitter_followers: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Twitter Followers',
    multiLabel: {
      top: 'Twitter',
      bottom: 'Followers'
    }
  },
  reddit_subscribers: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Reddit Subscribers',
    multiLabel: {
      top: 'Reddit',
      bottom: 'Subscribers'
    }
  },
  telegram_channel_user_count: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Telegram Channel User Count',
    multiLabel: {
      top: 'Telegram Channel',
      bottom: 'User Count'
    }
  },
  pull_requests_merged: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Pull Requests Merged',
    multiLabel: {
      top: 'Pull Requests',
      bottom: 'Merged'
    }
  },
  pull_request_contributors: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Pull Request Contributors',
    multiLabel: {
      top: 'Pull Request',
      bottom: 'Contributors'
    }
  },
  commit_count_4_weeks: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Commit count 4 weeks',
    multiLabel: {
      top: 'Commit count',
      bottom: '4 week'
    }
  },
  genesis_date: {
    displayType: exports.displayTypes.DATE,
    label: 'Genesis Date'
  }
});
exports.coreOnlyFields = Object.freeze({
  symbol: {
    displayType: exports.displayTypes.STRING,
    label: 'Ticker'
  },
  name: {
    displayType: exports.displayTypes.STRING,
    label: 'Name',
    width: 200
  },
  sparkline: {
    displayType: exports.displayTypes.STRING,
    label: 'Sparkline',
    component: 'SparkLineCell'
  },
  sharePrice: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: 'Price',
    component: 'PriceCell',
    width: 150
  },
  change: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: 'Today $ Chg',
    cryptoLabel: '24H $ Chg',
    component: 'ChangeCell',
    extendedField: 'extendedChange',
    width: 150
  },
  changePercent: {
    displayType: exports.displayTypes.PERCENTAGE_2_DECIMALS,
    label: 'Today % Chg',
    cryptoLabel: '24H % Chg',
    component: 'ChangeCell',
    extendedField: 'extendedChangePercent',
    width: 150
  },
  monetaryChange: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '$ Chg',
    isDY: true
  },
  monetaryChange1D: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '1D $ Chg',
    isDY: true
  },
  monetaryChange5D: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '5D $ Chg',
    isDY: true
  },
  monetaryChange1M: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '1M $ Chg',
    isDY: true
  },
  monetaryChange3M: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '3M $ Chg',
    isDY: true
  },
  monetaryChange6M: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '6M $ Chg',
    isDY: true
  },
  monetaryChangeYTD: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: 'YTD $ Chg',
    isDY: true
  },
  monetaryChange1Y: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '1Y $ Chg',
    isDY: true
  },
  monetaryChange2Y: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '2Y $ Chg',
    isDY: true
  },
  monetaryChange5Y: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    smallVal: true,
    label: '5Y $ Chg',
    isDY: true
  },
  return: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Return',
    isDY: true
  },
  return1D: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '1D Return',
    isDY: true
  },
  return5D: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '5D Return',
    isDY: true
  },
  return1M: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '1M Return',
    isDY: true
  },
  return3M: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '3M Return',
    isDY: true
  },
  return6M: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '6M Return',
    isDY: true
  },
  returnYTD: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'YTD Return',
    isDY: true
  },
  return1Y: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '1Y Return',
    isDY: true
  },
  return2Y: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '2Y Return',
    isDY: true
  },
  return5Y: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: '5Y Return',
    isDY: true
  },
  sector: {
    displayType: exports.displayTypes.STRING,
    label: 'Sector',
    width: 200
  },
  marketCap: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Market Cap',
    description: 'Market Cap (Capitalization) is the total market value of all outstanding shares of a company.'
  },
  gaapRev: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Company Guidance: GAAP Revenue',
    multiLabel: {
      top: 'GAAP',
      bottom: 'Revenue (FY)'
    },
    isFY: true,
    isGuidance: true,
    component: 'priceRangeCell'
  },
  gaapEps: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Company Guidance: GAAP EPS',
    multiLabel: {
      top: 'GAAP',
      bottom: 'EPS (FY)'
    },
    isFY: true,
    isGuidance: true,
    component: 'priceRangeCell'
  },
  adjRev: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Company Guidance: Adj. Revenue',
    multiLabel: {
      top: 'Adj.',
      bottom: 'Revenue (FY)'
    },
    isFY: true,
    isGuidance: true,
    component: 'priceRangeCell'
  },
  adjEps: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'Company Guidance: Adj. EPS',
    multiLabel: {
      top: 'Adj.',
      bottom: 'EPS (FY)'
    },
    isFY: true,
    isGuidance: true,
    component: 'priceRangeCell'
  },
  netDebtAndPref: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Net Debt & Pref.',
    description: "Net Debt & Preferred is calculated as a company's total debt less its liquid assets. \
    The generic formula is Net Debt = Short Term Debt (<12 months) + Long Term Debt (>12 months) - Cash & Cash Equivalents"
  },
  ev: {
    displayType: exports.displayTypes.SIMPLE_MONEY,
    label: 'Entprs. Val (EV)',
    description: "Enterprise Value (EV) is measure of the value of a company's total core assets, \
    which takes into account the sum of all outstanding claims on the company's assets, less the \
    company's excess (non-core) assets. The generic formula is EV = Market Cap + Total Debt + \
    Preferred Stock + Minority Interest - Cash - Other Non-core Assets."
  },
  borrowCost: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Borrow Cost',
    description: 'Borrow Cost, also known as a Stock Loan Fee, represents the annual amount \
    a brokerage charges a short seller in order to borrow and sell shares short.'
  },
  averageVolume: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Avg. Vol',
    description: 'Average Volume represents the average number of shares of a company that swap hands in exchange trading each day.'
  },
  beta: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'Beta'
  },
  dividendPerShare: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'Dividend / Shr.',
    isFY: true,
    description: "Dividend per Share shows what the company's annual dividend payout is per share of stock owned."
  },
  dividendYield: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Dividend Yield',
    description: "Dividend Yield represents the ratio of the company's annual dividend compared to its share price."
  },
  nextEarningsDate: {
    displayType: exports.displayTypes.EARNINGS_DATE,
    label: 'Next Earnings'
  },
  evRevenueLTM: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'EV / Rev (LTM)',
    description: "EV / Revenue represents the ratio of a company's Enterprise Value to its Total \
    Revenue. This is commonly used as a valuation metric for high-growth companies, often in the technology sector. \
    This can also be useful if a company has yet to achieve positive operating profits."
  },
  ebitdaMarginLTM: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EBITDA Margin (LTM)',
    multiLabel: {
      top: 'EBITDA',
      bottom: 'Margin (LTM)'
    },
    description: 'EBITDA Margin shows EBITDA as a percentage of Total Revenue. EBITDA is defined as earnings before interest, taxes, depreciation and amortization. \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful capital expenditures, finite-life intangible assets, and/or changes in working capital.'
  },
  ebitMarginLTM: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EBIT Margin (LTM)',
    multiLabel: {
      top: 'EBIT',
      bottom: 'Margin (LTM)'
    },
    description: 'EBIT Margin shows EBIT as a percentage of Total Revenue. EBIT is defined as earnings before interest and taxes. It is used as a proxy for unlevered \
    (before interest) free cash flow for companies that do not have meaningful changes in working capital. \
    This is a better metric than EBITDA for companies that have meaningful capital expenditures and/or finite-life intangibile assets.'
  },
  evEbitdaLTM: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'EV / EBITDA (LTM)',
    multiLabel: {
      top: 'EV /',
      bottom: 'EBITDA (LTM)'
    },
    description: "'EV / EBITDA represents the ratio of a company's Enterprise Value to its EBITDA (earnings before interest, taxes, depreciation and amortization). \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have meaningful capital expenditures, \
    finite-life intangible assets, and/or changes in working capital."
  },
  evEbitLTM: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'EV / EBIT (LTM)',
    multiLabel: {
      top: 'EV /',
      bottom: 'EBIT (LTM)'
    },
    description: "EV / EBIT represents the ratio of a company's Enterprise Value to its EBIT (earnings before interest and taxes). \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful changes in working capital. This is a better metric than EBITDA for companies that \
    have meaningful capital expenditures and/or finite-life intangibile assets."
  },
  priceEarningsLTM: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'P/E (LTM)',
    description: "The ratio of a company's price per share to its earnings per share. Earnings per share (EPS) \
    is defined as Net Income divided by the total number of outstanding shares. This measure tells you the \
    accounting profit of the company that each share is entitled to."
  },
  revenueLTM: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Revenue (LTM)'
  },
  ebitdaLTM: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'EBITDA (LTM)',
    description: 'EBITDA is defined as earnings before interest, taxes, depreciation and amortization. \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful capital expenditures, finite-life intangible assets, and/or changes in working capital.'
  },
  ebitLTM: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'EBIT (LTM)',
    description: 'EBIT is defined as earnings before interest and taxes. It is used as a proxy for unlevered \
    (before interest) free cash flow for companies that do not have meaningful changes in working capital. \
    This is a better metric than EBITDA for companies that have meaningful capital expenditures and/or finite-life intangibile assets.'
  },
  epsLTM: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'EPS (LTM)',
    description: 'Earnings per Share (EPS) is a measure of net income for each share of stock outstanding. \
    This measure tells you the accounting profit of the company that each share is entitled to.'
  },
  // comps Year
  evRevenue: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'EV / Rev',
    isFY: true,
    ltm: 'evRevenueLTM',
    description: "EV / Revenue represents the ratio of a company's Enterprise Value to its Total \
    Revenue. This is commonly used as a valuation metric for high-growth companies, often in the technology sector. \
    This can also be useful if a company has yet to achieve positive operating profits."
  },
  evEbitda: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'EV / EBITDA',
    multiLabel: {
      top: 'EV /',
      bottom: 'EBITDA (FY)'
    },
    isFY: true,
    ltm: 'evEbitdaLTM',
    description: "'EV / EBITDA represents the ratio of a company's Enterprise Value to its EBITDA (earnings before interest, taxes, depreciation and amortization). \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have meaningful capital expenditures, \
    finite-life intangible assets, and/or changes in working capital."
  },
  evEbit: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'EV / EBIT',
    multiLabel: {
      top: 'EV /',
      bottom: 'EBIT (FY)'
    },
    isFY: true,
    ltm: 'evEbitLTM',
    description: "EV / EBIT represents the ratio of a company's Enterprise Value to its EBIT (earnings before interest and taxes). \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful changes in working capital. This is a better metric than EBITDA for companies that \
    have meaningful capital expenditures and/or finite-life intangibile assets."
  },
  priceEarnings: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'P/E',
    isFY: true,
    ltm: 'priceEarningsLTM',
    description: "The ratio of a company's price per share to its earnings per share. Earnings per share (EPS) \
    is defined as Net Income divided by the total number of outstanding shares. This measure tells you the \
    accounting profit of the company that each share is entitled to."
  },
  operatingCashPerShare: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Oper. Cash Flow / Shr.',
    multiLabel: {
      top: 'Oper Cash Flow /',
      bottom: 'Share (FY)'
    },
    isFY: true,
    description: "Operating Cash Flow per Share is equal to Operating Cash Flow divided by the number of outstanding shares. \
    Operating Cash Flow is a measure of the amount of cash generated from a company's normal business operations."
  },
  freeCashFlowPerShare: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'Free Cash Flow / Shr.',
    multiLabel: {
      top: 'Free Cash Flow /',
      bottom: 'Share (FY)'
    },
    isFY: true,
    description: 'Free Cash Flow per Share is equal to Free Cash Flow divided by the number of outstanding shares. \
      Free Cash Flow is equal to Cash Flow From Operations less Capital Expenditures. This metric is supposed to \
      represent the actual cash generated by the business that is available for shareholders (after paying for all expenses, \
      interest, and capital expenditures, including changes in working capital). You can compare Free Cash Flow to the Net \
      Income of a company to see whether accounting earnings are actually turning into cash.'
  },
  returnOnAssets: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Return on Assets',
    multiLabel: {
      top: 'Return on',
      bottom: 'Assets (FY)'
    },
    isFY: true,
    description: 'Return on Assets shows how efficiently a company turns its assets into earnings. It is calculated as Net \
    Income divided by Total Assets. This measure is heavily used to look at financial firms, especially banks.'
  },
  returnOnEquity: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Return on Equity',
    multiLabel: {
      top: 'Return on',
      bottom: 'Equity (FY)'
    },
    isFY: true,
    description: "Return on Equity is the lever equivalent of Return on Assets. It is calculated as Net Income \
    divided by Total Equity. This measure can be found by taking a company's ROA and multiplying it \
    by the Leverage Ratio (Total Assets divided by Total Equity)."
  },
  priceBookValue: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'Price / Book',
    multiLabel: {
      top: 'Price /',
      bottom: 'Book (FY)'
    },
    isFY: true,
    description: "Price to Book Value is calculated as the company's share price divided by its Book Value per Share. This \
    is often viewed within the context of a financial firm's Return on Equity."
  },
  priceTanBookValue: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'Price / Tan Book',
    multiLabel: {
      top: 'Price /',
      bottom: 'Tan Book (FY)'
    },
    isFY: true,
    description: "Price to Tangible Book Value is calculated as the company's share price divided by its Tangible Book Value per Share. This \
    is often viewed within the context of a financial firm's Return on Equity."
  },
  priceNAV: {
    displayType: exports.displayTypes.MULTIPLIER,
    label: 'Price / NAV',
    isFY: true,
    description: "Price to Net Asset Value is calculated as the company's share price divided by its Net Asset Value per Share. This \
    is generally analagous to Price to Book Value, and is often used for funds."
  },
  netInvestmentIncome: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Net Investment Income',
    multiLabel: {
      top: 'Net Investment',
      bottom: 'Income (FY)'
    },
    isFY: true
  },
  interestExpenses: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Interest Expenses',
    multiLabel: {
      top: 'Interest',
      bottom: 'Expenses (FY)'
    },
    isFY: true,
    description: 'Interest Expenses represent the value of interest that a company pays on any debt it has incurred, such as loans, bonds, convertible debt or other lines of credit.'
  },
  interestExpensesMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Interest Expenses Margin',
    multiLabel: {
      top: 'Interest Expenses',
      bottom: 'Margin (FY)'
    },
    isFY: true
  },
  totalDebt: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Total Debt',
    isFY: true
  },
  navPerShare: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'NAV / Shr.',
    isFY: true
  },
  gaExpenses: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'G&A Expenses',
    multiLabel: {
      top: 'G&A',
      bottom: 'Expenses (FY)'
    },
    isFY: true,
    description: 'General & Administrative (G&A) Expenses are operational overhead expenses that impact an entire business, but \
    are not necessarily tied to any particular function or department within the company. These can include rent, accounting, \
    advertising, legal fees, utilities, certain management salaries, and more.'
  },
  loadLossProvisions: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Loan Loss Provisions',
    multiLabel: {
      top: 'Loan Loss',
      bottom: 'Provisions (FY)'
    },
    isFY: true
  },
  nonInterestExpense: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Non Interest Expenses',
    multiLabel: {
      top: 'Non Interest',
      bottom: 'Expenses (FY)'
    },
    isFY: true
  },
  bookValuePerShare: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'Book / Shr.',
    multiLabel: {
      top: 'Book /',
      bottom: 'Share (FY)'
    },
    isFY: true,
    description: "Book Value per Share is calculated as Common Shareholders' Equity divided by total oustanding shares."
  },
  tanBookValuePerShare: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'Tan Book / Shr.',
    multiLabel: {
      top: 'Tan Book /',
      bottom: 'Share (FY)'
    },
    isFY: true,
    description: "Tangible Book Value Per Share is a company's Book Value Per Share, excluding \
    intangible items like Goodwill from acquisitions."
  },
  riskWeightedAssets: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Risk Weighted Assets',
    multiLabel: {
      top: 'Risk Weighted',
      bottom: 'Assets (FY)'
    },
    isFY: true
  },
  tier1Capital: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Tier 1 Capital',
    multiLabel: {
      top: 'Tier 1',
      bottom: 'Capital (FY)'
    },
    isFY: true,
    description: 'Tier 1 Capital includes Common Equity and qualifying preferred stock (for preferred stock to qualify, \
    it must meet specific Basel III regulatory criteria).'
  },
  nonPerformingAssets: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Non Performing Assets',
    multiLabel: {
      top: 'Non Performing',
      bottom: 'Assets (FY)'
    },
    isFY: true
  },
  niiRevenue: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'NII / Revenue',
    multiLabel: {
      top: 'NII /',
      bottom: 'Revenue (FY)'
    },
    isFY: true,
    description: 'NII / Revenue is defined as Net Interest Income divided by Total Revenue. It is a way of judging what \
    percentage of revenue comes from pure interest margin and not other sources like fees or anciliary business.'
  },
  nieRevenue: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'NIE / Revenue',
    multiLabel: {
      top: 'NIE /',
      bottom: 'Revenue (FY)'
    },
    isFY: true,
    description: "NIE / Revenue is defined as Non-Interest Expense divided by Total Revenue. This is used to evauate what \
    percentage of a bank's revenue is spent on non-interest core expenses, such as labor, technology, real estate and marketing."
  },
  tier1CapitalRatio: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Tier 1 Ratio',
    multiLabel: {
      top: 'Tier 1',
      bottom: 'Ratio (FY)'
    },
    isFY: true,
    description: 'Tier 1 Capital Ratio is the primary regulatory capital ratio for banks. It is defined as \
    Common Equity divided by Risk-Weighted Assets.'
  },
  totalRevenue: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Revenue',
    isFY: true,
    ltm: 'revenueLTM',
    description: 'Revenue is the income that a business generates from its normal business activities. \
      This is often in the form of sales of goods and services to customers, though can sometimes \
      come from interest, royalties, or other fees.'
  },
  totalRevenueGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Rev Growth',
    multiLabel: {
      top: 'Revenue',
      bottom: 'Growth (FY)'
    },
    isFY: true,
    description: 'Revenue Growth is shown here. Revenue is the income that a business generates from its normal business activities. \
    This is often in the form of sales of goods and services to customers, though can sometimes \
    come from interest, royalties, or other fees.'
  },
  cogs: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'COGS',
    isFY: true,
    description: "Cost of Goods Sold (COGS) are labor and materials expenses associated with the production of a company's goods or services."
  },
  cogsMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'COGS Margin',
    multiLabel: {
      top: 'COGS',
      bottom: 'Margin (FY)'
    },
    isFY: true,
    description: "COGS Margin is defined as COGS divided by Total Revenue. Cost of Goods Sold (COGS) are labor and materials \
    expenses associated with the production of a company's goods or services."
  },
  grossProfit: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Gross Profit',
    multiLabel: {
      top: 'Gross',
      bottom: 'Profit (FY)'
    },
    isFY: true,
    description: 'Gross Profit is the profit a company generates after \
    deducting expenses related to producing good and services. It is defined as Total Revenue less COGS.'
  },
  grossProfitMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Gross Margin',
    multiLabel: {
      top: 'Gross',
      bottom: 'Margin (FY)'
    },
    isFY: true,
    description: 'Gross Profit Margin is equal to Gross Profit divided by Total Revenue. Gross Profit is the profit a company generates after \
    deducting expenses related to producing good and services. It is defined as Total Revenue less COGS.'
  },
  grossProfitGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Gross Profit Growth',
    multiLabel: {
      top: 'Gross Profit',
      bottom: 'Growth (FY)'
    },
    isFY: true,
    description: 'Gross Profit Growth is shown here. Gross Profit is the profit a company generates after \
    deducting expenses related to producing good and services. It is defined as Total Revenue less COGS.'
  },
  operationExpenses: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'OpEx',
    isFY: true
  },
  operationExpensesMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'OpEx Margin',
    isFY: true
  },
  operationExpensesGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'OpEx Growth',
    multiLabel: {
      top: 'OpEx',
      bottom: 'Growth (FY)'
    },
    isFY: true
  },
  ebit: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'EBIT',
    isFY: true,
    ltm: 'ebitLTM',
    description: 'EBIT is defined as earnings before interest and taxes. It is used as a proxy for unlevered \
    (before interest) free cash flow for companies that do not have meaningful changes in working capital. \
    This is a better metric than EBITDA for companies that have meaningful capital expenditures and/or finite-life intangibile assets.'
  },
  ebitMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EBIT Margin',
    multiLabel: {
      top: 'EBIT',
      bottom: 'Margin (FY)'
    },
    isFY: true,
    ltm: 'ebitMarginLTM',
    description: 'EBIT Margin shows EBIT as a percentage of Total Revenue. EBIT is defined as earnings before interest and taxes. It is used as a proxy for unlevered \
    (before interest) free cash flow for companies that do not have meaningful changes in working capital. \
    This is a better metric than EBITDA for companies that have meaningful capital expenditures and/or finite-life intangibile assets.'
  },
  ebitGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EBIT Growth',
    multiLabel: {
      top: 'EBIT',
      bottom: 'Growth (FY)'
    },
    isFY: true,
    description: 'EBIT Growth is shown here. EBIT is defined as earnings before interest and taxes. It is used as a proxy for unlevered \
    (before interest) free cash flow for companies that do not have meaningful changes in working capital. \
    This is a better metric than EBITDA for companies that have meaningful capital expenditures and/or finite-life intangibile assets.'
  },
  ebitda: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'EBITDA',
    isFY: true,
    ltm: 'ebitdaLTM',
    description: 'EBITDA is defined as earnings before interest, taxes, depreciation and amortization. \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful capital expenditures, finite-life intangible assets, and/or changes in working capital.'
  },
  ebitdaMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EBITDA Margin',
    multiLabel: {
      top: 'EBITDA',
      bottom: 'Margin (FY)'
    },
    isFY: true,
    ltm: 'ebitdaMarginLTM',
    description: 'EBITDA Margin shows EBITDA as a percentage of Total Revenue. EBITDA is defined as earnings before interest, taxes, depreciation and amortization. \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful capital expenditures, finite-life intangible assets, and/or changes in working capital.'
  },
  ebitdaGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EBITDA Growth',
    multiLabel: {
      top: 'EBITDA',
      bottom: 'Growth (FY)'
    },
    isFY: true,
    description: 'EBITDA Growth is shown here. EBITDA is defined as earnings before interest, taxes, depreciation and amortization. \
    It is used as a proxy for unlevered (before interest) free cash flow for companies that do not have \
    meaningful capital expenditures, finite-life intangible assets, and/or changes in working capital.'
  },
  da: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'D&A',
    isFY: true,
    description: "Depreciation and Amortization (D&A) represent non-cash expenses on a company's income statement. \
    Depreciation represents the cost of capital assets being consumed over time, and amortization is the equivalent for \
    intangible assets."
  },
  daMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'D&A Margin',
    multiLabel: {
      top: 'D&A',
      bottom: 'Margin (FY)'
    },
    isFY: true,
    description: "D&A Margin shows D&A as a percentage of Total Revenue. Depreciation and Amortization (D&A) represent non-cash expenses on a company's income statement. \
    Depreciation represents the cost of capital assets being consumed over time, and amortization is the equivalent for \
    intangible assets."
  },
  interestAndOther: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Interest & Other',
    multiLabel: {
      top: 'Interest &',
      bottom: 'Other (FY)'
    },
    // width: 220,
    isFY: true
  },
  preTaxIncome: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Pre Tax Income',
    multiLabel: {
      top: 'Pre Tax',
      bottom: 'Income (FY)'
    },
    isFY: true,
    description: 'Pretax Income, also called as Earnings Before Taxes, is income earned by a business after adjusting for all operating expenses, D&A, finance charges such \
    as interest payments, but before the deduction of taxes.'
  },
  taxes: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Taxes',
    isFY: true
  },
  taxRate: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Tax Rate',
    isFY: true
  },
  netIncome: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Net Income',
    isFY: true,
    description: "Net Income, also referred to as Net Profit, is profit left over after all operating \
    expenses, G&A expenses, COGS, D&A, Interest & Taxes are deducted. It represents how much a company's revenue \
    exceeds the costs of running the business, and a primary indicator of a company's profitability."
  },
  netIncomeMargin: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Net Income Margin',
    multiLabel: {
      top: 'Net Income',
      bottom: 'Margin (FY)'
    },
    isFY: true,
    description: "Net Income Margin shows Net Income as a percentage of Total Revenue. Net Income, also referred to as Net Profit, is profit left over after all operating \
    expenses, G&A expenses, COGS, D&A, Interest & Taxes are deducted. It represents how much a company's revenue \
    exceeds the costs of running the business, and a primary indicator of a company's profitability."
  },
  netIncomeGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Net Income Growth',
    multiLabel: {
      top: 'Net Income',
      bottom: 'Growth (FY)'
    },
    isFY: true,
    description: "Net Income Growth is shown here. Net Income, also referred to as Net Profit, is profit left over after all operating \
    expenses, G&A expenses, COGS, D&A, Interest & Taxes are deducted. It represents how much a company's revenue \
    exceeds the costs of running the business, and a primary indicator of a company's profitability."
  },
  shares: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Shares',
    isFY: true,
    description: "The total number of shares of a company's stock outstanding."
  },
  eps: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    smallVal: true,
    label: 'EPS',
    isFY: true,
    ltm: 'epsLTM',
    description: 'Earnings per Share (EPS) is a measure of net income for each share of stock outstanding. \
    This measure tells you the accounting profit of the company that each share is entitled to.'
  },
  epsGrowth: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'EPS Growth',
    multiLabel: {
      top: 'EPS',
      bottom: 'Growth (FY)'
    },
    isFY: true,
    description: 'EPS Growth is shown here. Earnings per Share (EPS) is a measure of net income for each share of stock outstanding. \
    This measure tells you the accounting profit of the company that each share is entitled to.'
  },
  week52Low: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: '52 Week Low',
    multiLabel: {
      top: '52 Week',
      bottom: 'Low'
    },
    smallVal: true,
    description: 'The lowest close price in the last 52 weeks.'
  },
  week52High: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: '52 Week High',
    multiLabel: {
      top: '52 Week',
      bottom: 'High'
    },
    smallVal: true,
    description: 'The highest close price in the last 52 weeks.'
  },
  week104Low: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: '104 Week Low',
    multiLabel: {
      top: '104 Week',
      bottom: 'Low'
    },
    smallVal: true,
    description: 'The lowest close price in the last 104 weeks.'
  },
  week104High: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: '104 Week High',
    multiLabel: {
      top: '104 Week',
      bottom: 'High'
    },
    smallVal: true,
    description: 'The highest close price in the last 104 weeks.'
  },
  market: {
    displayType: exports.displayTypes.STRING,
    label: 'Market'
  }
});
/**
 * Array of postfix values to concatenate with coreOnlyFields.
 *
 * {string} value: main postfix identifier.
 * {string} label: displayed string.
 * {any} displayFor: coreOnlyFields to concatenate (e.g. isLTM, isDY, isFY, isGuidance)
 * {boolean} isDefault: is timeFrameOperator the dropdown's default value
 */

exports.timeFrameOperators = Object.freeze([{
  value: '1D',
  label: '1D',
  displayFor: {
    __DY: true
  },
  isDefault: true
}, {
  value: '5D',
  label: '5D',
  displayFor: {
    __DY: true
  }
}, {
  value: '1M',
  label: '1M',
  displayFor: {
    __DY: true
  }
}, {
  value: '3M',
  label: '3M',
  displayFor: {
    __DY: true
  }
}, {
  value: '6M',
  label: '6M',
  displayFor: {
    __DY: true
  }
}, {
  value: 'YTD',
  label: 'YTD',
  displayFor: {
    __DY: true
  }
}, {
  value: '1Y',
  label: '1Y',
  displayFor: {
    __DY: true
  }
}, {
  value: '2Y',
  label: '2Y',
  displayFor: {
    __DY: true
  }
}, {
  value: '5Y',
  label: '5Y',
  displayFor: {
    __DY: true
  }
}, {
  value: '__FY-5__FQ0',
  label: 'FY-5',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY-4__FQ0',
  label: 'FY-4',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY-3__FQ0',
  label: 'FY-3',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY-2__FQ0',
  label: 'FY-2',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY-1__FQ0',
  label: 'FY-1',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY0__FQ0',
  label: 'FY0',
  displayFor: {
    __FY: true,
    __LTM: true
  },
  isDefault: true
}, {
  value: '__FY1__FQ0',
  label: 'FY1',
  displayFor: {
    __FY: true,
    __LTM: true,
    __GD: true
  }
}, {
  value: '__FY2__FQ0',
  label: 'FY2',
  displayFor: {
    __FY: true,
    __LTM: true,
    __GD: true
  }
}, {
  value: '__FY3__FQ0',
  label: 'FY3',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: 'LTM',
  label: 'LTM',
  displayFor: {
    __LTM: true
  }
}, {
  value: '__FY0__FQ-4',
  label: 'FQ-4',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY0__FQ-3',
  label: 'FQ-3',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY0__FQ-2',
  label: 'FQ-2',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY0__FQ-1',
  label: 'FQ-1',
  displayFor: {
    __FY: true,
    __LTM: true
  }
}, {
  value: '__FY0__FQ1',
  label: 'FQ1',
  displayFor: {
    __FY: true,
    __LTM: true,
    __GD: true
  }
}, {
  value: '__FY0__FQ2',
  label: 'FQ2',
  displayFor: {
    __FY: true,
    __LTM: true,
    __GD: true
  }
}, {
  value: '__FY0__FQ3',
  label: 'FQ3',
  displayFor: {
    __FY: true,
    __LTM: true,
    __GD: true
  }
}, {
  value: '__FY0__FQ4',
  label: 'FQ4',
  displayFor: {
    __FY: true,
    __LTM: true,
    __GD: true
  }
}]);
exports.fundOnlyFields = Object.freeze({
  weight: {
    displayType: exports.displayTypes.PERCENTAGE,
    label: 'Weight'
  },
  numberOfShares: {
    displayType: exports.displayTypes.NUMBER,
    label: '# Shares Held'
  },
  marketValueHeld: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Market Value Held',
    multiLabel: {
      top: 'Market Value',
      bottom: 'Held'
    }
  }
});
exports.institutionalOnlyFields = Object.freeze({
  outstandingSharesPercent: {
    displayType: exports.displayTypes.PERCENTAGE_2_DECIMALS,
    label: '% Outstanding Shares',
    multiLabel: {
      top: '% Outstanding',
      bottom: 'Shares'
    }
  },
  lastSECFilingType: {
    displayType: exports.displayTypes.STRING,
    label: 'Last Filling Type',
    multiLabel: {
      top: 'Last Filling',
      bottom: 'Type'
    }
  },
  lastSECFilingDate: {
    displayType: exports.displayTypes.DATE,
    label: 'Last Filling Date',
    multiLabel: {
      top: 'Last Filling',
      bottom: 'Date'
    }
  },
  numberOfSharesPrevious: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Prev. # Shares Held',
    multiLabel: {
      top: 'Prev. #',
      bottom: 'Shares Held'
    }
  },
  sharesChange: {
    displayType: exports.displayTypes.NUMBER,
    label: '# Shares Change',
    multiLabel: {
      top: '# Shares',
      bottom: 'Change'
    }
  }
});
exports.portfolioOnlyFields = Object.freeze({
  trAssetCategoryName: {
    displayType: exports.displayTypes.STRING,
    label: 'Security Type',
    width: 150
  },
  tradeName: {
    displayType: exports.displayTypes.STRING,
    label: 'Trade Name'
  },
  tradeDate: {
    displayType: exports.displayTypes.STRING,
    label: 'Date'
  },
  transactionDate: {
    displayType: exports.displayTypes.DATE,
    label: 'Date'
  },
  tradeType: {
    displayType: exports.displayTypes.STRING,
    label: 'Type'
  },
  tradeQuantity: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Quantity'
  },
  tradePerUnitPrice: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Price'
  },
  tradeTotalAmount: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Amount'
  },
  // portfolio position table columns
  portfolioPercent: {
    displayType: exports.displayTypes.PERCENTAGE_2_DECIMALS,
    label: '% Portfolio',
    width: 120
  },
  portfolioHoldingPrice: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Price',
    width: 90
  },
  portfolioHoldingValue: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Value',
    width: 80
  },
  portfolioHoldingImpliedValue: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Implied Value',
    width: 120
  },
  portfolioHoldingImpliedPosition: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Implied Position',
    width: 130
  },
  portfolioHoldingPosition: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Position',
    width: 100
  },
  portfolioHoldingCostBasis: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Cost Basis',
    width: 100
  },
  portfolioHoldingAvgCost: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Avg. Cost',
    width: 110
  },
  portfolioHoldingGainLossValueToday: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    label: '',
    component: 'ChangeCellNoStreaming',
    multiLabel: {
      top: 'Gain/Loss $',
      bottom: '(Today)'
    }
  },
  portfolioHoldingGainLossPercentToday: {
    displayType: exports.displayTypes.PERCENTAGE_2_DECIMALS,
    label: '',
    component: 'ChangeCellNoStreaming',
    multiLabel: {
      top: 'Gain/Loss %',
      bottom: '(Today)'
    }
  },
  portfolioHoldingGainLossValueSinceInception: {
    displayType: exports.displayTypes.MONEY_ABSOLUTE,
    label: 'Since Inception Gain/Loss $',
    component: 'ChangeCellNoStreaming',
    multiLabel: {
      top: 'Gain/Loss $',
      bottom: '(Total)'
    },
    width: 150
  },
  portfolioHoldingGainLossPercentSinceInception: {
    displayType: exports.displayTypes.PERCENTAGE_2_DECIMALS,
    label: 'Since Inception Gain/Loss %',
    component: 'ChangeCellNoStreaming',
    multiLabel: {
      top: 'Gain/Loss %',
      bottom: '(Total)'
    },
    width: 150
  },
  atomSectorName: {
    displayType: exports.displayTypes.STRING,
    label: 'Sector'
  }
});
exports.scenarioOnlyFields = Object.freeze({
  tradeValue: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Trade Value'
  },
  currentTotalValue: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'Current Total Value',
    width: 150
  },
  newTotalValue: {
    displayType: exports.displayTypes.CURRENCY_MONEY,
    label: 'New Total Value',
    width: 150
  },
  valueChange: {
    displayType: exports.displayTypes.PERCENTAGE_2_DECIMALS,
    label: 'Value Change',
    component: 'ChangeCellNoStreaming'
  },
  currentShares: {
    displayType: exports.displayTypes.NUMBER,
    label: 'Current Shares'
  },
  newShares: {
    displayType: exports.displayTypes.NUMBER,
    label: 'New Shares'
  }
});
exports.compsFieldsMap = Object.freeze(__assign(__assign(__assign(__assign(__assign(__assign({}, exports.coreOnlyFields), exports.fundOnlyFields), exports.institutionalOnlyFields), exports.portfolioOnlyFields), exports.cryptoOnlyFields), exports.scenarioOnlyFields));