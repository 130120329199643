import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { ADD_PARTNER, GET_PARTNER_LIST } from '../common/queries';
import {
  CloseButtonContainer,
  Input,
  InputContainer,
  PaddedGreenClickableButton,
} from '../common/styledComponents';
import Modal from '../components/configurationModals/Modal';
import styled, { css } from 'styled-components';
import AtomIcon from '../common/AtomIcon';
import { useMutation } from '@apollo/react-hooks';
import {
  AddMemberContainer,
  AddMemberContent,
  FlexContainer,
  MainTextContainer,
  MemberInput,
  TextContainer,
} from './configurationModals/AddMemberModal';

const AddPartnerModal = ({ closeModal }) => {
  const defaultExpiryString = DateTime.now()
    .plus({ days: 31 })
    .toJSDate()
    .toLocaleDateString();
  const [submitted, setSubmitted] = useState(false);
  const [defaultExpiry, setdefaultExpiry] = useState(true);
  const [expiryString, setExpiryString] = useState(defaultExpiryString);
  const [fields, setFields] = useState({
    name: '',
    description: '',
    tier: 'Limited',
    expiry: DateTime.now().plus({ days: 31 }).toJSDate(),
    icon: null,
  });

  const [createPartner, { data, loading, error }] = useMutation(ADD_PARTNER);
  const response = data?.addPartner;
  const success = response?.success;
  const showError =
    !loading && !success && (response?.message || error?.message);
  if (submitted && !loading) {
    setSubmitted(false);
  }

  const dateFormat = new RegExp(
    '^([0]?[1-9]|[1][0-2])[./-]([0]?[1-9]|[12][0-9]|[3][01])[./-]([0-9]{4})$',
  );

  const disableSubmit =
    !fields.name?.length ||
    !fields.tier?.length ||
    submitted ||
    !dateFormat.test(expiryString);

  const onSubmit = async () => {
    if (disableSubmit) return;
    setFields({ ...fields, expiry: new Date(expiryString) });
    setSubmitted(true);
    createPartner({
      variables: fields,
      refetchQueries: [{ query: GET_PARTNER_LIST }],
    });
  };

  return (
    <Modal closeModal={closeModal}>
      <AddMemberContainer>
        <AddMemberContent
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <FlexContainer>
            <CloseButtonContainer onClick={() => closeModal()}>
              <AtomIcon
                size={35}
                icon={'close'}
                iconCss={
                  css`
                    fill: var(--atom-green);
                  ` as any
                }
                onClick={close}
              />
            </CloseButtonContainer>
          </FlexContainer>
          {!success && (
            <MainTextContainer>
              <TextContainer>Add Partner</TextContainer>
              <InputContainer>
                <MemberInput
                  onChange={e => setFields({ ...fields, name: e.target.value })}
                  placeholder='Partner name*'
                  value={fields.name}
                />
                <SelectContainer as='div'>
                  <SelectBox
                    as='select'
                    onChange={e =>
                      setFields({ ...fields, tier: e.target.value })
                    }
                  >
                    <option value='Limited'>Tier: Trial</option>
                    <option value='Normal'>Tier: Normal</option>
                  </SelectBox>
                </SelectContainer>
              </InputContainer>
              <InputContainer>
                <MemberInput
                  onChange={e =>
                    setFields({ ...fields, description: e.target.value })
                  }
                  placeholder='Description'
                  value={fields.description}
                />
              </InputContainer>
              <InputContainer>
                <div style={{ padding: '1em 0.5em' }}>Upload Icon Image:</div>
                <MemberInput
                  type='file'
                  onChange={e =>
                    setFields({ ...fields, icon: e.target.files[0] })
                  }
                />
              </InputContainer>
              {fields.tier == 'Limited' && (
                <>
                  <InputContainer>
                    <div style={{ padding: '1em 0.5em' }}>Expiry Date:</div>
                    <SelectContainer as='div'>
                      <SelectBox
                        as='select'
                        onChange={e => {
                          setdefaultExpiry(
                            e.target.value == 'default' ? true : false,
                          );
                          setExpiryString(
                            e.target.value == 'default'
                              ? defaultExpiryString
                              : '',
                          );
                        }}
                      >
                        <option value={'default'}>Default</option>
                        <option value={'manual'}>Manual</option>
                      </SelectBox>
                    </SelectContainer>
                    <MemberInput
                      onChange={e => {
                        setExpiryString(e.target.value);
                        if (dateFormat.test(e.target.value)) {
                          setFields({
                            ...fields,
                            expiry: new Date(e.target.value),
                          });
                        }
                      }}
                      placeholder={'MM/DD/YYYY'}
                      value={expiryString}
                      disabled={defaultExpiry}
                    />
                  </InputContainer>
                </>
              )}

              {showError && (
                <>
                  <ErrorText>{`Partner was not created.`}</ErrorText>
                  <ErrorText>
                    {`${response?.message || error?.message}`}
                  </ErrorText>
                </>
              )}
              <InputContainer>
                <PaddedGreenClickableButton
                  disabled={disableSubmit}
                  onClick={onSubmit}
                >{`confirm`}</PaddedGreenClickableButton>
              </InputContainer>
            </MainTextContainer>
          )}
          {success && (
            <MainTextContainer>
              <TextContainer>Partner Created Successfully!</TextContainer>
              <pre>
                {JSON.stringify(
                  { ...response.partner, apiKey: response.apiKey },
                  null,
                  2,
                )}
              </pre>
            </MainTextContainer>
          )}
        </AddMemberContent>
      </AddMemberContainer>
    </Modal>
  );
};

const SelectContainer = styled(Input as any)`
  margin: 5px;
  padding: 0;
`;

const SelectBox = styled(Input as any)`
  margin: 0 15px 0 0;
  outline: none;
`;

const ErrorText = styled.div`
  margin: 5px 10px 0px 10px;
  font-size: 1em;
  color: var(--atom-red);
  max-width: fit-content;
`;

export default AddPartnerModal;
