import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import AtomIcon from '../common/AtomIcon';

const OptionBox = props => {
  const {
    icon,
    text,
    Modal,
    enabled,
    locked,
    editMode,
    onClick,
    isSelectionToggleable,
  } = props;
  const hasIcon = icon ? true : false;
  const [selected, setSelected] = useState(enabled);

  useEffect(() => {
    if (!editMode) {
      setSelected(enabled);
    }
  }, [editMode, enabled]);

  const getModalOrSpace = ({ hasIcon, Modal }) => {
    return hasIcon && Modal && Modal() ? <Modal /> : hasIcon ? <br /> : <></>;
  };
  const spacedText = text?.replaceAll('/', '  /');
  return (
    <BoxItem
      hasIcon={hasIcon}
      enabled={enabled}
      locked={locked}
      onClick={() => {
        if (editMode && !locked) {
          onClick();
          if (isSelectionToggleable) {
            setSelected(!selected);
          }
        }
      }}
      editMode={editMode}
      selected={editMode ? selected : enabled}
    >
      <BoxContent>
        {icon && (
          <IconContainer>
            <AtomIcon icon={icon} size={30} />
          </IconContainer>
        )}
        {text && <div>{spacedText}</div>}
        {getModalOrSpace({ hasIcon, Modal })}
      </BoxContent>
    </BoxItem>
  );
};

const IconContainer = styled.div`
  padding-bottom: 0.5em;
`;

const BoxItem = styled.div<{
  hasIcon: boolean;
  enabled?: boolean;
  locked?: boolean;
  editMode: boolean;
  selected: boolean;
}>`
  border-radius: 6px;
  margin: 1em;
  padding: ${props => (props.hasIcon ? '5' : '2')}em 1em;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(
    --atom-${props => ((props.enabled || props.editMode) && props.selected ? 'contrast' : 'light-text')}
  );
  background-color: var(
    --atom-brand-${props => ((props.enabled || props.editMode) && props.selected ? '5' : '1')}
  );
  border: solid 2px var(--atom-brand-4);
  cursor: ${props =>
    props.locked ? 'not-allowed' : props.editMode ? 'pointer' : 'default'};
`;

const BoxContent = styled.div``;

export default OptionBox;
