/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { createRoot } from 'react-dom/client';
import ApolloClient from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hoc';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import App from './App';
import './index.css';

const uploadLink = createUploadLink({
  uri: '/graphql',
  credentials: 'same-origin',
}) as unknown;

// eslint-disable-next-line import/prefer-default-export
export const apolloClient = new ApolloClient({
  link: uploadLink as ApolloLink,
  cache: new InMemoryCache(),
  name: 'atom-web',
});

const Entry = (
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>
);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(Entry);
