"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewsSourceLogoMap = exports.NewsFeedV2NewsSource = exports.NewsTypeNewsSourceMap = exports.NewsSourceToNewsType = exports.NewsStoryType = exports.NewsSource = void 0;
var NewsSource;

(function (NewsSource) {
  NewsSource["Reuters"] = "Reuters";
  NewsSource["Benzinga"] = "Benzinga";
  NewsSource["Coindesk"] = "Coindesk";
  NewsSource["Briefing"] = "Briefing.com";
  NewsSource["MTNewswires"] = "MTNewswires";
  NewsSource["PressRelease"] = "PressRelease";
  NewsSource["StockStoryOrg"] = "StockStory.org";
})(NewsSource = exports.NewsSource || (exports.NewsSource = {})); // NewsStoryType is being used in atom-data. Please let data team know before any addition or deletion.


var NewsStoryType;

(function (NewsStoryType) {
  NewsStoryType["BenzingaStory"] = "BenzingaStory";
  NewsStoryType["BriefingMarketSummary"] = "BriefingMarketSummary";
  NewsStoryType["BrokerageAction"] = "BrokerageAction";
  NewsStoryType["CoindeskStory"] = "CoindeskStory";
  NewsStoryType["InPlayBIPStory"] = "InPlayBIPStory";
  NewsStoryType["MtNewswiresStory"] = "MtNewswiresStory";
  NewsStoryType["RelevantFacts"] = "RelevantFacts";
  NewsStoryType["ReutersStory"] = "ReutersStory";
  NewsStoryType["StockStory"] = "StockStory";
  NewsStoryType["StockStoryOrg"] = "StockStoryOrg";
  NewsStoryType["WiimStory"] = "WiimStory";
})(NewsStoryType = exports.NewsStoryType || (exports.NewsStoryType = {}));

exports.NewsSourceToNewsType = {
  Benzinga: [NewsStoryType.BenzingaStory, NewsStoryType.WiimStory],
  Reuters: [NewsStoryType.ReutersStory],
  'Briefing.com': [NewsStoryType.BrokerageAction, NewsStoryType.InPlayBIPStory, NewsStoryType.StockStory, NewsStoryType.BriefingMarketSummary],
  Coindesk: [NewsStoryType.CoindeskStory],
  MTNewswires: [NewsStoryType.MtNewswiresStory],
  PressRelease: [NewsStoryType.RelevantFacts],
  'StockStory.org': [NewsStoryType.StockStoryOrg]
};
exports.NewsTypeNewsSourceMap = {
  BenzingaStory: NewsSource.Benzinga,
  BriefingMarketSummary: NewsSource.Briefing,
  BrokerageAction: NewsSource.Briefing,
  CoindeskStory: NewsSource.Coindesk,
  InPlayBIPStory: NewsSource.Briefing,
  MtNewswiresStory: NewsSource.MTNewswires,
  RelevantFacts: NewsSource.PressRelease,
  ReutersStory: NewsSource.Reuters,
  StockStory: NewsSource.Briefing,
  WiimStory: NewsSource.Benzinga,
  StockStoryOrg: NewsSource.StockStoryOrg
};
var NewsFeedV2NewsSource;

(function (NewsFeedV2NewsSource) {
  NewsFeedV2NewsSource["Benzinga"] = "Benzinga";
  NewsFeedV2NewsSource["Briefing.com"] = "Briefing.com";
  NewsFeedV2NewsSource["Coindesk"] = "Coindesk";
  NewsFeedV2NewsSource["MTNewswires"] = "MT Newswires";
  NewsFeedV2NewsSource["Reuters"] = "Reuters";
  NewsFeedV2NewsSource["StockStory.org"] = "StockStory.org";
})(NewsFeedV2NewsSource = exports.NewsFeedV2NewsSource || (exports.NewsFeedV2NewsSource = {}));

exports.NewsSourceLogoMap = {
  Benzinga: 'Benzinga.png',
  Reuters: 'Reuters.png',
  'Briefing.com': 'BriefingDotCom.png',
  Coindesk: 'Coindesk.png',
  MTNewswires: 'MTNewswires.png',
  PressRelease: 'PressRelease.png',
  'StockStory.org': 'StockStory.png'
};