import React from 'react';
import styled from 'styled-components';
import { AdminContext } from '../../common/AdminContext';
import MainHeader from '../../components/MainHeader';
import apiAxios from './apiAxios';

import Wizard, {
  ErrorText,
  AccountActionButton,
  ErrorSpace,
  Form,
  FinalRow,
  SuccessSpace,
  SuccessText,
} from './AdminWizard';
import {
  IndexHeader,
  Input,
  WidthGreenClickableButton,
} from '../../common/styledComponents';

interface WizardState {
  error: string | null;
  username?: string;
  password?: string;
  loading: boolean;
  success: string;
}
type OnFinishLoginType = (
  doneSignUp?: null,
  pathType?: string,
  which?: string,
  news?: string,
  format?: string,
  pathId?: string,
  payment?: boolean,
) => Promise<void> | void;

export class ForgotPasswordAdmin extends React.Component<
  {
    isStep: boolean;
    onFinishLogin: OnFinishLoginType;
    code?: string;
  },
  WizardState
> {
  usernameRef: any;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      username: '',
      success: '',
    };

    this.usernameRef = React.createRef();
  }

  render() {
    const { error, loading, success } = this.state;

    return (
      <div>
        <MainHeader includeRight={false} includeSignIn={true} />
        <ForgotPasswordContainer>
          <ForgotPasswordSection>
            <IndexHeader>Forgot Password?</IndexHeader>

            <Form>
              {error && (
                <ErrorSpace>
                  <ErrorText>{error}</ErrorText>
                </ErrorSpace>
              )}
              <Input
                placeholder='Email'
                value={this.state.username}
                onChange={e => this.setState({ username: e.target.value })}
                style={{ width: '22.5em' }}
              />
              <FinalRow>
                {success ? (
                  <SuccessSpace>
                    <SuccessText>{success}</SuccessText>
                  </SuccessSpace>
                ) : (
                  <WidthGreenClickableButton
                    active
                    disabled={loading}
                    onClick={e => this.handleSubmit(e)}
                  >
                    Send instructions
                  </WidthGreenClickableButton>
                )}
              </FinalRow>
            </Form>
          </ForgotPasswordSection>
        </ForgotPasswordContainer>
      </div>
    );
  }

  componentWillUnmount(): void {
    // window.removeEventListener('beforeunload', this.handleLeave);
  }

  toggleAgreeTOC = () => {
    // this.setState({
    //   agreeTOC: !this.state.agreeTOC,
    // });
  };

  handleSubmit = async e => {
    e && e.preventDefault();

    const user = {
      input: this.state.username,
    };

    this.setState({
      loading: true,
    });

    try {
      const response = await apiAxios.post('/session/forgotPassword', user);
      const { data } = response;
      if (data.success) {
        this.setState({
          success: 'An email will be sent momentarily if this account exists.',
          loading: false,
        });
      } else if (data.error) {
        this.setState({
          error: data.error,
          loading: false,
        });
      }
    } catch (err) {
      this.setState({
        error: 'An error occurred while resetting password.',
        loading: false,
      });
    }
  };
}

export const ForgotPasswordWrapper = props => (
  <AdminContext.Consumer>
    {({ onFinishLogin }) => (
      <ForgotPasswordAdmin {...props} onFinishLogin={onFinishLogin} />
    )}
  </AdminContext.Consumer>
);

export default ForgotPasswordWrapper;

export const ForgotPasswordContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ForgotPasswordSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  margin: 1.5em 0em;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  align-items: center;
  flex-direction: column;
`;
