"use strict";

var _a;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrExchangeCodeToAtomExchange = exports.AtomExchange = exports.CorePlatformExchangesAndCrypto = exports.CorePlatformExchangesAndBrazil = exports.CorePlatformExchanges = exports.IexUSExchanges = void 0;

var assets_1 = require("./assets");

var IexUSExchanges;

(function (IexUSExchanges) {
  IexUSExchanges["XNYS"] = "New York Stock Exchange Inc";
  IexUSExchanges["ARCX"] = "Nyse Arca";
  IexUSExchanges["BATS"] = "Cboe Bzx U S Equities Exchange";
  IexUSExchanges["XNAS"] = "Nasdaq All Markets";
  IexUSExchanges["XASE"] = "Nyse Mkt Llc";
  IexUSExchanges["XPOR"] = "Portal";
})(IexUSExchanges = exports.IexUSExchanges || (exports.IexUSExchanges = {}));

var CorePlatformExchanges;

(function (CorePlatformExchanges) {
  CorePlatformExchanges[CorePlatformExchanges["BZX"] = 0] = "BZX";
  CorePlatformExchanges[CorePlatformExchanges["PNK"] = 1] = "PNK";
  CorePlatformExchanges[CorePlatformExchanges["TOR"] = 2] = "TOR";
})(CorePlatformExchanges = exports.CorePlatformExchanges || (exports.CorePlatformExchanges = {}));

var CorePlatformExchangesAndBrazil;

(function (CorePlatformExchangesAndBrazil) {
  CorePlatformExchangesAndBrazil[CorePlatformExchangesAndBrazil["BZX"] = 0] = "BZX";
  CorePlatformExchangesAndBrazil[CorePlatformExchangesAndBrazil["PNK"] = 1] = "PNK";
  CorePlatformExchangesAndBrazil[CorePlatformExchangesAndBrazil["TOR"] = 2] = "TOR";
  CorePlatformExchangesAndBrazil[CorePlatformExchangesAndBrazil["SAO"] = 3] = "SAO";
})(CorePlatformExchangesAndBrazil = exports.CorePlatformExchangesAndBrazil || (exports.CorePlatformExchangesAndBrazil = {}));

var CorePlatformExchangesAndCrypto;

(function (CorePlatformExchangesAndCrypto) {
  CorePlatformExchangesAndCrypto[CorePlatformExchangesAndCrypto["BZX"] = 0] = "BZX";
  CorePlatformExchangesAndCrypto[CorePlatformExchangesAndCrypto["PNK"] = 1] = "PNK";
  CorePlatformExchangesAndCrypto[CorePlatformExchangesAndCrypto["TOR"] = 2] = "TOR";
  CorePlatformExchangesAndCrypto[CorePlatformExchangesAndCrypto["ATOM_CRYPTO"] = 3] = "ATOM_CRYPTO";
})(CorePlatformExchangesAndCrypto = exports.CorePlatformExchangesAndCrypto || (exports.CorePlatformExchangesAndCrypto = {}));

var AtomExchange;

(function (AtomExchange) {
  AtomExchange["NASDAQ"] = "NASDAQ";
  AtomExchange["NYSE"] = "NYSE";
  AtomExchange["NYSEArca"] = "NYSE Arca";
  AtomExchange["OTCMarkets"] = "OTC Markets";
  AtomExchange["OTCPinkCurrent"] = "OTC Pink Current";
  AtomExchange["OTCPinkLimited"] = "OTC Pink Limited";
  AtomExchange["PORTAL"] = "Portal";
  AtomExchange["TSX"] = "TSX";
  AtomExchange["BATS"] = "BATS";
  AtomExchange["BOVESPA"] = "BOVESPA";
})(AtomExchange = exports.AtomExchange || (exports.AtomExchange = {}));

exports.TrExchangeCodeToAtomExchange = (_a = {}, _a[assets_1.TrExchangeCode.bzx] = AtomExchange.BATS, _a[assets_1.TrExchangeCode.tor] = AtomExchange.TSX, _a[assets_1.TrExchangeCode.pnk] = AtomExchange.OTCMarkets, _a[assets_1.TrExchangeCode.sao] = AtomExchange.BOVESPA, _a);