"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ADMIN_ONLY_WEBHOOK_TYPES = exports.WebhookType = void 0;
var WebhookType;

(function (WebhookType) {
  WebhookType["documents"] = "documents";
  WebhookType["portfolio"] = "portfolio";
})(WebhookType = exports.WebhookType || (exports.WebhookType = {}));

exports.ADMIN_ONLY_WEBHOOK_TYPES = [WebhookType.portfolio];