"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GROWTH_FIELDS = exports.MARGINS_AND_RATIOS_FIELDS = exports.FINANCIAL_FIELDS = exports.VALUATION_FIELDS = exports.SectorType = void 0;
var SectorType;

(function (SectorType) {
  SectorType["Unknown"] = "Unknown";
  SectorType["FinancialBank"] = "FinancialBank";
  SectorType["FinancialNonBank"] = "FinancialNonBank";
  SectorType["NonFinancial"] = "NonFinancial";
})(SectorType = exports.SectorType || (exports.SectorType = {}));

exports.VALUATION_FIELDS = [{
  displayName: 'EV / Revenue',
  fieldName: 'evRev',
  ratio: ['ev', 'REV'],
  live: [true, false],
  fields: [SectorType.NonFinancial],
  imputed: true,
  hideQuarterly: true
}, {
  displayName: 'EV / EBITDA',
  fieldName: 'evEBITDA',
  ratio: ['ev', 'Atom__EBITDA'],
  live: [true, false],
  fields: [SectorType.NonFinancial],
  imputed: true,
  hideQuarterly: true
}, {
  displayName: 'EV / EBIT',
  fieldName: 'evEBIT',
  ratio: ['ev', 'EBIT'],
  live: [true, false],
  fields: [SectorType.NonFinancial],
  imputed: true,
  hideQuarterly: true
}, {
  displayName: 'Price / Book Value',
  fieldName: 'pricePerBookValue',
  ratio: ['price', 'BVPS'],
  live: [true, false],
  fields: [SectorType.FinancialNonBank, SectorType.FinancialBank],
  imputed: true
}, {
  displayName: 'Price / Tangible Book Value',
  fieldName: 'pricePerTangibleBookValue',
  ratio: ['price', 'TBVPS'],
  live: [true, false],
  fields: [SectorType.FinancialBank, SectorType.FinancialNonBank],
  imputed: true
}, {
  displayName: 'Price / NAV',
  fieldName: 'pricePerNAV',
  ratio: ['price', 'NAVPS'],
  live: [true, false],
  fields: [SectorType.FinancialNonBank],
  imputed: true
}, {
  displayName: 'Price / Earnings',
  fieldName: 'pricePerEarnings',
  ratio: ['price', 'EPS'],
  live: [true, false],
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank],
  imputed: true,
  hideQuarterly: true
}];
exports.FINANCIAL_FIELDS = [{
  displayName: 'Revenue',
  field: 'revenue',
  fieldName: 'REV',
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Net Investment Income',
  field: 'netInvestmentIncome',
  fieldName: 'NETINVINC',
  fields: [SectorType.FinancialNonBank]
}, {
  displayName: 'Operating Expenses',
  field: 'totalOperatingExpense',
  fieldName: 'OPREXP',
  fields: [SectorType.FinancialNonBank]
}, {
  displayName: 'Interest Expenses',
  field: 'totalInterestExpense',
  fieldName: 'INTEXP',
  fields: [SectorType.FinancialNonBank]
}, {
  displayName: 'Net Interest Income',
  field: 'netInterestIncome',
  fieldName: 'NII',
  fields: [SectorType.FinancialBank]
}, {
  displayName: 'Loan Loss Provisions',
  field: 'loanLossProvision',
  fieldName: 'LLP',
  fields: [SectorType.FinancialBank]
}, {
  displayName: 'Non-Interest Expense',
  field: 'nonInterestExpense',
  fieldName: 'NIE',
  fields: [SectorType.FinancialBank]
}, {
  displayName: 'EBITDA',
  field: 'ebitda',
  fieldName: 'Atom__EBITDA',
  fields: [SectorType.NonFinancial]
}, {
  displayName: 'EBIT',
  field: 'ebit',
  fieldName: 'EBIT',
  fields: [SectorType.NonFinancial]
}, {
  displayName: 'Net Income',
  field: 'netIncome',
  fieldName: 'Atom__NPROFIT',
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Total Debt',
  field: 'totalDebt',
  fieldName: 'TOTDEBT',
  fields: [SectorType.FinancialNonBank]
}, {
  displayName: 'EPS',
  field: 'eps',
  fieldName: 'EPS',
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Operating Cash Flow per Share',
  field: 'operatingCashFlowPerShare',
  fieldName: 'CFPS',
  fields: [SectorType.NonFinancial]
}, {
  displayName: 'Free Cash Flow per Share',
  field: 'freeCashFlowPerShare',
  fieldName: 'FCFPS',
  fields: [SectorType.NonFinancial]
}, {
  displayName: 'Dividend per Share',
  field: 'dividendPerShare',
  fieldName: 'DPS',
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Book Value per Share',
  field: 'bookValuePerShare',
  fieldName: 'BVPS',
  fields: [SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Tangible Book Val. Per Share',
  field: 'tangibleBookValuePerShare',
  fieldName: 'TBVPS',
  fields: [SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Risk-Weighted Assets',
  field: 'riskWeightedAssets',
  fieldName: 'RWA',
  fields: [SectorType.FinancialBank]
}, {
  displayName: 'Tier 1 Capital',
  field: 'tier1Capital',
  fieldName: 'TIER1',
  fields: [SectorType.FinancialBank]
}, {
  displayName: 'Non-Performing Assets',
  field: 'nonPerformingAssets',
  fieldName: 'NPA',
  fields: [SectorType.FinancialBank]
}, {
  displayName: 'NAV per Share',
  field: 'navPerShare',
  fieldName: 'NAVPS',
  fields: [SectorType.FinancialNonBank]
}, {
  displayName: 'G&A Expense',
  field: 'gaExpense',
  fieldName: 'GENADMEXP',
  fields: [SectorType.FinancialBank, SectorType.FinancialNonBank, SectorType.NonFinancial]
}];
exports.MARGINS_AND_RATIOS_FIELDS = [{
  displayName: 'NII / Revenue',
  fieldName: 'nIIOverRevenue',
  percent: ['NII', 'REV'],
  live: [false, false],
  fields: [SectorType.FinancialBank],
  imputed: true
}, {
  displayName: 'NIE / Revenue',
  fieldName: 'nIEOverRevenue',
  percent: ['NIE', 'REV'],
  live: [false, false],
  fields: [SectorType.FinancialBank],
  imputed: true
}, {
  displayName: 'Gross Margin',
  fieldName: 'grossMargin',
  percent: ['GROSSINC', 'REV'],
  live: [false, false],
  fields: [SectorType.NonFinancial],
  imputed: true
}, {
  displayName: 'EBITDA Margin',
  fieldName: 'ebitdaMargin',
  percent: ['Atom__EBITDA', 'REV'],
  live: [false, false],
  fields: [SectorType.NonFinancial],
  imputed: true
}, {
  displayName: 'EBIT Margin',
  fieldName: 'ebitMargin',
  percent: ['EBIT', 'REV'],
  live: [false, false],
  fields: [SectorType.NonFinancial],
  imputed: true
}, {
  displayName: 'Int. Exp. Margin',
  fieldName: 'intExpMargin',
  percent: ['INTEXP', 'REV'],
  live: [false, false],
  imputed: true,
  fields: [SectorType.FinancialNonBank]
}, {
  displayName: 'Operating Expense Margin',
  fieldName: 'operatingExpenseMargin',
  percent: ['OPREXP', 'REV'],
  live: [false, false],
  fields: [SectorType.FinancialNonBank],
  imputed: true
}, {
  displayName: 'Net Income Margin',
  fieldName: 'netIncomeMargin',
  percent: ['Atom__NPROFIT', 'REV'],
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank],
  live: [false, false],
  imputed: true
}, {
  displayName: 'Return on Assets',
  fieldName: 'returnOnAssets',
  percent: ['ROA'],
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Return on Equity',
  fieldName: 'returnOnEquity',
  percent: ['ROE'],
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'Tier 1 Capital Ratio',
  fieldName: 'tier1CapitalRatio',
  percent: ['TIER1', 'RWA'],
  live: [false, false],
  fields: [SectorType.FinancialBank],
  imputed: true
}];
exports.GROWTH_FIELDS = [{
  displayName: 'Revenue Growth',
  field: 'revenueGrowth',
  growth: true,
  fieldName: 'REV',
  percent: true,
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}, {
  displayName: 'EBITDA Growth',
  field: 'ebitdaGrowth',
  growth: true,
  fieldName: 'Atom__EBITDA',
  percent: true,
  fields: [SectorType.NonFinancial]
}, {
  displayName: 'EBIT Growth',
  field: 'ebitGrowth',
  growth: true,
  fieldName: 'EBIT',
  percent: true,
  fields: [SectorType.NonFinancial]
}, {
  displayName: 'EPS Growth',
  field: 'epsGrowth',
  growth: true,
  fieldName: 'EPS',
  percent: true,
  fields: [SectorType.NonFinancial, SectorType.FinancialBank, SectorType.FinancialNonBank]
}];