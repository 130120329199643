import { apolloClient } from '..';
import { TRACK_WITH_HUBSPOT } from '../modules/common/queries';

export enum TimelineEvent {
  'SignupStartedClient' = 1182598,
  'AccountCreatedClient' = 1182599,
}

export const getHSEventString = (eventName, props?) => {
  const cookies = {};
  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=');
    cookies[key.trim()] = value;
  });
  const hutk = cookies['hubspotutk'];

  const dataString = JSON.stringify({
    'eventTemplateId': TimelineEvent[eventName].valueOf(), //eventName must match TimelineEvent enum
    'utk': hutk,
    'tokens': props, //names must match EXACTLY to tokens in the hubspot timeline event app
    //if getting a bad request error, make sure the tokens match
  });

  return dataString;
};
const track = async (eventName, props) => {
  if (!TimelineEvent[eventName]) {
    console.log('Cannot track this event in hubspot yet');
    return;
  }
  try {
    await apolloClient.mutate({
      mutation: TRACK_WITH_HUBSPOT,
      variables: { hubspotData: getHSEventString(eventName, props) },
    });
  } catch (e) {
    console.log('Hubspot tracking mutation failed', e);
  }
};

const hubspot = {
  track,
};

export default hubspot;
