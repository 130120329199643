import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import MobileModal from './configurationModals/MobileModal';
import GlobalModal from './configurationModals/GlobalSetting';
import NewsFeedModal from './configurationModals/NewsFeedSetting';
import OptionBox from './OptionBox';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_OVERVIEW_CONFIG,
  UPDATE_OVERVIEW_CONFIG,
  GET_WHITELIST_URL_CONFIG,
  GET_PARTNER_CONFIG_ACTIVITY,
} from '../common/queries';
import { AdminContext } from '../common/AdminContext';
import Popup from './configurationModals/TempPopup';
import KeyRotationPopup from './configurationModals/KeyRotationPopup';
import { AtomAssetSubtypeCode } from '@atom-finance/atom-types';
import { AgGridPagination } from '../common/TablePagination';
import { ceil } from 'lodash';
import moment from 'moment/moment';

const OverviewContent = () => {
  const pageSize = 100;

  const [currentPage, setCurrentPage] = useState(0);

  const {
    loading,
    data: getConfigResponse,
    refetch,
  } = useQuery(GET_PARTNER_CONFIG_ACTIVITY, {
    variables: { pageSize, currentPage },
  });

  const partnerConfigActivityDescriptions =
    getConfigResponse?.partnerConfigActivity?.activity ?? [];

  const totalCount = getConfigResponse?.partnerConfigActivity?.totalCount ?? 0;

  const maxPages = ceil(totalCount / pageSize);

  const paginationGetPageSize = () => {
    return pageSize;
  };

  const paginationGetCurrentPage = () => {
    return currentPage;
  };

  const paginationGetRowCount = () => {
    return totalCount;
  };

  const paginationGetTotalPages = () => {
    return maxPages;
  };

  const paginationGoToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginationGoToNextPage = () => {
    if (currentPage < maxPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  if (loading) {
    return (
      <>
        <SmallText>Loading...</SmallText>
      </>
    );
  } else if (totalCount === 0) {
    return (
      <>
        <SmallText>No changes</SmallText>
      </>
    );
  }

  return (
    <>
      {partnerConfigActivityDescriptions.length > 0 ? (
        <AgGridPagination
          data={partnerConfigActivityDescriptions}
          containerStyle={{ padding: '0.5rem 1rem .5rem .5rem' }}
          showPageSize
          paginationKey={'partnerConfigActivity'}
          paginationSetPageSize={null}
          paginationGetPageSize={paginationGetPageSize}
          paginationGetCurrentPage={paginationGetCurrentPage}
          paginationGetRowCount={paginationGetRowCount}
          paginationGetTotalPages={paginationGetTotalPages}
          paginationGoToPreviousPage={paginationGoToPreviousPage}
          paginationGoToNextPage={paginationGoToNextPage}
        />
      ) : null}
      {partnerConfigActivityDescriptions.map((row, index) => {
        const { authorFirstName, authorLastName, description, createdAt } = row;
        const date = new Date(parseInt(createdAt));
        const momentDate = moment(date);
        const timestamp = momentDate.format('MMMM Do YYYY, h:mm:ss a');
        return (
          <ConfigurationContainer key={index}>
            <SmallText>
              <MoreLink>
                {authorFirstName} {authorLastName}
              </MoreLink>
              <SubText> {description}</SubText>
              <TimeStamp> {'' + timestamp} </TimeStamp>
            </SmallText>
          </ConfigurationContainer>
        );
      })}
      {partnerConfigActivityDescriptions.length > 0 ? <Break /> : null}
    </>
  );
};

const TimeStamp = styled.a`
  float: right;
`;

const ConfigurationContainer = styled.div`
  border-top: solid 1px var(--atom-brand-5);
  padding: 1em 1em 1em 0.5em;
`;

const Break = styled.div`
  border-top: solid 1px var(--atom-brand-5);
  padding: 0;
`;

const SmallText = styled.div`
  font-size: 15px;
`;

const MoreLink = styled.a`
  color: var(--atom-dark-contrast);
`;

const SubText = styled.a`
  color: var(--atom-brand-7);
`;

export default OverviewContent;
