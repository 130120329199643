import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { AUTHENTICATE_AS_ADMIN } from '../../common/queries';
import MainHeader, { AppLogo, LeftContent } from '../../components/MainHeader';
import {
  GreenClickableText,
  Input,
  InputContainer,
  ResponseText,
  Spinner,
  WidthGreenClickableButton,
} from '../../common/styledComponents';
import SelfSignUpForm from './SignUpForm';
import { Link } from 'react-router-dom';
import atomlytics from '../../../helpers/atomlytics';

const SignIn = () => {
  const [authenticateAsPartner, authenticateAsPartnerResponse] = useMutation(
    AUTHENTICATE_AS_ADMIN,
  );
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [signUp, setSignUp] = useState(false);

  const authUser = useCallback(
    async (email: string, password: string) => {
      authenticateAsPartner({
        variables: { email, password },
      });
    },
    [authenticateAsPartner],
  );

  const { loading, data } = authenticateAsPartnerResponse;
  const success = data?.authenticateAsAdmin?.success;
  const message = data?.authenticateAsAdmin?.message;
  const disableSubmit = !userEmail?.length || !userPassword?.length || loading;

  if (success) {
    window.location.href = '/';
  }

  if (signUp) {
    return (
      <>
        <SelfSignUpForm setSignUp={setSignUp} />
      </>
    );
  }
  return (
    /*Sign In Screen*/
    <>
      <MainHeader includeRight={false} />
      <SigninContainer>
        <SigninSection>
          <TitleSectionTop>Atom Finance</TitleSectionTop>
          <TitleSectionBottom>for Business</TitleSectionBottom>
          <Input
            onChange={e => setUserEmail(e.target.value)}
            placeholder='Enter email address'
            value={userEmail}
            style={{ width: '22em' }}
          />
          <Input
            onChange={e => setUserPassword(e.target.value)}
            placeholder='Password'
            type='password'
            value={userPassword}
            style={{ width: '22em' }}
            autoComplete='off'
          />
          <ResponseText success={success}>{message}</ResponseText>
          <InputContainer>
            <WidthGreenClickableButton
              disabled={disableSubmit}
              onClick={() => {
                if (!disableSubmit) authUser(userEmail, userPassword);
              }}
            >
              {loading ? <Spinner smaller={true} /> : `SIGN IN`}
            </WidthGreenClickableButton>
          </InputContainer>
          <StyledForgotLink to='/forgotPassword' style={{ fontSize: '15px' }}>
            RESET PASSWORD
          </StyledForgotLink>
          <GreenClickableText
            style={{ fontSize: '15px', paddingTop: '1.5rem' }}
            onClick={() => {
              atomlytics.signupStartedClient({
                signup_type: 'portal',
                signup_location: 'homepage',
              });
              setSignUp(true);
            }}
          >
            {`SIGN UP AND GET MY FREE TRIAL KEY`}
          </GreenClickableText>
        </SigninSection>
      </SigninContainer>
    </>
  );
};

const TitleSectionTop = styled.div`
  font-size: 48px;
`;

const TitleSectionBottom = styled.div`
  font-size: 48px;
  margin-bottom: 1.5em;
`;

const SigninSection = styled.div`
  padding: 6em;
  border-radius: 6px;
  margin: 1.5em 0em;
  border: 1px solid var(--atom-dark-brand-5);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SigninContainer = styled.div`
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledForgotLink = styled(Link)`
  color: var(--atom-green);
  margin-top: 15px;
  font-weight: bold;
  &:hover {
    color: var(
      --atom-${props => (props.disabled ? 'brand-7' : 'dark-green-hover')}
    );
  }
`;

export default SignIn;
