import React, { useState } from 'react';
import Modal from './Modal';
import styled, { css } from 'styled-components';
import AtomIcon from '../../common/AtomIcon';
import { Spinner } from '../../common/styledComponents';
import { useMutation } from '@apollo/react-hooks';
import { GET_PARTNER_ICON, UPDATE_PARTNER_API_KEY } from '../../common/queries';

const KeyRotationPopup = ({
  showModal,
  closeModal,
  selectedKeyRotation,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateAPIKey] = useMutation(UPDATE_PARTNER_API_KEY, {
    refetchQueries: [{ query: GET_PARTNER_ICON }],
  });

  const rotateKeys = async () => {
    setIsLoading(true);
    if (selectedKeyRotation === 'apiKey') {
      const updateAPIKeyPromise = updateAPIKey();
      updateAPIKeyPromise.then(
        value => {
          // promise was fulfilled
          const { updatePartnerAPIKey: reponseData } = value.data;
          if (reponseData.success) {
            // API Key was successfully rotated
            alert('API Keys rotated successfully');
            setIsLoading(false);
            closeModal();
          } else {
            // API Key did not rotate
            alert('Failed to rotate API Keys');
            setIsLoading(false);
          }
        },
        () => {
          // promise was rejected
          alert('Failed to rotate API Keys');
          setIsLoading(false);
        },
      );
    } else if (selectedKeyRotation === 'oauthToken') {
      alert('Currently in progress');
      setIsLoading(false);
      //   closeModal();
    }
  };
  return (
    <>
      {showModal && (
        <Modal closeModal={() => {}}>
          <Popup>
            <>
              <AtomIcon
                size={26}
                style={{ position: 'absolute', right: '15px', top: '10px' }}
                icon={'close'}
                iconCss={
                  css`
                    fill: var(--atom-green);
                  ` as any
                }
                onClick={closeModal}
              />
              <Label>
                {`Are you sure you want to rotate ${
                  selectedKeyRotation === 'apiKey' ? 'API Keys' : 'OAuth Tokens'
                }?`}
              </Label>
              <ConfirmButtonContainer>
                <ConfirmButton onClick={rotateKeys}>
                  <ConfirmLabel>
                    {!isLoading ? 'CONFIRM' : <Spinner smaller></Spinner>}
                  </ConfirmLabel>
                </ConfirmButton>
              </ConfirmButtonContainer>
            </>
          </Popup>
        </Modal>
      )}
    </>
  );
};

const ConfirmButtonContainer = styled.div`
  height: 2rem;
`;

const ConfirmButton = styled.div`
  cursor: pointer;
  margin-top: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  width: 5.25rem;
  height: 100%;
  background-color: var(--atom-dark-green);
  border-radius: 3px;
  &:hover {
    background-color: var(--atom-dark-green-hover);
  }
`;

const ConfirmLabel = styled.div`
  padding-top: 6px;
  color: var(--atom-brand-1);
  font-size: 14px;
  font-weight: bold;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--atom-dark-brand-3);
  border-radius: 10px;
  align-items: center;
  width: 30rem;
  height: 10rem;
  box-sizing: border-box;
  text-align: center;
`;

const Label = styled.div`
  font-size: 17px;
  font-weight: 300;
  color: white;
  padding-top: 3rem;
`;

export default KeyRotationPopup;
