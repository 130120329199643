"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.marketCalendarPeriods = exports.MarketCloseReasons = void 0;

var luxon_1 = require("luxon");

var index_1 = require("../index");

var luxMarkets_1 = require("./luxMarkets");

var luxonUtils_1 = require("./luxonUtils");

exports.MarketCloseReasons = ['NORMAL_CLOSE_HOURS', 'HOLIDAY', 'WEEKEND', 'EARLY_CLOSE', 'LATE_OPEN'];

var orderedTimeOfDayFunctions = function orderedTimeOfDayFunctions(market) {
  var time = luxon_1.DateTime.now();
  var timeFunctions = Object.values(market.toTimeOfDay); // Add an extra funciton that sets time to start of day

  timeFunctions.push(function (t) {
    return t.startOf('day');
  }); // Run all timeOfDay functions with the same dateTime and order them

  return timeFunctions.filter(function (timeFunc) {
    return typeof timeFunc === 'function';
  }).sort(function (setTimeA, setTimeB) {
    return setTimeA(time).toMillis() - setTimeB(time).toMillis();
  });
};

var possiblePeriodEndTimes = function possiblePeriodEndTimes(start, end, market) {
  var timeFunctions = orderedTimeOfDayFunctions(market);
  var times = [];
  var time = start;
  var done = false;

  while (!done) {
    timeFunctions.forEach(function (nextTime) {
      time = nextTime(time);

      if (time > end) {
        done = true;
      } else if (time > start) {
        times.push(time);
      }
    });
    time = time.plus({
      days: 1
    });
  } // Ensure that the end of the range is added


  if (times[times.length - 1] !== end) {
    times.push(end);
  }

  return times;
};

var marketCloseReason = function marketCloseReason(market, dateTime) {
  if ((0, luxonUtils_1.isLuxWeekend)(dateTime)) return 'WEEKEND';
  if ((0, luxonUtils_1.isLuxHoliday)(market.code, dateTime)) return 'HOLIDAY';
  var toTimeOfDay = market.toTimeOfDay;
  if (dateTime < toTimeOfDay.open(dateTime) || dateTime >= toTimeOfDay.close(dateTime)) return 'NORMAL_CLOSE_HOURS';

  if ((0, luxonUtils_1.isLuxHalfHoliday)(market.code, dateTime)) {
    if (toTimeOfDay.earlyClose && dateTime >= toTimeOfDay.earlyClose(dateTime)) return 'EARLY_CLOSE';
    if (toTimeOfDay.lateOpen && dateTime < toTimeOfDay.lateOpen(dateTime)) return 'LATE_OPEN';
  }
};

var marketState = function marketState(market, dateTime) {
  var status = (0, luxonUtils_1.getMarketStatus)(index_1.MarketToTrExchangeCode[market.code], dateTime.plus({
    seconds: 1
  }));
  var state = {
    time: dateTime,
    status: status
  };

  if (status === luxonUtils_1.MarketStatus.Closed) {
    state.marketCloseReason = marketCloseReason(market, dateTime);
  }

  return state;
};

var marketCalendarPeriods = function marketCalendarPeriods(marketCode, startTime, endTime) {
  var market = (0, luxMarkets_1.luxMarket)(marketCode);
  startTime = startTime.setZone(market.zone);
  endTime = endTime.setZone(market.zone);
  var prevState = marketState(market, startTime);
  var allTimes = possiblePeriodEndTimes(startTime, endTime, market);
  var periods = []; // Check all times where market status could change & create periods

  allTimes.forEach(function (time, index) {
    var newState = marketState(market, time);

    if (newState.status !== prevState.status || newState.marketCloseReason !== prevState.marketCloseReason || index === allTimes.length - 1) {
      periods.push({
        status: prevState.status,
        start: prevState.time.toUTC().toMillis(),
        end: newState.time.toUTC().toMillis(),
        marketCloseReason: prevState.marketCloseReason
      });
      prevState = newState;
    }
  });
  return periods;
};

exports.marketCalendarPeriods = marketCalendarPeriods;