"use strict";
/**
 * LUP (LOOKUPS & POSSIBLE VALUES) types for EDI
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EdiOutturnStyle = exports.EdiPaytype = exports.EdiEventCD = exports.EdiAction = void 0;
var EdiAction;

(function (EdiAction) {
  EdiAction["CANCELLED"] = "C";
  EdiAction["DELETED"] = "D";
  EdiAction["INSERTED"] = "I";
  EdiAction["UPDATED"] = "U";
  EdiAction["PAY_DETAILS_CANCELLED_ISSUER"] = "P";
  EdiAction["PAY_DETAILS_DELETED_SUPPLIER"] = "Q";
})(EdiAction = exports.EdiAction || (exports.EdiAction = {}));

var EdiEventCD;

(function (EdiEventCD) {
  EdiEventCD["ADD"] = "ADD";
  EdiEventCD["AGCHG"] = "AGCHG";
  EdiEventCD["AGM"] = "AGM";
  EdiEventCD["AGNCY"] = "AGNCY";
  EdiEventCD["ANN"] = "ANN";
  EdiEventCD["ARR"] = "ARR";
  EdiEventCD["ASSM"] = "ASSM";
  EdiEventCD["AUCT"] = "AUCT";
  EdiEventCD["BB"] = "BB";
  EdiEventCD["BBC"] = "BBC";
  EdiEventCD["BBCC"] = "BBCC";
  EdiEventCD["BBE"] = "BBE";
  EdiEventCD["BBEC"] = "BBEC";
  EdiEventCD["BDC"] = "BDC";
  EdiEventCD["BHM"] = "BHM";
  EdiEventCD["BKRP"] = "BKRP";
  EdiEventCD["BNDLQ"] = "BNDLQ";
  EdiEventCD["BOCHG"] = "BOCHG";
  EdiEventCD["BON"] = "BON";
  EdiEventCD["BONDFULL"] = "BONDFULL";
  EdiEventCD["BONDLITE"] = "BONDLITE";
  EdiEventCD["BR"] = "BR";
  EdiEventCD["BSCHG"] = "BSCHG";
  EdiEventCD["BSKCC"] = "BSKCC";
  EdiEventCD["BWCHG"] = "BWCHG";
  EdiEventCD["CALL"] = "CALL";
  EdiEventCD["CALLPUT"] = "CALLPUT";
  EdiEventCD["CANCEL"] = "CANCEL";
  EdiEventCD["CAPRD"] = "CAPRD";
  EdiEventCD["CH144"] = "CH144";
  EdiEventCD["CLEAN"] = "CLEAN";
  EdiEventCD["CLSAC"] = "CLSAC";
  EdiEventCD["CMACQ"] = "CMACQ";
  EdiEventCD["CONSD"] = "CONSD";
  EdiEventCD["CONV"] = "CONV";
  EdiEventCD["CONVT"] = "CONVT";
  EdiEventCD["CORR"] = "CORR";
  EdiEventCD["COSNT"] = "COSNT";
  EdiEventCD["CPOPN"] = "CPOPN";
  EdiEventCD["CPOPT"] = "CPOPT";
  EdiEventCD["CRCHG"] = "CRCHG";
  EdiEventCD["CRDRT"] = "CRDRT";
  EdiEventCD["CTCHG"] = "CTCHG";
  EdiEventCD["CTX"] = "CTX";
  EdiEventCD["CURRD"] = "CURRD";
  EdiEventCD["DEFUNCT"] = "DEFUNCT";
  EdiEventCD["DIST"] = "DIST";
  EdiEventCD["DIV"] = "DIV";
  EdiEventCD["DIVIF"] = "DIVIF";
  EdiEventCD["DIVRC"] = "DIVRC";
  EdiEventCD["DMRGR"] = "DMRGR";
  EdiEventCD["DPRCP"] = "DPRCP";
  EdiEventCD["DRCHG"] = "DRCHG";
  EdiEventCD["DRIP"] = "DRIP";
  EdiEventCD["DVST"] = "DVST";
  EdiEventCD["ENT"] = "ENT";
  EdiEventCD["EXCHG"] = "EXCHG";
  EdiEventCD["FFC"] = "FFC";
  EdiEventCD["FI"] = "FI";
  EdiEventCD["FINCH"] = "FINCH";
  EdiEventCD["FRANK"] = "FRANK";
  EdiEventCD["FRNFX"] = "FRNFX";
  EdiEventCD["FTRAN"] = "FTRAN";
  EdiEventCD["FTT"] = "FTT";
  EdiEventCD["FYCHG"] = "FYCHG";
  EdiEventCD["ICC"] = "ICC";
  EdiEventCD["IFCHG"] = "IFCHG";
  EdiEventCD["INCHG"] = "INCHG";
  EdiEventCD["INDEF"] = "INDEF";
  EdiEventCD["INT"] = "INT";
  EdiEventCD["INTBC"] = "INTBC";
  EdiEventCD["INTPY"] = "INTPY";
  EdiEventCD["IRCHG"] = "IRCHG";
  EdiEventCD["ISCHG"] = "ISCHG";
  EdiEventCD["ISSDD"] = "ISSDD";
  EdiEventCD["ISSUR"] = "ISSUR";
  EdiEventCD["LCC"] = "LCC";
  EdiEventCD["LEICH"] = "LEICH";
  EdiEventCD["LIQ"] = "LIQ";
  EdiEventCD["LSTAT"] = "LSTAT";
  EdiEventCD["LTCHG"] = "LTCHG";
  EdiEventCD["MFCON"] = "MFCON";
  EdiEventCD["MIFID"] = "MIFID";
  EdiEventCD["MKCHG"] = "MKCHG";
  EdiEventCD["MKTSG"] = "MKTSG";
  EdiEventCD["MRGR"] = "MRGR";
  EdiEventCD["MTCHG"] = "MTCHG";
  EdiEventCD["NLIST"] = "NLIST";
  EdiEventCD["NS"] = "NS";
  EdiEventCD["ODDLT"] = "ODDLT";
  EdiEventCD["PID"] = "PID";
  EdiEventCD["PIK"] = "PIK";
  EdiEventCD["PO"] = "PO";
  EdiEventCD["PRCHG"] = "PRCHG";
  EdiEventCD["PRF"] = "PRF";
  EdiEventCD["PVRD"] = "PVRD";
  EdiEventCD["RCAP"] = "RCAP";
  EdiEventCD["RCONV"] = "RCONV";
  EdiEventCD["RD"] = "RD";
  EdiEventCD["RDNOM"] = "RDNOM";
  EdiEventCD["REDEM"] = "REDEM";
  EdiEventCD["REDMT"] = "REDMT";
  EdiEventCD["REISS"] = "REISS";
  EdiEventCD["RESTORE"] = "RESTORE";
  EdiEventCD["REVERT"] = "REVERT";
  EdiEventCD["ROCHG"] = "ROCHG";
  EdiEventCD["RTRAC"] = "RTRAC";
  EdiEventCD["RTS"] = "RTS";
  EdiEventCD["SACHG"] = "SACHG";
  EdiEventCD["SBFDCH"] = "SBFDCH";
  EdiEventCD["SBFDTR"] = "SBFDTR";
  EdiEventCD["SCAGY"] = "SCAGY";
  EdiEventCD["SCCHG"] = "SCCHG";
  EdiEventCD["SCEXH"] = "SCEXH";
  EdiEventCD["SCMST"] = "SCMST";
  EdiEventCD["SCSWP"] = "SCSWP";
  EdiEventCD["SCXTC"] = "SCXTC";
  EdiEventCD["SD"] = "SD";
  EdiEventCD["SDCHG"] = "SDCHG";
  EdiEventCD["SDLEXC"] = "SDLEXC";
  EdiEventCD["SECRC"] = "SECRC";
  EdiEventCD["SEDOL"] = "SEDOL";
  EdiEventCD["SHOCH"] = "SHOCH";
  EdiEventCD["SXTCH"] = "SXTCH";
  EdiEventCD["TKOVR"] = "TKOVR";
  EdiEventCD["TLELK"] = "TLELK";
  EdiEventCD["TRCHG"] = "TRCHG";
  EdiEventCD["TRNCH"] = "TRNCH";
  EdiEventCD["UMCHG"] = "UMCHG";
  EdiEventCD["UNSTP"] = "UNSTP";
  EdiEventCD["WAREX"] = "WAREX";
  EdiEventCD["WTCHG"] = "WTCHG";
  EdiEventCD["WXCHG"] = "WXCHG";
})(EdiEventCD = exports.EdiEventCD || (exports.EdiEventCD = {}));

var EdiPaytype;

(function (EdiPaytype) {
  EdiPaytype["Cash"] = "C";
  EdiPaytype["Stock"] = "S";
})(EdiPaytype = exports.EdiPaytype || (exports.EdiPaytype = {}));

var EdiOutturnStyle;

(function (EdiOutturnStyle) {
  EdiOutturnStyle["ADEX"] = "ADEX";
  EdiOutturnStyle["NEWO"] = "NEWO";
})(EdiOutturnStyle = exports.EdiOutturnStyle || (exports.EdiOutturnStyle = {}));