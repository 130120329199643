"use strict";
/**
 * Using strings so it can be used by whatever date lib
 * Old dates are useful for calculating correct chart returns
 * Schedule from https://www.nyse.com/markets/hours-calendars
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OTC_HALF_MARKET_HOLIDAYS = exports.OTC_FULL_MARKET_HOLIDAYS = exports.SAO_FULL_MARKET_HOLIDAYS = exports.SAO_HALF_MARKET_HOLIDAYS_LATE_START = exports.NYSE_FULL_MARKET_HOLIDAYS = exports.NYSE_HALF_MARKET_HOLIDAYS = exports.TOR_HALF_MARKET_HOLIDAYS = exports.TOR_FULL_MARKET_HOLIDAYS = void 0; //https://www.marketbeat.com/stock-market-holidays/canada/ for 2023 onwards
// https://www.tsx.com/trading/calendars-and-trading-hours/calendar for 2022

exports.TOR_FULL_MARKET_HOLIDAYS = new Set([//2022
'2022-01-03', '2022-02-21', '2022-04-15', '2022-05-23', '2022-05-30', '2022-06-20', '2022-07-01', '2022-07-04', '2022-08-01', '2022-09-05', '2022-10-10', '2022-11-24', '2022-12-26', '2022-12-27', // 2023
'2023-01-02', '2023-02-20', '2023-04-07', '2023-05-22', '2023-07-03', '2023-08-07', '2023-09-04', '2023-10-09', '2023-12-25', '2023-12-26', //2024
'2024-01-01', '2024-02-19', '2024-03-29', '2024-05-20', '2024-07-01', '2024-08-05', '2024-09-02', '2024-10-14', '2024-12-25', '2024-12-26', //2025
'2025-01-01', '2025-02-17', '2025-04-18', '2025-05-19', '2025-07-01', '2025-08-04', '2025-09-01', '2025-10-13', '2025-12-25', '2025-12-26', //2026
'2026-01-01', '2026-02-16', '2026-04-03', '2026-05-18', '2026-07-01', '2026-08-03', '2026-09-07', '2026-10-12', '2026-12-25', '2026-12-28', //2027
'2027-01-01', '2027-02-15', '2027-03-26', '2027-05-24', '2027-07-01', '2027-08-02', '2027-09-06', '2027-10-11', '2027-12-27', '2027-12-28', //2028
'2028-01-03', '2028-02-21', '2028-04-14', '2028-05-22', '2028-07-03', '2028-08-07', '2028-09-04', '2028-10-09', '2028-12-25', '2028-12-26']); //https://www.tsx.com/trading/calendars-and-trading-hours/calendar

exports.TOR_HALF_MARKET_HOLIDAYS = new Set([//2021
'2021-12-24' //none in 2022
//none in 2023
//none in 2024
]); //https://www.businesswire.com/news/home/20221221005578/en/NYSE-Group-Announces-2023-2024-and-2025-Holiday-and-Early-Closings-Calendar

exports.NYSE_HALF_MARKET_HOLIDAYS = new Set([//2020
'2020-11-27', '2020-12-24', //2021
'2021-11-26', //2022
'2022-11-25', //2023
'2023-07-03', '2023-11-24', //2024
'2024-07-03', '2024-11-29', '2024-12-24', //2025
'2025-07-03', '2025-11-28', '2025-12-24', //2026
'2026-11-27', '2026-12-24']);
exports.NYSE_FULL_MARKET_HOLIDAYS = new Set([// 2000
'2000-01-17', '2000-02-21', '2000-04-21', '2000-05-29', '2000-07-04', '2000-09-04', '2000-11-23', '2000-12-25', // 2001
'2001-01-01', '2001-01-15', '2001-02-19', '2001-04-13', '2001-05-28', '2001-07-04', '2001-09-03', '2001-09-11', '2001-09-12', '2001-09-13', '2001-09-14', '2001-11-22', '2001-12-25', // 2002
'2002-01-01', '2002-01-21', '2002-02-18', '2002-03-29', '2002-05-27', '2002-07-04', '2002-09-02', '2002-12-25', '2002-11-28', // 2003
'2003-01-01', '2003-01-20', '2003-02-17', '2003-04-18', '2003-05-26', '2003-07-04', '2003-09-01', '2003-11-27', '2003-12-25', // 2004
'2004-01-01', '2004-01-19', '2004-02-16', '2004-04-09', '2004-05-31', '2004-06-11', '2004-07-05', '2004-09-06', '2004-11-25', '2004-12-24', // 2005
'2005-01-17', '2005-02-21', '2005-03-25', '2005-05-30', '2005-07-04', '2005-09-05', '2005-11-24', '2005-12-26', // 2006
'2006-01-02', '2006-01-16', '2006-02-20', '2006-04-14', '2006-05-29', '2006-07-04', '2006-09-04', '2006-11-23', '2006-12-25', // 2007
'2007-01-01', '2007-01-02', '2007-01-15', '2007-02-19', '2007-04-06', '2007-05-28', '2007-07-04', '2007-09-03', '2007-11-22', '2007-12-25', //2008
'2008-01-01', '2008-01-21', '2008-02-18', '2008-03-21', '2008-05-26', '2008-07-04', '2008-09-01', '2008-11-27', '2008-12-25', //2009
'2009-01-01', '2009-01-19', '2009-02-16', '2009-04-10', '2009-05-25', '2009-07-03', '2009-09-07', '2009-11-26', '2009-12-25', // 2010
'2010-01-01', '2010-01-18', '2010-02-15', '2010-04-02', '2010-05-31', '2010-07-05', '2010-09-06', '2010-11-25', '2010-12-24', // 2011
'2011-01-17', '2011-02-21', '2011-04-22', '2011-05-30', '2011-07-04', '2011-09-05', '2011-11-24', '2011-12-26', // 2012
'2012-01-02', '2012-01-16', '2012-02-20', '2012-04-06', '2012-05-28', '2012-07-04', '2012-09-03', '2012-10-29', '2012-10-30', '2012-11-22', '2012-12-25', // 2013
'2013-01-01', '2013-01-21', '2013-02-18', '2013-03-29', '2013-05-27', '2013-07-04', '2013-09-02', '2013-11-28', '2013-12-25', // 2014
'2014-01-01', '2014-01-20', '2014-02-17', '2014-04-18', '2014-05-26', '2014-07-04', '2014-09-01', '2014-11-27', '2014-12-25', // 2015
'2015-01-01', '2015-01-19', '2015-02-16', '2015-04-03', '2015-05-25', '2015-07-03', '2015-09-07', '2015-11-26', '2015-12-25', // 2016
'2016-01-01', '2016-01-18', '2016-02-15', '2016-03-25', '2016-03-30', '2016-07-04', '2016-09-05', '2016-11-24', '2016-12-25', // 2017
'2017-01-02', '2017-01-16', '2017-02-20', '2017-04-14', '2017-05-29', '2017-07-04', '2017-09-04', '2017-11-23', '2017-12-25', // 2018
'2018-01-01', '2018-01-15', '2018-02-19', '2018-03-30', '2018-05-28', '2018-07-04', '2018-09-03', '2018-11-22', '2018-12-25', // 2019
'2019-01-01', '2019-01-14', '2019-02-18', '2019-04-19', '2019-05-27', '2019-07-04', '2019-09-02', '2019-11-28', '2019-12-25', // 2020
'2020-01-01', '2020-01-20', '2020-02-17', '2020-04-10', '2020-05-25', '2020-07-03', '2020-09-07', '2020-11-26', '2020-12-25', // 2021
'2021-01-01', '2021-01-18', '2021-02-15', '2021-04-02', '2021-05-31', '2021-07-05', '2021-09-06', '2021-11-25', '2021-12-24', // 2022
'2022-01-17', '2022-02-21', '2022-04-15', '2022-05-30', '2022-06-20', '2022-07-04', '2022-09-05', '2022-11-24', '2022-12-26', // 2023
'2023-01-02', '2023-01-16', '2023-02-20', '2023-04-07', '2023-05-29', '2023-06-19', '2023-07-04', '2023-09-04', '2023-11-23', '2023-12-25', // 2024
'2024-01-01', '2024-01-15', '2024-02-19', '2024-03-29', '2024-05-27', '2024-06-19', '2024-07-04', '2024-09-02', '2024-11-28', '2024-12-25', //2025
'2025-01-01', '2025-01-20', '2025-02-17', '2025-04-18', '2025-05-26', '2025-06-19', '2025-07-04', '2025-09-01', '2025-11-27', '2025-12-25', // 2026
'2026-01-01', '2026-01-19', '2026-02-16', '2026-04-03', '2026-05-25', '2026-06-19', '2026-07-03', '2026-09-07', '2026-11-26', '2026-12-25']); // open 1pm - 4:55pm BRT on "late start" days
// https://www.tradinghours.com/markets/bovespa/holidays
// there don't seem to be early close brazil holidays (as of 2021)

exports.SAO_HALF_MARKET_HOLIDAYS_LATE_START = new Set(['2021-02-17', '2022-03-02', '2023-02-22', '2024-02-14']); //https://www.tradinghours.com/markets/bovespa

exports.SAO_FULL_MARKET_HOLIDAYS = new Set([// 2021
'2021-01-01', '2021-01-25', '2021-02-15', '2021-04-02', '2021-04-21', '2021-06-03', '2021-07-09', '2021-09-07', '2021-10-12', '2021-11-02', '2021-11-15', '2021-12-24', '2021-12-31', // 2022
'2022-01-01', '2022-02-28', '2022-03-01', '2022-04-15', '2022-04-21', '2022-06-16', '2022-09-07', '2022-10-12', '2022-11-02', '2022-11-15', '2022-12-30', //2023
'2023-02-20', '2023-02-21', '2023-04-07', '2023-04-21', '2023-05-01', '2023-06-08', '2023-09-07', '2023-10-12', '2023-11-02', '2023-11-15', '2023-12-25', '2023-12-29', //2024
'2024-01-01', '2024-02-12', '2024-02-13', '2024-03-29', '2024-05-30', '2024-11-15', '2024-11-20', '2024-12-24', '2024-12-25', '2024-12-31']); //https://www.otcmarkets.com/market-hours

exports.OTC_FULL_MARKET_HOLIDAYS = new Set([// 2022
'2022-01-01', '2022-01-17', '2022-02-21', '2022-04-15', '2022-05-30', '2022-06-20', '2022-07-04', '2022-09-05', '2022-11-24', '2022-12-26', //2023
'2023-01-02', '2023-01-16', '2023-02-20', '2023-04-07', '2023-05-29', '2023-06-19', '2023-07-04', '2023-09-04', '2023-11-23', '2023-12-25', //2024
'2024-01-01', '2024-01-15', '2024-02-19', '2024-03-29', '2024-05-27', '2024-06-19', '2024-07-04', '2024-09-02', '2024-11-28', '2024-12-25', //2025
'2025-01-01', '2025-01-20', '2025-02-17', '2025-04-18', '2025-05-26', '2025-06-19', '2025-07-04', '2025-09-01', '2025-11-27', '2025-12-25']); //https://www.otcmarkets.com/market-hours

exports.OTC_HALF_MARKET_HOLIDAYS = new Set([//2022
'2022-11-25', //2023
'2023-07-03', '2023-11-24', //2024
'2024-07-03', '2024-11-29', '2024-12-24', //2025
'2025-07-03', '2025-11-28', '2025-12-24']);