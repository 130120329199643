import React from 'react';

export type Admin = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isAtomAdmin?: boolean;
  accessStatus?: boolean;
};

export type Partner = {
  id: string;
  name: string;
  language: string;
  expiry: Date;
  admins: Admin[];
};

type AdminContext = {
  admin: Admin | undefined;
  partner: Partner | undefined;
  updateAdminContext: (a) => void;
  onFinishLogin: () => void;
};

export const defaultAdminContext: AdminContext = {
  admin: undefined,
  partner: undefined,
  updateAdminContext: a => {},
  onFinishLogin: () => {},
};

export const AdminContext = React.createContext(defaultAdminContext);
