"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionTerm = void 0;
var SubscriptionTerm;

(function (SubscriptionTerm) {
  SubscriptionTerm["MONTHLY"] = "monthly";
  SubscriptionTerm["ANNUAL"] = "annual";
})(SubscriptionTerm = exports.SubscriptionTerm || (exports.SubscriptionTerm = {}));